import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class AdvantageOfPlatformDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Advantages: [{
                title: `Make it Simple`,
                text: `Experience seamless platform implementation from on-boarding to portfolio selection to tax optimization.`
            }, {
                title: `Lower Your Management Fees`,
                text: `Gain institutional quality strategies at aggressively low rates.`
            }, {
                title: `Empower Yourself`,
                text: `Leave Client accounts where they are, execute anyway you prefer. Customize as much or little as you like.`
            }, {
                title: `Be Dynamic`,
                text: `Help clients reduce risk, protect capital and grow assets through AR’s fully-automated dynamic asset allocation model.`
            }, {
                title: `Increase Your Potential`,
                text: `Provide any size client with hedge fund strategies without minimums or lockups.`
            }, {
                title: `Keep it Transparent`,
                text: `Keep clients continually updated on performance, risk, costing and more, anytime/anywhere.`
            }, {
                title: `Free up Time`,
                text: `Start spending more time with clients and less time managing investments.`
            }, {
                title: `Be Completely Liquid`,
                text: `Highly liquid broad-based index ETFs totally controlled by you in separate accounts makes getting in and out fast and easy.`
            },]
        }
    }


    render() {
        return (
            <Container>
                <Row><p className="platform-advantage-title">The AllocateRite Advantage</p></Row>
                <Row className="platform-advantage-details">
                {this.state.Advantages.map(advantage =>
                    <Col key={advantage.title} sm={6}>
                        <p className="platform-advantage-detail-title">{advantage.title}</p>
                        <p className="platform-advantage-details-text">{advantage.text}</p>
                    </Col>
                )}
                </Row>
            </Container>
        )
    }
}

export default AdvantageOfPlatformDetails
