import React, { Component } from "react";
import { Card, Row, Button, Form, } from "react-bootstrap";
import { FaArrowLeft, FaSearch, FaTimes, FaQuestionCircle } from "react-icons/fa";
import SingleCompanyCard from "../singleCompanyCard"
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Youtube from 'react-youtube'
// import Comms_list from "../../assets/homeIcons/comms_list.svg"
// import Crypto  from "../../assets/homeIcons/crypto.svg"
// import Recovery  from "../../assets/homeIcons/recovery.svg"
// import Semiconductor  from "../../assets/homeIcons/semiconductor.svg"
import Japan  from "../../assets/homeIcons/japan.svg"
import India  from "../../assets/homeIcons/india.svg"
import Germany  from "../../assets/homeIcons/germany.svg"
import China  from "../../assets/homeIcons/china.svg"
import Brazil  from "../../assets/homeIcons/brazil.svg"
import ARlogo from "../../assets/ar_logo.png"
import ForeSciteNoText from "../../assets/newUI/ForesciteNoText.png"
import Explore from "../../assets/newUI/explore.png"
import Crypto from "../../assets/newUI/crypto.png"
import Recovery from "../../assets/newUI/profits.png"
import Comms_list from "../../assets/newUI/global.png"
import Semis from "../../assets/newUI/semis.png"
import Cannabis from "../../assets/newUI/cannabis.png"
import EV from "../../assets/newUI/ev.png"
import Energy from "../../assets/newUI/energy.png"
import Finance from "../../assets/newUI/finance.png"
import Healthcare from "../../assets/newUI/healthcare.png"
import SaaS from "../../assets/newUI/saas.png"
import Active from "../../assets/newUI/active.png"
import Etf from "../../assets/newUI/etf.jpg"
import NoImage from "../../assets/newUI/logo_notfound.png"

import ENDPOINT from '../Endpoint'

import { NavLink, /*Redirect*/ } from "react-router-dom"
import SearchCard from "./SearchCard"

const brandColor = "#00A99D"

const THEME = {
    'SelectTheme': 'Choose an Investment Theme',
    "Brazil": 'Brazil',
    "Cannabis": 'Cannabis',
    "China": 'China',
    "Communications": 'Communications',
    "Crypto": 'Crypto',
    "EV": 'Electronic Vehicles ',
    "ETFs": 'ETFs',
    "Energy": 'Energy',
    "FAANG": 'FAANG',
    "Finance": 'Finance',
    "Germany": 'Germany',
    "Healthcare": 'Healthcare',
    "India": 'India',
    "Japan": 'Japan',
    "most_active": 'Most Active Stocks Today',
    "Recovery": 'Recovery',
    "SaaS": 'Software as a Service',
    "Semis": 'Semiconductors',
    "Technology":'Technology',
    
}

const THEMEDESC = {
    'SelectTheme': 'Investment Themes',
    "Brazil": 'A collection of the largest companies based in Brazil.',
    "Cannabis": 'Also known as "pot stocks” which cover a range of applications across the entire supply chain. From consumer goods focused on the emerging CBD trend to cannabis-focused bio-tech. this theme includes stocks such as  like Aurora Cannabis and Canopy Growth, to heritage brands like ScottsMiracle-Gro.',
    "China": 'A collection of the largest companies based in China',
    "Communications": 'The communications sector is comprised of a broad range of companies that sell phone and Internet services via traditional landline, broadband, or wireless.',
    "Crypto": 'These are crypto curriencies with their value shown in US Dollars.',
    "EV": 'Companies who have a direct impact on Electric Vehicles. These companies can involved in  creating the batteries, the car itself, or other specific parts of electric vehicles.',
    "Energy": 'A stock in a company whose predominant business is the production or sale of energy. Energy stock may include shares in both upstream companies, such as oil exploration firms, and downstream companies, such as oil refineries.',
    "FAANG": 'FAANG is an acronym referring to the stocks of the five most popular and best-performing American technology companies: Facebook, Amazon, Apple, Netflix and Google (now known as Alphabet).',
    "Finance": 'A wide range of companies involved in retail and commercial banking, accounting, insurance, asset management, credit cards, and brokerage. Well-known companies in the sector include JP Morgan, Bank of America, Goldman Sachs Group and more.',
    "Germany": 'A collection of the largest companies based in Germany.',
    "Healthcare": 'Healthcare stocks fall into six categories: pharmaceuticals, biotechnology, medical equipment, sales, insurance, and facilities. While the healthcare industry has good growth prospects and is often economy-proof, it also carries some unique investment risks.',
    "India": 'A collection of the largest companies based in India.',
    "Japan": 'A collection of the largest companies based in Japan.',
    "most_active": 'This list represents the 20 most actively traded stocks. Updated daily.',
    "Recovery": "Companies that benefit from both an increase in revenue as we proceed toward a general reopening of the economy, as well as from an influx of retail investors' stimulus money, are kind of the ultimate recovery stocks.",
    "SaaS": 'Software as a service is a software licensing and delivery model in which software is licensed on a subscription basis and is centrally hosted. It is sometimes referred to as "on-demand software.',
    "Semis": 'The term SaaS stands for Software as a Service. SaaS companies are centrally-hosted and tend to be subscription-based, which enables many of them to combine a relatively low product delivery cost with a recurring revenue model. The companies that make up this theme—Oracle, Salesforce, and Shopify to name a few—are helping to power thousands of enterprise, mid-sized, and small businesses globally.',
    "Technology":'Any stock involved in the technology sector, from semiconductor producers to software providers. Tech stocks are often a leading indicator for the economy and the stock market.',
    
}

export default class PriceTrendPredictor extends Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.state = {
            searchMomentumText: "",
            isSmallScreen: false,
            momDataSource: [],
            isMomDataLoading: false,
            showMore: true,
            selectedPreset: null,
            presetTickers: null,
            showVideo: false,
            hideHowTo: false,
            themeDesc: {}
        };
    }

    

    componentDidMount() {
        this.fetchCommonStocks()
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        this.input.current.focus();
        // document.title = `AllocateRite - Search`;
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    setSelectedPreset = (sector)=>{
        if(sector !== 'SelectTheme'){

            let selectedPreset
            if (this.state.presetTickers) {
                if (this.state.selectedPreset === sector){
                    selectedPreset = null
                } 
                else{
                    selectedPreset = sector
                }
                this.setState({selectedPreset, searchMomentumText:''})
            }
        }
    }

    updateWindowDimensions = () => {
        if (window.innerWidth < 1000) {
            this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
        } else {
            this.setState({ isSmallScreen: false, screenWidth: window.innerWidth });
        }
    };

    // fetchPresetTickers = ()=>{
    //     let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
    //     fetch(url, {
    //         "Access-Control-Allow-Headers": {
    //             "Content-Type": "application/json",
    //             "Cache-Control": "no-cache",
    //             "Access-Control-Request-Headers": "*",
    //             "Access-Control-Allow-Origin": "*",
    //             "Access-Control-Allow-Methods": "*",
    //         },
    //     })
    //         .then((res) => res.json())
    //         .then((responseJson)=>{
    //             this.setState({presetTickers: responseJson.lists})
    //         })
        
    // }

    fetchCommonStocks = ()=>{
        // let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
        let url = `${ENDPOINT}/search/ticker-list`
        fetch(url)
            .then((res) => res.json())
            .then((responseJson)=>{
                // console.log('keys', Object.keys(responseJson.lists))
                this.setState({presetTickers: responseJson.lists, themeDesc: responseJson.descriptions})
            })
    }

    createBox = (ele)=>{
        let url = `/CompanyPage/${ele.symbol.toUpperCase()}`
        return (
            <NavLink exact={true} to={url}>
                <div 
                onMouseEnter={()=>this.setState({hover:ele})}
                onMouseLeave={()=>this.setState({hover:''})}
                style={{width:125,height:200, marginBottom:10, marginRight:15}}
                // style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
            >
                <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:this.state.hover === ele? 'rgb(225,225,225)':'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:125, height:125,justifySelf:'flex-end',border:'solid',borderWidth:'0.25px', borderColor:'rgb(17,17,17)' }}>
                    {ele.imageURL === "not-found"?
                        <div style={{objectFit:'contain', width:50,height:50,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(17,17,17)',borderRadius:10 }}
                        >
                            <p style={{margin:0, fontSize:24,fontWeight:'bold', color:'rgb(255,255,255)'}}>{ele.symbol[0]}</p>
                        </div>
                    :
                        <img 
                            // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
                            src={ele.imageURL}
                            style={{objectFit:'contain', width:80,height:80,padding:10, }}
                        />
                    }
                </div>
                <div style={{display:'flex', flexDirection: 'column', width:'100%', padding:10 }}>
                    <p style={{ margin: 0, textAlign: 'center', fontWeight:'bold', color:'rgb(17,17,17)' }}>{ele.symbol}</p>
                    <p style={{ margin: 0, textAlign: 'center', fontWeight:'bold', color:'rgb(17,17,17)',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>{ele.company}</p>
                    {/* <p style={{ margin: 0, textAlign: 'center',overflow: 'hidden',  textOverflow: 'ellipsis' }}>{ele.company}</p> */}
                </div>
            </div>
            </NavLink>
            )
        }

    
    onSubmitSearchMomentum = () => {
        const { searchMomentumText } = this.state;
        this.setState({ isMomDataLoading: true });
        // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
        let url = `${ENDPOINT}/search/ticker?search=${searchMomentumText.toUpperCase()}`;
        fetch(url)
            .then((res) => res.json())
            .then((responseJson) => {
                var data = responseJson.content;
                this.setState({
                    isMomDataLoading: false,
                    momDataSource: data,
                });
            })
            .catch((err) => {
                this.setState({ isMomDataLoading: false });
                console.log(err);
            });
    };

    howToBox = ()=>{
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: '#F2F2F999', padding:15, borderRadius: 15, width: '100%'}}>
                <a href='https://www.youtube.com/watch?v=8KNaR9o-Qik' style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(109,105,173)'}}>How to use the Stock Price Trend Predictor</a>
                {/* {this.state.showMore? */}
                <p style={{fontSize: 14}}>
                        Try using the AllocateRite Stock Price Trend Predictor to help you with your short-term trading. Short-Term trading is highly risky, and a lot of traders based their trading on unreliable or outdated information. Instead, you can leverage the power of AllocateRite's Data Science and Predictive Analytics to help forecast short-term Price Trends.
                {/* <strong onClick={() => this.setState({ showMore: false })} style={{ color: '#00A99D', cursor: 'pointer', }}> Show less...</strong> */}
                </p>
                {/* :
                <p style={{fontSize: 14}}>
                        Try using the AllocateRite Stock Price Trend Predictor to help you with your short-term trading. Short-Term trading is highly risky, <strong onClick={() => this.setState({ showMore: true })} style={{ color: '#00A99D', cursor: 'pointer',}}> Show more...</strong>
                </p>} */}
            </div>
        )
    }

    individuleCompanyCard = (location, sector) => {
        let columns = window.innerWidth / 280
        let data
        if (location === 'search'){
            data = this.state.momDataSource;
        } else{
            data = this.state.presetTickers[sector]
        }
        let mapped = data.map(item=>this.createBox(item))
        return (
            <div
                style={{
                    padding: "2%",
                    textDecoration: "none",
                    display:'flex',
                    flexWrap:'wrap',                    
                }}
            >
                <div style={{width:'100%',display:'flex',alignItems:'center',flexWrap:'wrap', justifyContent:this.props.isSmallScreen?'center':''}}>
                    {data.length >0 ?  mapped : <p>No Results Found</p>}      
                </div>    
            </div>
        );
    };

    appLinks(){
        return(
            <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: 50, marginTop: 10}}>
                <a href="https://apps.apple.com/us/app/allocaterite/id1478461135">
                    <img
                        className="features-store-download"
                        style={{ height: 50, width: 'auto' }}
                        src="../images/app_download_icon.png"
                        alt="apple-store"
                    />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.allocaterite&hl=en_US">
                    <img
                        className="features-store-download"
                        style={{height: 50, width: 'auto'}}
                        src="../images/google_download_icon.png"
                        alt="google-store"
                    />
                </a>
            </div>
        )
    }

    presetIcons = ()=>{
        const {isSmallScreen} = this.props
        if(isSmallScreen){
            if(this.state.presetTickers){
                let arr = ['SelectTheme', ...Object.keys(this.state.presetTickers)]
                let mapped = []
               arr.forEach(option=>{
                    mapped.push(
                        <option value={option} selected={this.state.theme == option}>{THEME[option]}</option>
                    ) 
                })
                return(
                    <div style={{width:'100%', display:'flex',justifyContent:'center', alignItems:'center'}}>
                        <select placeholder="Select Below" onChange={(e)=>this.setSelectedPreset(e.target.value)} value={this.state.selectedPreset}>
                            {mapped}
                        </select>
                    </div>
                )
            }
            else{
                return null
                return (
                    <div style={{display: 'flex', flexDirection: 'column', width: '60%', margin:'auto'}}>
                        <p style={{margin:'auto',width:'100%', color:'#878b95', textAlign:'center', color:'rgb(109,105,173)', marginBottom:8, fontWeight:'bold'}}>Investing Themes</p>
                        
                        <div style={{display: 'flex', justifyContent: 'center',flexWrap:'wrap', width: '80%',margin:'auto',marginBottom:5}}>
                            <div 
                                style={{display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2}}
                                onClick={() => this.setSelectedPreset("most_active")}
                            >
                                <img
                                src={Active}
                                style={{height: 20, marginRight: 10}}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>TOP 30</p> 
                            </div>
                            <div 
                                style={{display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2}}
                                onClick={() => this.setSelectedPreset('Communications')}
                            >
                                <img
                                src={Comms_list}
                                style={{height: 20, marginRight: 10}}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>COMMS</p> 
                            </div>
                            <div 
                                style={{display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2}}
                                onClick={() => this.setSelectedPreset('Recovery')}
                            >
                            <img
                            src={Recovery}
                            style={{height: 20, marginRight: 10}}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>RECOVERY</p> 
                            </div>
                            <div 
                                style={{display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2}}
                                onClick={() => this.setSelectedPreset('Semis')}
                            >
                            <img
                            src={Semis}
                            style={{height: 20, marginRight: 10}}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>SEMIS</p> 
                            </div>
                            <div 
                                style={{display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2}}
                                onClick={() => this.setSelectedPreset('Crypto')}
                            >
                            <img
                            src={Crypto}
                            style={{height: 20, marginRight: 10}}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>CRYPTO</p> 
                            </div>
                        
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('ETF')}
                            >
                                <img
                                    src={Etf}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>ETF</p>
                            </div>
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('Finance')}
                            >
                                <img
                                    src={Finance}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>FINANCE</p>
                            </div>
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('Cannabis')}
                            >
                                <img
                                    src={Cannabis}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>CANNABIS</p>
                            </div>
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('EV')}
                            >
                                <img
                                    src={EV}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>EV</p>
                            </div>
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('Energy')}
                            >
                                <img
                                    src={Energy}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>ENERGY</p>
                            </div>
                            
                            
                        
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('Healthcare')}
                            >
                                <img
                                    src={Healthcare}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>HEALTHCARE</p>
                            </div>
                        
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('SaaS')}
                            >
                                <img
                                    src={SaaS}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>SaaS</p>
                            </div>
                        
                        
                            
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('Brazil')}
                            >
                                <img
                                    src={Brazil}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>BRAZIL</p>
                            </div>
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('India')}
                            >
                                <img
                                    src={India}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>INDIA</p>
                            </div>
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('Japan')}
                            >
                                <img
                                    src={Japan}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>JAPAN</p>
                            </div>
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('China')}
                            >
                                <img
                                    src={China}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>CHINA</p>
                            </div>
                            
                        
                            <div
                                style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                                onClick={() => this.setSelectedPreset('Germany')}
                            >
                                <img
                                    src={Germany}
                                    style={{ height: 20, marginRight:10 }}
                                />
                                <p style={{margin:0, color:'#878b95', fontSize:12}}>GERMANY</p>
                            </div>
                        
                        </div>
                    </div>
                )
            }

        }else{

            return (
                <div style={{display: 'flex', flexDirection: 'column', width: '60%', margin:'auto'}}>
                    <p style={{margin:'auto',width:'100%', color:'#878b95', textAlign:'center', color:'rgb(82,140,139)', marginBottom:8, fontWeight:'bold'}}>Investing Themes</p>
                    
                    <div style={{display: 'flex', justifyContent: 'center', width: '90%',margin:'auto',marginBottom:5}}>
                        <div 
                            style={{display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2}}
                            onClick={() => this.setSelectedPreset("most_active")}
                        >
                            <img
                            src={Active}
                            style={{height: 20, marginRight: 10}}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>TOP 30</p> 
                        </div>
                        <div 
                            style={{display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2}}
                            onClick={() => this.setSelectedPreset('Communications')}
                        >
                            <img
                            src={Comms_list}
                            style={{height: 20, marginRight: 10}}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>COMMS</p> 
                        </div>
                        <div 
                            style={{display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2}}
                            onClick={() => this.setSelectedPreset('Recovery')}
                        >
                        <img
                        src={Recovery}
                        style={{height: 20, marginRight: 10}}
                        />
                        <p style={{margin:0, color:'#878b95', fontSize:12}}>RECOVERY</p> 
                        </div>
                        <div 
                            style={{display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2}}
                            onClick={() => this.setSelectedPreset('Semis')}
                        >
                        <img
                        src={Semis}
                        style={{height: 20, marginRight: 10}}
                        />
                        <p style={{margin:0, color:'#878b95', fontSize:12}}>SEMIS</p> 
                        </div>
                        
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('Finance')}
                        >
                            <img
                                src={Finance}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>FINANCE</p>
                        </div>
                        <div 
                            style={{display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2}}
                            onClick={() => this.setSelectedPreset('Crypto')}
                        >
                            <img
                            src={Crypto}
                            style={{height: 20, marginRight: 10}}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>CRYPTO</p> 
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', width: '90%',margin:'auto',marginBottom:5}}>
                        
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('Healthcare')}
                        >
                            <img
                                src={Healthcare}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>HEALTHCARE</p>
                        </div>
                        
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('ETFs')}
                        >
                            <img
                                src={Etf}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>ETFs</p>
                        </div>
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('Cannabis')}
                        >
                            <img
                                src={Cannabis}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>CANNABIS</p>
                        </div>
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('EV')}
                        >
                            <img
                                src={EV}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>EV</p>
                        </div>
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('Energy')}
                        >
                            <img
                                src={Energy}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>ENERGY</p>
                        </div>
                        
                        
                       
                        
                       
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('SaaS')}
                        >
                            <img
                                src={SaaS}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>SaaS</p>
                        </div>
                       
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', width: '90%',margin:'auto',marginBottom:5}}>
                        
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('Brazil')}
                        >
                            <img
                                src={Brazil}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>BRAZIL</p>
                        </div>
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('India')}
                        >
                            <img
                                src={India}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>INDIA</p>
                        </div>
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('Japan')}
                        >
                            <img
                                src={Japan}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>JAPAN</p>
                        </div>
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('China')}
                        >
                            <img
                                src={China}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>CHINA</p>
                        </div>
                        
                       
                        <div
                            style={{ display: 'flex', cursor: 'pointer',borderRadius:5,border:'solid',borderColor:'#dddddd', padding:5,width:'100%',justifyContent:'center',alignItems:'center', marginLeft:2,marginRight:2 }}
                            onClick={() => this.setSelectedPreset('Germany')}
                        >
                            <img
                                src={Germany}
                                style={{ height: 20, marginRight:10 }}
                            />
                            <p style={{margin:0, color:'#878b95', fontSize:12}}>GERMANY</p>
                        </div>
                      
                    </div>
                </div>
            )
        }
    }

    showTickers = (sector)=>{
        const {
            isSmallScreen,
            isMomCurSelected,
        } = this.state;

        let loadingdiv = (
            <div
                style={{
                    height: 300,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <p>loading...</p>
            </div>
        );
        return (
            <Card
                style={{
                    width: "100%",
                    marginTop: 20,
                    backgroundColor:'rgb(246,245,253)',
                    border:'none'
                }}
            >
                <div style={{display: 'flex',flexDirection: 'column', alignItems: 'center',justifyContent:'center' }}>
                    {this.state.selectedPreset && !this.props.isSmallScreen?
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold',fontSize:'28px',textAlign:'center'}}>
                        {THEME[this.state.selectedPreset]}
                    </p>
                    :null}
                    {this.state.selectedPreset && !this.props.isSmallScreen?
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'normal',fontSize:'22px',textAlign:'center'}}>
                        {this.state.themeDesc[this.state.selectedPreset]}
                    </p>
                    :null}
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'normal'}}>
                        Select a ticker for more information 
                    </p>
                </div>
                {/* <div style={{display:'flex', justifyContent: 'space-around', paddingTop: 10,paddingLeft: 10, paddingRight: 10, paddingBottom: 0, marginBottom:0 }}>
                    <p style={{ margin:0, fontWeight: 'bold',color: '#a8a6a5'}}> Trending Even</p>
                    <p style={{ margin:0, fontWeight: 'bold',color: '#25d09c'}}> Trending Up</p>
                    <p style={{ margin:0, fontWeight: 'bold',color: '#e31111'}}> Trending Down</p>
                </div>*/}
                <div>
                    {this.individuleCompanyCard('x', sector) }
                    {/* <SearchCard /> */}
                </div>
                
            </Card>
        );
    }

    trendPredictordiv = () => {
        const {
            isMomDataLoading,
            momDataSource,
            searchMomentumText,
            isSmallScreen,
            isMomCurSelected,
        } = this.state;

        return (
            <Card
                style={{
                    width: "100%",
                    marginTop: 5,
                    backgroundColor:'rgb(246,245,253)',
                    border:'none'

                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold' }}>
                        select a ticker for more information
                    </p>
                </div>
                <div>
                    {this.displayMomResults()}
                </div>
            </Card>
        );
    };

    displayMomResults = ()=>{
        const {
            isMomDataLoading,
            momDataSource,
            searchMomentumText,
            isSmallScreen,
            isMomCurSelected,
        } = this.state;
        if (isMomDataLoading === false && momDataSource.length > 0){
            return this.individuleCompanyCard('search', '')
        } else if (isMomDataLoading === false){
            return(
            < div
                style = {{
                    height: 300,
                    paddingTop: isSmallScreen ? 80 : null,
                    paddingBottom: isSmallScreen ? 80 : null,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h5
                    style={{
                        margin: isSmallScreen ? 40 : 20,
                        color: "#808080",
                        fontSize: isSmallScreen ? 15 : 18,
                    }}
                >
                    {searchMomentumText.length < 2
                        ? null
                        : "no results try a different ticker or company name"}
                </h5>
            </div >
            
            )
        }
        else{
            return (
                <div
                    style={{
                        height: 300,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <p>loading...</p>
                </div>
            )
        }
    }

    render() {
        return (
            <div style={{}}>
                <div
                    style={{
                        paddingTop: 10,
                        // backgroundColor: '#F2F2F999',
                        height: '100%',
                        width:'100%',
                        borderRadius: '50px', backgroundColor:'rgb(255,255,255)'
                    }}
                >
                   <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginBottom:20, marginTop: 20,padding: this.props.isSmallScreen? 10:null  }}>
                       <img
                            src={ForeSciteNoText}
                            style={{height:40, marginRight: this.props.isSmallScreen?0:10}}
                        />
                        <p style={{margin:0, fontSize: 24, textAlign:'center'}}>Explore ForeSCITE Technologies</p>
                   </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            flex: 1,
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: 'rgb(255,255,255)', width: '75%', marginBottom: 20, borderRadius: 20, paddingLeft: 10, display: 'flex', justifyContent: 'flex-start', boxShadow: this.state.hover ? '0 0 10px rgb(223,225,229)' : 'none', borderColor: 'rgb(237,238,240)', borderStyle: 'solid', borderWidth: 'thin', minWidth: 250, 
                            }}
                            onMouseEnter={() => this.setState({ hover: true })}
                            onMouseLeave={() => this.setState({ hover: false })}
                        >
                            <div style={{ minWidth: 20, width: '5%', /*backgroundColor: '#00A99D',*/ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                                <FaSearch size={'50%'} style={{color: '#dddddd'}} />                        
                            </div>
                            <input
                                type="text"
                                style={{ width: '90%', border: 'none', outline: 'none', paddingTop: 5, paddingBottom: 5}}
                                value={this.state.searchMomentumText}
                                onChange={(event) => {
                                    let searchMomentumText = event.target.value;
                                    this.setState({ searchMomentumText, selectedPreset: null }, () => {
                                        const { isSearching } = this.state;
                                        if (isSearching) {
                                            return;
                                        } else {
                                            this.setState({ isSearching: true });
                                            setTimeout(() => {
                                                this.setState(
                                                    { isSearching: false },
                                                    this.onSubmitSearchMomentum
                                                );
                                            }, 2000);
                                        }
                                    });
                                }}
                                onFocus={() => {
                                    this.setState({ showTrendPredictor: true });
                                }}
                                placeholder="Enter stock symbol or company name"
                                ref={this.input}
                            />
                            {/* {!this.state.isSmallScreen?
                                <div style={{display:'flex', justifyContent:'center', alignContent:'center', backgroundColor: 'rgb(247,247,251)', cursor: 'pointer'}} onClick={()=>this.setState({showVideo:!this.state.showVideo})}>
                                    <p style={{margin: 0, paddingLeft:10, paddingRight:10, marginTop: 5, fontWeight:'bold', color: brandColor}}>
                                        Video
                                    </p>
                                </div>
                                :null
                            } */}
                            
                            </div>
                            <div style={{width:'100%',display:'flex'}}>
                            {this.presetIcons()}

                            </div>
                           
                    </div>
                    {this.state.momDataSource || this.state.selectedPreset? null:
                        <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', marginTop: 40, marginBottom: 10,}}>
                            {this.howToBox()}
                        </div>
                    }
                    {/* <div style={{marginTop:20}}>
                        {this.appLinks()}
                    </div> */}
                    <div style={{height: 80}}/>
                    {/* <div style={{ width: '80%', margin: 'auto', borderRadius: 10 }}>
                        <Footer/>
                    </div> */}
                </div>
                <div style={{width:'100%', margin:'auto'}}>
                    {this.state.selectedPreset ? this.showTickers(this.state.selectedPreset):null }
                    {this.state.searchMomentumText.length > 0 ?this.trendPredictordiv():null }
                </div>
            </div>
        );
    }
}
