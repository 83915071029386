import React from 'react'
import { PlaidLink } from 'react-plaid-link';
import ENDPOINT from './Endpoint'
const brandColor = "#00A99D"

export default class LinkToPlaid extends React.Component{
    state={
        respMeta:false,
        respToken:false
    }

    findOtherPortfoliosSameInstitution = (instName)=>{
        console.log('looking for others')
        let itemId = this.getItemId(instName)
        fetch(`${ENDPOINT}/plaid/accounts?unconnectedAccounts=true&itemId=${itemId}`,{
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
              },
        })
        .then(res => res.json())
        .then((respJSON)=> {
            console.log('respJSON: ', respJSON)
            this.setState({sameInst:{...this.state.sameInst, [this.state.selectedInst]:respJSON}})
        })
        .catch(err=>console.log('catching: ', err))

    }

    getItemId = (instName)=>{
        let id = ''
        this.props.linkedPorts.forEach(ele=>{
            if (ele.instName === instName){
                id = ele.itemId
            }
        })
        return id
    }

    addOtherPortSameInstituion = ()=>{
        let getbody = []
        this.state.sameInst[this.state.selectedInst].forEach(ele=>{
            if(ele.checked){
                getbody.push({
                    "id": ele.itemId,
                    "itemId": this.getItemId(),
                    "mask" : ele.mask,
                    "name" : ele.name
                })
            }
        })
        fetch(`${ENDPOINT}/plaid/accounts/add`,{
            method: "POST",
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
              },
            body:JSON.stringify(getbody)
        }) 
        .then(res => res.json())
        .then(responseJson => console.log(responseJson))
        .catch(err=>console.log(`catching errors`, err))
    }

    handleOnSuccess = (public_token, metadata)=> {
        // send token to client server
        // fetch("/auth/public_token", {
        //   public_token: public_token
        // });
        let accountId = metadata.account_id
        let itemId = metadata.institution.institution_id
        this.linkAccount(public_token, metadata)
        // let resp = this.findOtherPortfoliosSameInstitution(itemId)
        // if (resp.length > 0){
        //     this.addOtherPortSameInstituion(accountId, itemId) 
        // }
        this.setState({respMeta:metadata, respToken:public_token})
    }
    
      handleOnExit() {
        console.log('handling exit')
        window.location.reload()

        // handle the case when your user exits Link
        // For the sake of this tutorial, we're not going to be doing anything here.
    }
    
      handleClick(res) {
        console.log('handling Click',res)
        // fetch("/transactions").then(res => {
        //   this.setState({ transactions: res.data });
        // });
      }



    linkAccount = (token, meta)=>{
        let id = meta.account_id
        let mask = meta.account.mask
        let name = meta.account.mask

        let inst_id = meta.institution.institution_id
        let inst_name = meta.institution.name
        let public_token = token

        let body = { 
            accounts:[ {  id, mask, name } ],
            inst_id,
            inst_name,
            public_token,
            type:this.props.bank? "depository":"investment",
            // env:
        }
        // console.log('body: ', body)

        // fetch(`${ENDPOINT}/plaid/link`,{
        fetch(`https://api.allocaterite.com/mobile-plaid/plaid/link`,{
            method:'POST',
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(respJSON=>console.log('respJSON: ', respJSON))
        .catch(err=>console.log('catching: ', err))
    }

    
    

    render(){
        return(
            <div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <PlaidLink
                        clientName="AllocateRite"
                        // env="sandbox"
                        env="production"
                        product={this.props.bank? ["transactions"]:["investments"]}
                        publicKey="3e65a03171afbab180fa7198f87cef"
                        onExit={this.handleOnExit}
                        onSuccess={this.handleOnSuccess}
                        linkCustomizationName={this.props.bank? 'bank':'brokerage'}
                        // className="test"
                        // style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold'}}
                        style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer',}}
                    >
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold'}}>
                                {this.props.numAccounts > 0? `An account from a new institution` : `link an account`}
                            </p>
                    </PlaidLink>
                </div>
            </div>
        )
        
    }
}