import React from 'react'
import PublicHeader from '../PublicHeader'
import Footer from '../Footer';

import ENDPOINT from '../Endpoint'
import Spinner from 'react-bootstrap/Spinner'

import history from '../History'
import CreateDwAcc from '../dashboard/CreateDwAcc';

export default class Questionnaire extends React.Component{

    state = {
        dob: 'yyyy-mm-dd',
        reason:false,
        timeframe:false,
        risk:false,
        experience:false,
    }    

    toAccount = ()=>{
        window.location.replace("/Dashboard/Account")
    }

    submitQuestionaire = ()=>{
        this.setState({submitClicked:true})
        let dob = this.state.dob.split("-")

        // console.log('submittings Questionnaire !!')
       fetch(`${ENDPOINT}/dw/quiz`,{
        method: "POST",
        headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            Authorization: "Bearer " + this.props.user.idToken.jwtToken,
        },
        body:JSON.stringify({
            birthdayDay: dob[2],
            birthdayMonth: dob[1],
            birthdayYear: dob[0],
            investmentMainReason: this.state.reason,
            investmentPeriod: this.state.timeframe,
            riskTolerance: this.state.risk,
            investmentExperience: this.state.experience,
            investmentObjectives: null
        })
       })
       .then(response => response.json())
       .then(respJSON => {
           if(respJSON.success){
              this.toAccount()
           }
           else{
               console.log('missing field')
           }
       })

    }

    questions = ()=>{
        return(
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{width:'95%', margin:'auto',marginBottom:25}}>
                    <h4 style={{color:'rgb(17,17,17)'}}>Investment Questionnaire</h4>
                </div>
                <div style={{width:'95%', margin:'auto'}}>
                    <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                        <p style={{fontSize:20}}>What is your date of birth?</p>
                        <input type="date" value={this.state.dob} onChange={(e)=>{this.setState({dob:e.target.value})}}/>
                    </div>
                    <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                        <p style={{fontSize:20}}>What is the main reason for your investment?</p>
                        <input type="radio" id="SHORT_TERM" name="reason" checked={this.state.reason === "SHORT_TERM"} onChange={()=>this.setState({reason:'SHORT_TERM'})}/>
                        <label style={{marginLeft: 5,}}>Short Term (Emergency Cash Fund)</label><br/>
                        <input type="radio" id="MEDIUM_TERM" name="reason" checked={this.state.reason === "MEDIUM_TERM"} onChange={()=>this.setState({reason:'MEDIUM_TERM'})}/>
                        <label style={{marginLeft: 5,}}>Medium Term (Down Payment)</label><br/>
                        <input type="radio" id="LONG_TERM" name="reason" checked={this.state.reason === "LONG_TERM"} onChange={()=>this.setState({reason:'LONG_TERM'})}/>
                        <label style={{marginLeft: 5,}}>Long Term (College or Retirement Fund)</label>
                    </div>
                    <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                        <p style={{fontSize:20}}>What is your prior investment experience?</p>
                        <input type="radio" id="NONE" name="experience" checked={this.state.experience==="NONE"} onChange={()=>this.setState({experience:'NONE'})}/>
                        <label style={{marginLeft: 5,}}>No experience</label><br/>
                        <input type="radio" id="SOME" name="experience" checked={this.state.experience==="SOME"} onChange={()=>this.setState({experience:'SOME'})}/>
                        <label style={{marginLeft: 5,}}>Some experience</label><br/>
                        <input type="radio" id="CONSIDERABLE" name="experience" checked={this.state.experience==="CONSIDERABLE"} onChange={()=>this.setState({experience:'CONSIDERABLE'})}/>
                        <label style={{marginLeft: 5,}}>Considerable experience</label>
                    </div>
                    <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                        <p style={{fontSize:20}}>What is your risk tolerance?</p>
                        <input type="radio" id="LOW" name="risk" checked={this.state.risk === "LOW"} onChange={()=>this.setState({risk:'LOW'})}/>
                        <label style={{marginLeft: 5,}}>Minimize Loses</label><br/>
                        <input type="radio" id="MEDIUM" name="risk" checked={this.state.risk === "MEDIUM"} onChange={()=>this.setState({risk:'MEDIUM'})}/>
                        <label style={{marginLeft: 5,}}>Maintain gains while limiting losses</label><br/>
                        <input type="radio" id="GAINS" name="risk" checked={this.state.risk === "GAINS"} onChange={()=>this.setState({risk:'GAINS'})}/>
                        <label style={{marginLeft: 5,}}>Maximize Gains</label>
                    </div>
                    {/* <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                        <p style={{fontSize:20}}>How do you plan on using this for?</p>
                        <input type="radio" id="LONG_TERM" name="investmentObjectives" checked={this.state.investmentObjectives === "LONG_TERM"} onChange={()=>this.setState({investmentObjectives:'LONG_TERM'})}/>
                        <label style={{marginLeft: 5,}}>Long Term Investing</label>
                        <input type="radio" id="INFREQUENT" name="investmentObjectives" checked={this.state.investmentObjectives === "INFREQUENT"} onChange={()=>this.setState({investmentObjectives:'INFREQUENT'})}/>
                        <label style={{marginLeft: 5,}}>Infrequent Investing</label><br/>
                        <input type="radio" id="FREQUENT" name="investmentObjectives"checked={this.state.investmentObjectives === "FREQUENT"} onChange={()=>this.setState({investmentObjectives:'FREQUENT'})}/>
                        <label style={{marginLeft: 5,}}>Regular Trading</label><br/>
                        <input type="radio" id="ACTIVE_DAILY" name="investmentObjectives" checked={this.state.investmentObjectives === "ACTIVE_DAILY"} onChange={()=>this.setState({investmentObjectives:'ACTIVE_DAILY'})}/>
                        <label style={{marginLeft: 5,}}>Day Trading</label><br/>
                        <input type="radio" id="NEW" name="investmentObjectives" checked={this.state.investmentObjectives === "NEW"} onChange={()=>this.setState({investmentObjectives:'NEW'})}/>
                        <label style={{marginLeft: 5,}}>10 years or More</label><br/>
                    </div> */}
                    <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                        <p style={{fontSize:20}}>How long do you plan on investing?</p>
                        <input type="radio" id="1" name="timeframe" checked={this.state.timeframe === "1"} onChange={()=>this.setState({timeframe:'1'})}/>
                        <label style={{marginLeft: 5,}}>Less than 1 Year</label><br/>
                        <input type="radio" id="3" name="timeframe" checked={this.state.timeframe === "3"} onChange={()=>this.setState({timeframe:'3'})}/>
                        <label style={{marginLeft: 5,}}>3 years</label><br/>
                        <input type="radio" id="5" name="timeframe"checked={this.state.timeframe === "5"} onChange={()=>this.setState({timeframe:'5'})}/>
                        <label style={{marginLeft: 5,}}>5 years</label><br/>
                        <input type="radio" id="10" name="timeframe" checked={this.state.timeframe === "10"} onChange={()=>this.setState({timeframe:'10'})}/>
                        <label style={{marginLeft: 5,}}>10 years or More</label><br/>
                    </div>
                    
                    
                    <div style={{display:'flex',height:'100%', justifyContent:'center',alignItems:'center',}}>
                        <div 
                            style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid',borderColor:'#3b8e8c',borderWidth:'.25px', fontSize: 'smaller', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center', cursor:'pointer' }}
                            onClick={() => {
                                this.submitQuestionaire()
                            }
                            } 
                        >
                            {this.state.submitClicked? 
                             <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                   <span className="sr-only">Loading...</span>
                               </Spinner>
                            :
                            <p style={{ color: '#3b8e8c', margin: 0, fontWeight: 'bold', fontSize: 18 }}>
                                Submit
                            </p> 
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    updateDWDb = ()=>{
        // fetch()

    }


    render(){
        return(
            <div>
                <PublicHeader user={this.props.user} signOut={this.props.signOut}/>
                <div style={{width: '100%',backgroundColor: 'rgb(247, 246, 253)',paddingLeft: '1.5%',paddingRight: '1.5%',paddingTop: '1.5%', marginTop:75}}>
                    <div style={{marginTop:10, marginBottom:10}}>
                        {/* <h3 style={{textAlign:'center'}}>Transfer Funds</h3> */}
                    </div>
                    <div style={{paddingBottom:50, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <CreateDwAcc dontCheck={true} goBack={this.toAccount} accountPlusProduct={this.updateDWDb} user={this.props.user} />
                    </div>
                </div>
                <Footer isSmallScreen={this.props.isSmallScreen}/>
            </div>
        )
    }
}