import React from 'react'
import PublicHeader from '../PublicHeader'
import Footer from '../Footer';
import Transfers from '../dashboard/Transfers'

import ENDPOINT from '../Endpoint'

import history from '../History'

export default class Tranfers extends React.Component{

    

    toInvested = ()=>{
        window.location.replace("/Dashboard/Invest")
    }

    render(){
        return(
            <div>
                <PublicHeader user={this.props.user} signOut={this.props.signOut}/>
                <div style={{width: '100%',backgroundColor: 'rgb(247, 246, 253)',paddingLeft: '1.5%',paddingRight: '1.5%',paddingTop: '1.5%', marginTop:75}}>
                    <div style={{marginTop:10, marginBottom:10}}>
                        {/* <h3 style={{textAlign:'center'}}>Transfer Funds</h3> */}
                    </div>
                    <div style={{paddingBottom:50, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Transfers user={this.props.user}/>
                    </div>
                </div>
                <Footer isSmallScreen={this.props.isSmallScreen}/>
            </div>
        )
    }
}