import React from 'react'
import Topbackground from '../../assets/newUI/topbackground.png'
import Paper from '../../assets/newUI/paper.png'
import Goingup from '../../assets/newUI/goingup.png'
import Chat from '../../assets/newUI/chat.png'
import ShieldCheck from '../../assets/newUI/shieldCheck.png'
import PerformanceBackground from '../../assets/newUI/performanceBackground.png'
import Performance from '../../assets/newUI/performance.png'
import FirstPlace from '../../assets/newUI/firstPlace.png'
import Data from '../../assets/newUI/data.png'
import UsersIcon from '../../assets/newUI/usersicon.png'
import Foldergear from '../../assets/newUI/foldergear.png'
import Phoneshieldcoins from '../../assets/newUI/phoneshieldcoins.png'
import Check from '../../assets/newUI/check.png'
import Header from '../PublicHeader'
import Footer from '../Footer'
import { NavLink } from 'react-router-dom'
import Youtube from 'react-youtube'


const GREEN = '#669890'
const LIGHTGREEN = "#7bc0c3"
const WHITE = 'rgb(255,255,255)'
const styles = {
    perfContainer: {
        backgroundImage: `url(${PerformanceBackground})`,
        height: '100%',
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexWrap:'wrap'
    }
};

const videos={
    // "getting-started":{
    //     source:'5HAeOY_NGYcg',
    //     title:'Getting Started',
        
    // },
    "price-trend-predictor-1":{
        source: 'W6CeiROGzK0',
        title:'Price Trend Predictor #1',
       
    },
    "price-trend-predictor-2":{
        source: '8KNaR9o-Qik',
        title:'Price Trend Predictor #2',
       
    },
    "alerts":{
        source:'OHBOBLMERHo',
        title: 'Alerts'
    },
    "wealth-management": {
        source: 'im7bIZDPNyk',
        title: 'Wealth Management',
        
     },
    "risk-monkey":{
        source:'uzm1s_ac_pg',
        title:'Risk Monkey',
        
    },
    
    
}
export default class Home2 extends React.Component {

    state={
        showVideo: false,
        currentVideo: "price-trend-predictor-1"

    }

    componentDidMount(){
        document.title = `ForeSCITE`;
    }

    render() {
        // if(this.props.user){
        //     this.props.signOut()
        // }
        if(this.props.isSmallScreen){
          return(

            
            <div style={{ width: '100%',}}>
            <div>
                <Header user={this.props.user} signOut={this.props.signOut} isSmallScreen={this.props.isSmallScreen}/>
            </div>
            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: "space-evenly", }}>
                <div style={{ alignSelf: 'center', width: '40%', marginLeft:'5%', marginTop:this.props.isSmallScreen? 80:null}}>
                    <h1>The Next Generation of Financial Advising is Self Advising</h1>
                    <p>The Technology to manage your money like a pro</p>
                    <div style={{display:'flex', justifyContent:'flex-start',  width:'185%'}}>
                        <NavLink to="/Signup" style={{border:'solid', cursor:'pointer',borderRadius:20, display:'flex',borderWidth:.25, paddingLeft:'5%', paddingRight:'5%', marginRight:'5%',borderColor:GREEN, textDecoration:'none', color: GREEN }}>
                            <p style={{margin:0, color:GREEN}}>Get Started</p>
                        </NavLink>
                        <div onClick={()=>this.setState({showVideo:!this.state.showVideo})} style={{border:'solid', cursor:'pointer', borderRadius:20, display:'flex', justifyContent:"space-between", alignItems:"center", borderWidth:.25, paddingLeft:'5%', }}>
                            <div>
                                <p style={{margin:0}}>Watch Demo</p>
                            </div>
                            <div style={{borderRadius:'50%', backgroundColor:'rgb(17,17,17)', width:25, height:25, justifySelf:'flex-end', marginLeft:10}}>
                                <p style={{margin:0, color:'rgb(255,255,255)', textAlign:'center'}}>
                                    ▶
                                </p>
                            </div>
                        </div>
                    </div>
                    {this.state.showVideo ? 
                        <div style={{width:'60%', display:'flex',justifyContent:'center',alignItems:'center', height:'100%', minHeight: 240, marginLeft: 100, marginTop:50}}>
                        <Youtube
                            videoId={videos[this.state.currentVideo].source}// defaults -> null
                            // id={string}                       // defaults -> null
                            // className={string}                // defaults -> null
                            // containerClassName={string}       // defaults -> ''
                            opts={{ height: 315, width: 560 }}        // defaults -> {}
                            // onReady={func}                    // defaults -> noop
                            // onPlay={func}                     // defaults -> noop
                            // onPause={func}                    // defaults -> noop
                            onEnd={()=>{
                                if(this.state.currentVideo === 'price-trend-predictor-1'){this.setState({currentVideo:'price-trend-predictor-2'})}
                                else if(this.state.currentVideo === 'price-trend-predictor-2'){this.setState({currentVideo:'alerts'})}
                                else if(this.state.currentVideo === 'wealth-management'){this.setState({currentVideo:'risk-monkey'})}
                                else if(this.state.currentVideo === 'alerts'){this.setState({currentVideo:'wealth-management'})}
                                else if(this.state.currentVideo === 'risk-monkey'){this.setState({currentVideo:false})}
                                else{this.setState({currentVideo:false})}
                            }}                                   // defaults -> noop
                            // onError={func}                    // defaults -> noop
                            // onStateChange={func}              // defaults -> noop
                            // onPlaybackRateChange={func}       // defaults -> noop
                            // onPlaybackQualityChange={func}    // defaults -> noop
                        />
                        </div>
                    :null}
                </div>
                <div style={{ alignSelf: "baseline", width: '100%', marginTop:this.props.isSmallScreen? 180:100 }}>
                    <img
                        src={Topbackground}
                        style={{
                            // marginTop: 5,
                            width: '100%',
                            height: '100%',
                            justifySelf:'flex-end'
                        }}
                    />
                </div>
            </div>
            {/** END TOP */}
            <div style={{display:'flex', width:'90%', flexDirection:'column', margin:'auto',marginTop:20, }}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom: 15}}>
                    <h3>AI-POWERED FINTECH PLATFORM FOR THE MASSES.</h3>
                </div>
                <div style={{display:'flex', width: '80%', margin:'auto', flexDirection:'column'}}>
                    <div style={{display:'flex', flexDirection:'column', width: '100%'}}>
                        <div>
                            <img
                                style={{
                                    height:100,
                                    marginBottom: 10
                                }}
                                src={Paper}
                            />
                        </div>
                        <div>
                            <p style={{color:GREEN, fontWeight:'bold', fontSize:22}}>Maintain Full Discretion Over Investments</p>
                        </div>
                        <div>
                            <p>Keep your money exactly where it is. Just let us take a look so we can make recommendations. It is entirely your decision whether or not to use or recommendations.</p>
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', width: '100%'}}>
                        <div>
                            <img
                                style={{
                                    height:100,
                                    marginBottom: 10
                                }}
                                src={Goingup}
                            />
                        </div>
                        <div>
                            <p style={{color:GREEN,fontWeight:'bold', fontSize:22}}>AR - Invest</p>
                        </div>
                        <div>
                            <p>
                                Invest in our flagship strategy the AllocateRite U.S. Domestic Composite. Invested in highly liquid and diverse ETFs you never have to worry about getting your hands on your money or putting all your eggs in one basket.
                            </p>
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', width: '100%'}}>
                        <div>
                            <img
                                style={{
                                    height:100,
                                    marginBottom: 10
                                }}
                                src={Chat}
                            />
                        </div>
                        <div>
                            <p style={{color:GREEN,fontWeight:'bold', fontSize:22}}>24/7 Risk Monitoring & Reporting</p>
                        </div>
                        <div>
                            <p>
                                AI provides proactive risk monitoring and disciplined execution of investment decisions. Keeping you informed about your portfolio very step of the way.
                            </p>
                        </div>
                    </div>
                    
                </div>
                <div style={{width:'100%', margin:'auto', marginBottom: 80}}>
                    <NavLink to="/about" style={{width: '60%', margin:'auto', borderRadius:20, backgroundColor:GREEN, padding: 10}}>
                        <p style={{margin:0, textAlign:'center', color:WHITE}}>Learn More</p>
                    </NavLink>
                </div>
            </div>
            {/** End 2nd section */}
            <div style={{backgroundColor: "#fafafa", width: '100%', display:'flex', justifyContent:'space-between', alignItems:'center', padding: 50}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <p style={{fontSize: 36, fontWeight:'bold'}}>Your Money, Your Way</p>
                    <p style={{fontSize: 20, color: GREEN}}>Keep your money where it is. Unlike others, we value your freedom to manage your assets. We simply offer you the most powerful risk analysis AI tool on the market with the technology to manage your money more accurately than a financial advisor. AllocateRite is not a broker. It does custody funds and does not exercise discretion. Users have absolute control of their accounts and the funds in them.</p>
                    <p style={{color:LIGHTGREEN, fontWeight:'bold'}}>Suitable for your 401k, Retirement, or College Plan</p>
                </div>
                {this.props.isSmallScreen?null:
                <div>
                    <img
                        src={ShieldCheck}
                        style={{
                            height:400
                        }}
                    />
                </div>}
            </div>
            {/** End 3rd section */}
            <div style={styles.perfContainer}>
                <div>
                    <img 
                        src={Performance}
                        style={{height: 600, marginTop: 200, marginBottom:200}}
                    />
                </div>
                <div style={{display:"flex", flexDirection:'column', marginLeft:5}}>
                    <div>
                        <h1 style={{color:WHITE}}>ALLOCATERITE IS THE TOP PERFORMING ROBO</h1>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '50%'}}>
                            <p style={{color:WHITE}}>
                                Robo Advisor
                            </p>
                        </div>
                        <div style={{width: '50%'}}>
                            <p style={{color:WHITE, margin:0}}>
                                Two Year 
                            </p>
                            <p style={{color:WHITE}}>
                                Annualized Return 
                            </p>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginLeft:15, }}>
                        <div style={{width: '50%', display:'flex', marginLeft:'-6%'}}>
                            <img
                                src={FirstPlace}
                                style={{height: 50, marginTop: -20, marginRight:5}}
                            />
                            <p style={{color:WHITE}}>
                                ForeSCITE
                            </p>
                        </div>
                        <div style={{width: '50%', marginLeft:'6%'}}>
                            <p style={{color:WHITE}}>
                                8.18%
                            </p>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '50%'}}>
                            <p style={{color:WHITE}}>
                                Fidelity Go
                            </p>
                        </div>
                        <div style={{width: '50%'}}>
                            <p style={{color:WHITE}}>
                                7.26%
                            </p>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '50%'}}>
                            <p style={{color:WHITE}}>
                                WiseBanyan
                            </p>
                        </div>
                        <div style={{width: '50%'}}>
                            <p style={{color:WHITE}}>
                                7.00%
                            </p>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '50%'}}>
                            <p style={{color:WHITE}}>
                                Wealthfront
                            </p>
                        </div>
                        <div style={{width: '50%'}}>
                            <p style={{color:WHITE}}>
                               6.92%
                            </p>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '50%'}}>
                            <p style={{color:WHITE}}>
                                SigFig
                            </p>
                        </div>
                        <div style={{width: '50%'}}>
                            <p style={{color:WHITE}}>
                               6.87%
                            </p>
                        </div>
                    </div>
                    <div>
                        <p style={{color:WHITE}}>
                        AllocateRite returns are based on its flagship U.S. Domestic Composite fund. GIPS® verified through 2018. Performance through 6/30/19. Competitor returns are reported in Barron’s, 7/26/19 and are based on a static 60/40 allocation. In contrast, AllocateRite uses a dynamic mix of equities and fixed income.
                        </p>
                    </div>
                </div>
            </div>
            {/** END OF 4th section */}
            <div style={{backgroundColor: "#fafafa", width: '100%', display:'flex', justifyContent:'space-between', alignItems:'center', padding: 50}}>
                    {this.props.isSmallScreen?null:
                    <div>
                        <img
                            src={Phoneshieldcoins}
                            style={{height: 600}}
                        />
                    </div>}
                    <div>
                        <h4>YOU'RE IN CONTROL</h4>
                        <div>
                            <div style={{display:'flex', marginBottom: 10}}>
                                <div>
                                    <img
                                        src={Data}
                                        style={{marginRight: 10, width:100}}
                                    />
                                </div>
                                <div>
                                    <p>Use your current broker</p>
                                    <p>Choose your preferred broker across 2500 custodians</p>
                                </div>
                            </div>
                            <div style={{display:'flex', marginBottom: 10}}>
                                <div>
                                    <img
                                        style={{marginRight: 10, width:100}}
                                        src={UsersIcon}
                                    />
                                </div>
                                <div>
                                    <p>Select any ETF issuer</p>
                                    <p>Provided the ETFs you choose track the defaults well, they can be reliably slotted into any strategy.</p>
                                </div>
                            </div>
                            <div style={{display:'flex', marginBottom: 10}}>
                                <div>
                                    <img
                                        style={{marginRight: 10, width:100}}
                                        src={Foldergear}
                                    />
                                </div>
                                <div>
                                    <p>Automated, periodic rebalancing </p>
                                    <p>You choose a strategy and investment amount, and an AI optimizes trade orders to best lower risk and reap reward.</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            {/** end section 5 */}
            <div style={{width: '70%', margin:'auto', display:'flex', justifyContent:'center', flexDirection:'column', paddingBottom:120}}>
                <div style={{display:'flex', justifyContent:'center', flexDirection:'column', width:'100%',paddingTop:50, paddingBottom: 20}}>
                    <h2 style={{textAlign:'center'}}>CHOOSE WHICH PLAN IS RIGHT FOR YOU</h2>
                    <p style={{textAlign:'center', margin:0, fontSize:'larger'}}>Easily link your brokerage account to our AI-power analytics tools</p>
                    <p style={{textAlign:'center', margin:0, fontSize:'larger'}}>Invest smarter using our AI driven strategies and recommendations</p>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>    
                    <div style={{display:'flex', backgroundColor:'rgba(146, 149, 161, 0.05)', width: '90%', borderRadius: '5%', padding:'2.5%', }}>
                            <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                                <div style={{display:'flex', justifyContent:'center', flexDirection:'column', width:'100%'}}> {/**title */}
                                    <h5 style={{textAlign:'center',fontWeight:'bold'}}>Basic</h5>
                                    <h5 style={{textAlign:'center',fontWeight:'bold'}}>$9.99 / month</h5>

                                </div>
                                <div style={{display:'flex', flexDirection:'column',}}> {/**features */}
                                    <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                        <img
                                            src={Check}
                                            style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                        />
                                        <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                            <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Multiple Accounts</p>
                                            <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                            Link Mutiple Accounts and Get Access to Professional Risk Analysis
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                        <img
                                            src={Check}
                                            style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                        />
                                        <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                            <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Price Trend Prediction</p>
                                            <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                            Get access to price trend predictions of thousands of stocks worldwide
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                        <img
                                            src={Check}
                                            style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                        />
                                        <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                            <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Watchlist</p>
                                            <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                Track any stocks you want and set up notifications for when we think the price is going to dramatically change
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                        <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                            <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%', fontSize:'smaller'}}>
                                                * Free Trial begins immediately after you create an account with AllocateRite. You will be only charged if you subscribe.
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', borderRadius: 20, paddingLeft:10, paddingRight:10, paddingTop:2, paddingBottom:2, backgroundColor: GREEN}}>
                                            <p style={{margin:0, color:WHITE}}>
                                                Start Your Free Trial
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div style={{display:'flex', justifyContent: "space-between", backgroundColor:'rgba(146, 149, 161, 0.05)', width: '90%', borderRadius: '5%', padding:'2.5%',marginTop:10 }}>
                            <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                                <div style={{display:'flex', justifyContent:'center', flexDirection:'column', width:'100%'}}> {/**title */}
                                    <h5 style={{textAlign:'center', fontWeight:'bold'}}>Pro</h5>
                                    <h5 style={{textAlign:'center', fontWeight:'bold'}}>$29.99 / month</h5>
                                </div>
                                <div style={{display:'flex', flexDirection:'column',}}> {/**features */}
                                    <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                        <img
                                            src={Check}
                                            style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                        />
                                        <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                            <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Portfolio Construction and Optimization</p>
                                            <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                Import any portfolio and see how our AI recommends you invest in that set of securities
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                        <img
                                            src={Check}
                                            style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                        />
                                        <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                            <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Investing Made Right</p>
                                            <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                Invest in our Signature Robo-Strategies. Using the combination of regular portfolio rebalances and investing in liquid and diverse ETFs your money is in good hands.
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                        <img
                                            src={Check}
                                            style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                        />
                                        <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                            <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Financial Security</p>
                                            <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                            AI-Optimized Allocations Help Protect Against Risk
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                        <img
                                            src={Check}
                                            style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                        />
                                        <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                            <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Improve your Portfolio</p>
                                            <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                            Minimize Your Risk while Increasing Your Returns
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                        <img
                                            src={Check}
                                            style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                        />
                                        <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                            <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Plus all  features in the <strong>Basic Subscription</strong></p>
                                            {/* <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                            Minimize Your Risk while Increasing Your Returns
                                            </p> */}
                                        </div>
                                    </div>
                                    {/* <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                        <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                            <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%', fontSize:'smaller'}}>
                                                * Free Trial begins immediately after you create an account with AllocateRite. You will be only charged if you subscribe.
                                            </p>
                                        </div>
                                    </div> */}
                                    <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', borderRadius: 20, paddingLeft:10, paddingRight:10, paddingTop:2, paddingBottom:2, backgroundColor: '#599acf', width: '60%'}}>
                                            <p style={{margin:0, color:WHITE}}>
                                                Sign Up
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div style={{width:'100%'}}>
                <Footer isSmallScreen={this.props.isSmallScreen}/>
            </div>
        </div>
          )
        }
        else{
        return (
            <div style={{ width: '100%',}}>
                <div>
                    <Header user={this.props.user} signOut={this.props.signOut} isSmallScreen={this.state.isSmallScreen}/>
                </div>
                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: "space-evenly", }}>
                    <div style={{ alignSelf: 'center', width: '40%', marginLeft:'5%', marginTop:this.props.isSmallScreen? 80:null}}>
                        <h1>The Next Generation of Financial Advising is Self Advising</h1>
                        <p>The Technology to manage your money like a pro</p>
                        <div style={{display:'flex', justifyContent:'flex-start',  width:'100%'}}>
                            <NavLink to="/Signup" style={{border:'solid', borderRadius:20, display:'flex',borderWidth:.25, paddingLeft:'5%', paddingRight:'5%', marginRight:'5%',borderColor:GREEN, textDecoration:'none', color: GREEN }}>
                                <p style={{margin:0, color:GREEN}}>Get Started</p>
                            </NavLink>
                            <div onClick={()=>this.setState({showVideo:!this.state.showVideo})} style={{border:'solid', cursor: 'pointer',borderRadius:20, display:'flex', justifyContent:"space-between", alignItems:"center", borderWidth:.25, paddingLeft:'5%', }}>
                                <div>
                                    <p style={{margin:0}}>Watch Demo</p>
                                </div>
                                <div style={{borderRadius:'50%', backgroundColor:'rgb(17,17,17)', width:25, height:25, justifySelf:'flex-end', marginLeft:10}}>
                                    <p style={{margin:0, color:'rgb(255,255,255)', textAlign:'center'}}>
                                        ▶
                                    </p>
                                </div>
                            </div>
                        </div>
                        {this.state.showVideo ? 
                            <div style={{width:'60%', display:'flex',justifyContent:'center',alignItems:'center', height:'100%', minHeight: 240, marginLeft: 100, marginTop:50}}>
                            <Youtube
                                videoId={videos[this.state.currentVideo].source}// defaults -> null
                                // id={string}                       // defaults -> null
                                // className={string}                // defaults -> null
                                // containerClassName={string}       // defaults -> ''
                                opts={{ height: 315, width: 560 }}        // defaults -> {}
                                // onReady={func}                    // defaults -> noop
                                // onPlay={func}                     // defaults -> noop
                                // onPause={func}                    // defaults -> noop
                                onEnd={()=>{
                                    if(this.state.currentVideo === 'price-trend-predictor-1'){this.setState({currentVideo:'price-trend-predictor-2'})}
                                    else if(this.state.currentVideo === 'price-trend-predictor-2'){this.setState({currentVideo:'alerts'})}
                                    else if(this.state.currentVideo === 'wealth-management'){this.setState({currentVideo:'risk-monkey'})}
                                    else if(this.state.currentVideo === 'alerts'){this.setState({currentVideo:'wealth-management'})}
                                    else if(this.state.currentVideo === 'risk-monkey'){this.setState({currentVideo:false})}
                                    else{this.setState({currentVideo:false})}
                                }}                                   // defaults -> noop
                                // onError={func}                    // defaults -> noop
                                // onStateChange={func}              // defaults -> noop
                                // onPlaybackRateChange={func}       // defaults -> noop
                                // onPlaybackQualityChange={func}    // defaults -> noop
                            />
                            </div>
                        :null}
                    </div>
                    <div style={{ alignSelf: "baseline", width: '100%', marginTop:this.props.isSmallScreen? 180:100 }}>
                        <img
                            src={Topbackground}
                            style={{
                                // marginTop: 5,
                                width: '100%',
                                height: '100%',
                                justifySelf:'flex-end'
                            }}
                        />
                    </div>
                </div>
                {/** END TOP */}
                <div style={{display:'flex', width:'100%', flexDirection:'column'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom: 15}}>
                        <h3>AI-POWERED FINTECH PLATFORM FOR THE MASSES.</h3>
                    </div>
                    <div style={{display:'flex', width: '80%', margin:'auto', justifyContent:'space-between'}}>
                        <div style={{display:'flex', flexDirection:'column', width: '30%'}}>
                            <div>
                                <img
                                    style={{
                                        height:100,
                                        marginBottom: 10
                                    }}
                                    src={Paper}
                                />
                            </div>
                            <div>
                                <p style={{color:GREEN, fontWeight:'bold', fontSize:22}}>Maintain Full Discretion Over Your Investments</p>
                            </div>
                            <div>
                                <p>Keep your money exactly where it is. Just let us take a look so we can make recommendations. It is entirely your decision whether or not to use or recommendations.</p>
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', width: '30%'}}>
                            <div>
                                <img
                                    style={{
                                        height:100,
                                        marginBottom: 10
                                    }}
                                    src={Goingup}
                                />
                            </div>
                            <div>
                                <p style={{color:GREEN,fontWeight:'bold', fontSize:22}}>ForeSCITE - Invest</p>
                            </div>
                            <div>
                                <p>
                                    Invest in highly liquid and diverse ETFs you never have to worry about getting your hands on your money or putting all your eggs in one basket.
                                </p>
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', width: '30%'}}>
                            <div>
                                <img
                                    style={{
                                        height:100,
                                        marginBottom: 10
                                    }}
                                    src={Chat}
                                />
                            </div>
                            <div>
                                <p style={{color:GREEN,fontWeight:'bold', fontSize:22}}>24/7 Risk Monitoring & Reporting</p>
                            </div>
                            <div>
                                <p>
                                    AI provides proactive risk monitoring and disciplined execution of investment decisions. Keeping you informed about your portfolio very step of the way.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <div style={{width:'100%', margin:'auto', marginBottom: 80}}>
                        <NavLink to='/Platform'>
                            <div style={{width: '10%', margin:'auto', borderRadius:20, backgroundColor:GREEN, padding: 10}}>
                                <p style={{margin:0, textAlign:'center', color:WHITE}}>Learn More</p>
                            </div>
                        </NavLink>
                    </div>
                </div>
                {/** End 2nd section */}
                <div style={{backgroundColor: "#fafafa", width: '100%', display:'flex', justifyContent:'space-between', alignItems:'center', padding: 50}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                        <p style={{fontSize: 36, fontWeight:'bold'}}>Your Money, Your Way</p>
                        <p style={{fontSize: 20, color: GREEN}}>Keep your money where it is. Unlike others, we value your freedom to manage your assets. We simply offer you the most powerful risk analysis AI tool on the market with the technology to manage your money more accurately than a financial advisor. ForeSCITE is not a broker. It does custody funds and does not exercise discretion. Users have absolute control of their accounts and the funds in them.</p>
                        <p style={{color:LIGHTGREEN, fontWeight:'bold'}}>Suitable for your 401k, Retirement, or College Plan</p>
                    </div>
                    {this.props.isSmallScreen?null:
                    <div>
                        <img
                            src={ShieldCheck}
                            style={{
                                height:400
                            }}
                        />
                    </div>}
                </div>
                {/** End 3rd section */}
                <div style={styles.perfContainer}>
                    <div>
                        <img 
                            src={Performance}
                            style={{height: 600, marginTop: 200, marginBottom:200}}
                        />
                    </div>
                    <div style={{display:"flex", flexDirection:'column', marginLeft:5}}>
                        <div>
                            <h1 style={{color:WHITE}}>ForeSCITE IS THE TOP PERFORMING ROBO</h1>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '50%'}}>
                                <p style={{color:WHITE}}>
                                    Robo Advisor
                                </p>
                            </div>
                            <div style={{width: '50%'}}>
                                <p style={{color:WHITE, margin:0}}>
                                    Two Year 
                                </p>
                                <p style={{color:WHITE}}>
                                    Annualized Return 
                                </p>
                            </div>
                        </div>
                        <div style={{display: 'flex', marginLeft:5}}>
                            <div style={{width: '50%', display:'flex', marginLeft:'-6%'}}>
                                <img
                                    src={FirstPlace}
                                    style={{height: 50, marginTop: -20, marginRight:5}}
                                />
                                <p style={{color:WHITE}}>
                                    ForeSCITE
                                </p>
                            </div>
                            <div style={{width: '50%', marginLeft:'6%'}}>
                                <p style={{color:WHITE}}>
                                    8.18%
                                </p>
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '50%'}}>
                                <p style={{color:WHITE}}>
                                    Fidelity Go
                                </p>
                            </div>
                            <div style={{width: '50%'}}>
                                <p style={{color:WHITE}}>
                                    7.26%
                                </p>
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '50%'}}>
                                <p style={{color:WHITE}}>
                                    WiseBanyan
                                </p>
                            </div>
                            <div style={{width: '50%'}}>
                                <p style={{color:WHITE}}>
                                    7.00%
                                </p>
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '50%'}}>
                                <p style={{color:WHITE}}>
                                    Wealthfront
                                </p>
                            </div>
                            <div style={{width: '50%'}}>
                                <p style={{color:WHITE}}>
                                   6.92%
                                </p>
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '50%'}}>
                                <p style={{color:WHITE}}>
                                    SigFig
                                </p>
                            </div>
                            <div style={{width: '50%'}}>
                                <p style={{color:WHITE}}>
                                   6.87%
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '50%', margin:'auto'}}>
                            <p style={{color:WHITE}}>AllocateRite returns are based on its flagship U.S. Domestic Composite fund. GIPS® verified through 2018. Performance through 6/30/19. Competitor returns are reported in Barron’s, 7/26/19 and are based on a static 60/40 allocation. In contrast, AllocateRite uses a dynamic mix of equities and fixed income.</p>
                        </div>
                    </div>
                </div>
                {/** END OF 4th section */}
                <div style={{backgroundColor: "#fafafa", width: '100%', display:'flex', justifyContent:'space-around', alignItems:'center', padding: 50}}>
                        {this.props.isSmallScreen?null:
                        <div>
                            <img
                                src={Phoneshieldcoins}
                                style={{height: 600}}
                            />
                        </div>}
                        <div>
                            <h4>YOU'RE IN CONTROL</h4>
                            <div>
                                <div style={{display:'flex', marginBottom: 10}}>
                                    <div>
                                        <img
                                            src={Data}
                                            style={{marginRight: 10, width:100}}
                                        />
                                    </div>
                                    <div>
                                        <p>Use your current broker</p>
                                        <p>Choose your preferred broker across 2500 custodians</p>
                                    </div>
                                </div>
                                <div style={{display:'flex', marginBottom: 10}}>
                                    <div>
                                        <img
                                            style={{marginRight: 10, width:100}}
                                            src={UsersIcon}
                                        />
                                    </div>
                                    <div>
                                        <p>Select any ETF issuer</p>
                                        <p>Provided the ETFs you choose track the defaults well, they can be reliably slotted into any strategy.</p>
                                    </div>
                                </div>
                                <div style={{display:'flex', marginBottom: 10}}>
                                    <div>
                                        <img
                                            style={{marginRight: 10, width:100}}
                                            src={Foldergear}
                                        />
                                    </div>
                                    <div>
                                        <p>Automated, periodic rebalancing </p>
                                        <p>You choose a strategy and investment amount, and an AI optimizes trade orders to best lower risk and reap reward.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                {/** end section 5 */}
                <div style={{width: '70%', margin:'auto', display:'flex', justifyContent:'center', flexDirection:'column', paddingBottom:120}}>
                    <div style={{display:'flex', justifyContent:'center', flexDirection:'column', width:'100%',paddingTop:50, paddingBottom: 20}}>
                        <h2 style={{textAlign:'center'}}>CHOOSE WHICH PLAN IS RIGHT FOR YOU</h2>
                        <p style={{textAlign:'center', margin:0, fontSize:'larger'}}>Easily link your brokerage account to our AI-power analytics tools</p>
                        <p style={{textAlign:'center', margin:0, fontSize:'larger'}}>Invest smarter using our AI driven strategies and recommendations</p>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between'}}>    
                        <div style={{display:'flex', backgroundColor:'rgba(146, 149, 161, 0.05)', width: '40%', borderRadius: '5%', padding:'2.5%', }}>
                                <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                                    <div style={{display:'flex', justifyContent:'center', flexDirection:'column', width:'100%'}}> {/**title */}
                                        <h5 style={{textAlign:'center'}}>Basic</h5>
                                        <h5 style={{textAlign:'center'}}>FREE</h5>
    
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column',}}> {/**features */}
                                        <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                            <img
                                                src={Check}
                                                style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                            />
                                            <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                                <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Multiple Accounts</p>
                                                <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                Link Mutiple Accounts and Get Access to Professional Risk Analysis
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                            <img
                                                src={Check}
                                                style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                            />
                                            <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                                <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Price Trend Prediction</p>
                                                <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                Get access to price trend predictions of thousands of stocks worldwide
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                            <img
                                                src={Check}
                                                style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                            />
                                            <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                                <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Watchlist</p>
                                                <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                    Track any stocks you want and set up notifications for when we think the price is going to dramatically change
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                            <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                                <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%', fontSize:'smaller'}}>
                                                    * Free Trial begins immediately after you create an account with AllocateRite. You will be only charged if you subscribe.
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <NavLink to="/Signup">
                                                <div style={{display:'flex', cursor:'pointer',justifyContent:'center', alignItems:'center', borderRadius: 20, paddingLeft:10, paddingRight:10, paddingTop:2, paddingBottom:2, backgroundColor: GREEN}}>
                                                    <p style={{margin:0, color:WHITE}}>
                                                        Start Your Free Trial
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div style={{display:'flex', justifyContent: "space-between", backgroundColor:'rgba(146, 149, 161, 0.05)', width: '40%', borderRadius: '5%', padding:'2.5%', }}>
                                <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                                    <div style={{display:'flex', justifyContent:'center', flexDirection:'column', width:'100%'}}> {/**title */}
                                        <h5 style={{textAlign:'center'}}>Pro</h5>
                                        <h5 style={{textAlign:'center'}}>$5.99 / month</h5>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column',}}> {/**features */}
                                        <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                            <img
                                                src={Check}
                                                style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                            />
                                            <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                                <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Portfolio Construction and Optimization</p>
                                                <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                    Import any portfolio and see how our AI recommends you invest in that set of securities
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                            <img
                                                src={Check}
                                                style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                            />
                                            <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                                <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Investing Made Right</p>
                                                <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                    Invest in our Signature Robo-Strategies. Using the combination of regular portfolio rebalances and investing in liquid and diverse ETFs your money is in good hands.
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                            <img
                                                src={Check}
                                                style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                            />
                                            <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                                <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Financial Security</p>
                                                <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                AI-Optimized Allocations Help Protect Against Risk
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                            <img
                                                src={Check}
                                                style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                            />
                                            <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                                <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Improve your Portfolio</p>
                                                <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                Minimize Your Risk while Increasing Your Returns
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                            <img
                                                src={Check}
                                                style={{width: 20, marginRight: 15, alignSelf:'flex-start', marginTop:7}}
                                            />
                                            <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                                <p style={{margin:0, textAlign:'left', width:'100%', fontSize:'larger'}}>Plus all  features in the <strong>Basic Subscription</strong></p>
                                                {/* <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%'}}>
                                                Minimize Your Risk while Increasing Your Returns
                                                </p> */}
                                            </div>
                                        </div>
                                        {/* <div style={{display:'flex', justifyContent: "flex-start", width: '100%', alignItems:'center'}}>
                                            <div style={{display:"flex",flexDirection:'column', justifyContent:'flex-start', alignItems:'center',marginBottom:10}}>
                                                <p style={{color:'rgb(120,120,120)', margin: 0, width:'100%', fontSize:'smaller'}}>
                                                    * Free Trial begins immediately after you create an account with AllocateRite. You will be only charged if you subscribe.
                                                </p>
                                            </div>
                                        </div> */}
                                        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <NavLink to="/Signup" style={{width:'fit-content'}}>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', borderRadius: 20, paddingLeft:10, paddingRight:10, paddingTop:2, cursor:'pointer',paddingBottom:2, backgroundColor: '#599acf', width: '100%',}}>
                                                    <p style={{margin:0, color:WHITE}}>
                                                        Sign Up
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%'}}>
                    <Footer isSmallScreen={this.props.isSmallScreen}/>
                </div>
            </div>
        )
        }
    }
}