import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import FooterPageTitle from "../layout/FooterPageTitle";
import Footer from "../Footer";
import Header from "../PublicHeader"

export class GeneralDisclaimer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <div>
        <FooterPageTitle title="GENERAL DISCLAIMER" />
        <Container>
          <Row />
          <Row className="terms-top-paragraph">
            <h3 className="terms-title">
              GENERAL RISKS OF TRADING AND INVESTING
            </h3>
            <p className="terms-text">
              We believe it is vitally important that you read and fully
              understand the following risks of trading and investing:
            </p>
            <p className="terms-text">
              All securities trading, whether in stocks or other investment
              vehicles, is speculative in nature and involves significant risk
              of loss. We encourage our subscribers to invest carefully and to
              utilize the information available at the websites of{" "}
              <a className="terms-link" href="http://www.sec.gov">
                the Securities and Exchange Commission
              </a>{" "}
              and{" "}
              <a className="terms-link" href="http://www.nasd.com">
                {" "}
                the National Association of Securities Dealers
              </a>
              . The NASD has published information on how to invest carefully at
              its website. We also encourage you to get personal advice from
              your professional investment advisor and to make independent
              investigations before acting on information. We do not in any way
              warrant or guarantee the success of any action you take in
              reliance on our information.
            </p>
            <p className="terms-text">
              Past performance is not necessarily indicative of future results.
              All investments carry risk and all trading decisions of an
              individual remain the responsibility of that individual. There is
              no guarantee that systems, indicators, or trading signals will
              result in profits or that they will not result in losses. All
              investors are advised to fully understand all risks associated
              with any kind of trading or investing they choose to do.
            </p>
            <p className="terms-text">
              Hypothetical or simulated performance is not indicative of future
              results. Unless specifically noted otherwise, all profit examples
              provided in the our websites and publications are based on
              hypothetical or simulated trading, which means they are done on
              paper or electronically based on real market prices at the time
              the recommendation is disseminated to the subscribers of this
              service, but without actual money being invested. Also, such
              examples do not include the costs of subscriptions, commissions,
              and other fees, or examples of other recommendations as to which
              there were losses utilizing the timing at the time of the
              recommendations. Because the trades underlying these examples have
              not actually been executed, the results may understate or
              overstate the impact of certain market factors, such as lack of
              liquidity (discussed below). Simulated trading programs in general
              are also designed with the benefit of hindsight, which may not be
              relevant to actual trading. We make no representations or
              warranties that any account will or is likely to achieve profits
              similar to those shown, because hypothetical or simulated
              performance is not necessarily indicative of future results.
            </p>
            <p className="terms-text">
              AllocateRite publishes a constructed portfolio based on our
              recommendations. Your actual results may differ from results
              reported for the constructed portfolio for many reasons,
              including, without limitation: (i) performance results for the
              constructed portfolio do not reflect actual trading commissions
              that you may incur; (ii) performance results for the constructed
              portfolio do not account for the impact, if any, of certain market
              factors, such as lack of liquidity, that may affect your results;
              (iii) the exchange traded funds chosen for the constructed
              portfolio may be volatile, and although the “purchase” or “sale”
              of a security in the constructed portfolio will not be effected in
              the constructed portfolio until confirmation that the rebalancing
              alert has been sent to subscribers, slight delivery delays and
              other factors may cause the price you obtain to potentially differ
              materially from the price at the time the rebalancing alert was
              sent out. Past results are not indicative of future
              performance/results.
            </p>
            <p className="terms-text">
              Investing involves substantial risk. We do not make any guarantee
              or other promise as to any results that may be obtained from using
              the investment information. While past performance presented may
              be analyzed, past performance should not be considered indicative
              of future performance. No subscriber should make any investment
              decision without first consulting his or her own personal
              financial advisor and conducting his or her own research and due
              diligence, including carefully reviewing the prospectus and other
              public filings. To the maximum extent permitted by law,
              AllocateRite and its affiliates disclaim any and all liability in
              the event any information, commentary, analysis, opinions, advice
              and/or recommendations included within the website prove to be
              inaccurate, incomplete or unreliable, or result in any investment
              or other losses.
            </p>
          </Row>
          <Row>
            <p className="terms-bottom-paragraph">
              Subscribers may submit questions to AllocateRite directly through
              the website.
            </p>
          </Row>
        </Container>
        <Footer />
      </div>
      </div>
    );
  }
}

export default GeneralDisclaimer;
