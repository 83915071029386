import React from 'react'
import { Card, Row, } from "react-bootstrap";
import Header from "../layout/Header";
import SingleCompanyCard from "../singleCompanyCard"
import Footer from "../Footer";
import {FaChevronDown, FaArrowRight, FaArrowLeft}  from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import MobileWl from './mobileWl'
import HeaderDropdown from '../HeaderDropdown'
import TickerTape from './TickerTape'
import {  FaSearch } from "react-icons/fa";
import ENDPOINT from '../Endpoint'


const brandColor = "#00A99D"

export default class Watchlist extends React.Component{
    state={
        shownList: 'My Watchlist',
        showListOptions: false,
        editMode: false,
        start: 0,
        showAddModal:false,
        searchMomentumText:'',
        momDataSource: [],
        isSearching: false,
        refresh:true

    }

    componentDidMount(){
        // if (this.props.user) {
        //     this.fetchWatchList()
        // }
        setTimeout(()=>this.refreshWl(),5000)
    }

    refreshWl = ()=>{
        console.log('refreshing wl')
        this.props.refreshWl()
        if(this.state.refresh){
            setTimeout(()=>this.refreshWl(),5000)
        }
    }

    arrayToObject = (arr) => {
        let result = {};
        for (let i = 0; i < arr.length; i++) {
            result[arr[i].listTitle] = arr[i].tickers;
        }
        return result
    }

    fetchWatchList = () => {
        fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + this.props.user.idToken.jwtToken
            },
        }).then(response => response.json())
            .then(responseJson => {
                let watchListData = responseJson.watchlist;
                let predefinedLists = responseJson.predefinedLists
                let watchlistLastUpdated = (new Date(responseJson.lastUpdated * 1000)).toString()
                let sortedPredefinedLists = this.arrayToObject(predefinedLists)
                this.setState({ myWatchlist: watchListData, presetTickers: sortedPredefinedLists, watchlistLastUpdated })
            })
            .catch(error => console.log(error))
    }

    fetchPresetTickers = () => {
        let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
        fetch(url, {
            "Access-Control-Allow-Headers": {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Access-Control-Request-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
            },
        })
            .then((res) => res.json())
            .then((responseJson) => {
                this.setState({ presetTickers: responseJson.lists })
            })
    }

    onSubmitSearchMomentum = () => {
        const { searchMomentumText } = this.state;
        this.setState({ isMomDataLoading: true });
        // console.log(searchMomentumText);
        // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
        let url = `${ENDPOINT}/search/ticker?search=${searchMomentumText.toUpperCase()}`;
        fetch(url, {
            "Access-Control-Allow-Headers": {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Access-Control-Request-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
            },
        })
            .then((res) => res.json())
            .then((responseJson) => {
                var data = responseJson.content;
                this.setState({
                    isMomDataLoading: false,
                    momDataSource: data,
                });
            })
            .catch((err) => {
                this.setState({ isMomDataLoading: false });
                console.log(err);
            });
    };

    addCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    addModal = ()=>{
        if(this.state.showAddModal){
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height:'90%'}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Stock Search</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({showAddModal:false})}> X </p>
                        </div>
                        <div style={{width:'90%', margin:'auto', marginTop: 25, marginBottom: 50}}>
                            <p style={{fontSize:22}}>
                                Search for any stock by company name or symbol
                            </p>
                            <p style={{fontSize:22}}>
                                Click on any result to add it to your watchlist
                            </p>
                        </div>
                        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <div
                                style={{
                                    backgroundColor: 'rgb(255,255,255)', width: '75%', marginBottom: 20, borderRadius: 20, paddingLeft: 10, display: 'flex', justifyContent: 'flex-start', boxShadow: this.state.hover ? '0 0 10px rgb(223,225,229)' : 'none', borderColor: 'rgb(237,238,240)', borderStyle: 'solid', borderWidth: 'thin', minWidth: 250, 
                                }}
                                onMouseEnter={() => this.setState({ hover: 'input' })}
                                onMouseLeave={() => this.setState({ hover: false })}
                            >
                                <div style={{ minWidth: 20, width: '5%', /*backgroundColor: '#00A99D',*/ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                                    <FaSearch size={'50%'} style={{color: '#dddddd'}} />                        
                                </div>
                                <input 
                                    ref={this.input}
                                    style={{ width: '90%', border: 'none', outline: 'none', paddingTop: 5, paddingBottom: 5}}
                                    placeholder="Enter stock symbol or company name"
                                    type="text" 
                                    value={this.state.searchMomentumText} 
                                    onChange={(event) => {
                                    let searchMomentumText = event.target.value.toUpperCase();
                                    this.setState({ searchMomentumText, selectedPreset: null,errors:false }, () => {
                                        const { isSearching } = this.state;
                                        if (isSearching) {
                                            return;
                                        } else {
                                            this.setState({ isSearching: true });
                                            setTimeout(() => {
                                                this.setState(
                                                    { isSearching: false },
                                                    this.onSubmitSearchMomentum
                                                );
                                            }, 2000);
                                        }
                                    });
                                }} />
                                </div>
                        </div>
                        <div style={{maxHeight: 300, overflowY:'scroll', paddingLeft:20, paddingRight:20, marginTop:20}}>
                        {this.state.searchMomentumText.length > 0? 
                            this.state.isSearching? 
                            <div style={{width:'100%', marginTop:75}}>
                                <p style={{textAlign:'center', fontSize:22, fontWeight:'bold'}}> Loading...</p>
                            </div>:
                            this.state.momDataSource.length === 0?<p style={{textAlign:'center', fontSize:22, fontWeight:'bold'}}> No results found try a different ticker...</p>:
                            <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center',justifyContent:'center', flexWrap:'wrap', marginLeft:15, }}>
                                {this.state.momDataSource.map(ele=>this.createBox(ele))}
                            </div>
                            // this.state.momDataSource.map(ele =>
                            //     <div onClick={()=>{
                            //             let list = []
                            //             this.props.myWatchlist.forEach(ele=>list.push(ele.symbol))
                            //             if(!list.includes(ele.symbol)){
                            //                 this.props.addWl(ele)
                            //                 this.setState({showAddModal:false})
                            //             }
                            //         }}
                            //         onMouseEnter={()=>this.setState({hover:ele})}
                            //         style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
                            //     >
                            //         <div style={{display:'flex', flexDirection: 'column', width:'100%', padding:10 }}>
                            //             <p style={{ margin: 0, float: 'left', fontWeight:'bold' }}>{ele.symbol}</p>
                            //             <p style={{ margin: 0, float: 'left' }}>{ele.company}</p>
                            //         </div>
                            //     </div>
                            // )
                        :
                            <div style={{width:'100%', marginTop:75}}>
                                <p style={{textAlign:'center'}}> Search above to see results</p>
                            </div>
                        }
                        </div>
                        <div>
                            {this.showError()}
                        </div>
                    </div>
                </div>
            )
        }       
    }
    
    

    individuleCompanyCard = (data) => {
        
        return (
            <Row
                style={{
                    // padding: "2%",
                    textDecoration: "none",
                }}
            >
                {data.map((item, index) => {
                    return (
                        <SingleCompanyCard user={this.props.user} remove={this.props.removeFromWl} editMode={this.state.editMode} item={item} index={index} />
                    );
                })}
            </Row>
        );
    };

    countTickers = ()=>{
        
        let data
        if (this.state.shownList === 'My Watchlist') {
            data = this.props.myWatchlist
        } else {
            data = this.props.presetTickers[this.state.shownList]
        }
        if(data){
            return data.length
        }
        
        // return this.props.myWatchlist.length
    }

    showSuccess = () => {
        if (this.state.success) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(234,255,219)', borderRadius: 10, padding: 10, marginBottom: 5, width: '100%' }}>
                        <p style={{ margin: 0, textAlign:'center' }}>{this.state.success}</p>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    removeSuccess=()=>{
        setTimeout(()=>this.setState({success:false}),5000)
    }

    createBox = (ele)=>{
        // console.log('inside create box', ele)
        return (
            <div 
            // onClick={()=>{
            //     this.owned(this.state.acc,ele.symbol)
            //     this.getLivePrice(ele.symbol)
            //     this.setState({searchMomentumText:'', selected: ele, limitPrice:ele.priceUSD})
            // }}
            onClick={()=>{
                let list = []
                this.props.myWatchlist.forEach(ele=>list.push(ele.symbol))
                if(!list.includes(ele.symbol)){
                    this.props.addWl(ele)
                    this.setState({showAddModal:false, success:`${ele.symbol} has been added to your watchlist`})
                    this.removeSuccess()
                }
                else{
                    this.setState({errors: `${ele.symbol} is already on your watchlist`})
                }
            }}
            onMouseEnter={()=>this.setState({hover:ele})}
            onMouseLeave={()=>this.setState({hover:''})}
            style={{width:100,height:175, marginBottom:10, marginRight:15}}
            // style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
        >
            <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:this.state.hover === ele? 'rgb(225,225,225)':'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:100, height:100,justifySelf:'flex-end',border:'solid',borderWidth:'0.25px' }}>
            {ele.imageURL === "not-found"?
                <div style={{objectFit:'contain', width:50,height:50,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(17,17,17)',borderRadius:10 }}
                >
                    <p style={{margin:0, fontSize:24,fontWeight:'bold', color:'rgb(255,255,255)'}}>{ele.symbol[0]}</p>
                </div>
                :
                <img 
                    // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
                    src={ele.imageURL}
                    style={{objectFit:'contain', width:80,height:80,padding:10, }}
                />}
            </div>
            <div style={{display:'flex', flexDirection: 'column', width:'100%', padding:10 }}>
                <p style={{ margin: 0, textAlign: 'center', fontWeight:'bold' }}>{ele.symbol}</p>
                <p style={{ margin: 0, textAlign: 'center',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>{ele.company}</p>
            </div>
        </div>
            )
        }
    
    showError = ()=>{
        if(this.state.errors){
            return(
                <div style={{display:'flex', justifyContent:'center',alignItems:'center',margin:'auto', width:'60%', backgroundColor:'rgb(253,180,181)', padding:12, marginBottom:10, borderRadius:8,}}>
                    <p style={{margin:0, textAlign:'center'}}>
                        {this.state.errors}
                    </p>
                </div>
            )
        }
    }

    showTickers = () => {
        let data =  this.props.myWatchlist
        // if (this.state.shownList === 'My Watchlist') {
        //     data = this.props.myWatchlist
        // } else {
        //     data = this.props.presetTickers[this.state.shownList]
        // }
        let start = this.state.start
        let end
        if(data.length < start + 15){
            end = data.length 
        }else{
            end = start + 15
        }
        // let mappedData = [{ columnTitle:true, priceUSD:0, direction:0, directionPrev:0, symbol:'' }, ...data.slice(start,end)].map(ele=>(<HeaderDropdown size={'large'} remove={this.props.removeFromWl} editMode={this.state.editMode} companyName={ele.company} rf={ele.returnForecast} columnTitle={ele.columnTitle} priceUSD={ele.priceUSD} directionPrev={ele.directionPrev} direction={ele.direction} symbol={ele.symbol}/>))
        // let mappedData = [{ columnTitle:true},{subheading:true}, ...data.slice(start,end)].map(ele=>{
        let mappedData = [{subheading:true},{ columnTitle:true}, ...data.slice(start,end)].map(ele=>{
            // let ele1 = Object.values(ele)[0]
          return (<HeaderDropdown size={'large'} subheading={ele.subheading} img={ele.imageURL} remove={this.props.removeFromWl} editMode={this.state.editMode} companyName={ele.company} needOptimization={ele.needOptimization} rf={ele.returnForecastValue} columnTitle={ele.columnTitle} priceUSD={ele.priceUSD} directionPrev={ele.directionPrev} direction={ele.direction} symbol={ele.symbol}/>)
      })
        return (
            <Card
                style={{
                    width: "100%",
                    // marginTop: 20,
                    border:'none'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width:'100%' }}>
                    {/* {this.state.shownList === 'My Watchlist' ?<div style={{width: '20%'}}/> : null} */}
                    {/* <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold' }}>
                        {this.props.isSmallScreen? 'click a symbol for more info' :'select a ticker for more information'}
                    </p> */}
                    {/* {this.state.shownList === 'My Watchlist' ?
                        <div style={{ width: '30%',marginTop: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                            <p style={{ backgroundColor: 'rgb(248,248,247)', padding: 10, borderRadius: 8, fontSize: 16, margin:0}} onClick={()=>this.setState({editMode: !this.state.editMode})}>{this.props.isSmallScreen? 'Edit' :'Edit Watchlist'}</p>
                        </div>:<div style={{width:'30%'}}/>
                    } */}
                </div>
                {/* <div style={{display:'flex', justifyContent: 'space-around', paddingTop: 10,paddingLeft: 10, paddingRight: 10, paddingBottom: 0, marginBottom:0 }}>
                <p style={{ margin:0, fontWeight: 'bold',color: '#a8a6a5'}}> Trending Even</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#25d09c'}}> Trending Up</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#e31111'}}> Trending Down</p>
            </div>*/}
                <div style={{width:'100%' , display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', padding:'2%',margin:this.props.isSmallScreen?'':'auto' }}>
                    {/* {this.props.isSmallScreen ? <MobileWl remove={this.props.removeFromWl} editMode={this.state.editMode} data={data}/> :this.individuleCompanyCard(data)} */}
                    {this.props.isSmallScreen ? 
                        <MobileWl remove={this.props.removeFromWl} editMode={this.state.editMode} data={data}/> 
                        : 
                        <div className="mapped" style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'100%'}}>
                            {
                              mappedData
                            }
                        </div>
                }
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-around', width:'80%', margin:'auto', marginTop:0, marginBottom: 5}}>
                        {this.props.isSmallScreen || this.props.myWatchlist.length < 16? null :
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center', borderRadius: 8, padding:5, backgroundColor:'rgb(200,200,200)', cursor:'pointer', }} 
                                onClick={()=>{
                                    let start = this.state.start
                                    if(this.state.start-15>=0){
                                        window.scrollTo(0, 400)
                                        start-=15
                                    }else{
                                        console.log('out of bounds')
                                    }
                                    this.setState({start})
                                }}
                                >
                                {/* <p style={{margin:0}}>Back</p> */}
                                <FaArrowLeft />
                            </div>
                        }
                        {this.props.isSmallScreen || this.props.myWatchlist.length < 16? null :
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center', borderRadius: 8, padding:5, backgroundColor:'rgb(200,200,200)', cursor:'pointer', }} 
                                onClick={()=>{
                                    let start = this.state.start
                                    console.log('in clickEvent')
                                    if(this.state.start+15<=data.length){
                                        window.scrollTo(0, 400)
                                        start+=15
                                    }else{
                                        console.log('out of bounds')
                                    }
                                    this.setState({start})
                                }}
                            >
                                {/* <p style={{margin:0}}>Next</p> */}
                                <FaArrowRight/>
                            </div>
                        }
                    </div>
                    <div style={{margin:'auto',marginTop:10}}>
                        <p style={{fontSize:14}}>*All 30 day forecasts (medium term) and trends (short term, 2 hours - 2 days) are generated by our AI forecasting model. They are not intended to be advice or recomendation to buy or sell any stocks.</p>
                        {/* <p style={{fontSize:14}}>*All 30 day forecasts and short term trends are generated by our AI forecasting model. They are not intended to be advice or recomendation to buy or sell any stocks.</p> */}
                    </div>
            </Card>
        );
    }

    showLists = () =>{
        let categories = ['My Watchlist']
        Object.keys(this.props.presetTickers).forEach(ele=>categories.push(ele))
        categories = categories.filter(ele => ele !== this.state.shownList)
        // let mapped = categories.map(ele => <li onClick={()=>this.setState({shownList: ele, showListOptions: false, editMode: false })} style={{cursor: 'pointer', fontWeight: 'bold', width: '100%', margin: 'auto', marginLeft: '15%'}}>{ele}</li>)
        let mapped = categories.map(ele=><li style={{marginBottom: 5, cursor:'pointer', backgroundColor: this.state.shownList !== ele ? 'rgb(255,255,255)':brandColor , padding: 7, borderRadius:5, width:'100%'}} onClick={()=>this.setState({shownList:ele, showListOptions:false})}>{ele}</li>)

        return(
            <ul style={{ backgroundColor: 'rgb(251,251,253)', listStyleType: 'none', padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', borderRadius: 12, marginTop: -15, zIndex: 100}}>
                {mapped}
            </ul>
        )
    }

    remove = (symbol) =>{
        let filtered = this.props.myWatchlist.filter(ele=> ele.symbol !== symbol)
        this.setState({myWatchlist: filtered})
    }

    render(){
        // console.log('wl props', this.props)
        if (this.props.user == null){
            return(
                <div>
                    <Header user={this.props.user} signOut={this.props.signOut} />
                    <div>
                        <div style={{ marginTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <NavLink to={"/Login"} style={{ fontWeight: "bold", fontSize: 20, color:'#00A99D', backgroundColor: 'rgb(247,247,247)', borderRadius: 12, padding: '10px 20px'}}>
                                Please Login to see your watchlist
                            </NavLink>
                        </div>
                    </div>
                </div>
            )
        }else{
            // let data
            // if (this.state.shownList === 'My Watchlist') {
            //     data = this.props.myWatchlist
            // } else {
            //     data = this.props.presetTickers[this.state.shownList]
            // }
        return(
            <div style={{ paddingLeft: '2%', paddingRight:'2%',paddingTop:'2%', paddingBottom:'2%',borderRadius: '50px', backgroundColor:'rgb(255,255,255)'}}>
                <div style={{}}>
                    <div>
                        <TickerTape isSmallScreen={this.props.isSmallScreen}/>
                    </div>
                    <div style={{ width:'100%', marginTop: 30, display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto' }}>
                        <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', width:'95%'}}>
                            <div style={{display:'flex', flexDirection: 'column'}}>
                                <div /*onClick={() => this.setState({ showListOptions: !this.state.showListOptions })}*/ style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', cursor:'pointer',}}>
                                    <p style={{margin:0, fontSize: '1.5em'}}>
                                        {/* {this.state.shownList} */} My Watchlist
                                    </p>
                                    {/* <div  style={{marginLeft: 5, marginTop: 3}}>
                                        <FaChevronDown size={20} />
                                    </div> */}
                                </div>
                                <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                                    <p style={{margin:0}}>{this.countTickers()} Assets</p>
                                </div>
                                {/* <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                                    <p style={{margin:0}}>Last Changed: {}</p>
                                </div> */}
                            </div>
                            {this.showSuccess()}
                            <div style={{display:'flex',}}>
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', border:'solid', borderRadius:'5%',borderWidth:'.25px', borderColor: '#3b8e8c', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10,}} onClick={()=>this.setState({showAddModal:true})}>
                                    <p style={{margin:0,fontWeight:'500', color:'#3b8e8c'}}>Add New</p>
                                </div>
                                <div onClick={this.state.editMode? ()=>this.setState({editMode: false, refresh:true}) : ()=>this.setState({editMode: true, refresh:false})} style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', border:'solid', borderRadius:'5%',borderWidth:'.25px', borderColor: '#242a38', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10,textAlign:'center'}}>
                                    <p style={{margin:0,fontWeight:'500', color:'#242a38'}}>{this.state.editMode? 'Stop Edit':'Edit List'}</p>
                                </div>
                                {/* <div style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', border:'solid', borderRadius:'5%',borderWidth:'1.25', borderColor: '#ee5e68', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, backgroundColor:'#ee5e68'}}>
                                    <p style={{margin:0,fontWeight:'500', color:'#ffffff'}}>Delete</p>
                                </div> */}
                            </div>

                        </div>
                    </div>
                    {/* <div style={{ zIndex: 100, position: 'absolute' , display:'block', width: this.props.isSmallScreen? '50%' : '20%', left: this.props.isSmallScreen?'25%':'10%'   }}>
                        {this.state.showListOptions? this.showLists() : null}
                    </div> */}
                    <div style={{ /*marginTop: 20,*/ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                        {this.props.myWatchlist.length === 0?
                            <div>
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', border:'solid', borderRadius:'5px',borderWidth:'.25px', borderColor: '#3b8e8c', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10,}} onClick={()=>this.setState({showAddModal:true})}>
                                    <p style={{margin:0,fontWeight:'500', color:'#3b8e8c'}}>Set up your Watchlist by adding stocks!</p>
                                </div>
                            </div> 
                        :
                            this.showTickers()
                        }
                    </div>
                    
                </div>
                {this.addModal()}
            </div>
        )}
    }
}