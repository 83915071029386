import React from 'react'
import RoiChart from '../roiChart'
import BudgetCategory from './BudgetCategory'
import ENDPOINT from '../Endpoint'
import { PlaidLink } from 'react-plaid-link';

import { /**FaBars,*/ FaChevronRight }  from "react-icons/fa";

export default class Budgeting extends React.Component{
    state={
        // notifications:[
        //     {title:'Rent', price:'2,500', notificationTime:'1/24/21', type:'upcoming payment', actionDate:'1/31/21'},
        //     {title:'Store Name', price:'2,500', notificationTime:'1/24/21', type:'unusual transaction',actionDate:'1/24/21'},
        //     {title:'Rent', price:'2,500', notificationTime:'1/24/21', type:'upcoming payment', actionDate:'1/31/21'},
        //     {title:'Store Name', price:'2,500', notificationTime:'1/24/21', type:'unusual transaction',actionDate:'1/24/21'},
        //     {title:'Rent', price:'2,500', notificationTime:'1/24/21', type:'upcoming payment', actionDate:'1/31/21'},
        //     {title:'Store Name', price:'2,500', notificationTime:'1/24/21', type:'unusual transaction',actionDate:'1/24/21'},
        //     {title:'Rent', price:'2,500', notificationTime:'1/24/21', type:'upcoming payment', actionDate:'1/31/21'},
        //     {title:'Store Name', price:'2,500', notificationTime:'1/24/21', type:'unusual transaction',actionDate:'1/24/21'},
        // ],
        // frequency: 'Monthly',
        // strategy: 'U.S. Domestic Composite',
        // reccuring: 100,
        // initial: 100,
        data: 'loading',
        today:false,
        month:false,
        modal:false,
    }

    componentDidMount(){
        this.getBudgetData()
        this.currentDate()
    }

    getBudgetData = ()=>{
        let url = `${ENDPOINT}/dw/budgeting/`
        fetch(url,{
            method:'POST',
            headers:{
                Authorization: "Bearer " + this.props.user.idToken.jwtToken
            },
            body:JSON.stringify({
                // startDate:null
            }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log('budgetData', responseJson)
            this.setState({data:responseJson.payload})
        })
    }


    notificationRow = (notif)=>{
        let message =''
        if(notif.message){
            message = notif.message
        }
        else if (notif.type === 'upcoming payment'){
            message = `You have an upcoming ${notif.title} payment of ${notif.price} on ${notif.actionDate}`
        }
        else if(notif.type === 'unusual transaction'){
            message = `We noticed an unusual transaction of ${notif.price} at ${notif.title} on ${notif.actionDate}`
        }
        else if(notif.content === 'Some deposit'){
            message = 'You successfully deposited $2,000.000'
        }
        return(
            <div style={{width:'95%', margin:'auto', display:'flex', justifyContent:'space-between', borderBottom:'solid', borderWidth:'0.25px', borderColor:'#dddddd', padding:8}}>
                <div style={{width:'67%'}}>
                    <p style={{margin:0,fontSize:20}}>{notif.type.toUpperCase()}</p>
                    <p style={{margin:0,fontSize:18, color:'rgb(142,146,155)',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{notif.content}</p>
                </div>
                <div style={{width:'30%'}}>
                    <p style={{margin:0,fontSize:18, fontWeight:'bold',textAlign:'right'}}>{this.shiftDate(notif.dateGenerated)}</p>
                    <p style={{margin:0}}>&nbsp;</p>
                </div>
            </div>
        )

    }

    currentDate(){
        let monthsAb = ['Jan', 'Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec',]
        let monthsFull = ['January', 'February','March','April','May','June','July','August','September','October','November','December',]
        let today = new Date();
        let dd = String(today.getDate());
        let mm = String(today.getMonth()); //January is 0!
        let yyyy = today.getFullYear();
        // today = mm + '/' + dd + '/' + yyyy;
        today = monthsAb[mm] + ' ' + dd + ', ' + yyyy;
        this.setState({today, month:monthsFull[mm]}) 
    }

    plaidHandleOnSuccess = (public_token, metadata)=> {
        // send token to client server
        // fetch("/auth/public_token", {
        //   public_token: public_token
        // });
        let accountId = metadata.account_id
        let itemId = metadata.institution.institution_id
        this.linkPlaidAccount(public_token, metadata)
        // let resp = this.findOtherPortfoliosSameInstitution(itemId)
        // if (resp.length > 0){
        //     this.addOtherPortSameInstituion(accountId, itemId) 
        // }
        // this.setState({respMeta:metadata, respToken:public_token})
    }

    linkPlaidAccount = (token, meta)=>{
        let id = meta.account_id
        let mask = meta.account.mask
        let name = meta.account.mask

        let inst_id = meta.institution.institution_id
        let inst_name = meta.institution.name
        let public_token = token

        let body = { 
            accounts:[ {  id, mask, name } ],
            inst_id,
            inst_name,
            public_token,
            type:this.props.bank? "depository":"investment",
            subType: 'subtype'
            // env:
        }
        // console.log('body: ', body)

        // fetch(`${ENDPOINT}/plaid/link`,{
        fetch(`https://api.allocaterite.com/mobile-plaid/plaid/link`,{
            method:'POST',
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(respJSON=>console.log('respJSON: ', respJSON))
        .catch(err=>console.log('catching: ', err))
    }

    addCommas(x){
        if(x === ''){
            return ''
        }else{
            let arr = x.toString().split(".")
            if(arr[1]){
                return (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))+ '.' + arr[1]
            }
            else{
                return  (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            }
        } 
    }

    getCashFlow =()=>{
        let income = this.state.data.budgeting.incStat.income.sum
        let essExpense = this.state.data.budgeting.incStat.essExpense.sum
        let nonEssExpense = this.state.data.budgeting.incStat.nonEssExpense.sum
        let debtPay = this.state.data.budgeting.incStat.debtPay.sum
        return income - essExpense - nonEssExpense
    }

    shiftDate(dt){
        let arr = dt.split('-')
        return arr[1].replace(/^0+/, '') + '/' + arr[2].replace(/^0+/, '')+ '/' + arr[0]
    }

    render(){

        if(this.state.data === 'loading'){
            return(
                <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%', marginBottom:50}}>
                    <p style={{fontSize:24, fontWeight:'bold'}}>Loading...</p>
                </div>
            )
        }

        // income statement
        let income = this.state.data.budgeting.incStat.income.sum
        let essExpense = this.state.data.budgeting.incStat.essExpense.sum
        let nonEssExpense = this.state.data.budgeting.incStat.nonEssExpense.sum
        let debtPay = this.state.data.budgeting.incStat.debtPay.sum

        //Assets
        let cash = this.state.data.budgeting.finStat.asset.sumDepository
        let investments = this.state.data.budgeting.finStat.asset.sumInvestment

        //Liablities / what you owe
        let creditCard = this.state.data.budgeting.finStat.liability.sumCredit
        let loans = this.state.data.budgeting.finStat.liability.sumLoan

        //totals
        let liqNetWorth = this.state.data.budgeting.finStat.finStatSummary.liqNetWorth
        let netWorth = this.state.data.budgeting.finStat.finStatSummary.netWorth
        

        return(
            <div style={this.props.isSmallScreen?{width:'95%', margin:'auto' }:{width:'98%',display:'flex', alignItems:'flex-start', justifyContent:'space-between',margin:'auto' }}>
                
                <div style={{ paddingLeft: '2%', paddingRight:'2%',paddingTop:'2%', paddingBottom:'2%',borderRadius: '50px', backgroundColor:'rgb(255,255,255)', minWidth:300, width:'55%'}}>
                    <div style={{width: '90%', margin:'auto', textAlign:'left', display:"flex", justifyContent:'space-between'}}>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <p style={{margin:0, fontWeight:'bold', fontSize:32}}>
                                <strong style={{color:'rgb(97,162,155)'}}>${this.addCommas(netWorth.toFixed(2))}</strong>
                            </p>
                            <p style={{margin:0,fontWeight:400}}>Current Balance</p>
                        </div>
                        <div style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                            <p style={{margin:0,fontWeight:'bold', fontSize:20}}>{this.state.today?this.state.today:null}</p>
                        </div>
                    </div>
                    <div style={{width: '90%', margin:'auto', marginTop:25}}>
                        <div style={{width:'100%', paddingLeft:15,borderRadius:'15px 15px 0px 0px', backgroundColor:'rgb(33,37,49)', margin:'auto', padding:8}}>
                            <p style={{fontSize:'large' ,color:'rgb(255,255,255)', margin:0, fontWeight:'bold' }}>Assets</p>
                        </div>
                        <div style={{width:'100%', margin:'auto',backgroundColor:'rgb(243,243,243)', marginBottom:20}}>
                            <div style={{margin:'auto', paddingLeft:10, paddingRight:10, }}>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between',paddingTop:15, }}>
                                    <p style={{fontSize:'large' ,margin:0,fontWeight:'bold'}}>CASH</p>
                                    <p style={{fontSize:'large' ,margin:0,}}>${this.addCommas(cash.toFixed(2))}</p>
                                </div>
                                <hr></hr>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                                    <p style={{fontSize:'large' ,margin:0,fontWeight:'bold'}}>INVESTMENTS</p>
                                    <p style={{fontSize:'large' ,margin:0,}}>${this.addCommas(investments.toFixed(2))}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div style={{width: '90%', margin:'auto', marginTop:25}}>
                        <div style={{width:'100%', paddingLeft:15,borderRadius:'15px 15px 0px 0px', backgroundColor:'rgb(33,37,49)', margin:'auto', padding:8}}>
                            {/* <p style={{fontSize:'large' ,color:'rgb(255,255,255)', margin:0,fontWeight:"bold" }}>Liabilities</p> */}
                            <p style={{fontSize:'large' ,color:'rgb(255,255,255)', margin:0,fontWeight:"bold" }}>Owed</p>
                        </div>
                        <div style={{width:'100%', margin:'auto',backgroundColor:'rgb(243,243,243)', marginBottom:20}}>
                            <div style={{margin:'auto', paddingLeft:10, paddingRight:10, }}>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between',paddingTop:15, }}>
                                    <p style={{fontSize:'large' ,margin:0,fontWeight:'bold'}}>CREDIT CARD</p>
                                    <p style={{fontSize:'large' ,margin:0,}}>${this.addCommas(creditCard)}</p>
                                </div>
                                <hr></hr>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                                    <p style={{fontSize:'large' ,margin:0,fontWeight:'bold'}}>LOANS</p>
                                    <p style={{fontSize:'large' ,margin:0,}}>${this.addCommas(loans)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{width: '90%', margin:'auto', marginTop:25}}>
                            <p style={{margin:0, fontWeight:'bold', fontSize:24, textAlign:'center'}}>
                                <strong style={{color:'rgb(97,162,155)',}}>Transactions for {this.state.month? this.state.month + ' ' +this.state.today.split(" ")[2]:'this month'}</strong>
                            </p>
                        <div style={{width:'100%', paddingLeft:20,paddingRight:20, border:'solid', borderWidth:'0.5px', borderColor:'#dddddd'}}>
                            <div style={{borderBottom:'solid',borderWidth:'0.25px', borderColor:'#dddddd', display:'flex', alignItems:'center', justifyContent:'space-between', cursor:'pointer'}} onClick={()=>this.setState({modal:'income'})}>
                                    <p style={{margin:0, paddingTop:20,paddingBottom:20}}>Income</p>
                                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                    <p style={{margin:0, paddingTop:20,paddingBottom:20}}>${this.addCommas(income.toFixed(2))}</p>
                                    <FaChevronRight/>
                                </div>
                            </div>
                            <div style={{borderBottom:'solid',borderWidth:'0.25px', borderColor:'#dddddd', display:'flex', alignItems:'center', justifyContent:'space-between', cursor:'pointer'}} onClick={()=>this.setState({modal:'essEx'})}>
                                <p style={{margin:0, paddingTop:20,paddingBottom:20}}>Essential Expenses</p>
                                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                    <p style={{margin:0, paddingTop:20,paddingBottom:20}}>${this.addCommas(essExpense.toFixed(2))}</p>
                                    <FaChevronRight/>
                                </div>
                            </div>
                            <div style={{borderBottom:'solid',borderWidth:'0.25px', borderColor:'#dddddd', display:'flex', alignItems:'center', justifyContent:'space-between', cursor:'pointer'}} onClick={()=>this.setState({modal:'nonEssEx'})}>
                                <p style={{margin:0, paddingTop:20,paddingBottom:20}}>Non Essential Expenses</p>
                                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                    <p style={{margin:0, paddingTop:20,paddingBottom:20}}>${this.addCommas(nonEssExpense.toFixed(2))}</p>
                                    <FaChevronRight/>
                                </div>
                            </div>
                            <div style={{borderBottom:'solid',borderWidth:'0.25px', borderColor:'#dddddd', display:'flex', alignItems:'center', justifyContent:'space-between', cursor:'pointer'}} onClick={()=>this.setState({modal:'debt'})}>
                                <p style={{margin:0, paddingTop:20,paddingBottom:20}}>Debt</p>
                                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                    <p style={{margin:0, paddingTop:20,paddingBottom:20}}>${this.addCommas(debtPay.toFixed(2))}</p>
                                    <FaChevronRight/>
                                </div>
                            </div>
                            <div style={{borderBottom:'none',borderWidth:'0.25px', borderColor:'#dddddd', display:'flex', alignItems:'center', justifyContent:'space-between', cursor:'pointer'}} /*onClick={()=>this.setState({modal:'cashFlow'})}*/>
                                <p style={{margin:0, paddingTop:20,paddingBottom:20}}>Cash Flow</p>
                                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                    <p style={{margin:0, paddingTop:20,paddingBottom:20}}>${this.addCommas(this.getCashFlow().toFixed(2))}</p>
                                    {/* <FaChevronRight/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{minWidth:350, width:'40%'}}>
                    <div style={{ paddingLeft: '2%', paddingRight:'2%',paddingTop:'5%', paddingBottom:'5%',borderRadius: '50px', backgroundColor:'rgb(255,255,255)',minWidth:350, width:'40%', marginBottom:10}}>
                        <div style={{paddingLeft:20, paddingRight:20}}>
                            <p style={{fontSize:22, margin:0, textAlign:'left'}}> To get your full and complete financial picture, link all your accounts. </p>
                            {/* <p style={{fontSize:24, margin:0, textAlign:'left'}}> Get the most ForeSCITE </p>
                            <p style={{fontSize:24, margin:0, textAlign:'left'}}> and link another</p> */}
                            <div style={{}}>
                            <div onClick={()=>this.setState({filter:'bank'})}>

                            <PlaidLink
                                clientName="AllocateRite"
                                // env="sandbox"
                                env="production"
                                product={["transactions"]}
                                publicKey="3e65a03171afbab180fa7198f87cef"
                                onExit={this.handleOnExit}
                                onSuccess={this.plaidHandleOnSuccess}
                                linkCustomizationName='bank'
                                // className="test"
                                style={{ cursor:'pointer', backgroundColor:'transparent',border:'none', display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}
                                // style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold'}}
                                // style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer',}}
                            >
                                <div style={{backgroundColor:'rgb(97,162,155)',padding: '10px 20px',borderRadius: '10px', width:'70%', marginBottom:5}}>
                                    <p style={{fontSize:14, margin:0, textAlign:'center', fontWeight:'bold', color:'rgb(255,255,255)'}}>Bank Account</p>
                                </div>
                            </PlaidLink>
                            </div>
                            <div onClick={()=>this.setState({filter:'inv'})}>

                            <PlaidLink
                                clientName="AllocateRite"
                                // env="sandbox"
                                env="production"
                                product={["investments"]}
                                publicKey="3e65a03171afbab180fa7198f87cef"
                                onExit={this.handleOnExit}
                                onSuccess={this.plaidHandleOnSuccess}
                                linkCustomizationName='brokerage'
                                // className="test"
                                style={{ cursor:'pointer', backgroundColor:'transparent',border:'none', display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}
                                // style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold'}}
                                // style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer',}}
                            >
                                <div style={{backgroundColor:'rgb(97,162,155)',padding: '10px 20px',borderRadius: '10px', width:'70%'}}>
                                    <p style={{fontSize:14, margin:0, textAlign:'center', fontWeight:'bold', color:'rgb(255,255,255)'}}>Brokerage Account </p>
                                </div>
                            </PlaidLink>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingLeft: '2%', paddingRight:'2%',paddingTop:'2%', paddingBottom:'2%',borderRadius: '50px', backgroundColor:'rgb(255,255,255)',minWidth:350, width:'40%'}}>  
                        <div style={{display:'flex', justifyContent:'flex-start', flexWrap:'wrap'}}>
                            <div style={{paddingLeft:16}}>
                                <p style={{fontSize:24, margin:0}}>Spending Alerts</p>
                            </div>
                            <div style={{paddingLeft:16,paddingRight:16,paddingTop:5,paddingBottom:5, borderRadius:12, backgroundColor:'rgb(97,162,155)', marginLeft:'15px'}}>
                                <p style={{fontSize:18, color:'rgb(255,255,255)', margin:0,fontWeight:'bold'}}>{this.state.data.notifications.length}</p>
                            </div>
                        </div>
                        <div style={{marginTop:25}}>
                            {this.state.data.notifications.map(ele=>this.notificationRow(ele))}
                        </div>
                    </div>
                </div>
                {this.state.modal? <BudgetCategory data={this.state.data.budgeting.incStat} closeModal={()=>this.setState({modal:false})} category={this.state.modal}/>:null}
            </div>
        )
    }
            // return(
               
            //     <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12, marginBottom:25}}>
            //     <div style={{width:'95%', margin:'auto',marginBottom:25}}>
            //         {/*                     
            //             <img
            //                 src={}
            //             /> 
            //         */}
            //         <h3 style={{color:'rgb(17,17,17)', textAlign:'center'}}>Budgeting</h3>
            //         <h5 style={{color:'rgb(17,17,17)', textAlign:'center'}}>All your finances in one place</h5>
            //     </div>
            //     <hr></hr>
            //     <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
            //         <div style={{width:'47%', }}>
            //             <p style={{fontSize:18}}>Understand your spending habits </p>
            //             <p style={{fontSize:18}}>Link your bank account to better understand your spending</p>
            //             <p style={{fontSize:18}}>ForeSCITE's AI will help you save by studying your habits and finding money for you to save and invest</p>
            //             <p style={{fontSize:18}}>We will also warn you of any suspicious activity or large upcoming payments to make you sure you stay on top of your expenses </p>
            //         </div>
            //         <div style={{width:'47%'}}>
            //             <div style={{width:'100%', margin:'auto'}}>
            //                 <p>Your Complete Financial Picture</p>
            //             </div>
            //             <div>
            //                 <RoiChart strategy={this.state.strategy} initial={this.state.initial} reccuring={this.state.reccuring}/>
            //             </div>
            //             <div style={{display:'flex', flexDirection:'column',width:'80%', margin:'auto'}}>
            //                 <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', }}>
            //                     <div>
            //                         <p style={{margin:0}}>Strategy</p>
            //                         <select onChange={(e)=>this.setState({strategy:e.target.value})} value={this.state.strategy}>
            //                             <option value="U.S. Domestic Composite" selected={this.state.strategy === "U.S. Domestic Composite"}>{"U.S. Domestic Composite"}</option>
            //                             <option value="Diversified International Composite" selected={this.state.strategy === "Diversified International Composite"}>{"Diversified International Composite"}</option>
            //                             {/* <option value="Global Dynamic Blend" selected={this.state.strategy === "Global Dynamic Blend"}>{"Global Dynamic Blend"}</option> */}
            //                         </select>
            //                     </div>
            //                     <div style={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
            //                             <p style={{margin:0, textAlign:"right"}}>Initial Investment (USD)</p>
            //                             <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
            //                                 $<input type="text" placeholder="xxxxx" value={this.state.initial} onChange={(e)=>this.setState({initial:e.target.value})}/>
            //                             </div>
            //                         </div>
            //                 </div>
            //                 <div style ={{marginTop:10, }}>
            //                     <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', }}>
            //                         <div>
            //                             <p style={{margin:0}}>Frequency</p>
            //                             <div style={{display: "flex", justifyContent:'space-around', alignItems:'center'}}>
            //                                 <input style={{marginRight:5}} type="radio" value="Monthly" checked={this.state.frequency === "Monthly"} onClick={()=>this.setState({frequency:"Monthly"})}/> 
            //                                 <p style={{margin:0,marginRight:12}}>
            //                                     Monthly
            //                                 </p>
            //                                 <input style={{marginRight:5}} type="radio" value="Annually" checked={this.state.frequency === "Annually"} onClick={()=>this.setState({frequency:"Annually"})}/> 
            //                                 <p style={{margin:0,marginRight:12}}>
            //                                     Annually
            //                                 </p>
            //                             </div>
                                          
            //                         </div>
            //                         <div style={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
            //                             <p style={{margin:0}}>Recurring Deposit Amount (USD)</p>
            //                             <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
            //                                 $<input type="text" placeholder="xxxxx" value={this.state.reccuring} onChange={(e)=>this.setState({reccuring:e.target.value})}/>
            //                             </div>
            //                         </div>
            //                         </div>
            //                     </div>
            //             </div>
            //         </div>
            //     </div>
            //     <div style={{display:'flex',height:'100%', justifyContent:'center',alignItems:'center',marginTop:25}}>
            //           <LinkToPlaid bank={true} button={true} user={this.props.user} numAccounts={/*this.props.linkedPorts.length*/0}/>

            //     </div>
            //     </div>
            

            // )
}