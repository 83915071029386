import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterPageTitle from "../layout/FooterPageTitle";
import ChineseFooter from "../layout/ChineseFooter";
import Header from "../layout/Header"

export class ChineseTermsOfUse extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
        <div>
          <FooterPageTitle title="使用条款" />
          <Container>
            <Row />
            <Row className="terms-top-paragraph">
              <p className="terms-text">
                在浏览这个网页的过程中，意味着您同意并且理解关于这个网页以及网页上内容的使用条款以及相关法律信息。如果您接受并同意不对相关的条款，条件以及公告进行修改，在接受所有条款，条件以及公告的情况下您才可浏览网站。您对这个网页的使用就说明了您对所有条款，条件以及公告的认可同意。AllocateRite
                保留一切对本网页条款，条件以及公告修改的权利。
              </p>
              <p className="terms-text">
                请您认真阅读网页中的所有材料，包括所有脚注，法律免责声明，限制或者披露，以及任何版权或者所有权声明。任何应用在部分文件或者材料中的免责声明，限制，披露或者对冲条款与应用在整体材料中的相同，并且将被视作您咨询或者下载中的一部分。
              </p>
            </Row>
            <Row className="terms-paragraph-row">
              <h3>网站所有权和版权</h3>
              <p className="terms-text">
                AllocateRite的网页是由AllocateRite Holdings (GP)
                有限责任公司所拥有。AllocateRite或其授权人对AllocateRite网页中所有附带的截图，信息，材料，用户文档，用户界面，图片，信息安排，相关的软件以及AllocateRite或其授权人通过网页访问的其他专有财产视情况保留所有权。网页的所有权利都归AllocateRite或其授权人所有。该网页仅供您个人非商业使用。您不可以修改，传播，散播，展示，演示，再版，出版，许可，销售，或者模仿网页中的任何信息，软件，产品或者服务。
              </p>
            </Row>
            <Row className="terms-paragraph-row">
              <h3>未经授权访问</h3>
              <p className="terms-text">
                本网页并没有完全防止未授权的第三方的侵入。在阅读本网页的同时您需意识到在本网页上的任何信息都有可能被未授权的第三方通过网络访问。尽管AllocateRite已经做出了最大的努力来保护网页用户的隐私，但不能保证未授权的第三方无法访问该网页中的信息。在浏览本网页时，您
                认可AllocateRite将不会承担任何通知您未经授权第三方的访问及由于计算机网络传输或通信服务（不局限于互联网）过程中的数据泄露而引起责任。
              </p>
            </Row>
            <Row className="terms-paragraph-row">
              <h3>密码与安全</h3>
            </Row>
            <Row>
              <p className="terms-text">
                您需要对自己密码的保密以及使用而负责。您的密码是保障自身信息安全的重要途径。若您发现或者相信有未经授权的人获取了您的密码，请您立即与本公司联系。
              </p>
            </Row>
            <Row className="terms-paragraph-row">
              <h3>链接</h3>
            </Row>
            <Row>
              <p className="terms-text">
                AllocateRite并没有对本网页中无关联的链接进行审查，若存在，AllocateRite将不对这些外部网页或者这些外部网页内的链接所链接到的其他网页的内容所负责。因此您链接到的任何外部网页或者其他网页而引起的风险将由您自行承担。AllocateRite不对其链接中的第三方网页上发表的任何言论产生任何评论或观点。对于第三方网页上的所有内容，AllocateRite也不进行监管、控制和为其背书。AllocateRite对此类网站和言论不承担任何责任。
              </p>
              <p className="terms-text">
                我们的服务可能包含非AllocateRite
                LLC拥有或控制的第三方网站或服务的链接。
              </p>
              <p className="terms-text">
                AllocateRite
                LLC无法控制任何第三方网站或服务的内容，隐私政策或做法，也不承担任何责任。
                您进一步确认并同意，AllocateRite
                LLC不对因使用或依赖任何此类内容，商品或服务而造成或声称造成的或与之相关的任何损害或损失直接或间接承担责任或义务。
                通过任何此类网站或服务。
              </p>
              <p className="terms-text">
                我们强烈建议您阅读您访问的任何第三方网站或服务的条款和条件以及隐私政策。
              </p>
            </Row>
            <Row className="terms-paragraph-row">
              <h3>担保免责声明</h3>
            </Row>
            <Row>
              <p className="terms-text">
                本公司对本网页以及本网页包含的信息和材料，如文字，图片，软件，链接以及其他由包含“如是“，”可用“字样的其他信息，无论是明示还是默示，不进行任何形式的担保。在法律允许的最大程度下，AllocateRite不对网页以及网页中的任何信息的准确性，妥善性，完整性，可信性，时效性或者可用性承担任何责任，并且不对网页中的错误或者遗漏负责。对适销性，特殊意图的适合程度，非侵权，以及网页中其他任何形式的，明示或暗示的，法定的信息不存在任何形式的担保。本公司对网页中的任何信息修改都无需进行通知。AllocateRite对网页中功能的中断或错误，缺陷的修复以及可能对网页或者服务器造成损害的病毒或者其他有害物质不负任何责任。请注意，某些司法辖区不允许对某些担保的免责，因此上述部分或者全部内容可能不适用于您的情况。
              </p>
            </Row>
            <Row className="terms-paragraph-row">
              <h3>有限责任</h3>
              <p className="terms-text">
                在任何情况下，AllocateRite都不需要对您的计算机、个人或其他资产的任何形式上的伤害损失进行负责、维修或者更正。这些损失包括但不限于：对网页的连接使用，任何一方无法使用此网页，连接过程中的失败、错误、遗漏、中断、缺失、延迟操作或传输、计算机病毒或线路及系统的问题等等所造成的直接或间接，特殊、偶然或必然的损害或损失。
                即便AllocateRite或它的代表方都意识到服务过程中存在这类型伤害和损失发生的可能性，AllocateRite也不承担责任。
              </p>
            </Row>
            <Row className="terms-paragraph-row">
              <h3>帮助</h3>
              <Col className="terms-col" sm={12}>
                <p className="terms-text">
                  如果您需要关于网页的帮助或者您需要与AllocateRite联系，您可以与
                  <a className="terms-link" href="mailto:info@allocaterite.com">
                    AllocateRite的客户服务中心
                  </a>
                  联系。
                </p>
              </Col>
            </Row>
            <Row className="terms-paragraph-row">
              <h3>终止服务</h3>
            </Row>
            <Row>
              <p className="terms-text">
                AllocateRite可以以任何理由无需提前通知随时终止或者暂停对您的服务并无需承担任何责任，其情况包括但不限于您违反了条款。
              </p>
              <p className="terms-text">
                所有根据其性质仍然在终止服务后有效的条款应该在终止服务后仍然有效，包括但不限于，所有权规定，担保免责声明，有限责任赔偿。
              </p>
            </Row>
            <Row className="terms-paragraph-row">
              <h3>法律规定</h3>
            </Row>
            <Row>
              <p className="terms-text">
                这些条款应基于美利坚合众国法律进行管理和解释，而不考虑其法律条款的冲突。
              </p>
              <p className="terms-text">
                AllocateRite没有执行任何关于这些条款的权利将不会被视作对这些权利的放弃。如果这些条款当中存在部分被法院判定无效或者不可执行的条款，这些条款中其余的条款仍然有效。这些条款构成了双方之间的完整协议并且取代任何之前的任何双方协议。
              </p>
            </Row>
            <Row className="terms-paragraph-row">
              <h3>联系方式</h3>
            </Row>
            <Row>
              <p className="terms-text">
                如果您对这些条款存在疑问，请与
                <a
                  className="terms-contact-us"
                  href={`mailto:help@allocaterite.com`}
                >
                  本公司
                </a>
                联系。此使用条款请以英文版本为主，中文版本仅供参考之用。.
              </p>
              <p className="terms-text" />
            </Row>
            <Row className="terms-paragraph-row">
              <Col className="terms-bottom-row">
                <p className="terms-bottom-date">此版本更新于: 2018年6月27日</p>
              </Col>
            </Row>
          </Container>
          <ChineseFooter />
        </div>
      </div>
    );
  }
}

export default ChineseTermsOfUse;
