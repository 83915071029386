import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import StrategiesTitle from "../StrategiesTitle";
import StrategiesCharts from "../layout/StrategiesCharts";
import LineCharts from "../layout/LineCharts";
import FundDashboard from "../layout/FundDashboard";
import SecureYourFuture from "../layout/SecureYourFuture";
import ChineseStrategiesPolicy from "../layout/ChineseStrategiesPolicy";
import ChineseFooter from "../layout/ChineseFooter";
import Header from "../layout/Header"

export class ChineseStrategies extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  constructor(props) {
    super(props);
    this.state = {
      titles: [
        {
          id: 1,
          title: `24/7实施风险监控，更低的成本和更智能的税务管理引领您更顺畅的投资旅程`,
          subtitle: `在不同的条件下测试和验证事件。探索如何在长期提供卓越的风险调整回报`
        },
        {
          id: 2,
          title: `了解AllocateRite如何处理特定事件。`,
          subtitle: `因此，AllocateRite提供了卓越的风险调整回报。`
        }
      ]
    };
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
        <Container fluid>
          <Row className="page-blank-row" style={{height: 100}}/>
          <Row>
            <StrategiesTitle
              key={this.state.titles[0].id}
              value={this.state.titles[0]}
            />
          </Row>
          <Row>
            <StrategiesCharts isChinese={true} />
          </Row>
          <Row>
            <StrategiesTitle
              key={this.state.titles[1].id}
              value={this.state.titles[1]}
            />
          </Row>
          <Row>
            <LineCharts isChinese={true} />
          </Row>
          <Row>
            <FundDashboard />
          </Row>
          <Row>
            <ChineseStrategiesPolicy />
          </Row>
          <Row>
            <SecureYourFuture isChinese={true} />
          </Row>
          <Row className="page-blank-row" />
          <ChineseFooter />
        </Container>
        </div>
    );
  }
}

export default ChineseStrategies;
