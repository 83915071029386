import React from 'react'
import { Col, Card, Row, Button, } from "react-bootstrap";
// import ChangeSinceYest from "../changeSinceYest"
import ChangeSinceYest from "./ChangeSinceYest2"
import RiskNum from "./RiskNum"

const colored = [
    "rgb(41,253,47)",
    "rgb(51,253,47)",
    "rgb(69,253,47)",
    "rgb(69,253,47)",
    "rgb(90,253,47)",
    "rgb(90,253,47)",
    "rgb(254,187,44)",
    "rgb(254,187,44)",
    "rgb(253,160,40)",
    "rgb(253,160,40)",
    "rgb(253,160,40)",
    "rgb(253,134,36)",
    "rgb(253,134,36)",
    "rgb(253,100,33)",
    "rgb(253,82,31)",
    "rgb(252,57,29)",
    "rgb(252,32,28)",
    "rgb(252,32,28)",
    "rgb(252,32,28)",
    "rgb(252,32,28)",
];

export default class CompanyInfo extends React.Component{
    textColor(input){
        let num =((Math.abs(input) * 2) / 5) * 100
        if (colored[num.toFixed(0)]){
            return colored[num.toFixed(0)];
        } 
        else{
            return colored[19]
        }
    }
    setText(input){
      if(input < 5){
          return 'low risk'
      }
      if(input >= 5 && input <=7){
          return 'mid risk'
      }
      if(input > 7 && input < 11){
          return 'high risk'
      }
      if(input >= 11){
          return 'extreme risk'
      }
    }
    priceRow = ()=>{
        const {companyName, currentPrice,cvar,isCrypto,symbol,volume,tickerCurrency} = this.props
        let currentPriceInt = parseFloat(currentPrice.currentPrice)
        let hiPriceInt = parseFloat(currentPrice.hiPrice)
        let loPriceInt = parseFloat(currentPrice.loPrice)
        let openPriceInt = parseFloat(currentPrice.openPrice)
        let prevCloseChange = parseFloat(currentPrice.prevClose)
        let prevClosePrice = parseFloat(currentPrice.closePrice)
        let dollarChange = currentPriceInt - prevClosePrice
        let textColor = dollarChange < 0 ? 'rgb(181,23,28)' : 'rgb(32,179,125)'

        if(this.props.isSmallScreen){
            return(
            <div>
                <div>
                <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        marginRight: 5,
                        textAlign: "center",
                    }}
                    >
                    OPEN: 
                    </p>
                    <p
                    style={{
                        
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        color: 'rgb(32,179,125)',
                        textAlign: "center",
                    }}
                    >
                    {`${openPriceInt.toFixed(2)}`}
                    </p>
                </div>
                <div>
                <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        marginRight: 5,
                        textAlign: "center",
                    }}
                    >
                    HI: 
                    </p>
                    <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        color: 'rgb(32,179,125)',
                        textAlign: "center",
                        
                    }}
                    >
                    {`${hiPriceInt.toFixed(2)}`}
                    </p>
                </div>
                <div>
                <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        marginRight: 5,
                        textAlign: "center",

                    }}
                    >
                    LO: 
                    </p>
                    <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        color: 'rgb(32,179,125)',
                        textAlign: "center",
                        
                    }}
                    >
                    {`${loPriceInt.toFixed(2)}`}
                    </p>
                </div>
                <div>
                <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        marginRight: 5,
                        textAlign: "center",

                    }}
                    >
                    Daily Volume:
                    </p>
                    <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        color: 'rgb(32,179,125)',
                        textAlign: "center",
                        
                    }}
                    >
                    {volume.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")}
                    </p>
                </div>
            </div>
            )
        }
        else{
            if(isCrypto || !openPriceInt || !hiPriceInt || !loPriceInt){
                return null
            }else{
            return(
                <Row
                style={{
                    justifyContent: "space-between",
                    marginLeft: 1,
                    marginRight: 10,
                    height: 50,
                    backgroundColor: "rgb(255,255,255)",
                    borderRadius: 10,
                    padding: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    width: "100%",
                    marginTop: 10,
                    marginBottom: 5,
                    flexDirection:this.props.isSmallScreen?'column':'row'
                }}
                >
                <div style={{ display:'flex',alignItems:'center' }}>
                    <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        marginRight: 5,
                        textAlign: "center",
                    }}
                    >
                    OPEN: 
                    </p>
                    <p
                    style={{
                        
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        color: 'rgb(32,179,125)',
                        textAlign: "center",
                    }}
                    >
                    {`${openPriceInt.toFixed(2)}`}
                    </p>

                </div>
                <div style={{ display:'flex',alignItems:'center' }}>
                    <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        marginRight: 5,
                        textAlign: "center",
                    }}
                    >
                    HI: 
                    </p>
                    <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        color: 'rgb(32,179,125)',
                        textAlign: "center",
                        
                    }}
                    >
                    {`${hiPriceInt.toFixed(2)}`}
                    </p>

                </div>
                <div style={{ display:'flex',alignItems:'center' }}>
                    <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        marginRight: 5,
                        textAlign: "center",

                    }}
                    >
                    LO: 
                    </p>
                    <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        color: 'rgb(32,179,125)',
                        textAlign: "center",
                        
                    }}
                    >
                    {`${loPriceInt.toFixed(2)}`}
                    </p>
                </div>
                <div style={{ display:'flex',alignItems:'center' }}>
                    <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        marginRight: 5,
                        textAlign: "center",

                    }}
                    >
                    Daily Volume:
                    </p>
                    <p
                    style={{
                        fontSize: 15,
                        margin:0,
                        fontWeight: "bolder",
                        color: 'rgb(32,179,125)',
                        textAlign: "center",
                        
                    }}
                    >
                    {volume.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")}
                    </p>
                </div>
                    
                </Row>
            )}
        }
    }

    setGaugeText(cvar) {
        if (cvar <= 0.15) {
            return 'low risk'
        }
        if (cvar > 0.15 && cvar < 0.3) {
            return 'medium risk'
        }
        if (cvar >= 0.3) {
            return 'high risk'
        }
    }

    riskNum(cvar,ticker){
       
        if(ticker === 'BIL' || ticker === "TLT"){
            return '0'
        }
        else{
           if( Math.abs(cvar*200/10) > 10){
                return '10+'
           }else if (Math.abs(cvar*200/10) < 1){
               return '1'
           }
           else{
               return Math.abs(cvar*200/10).toFixed(0)
           }
        }
    }

    addCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render(){
        const {companyName, currentPrice,cvar,isCrypto,symbol,volume,tickerCurrency,industry} = this.props
        let currentPriceInt 
        let hiPriceInt 
        let loPriceInt 
        let openPriceInt
        let prevCloseChange
        let prevClosePrice 
        let dollarChange 
        let textColor 
        if(currentPrice){
            currentPriceInt = parseFloat(currentPrice.currentPrice)
            hiPriceInt = parseFloat(currentPrice.hiPrice)
            loPriceInt = parseFloat(currentPrice.loPrice)
            openPriceInt = parseFloat(currentPrice.openPrice)
            prevCloseChange = parseFloat(currentPrice.prevClose)
            prevClosePrice = parseFloat(currentPrice.closePrice)
            dollarChange = currentPriceInt - prevClosePrice
            textColor = dollarChange < 0 ? 'rgb(181,23,28)' : 'rgb(32,179,125)'
        }
        return (
            <div style={{borderRadius: '18px', backgroundColor: 'rgb(255,255,255)', height:'100%', width:'100%', paddingTop: '5%', paddingLeft:'3%', paddingRight:'3%', display:'flex', alignItems:'center'}}>
                <div style={{display:'flex', flex:2,flexDirection:'column' }}>
                    {/* <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:'50%',overflow:'hidden', width:100, height:100,justifySelf:'flex-end'}}>
                            <img 
                                src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
                                style={{objectFit:'contain', width:75,height:75}}
                            />
                        </div>
                    </div> */}
                    <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                        <p style={{ fontSize: '48px', fontWeight: 'bolder', fontStretch: 'normal', fontStyle: 'normal', lineHeight: '1.09', letterSpacing: 'normal', textAlign: 'left', color: 'rgb(123, 192, 195)',}}>
                            {this.addCommas(currentPriceInt.toFixed(2))}
                        </p>
                        <p style={{ fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: '1.18', letterSpacing: '0.66px', textAlign: 'left', color: 'rgb(123, 192, 195)', verticalAlign:'bottom', margin:0, marginBottom:7, marginLeft: '1%'}}>
                            {tickerCurrency}
                        </p>
                    </div>
                    {/* <div>
                        <p style={{fontSize: '28px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1, letterSpacing: '1.2px', textAlign: 'left', color: '#242A38' }}>
                            {symbol}
                        </p>
                    </div> */}
                    
                    <div>
                        <p style={{opacity: 0.53, fontSize: '24px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.08, letterSpacing: '0.48px', textAlign: 'left', color: '#242A38' }}>
                            {companyName}
                        </p>
                    </div>
                    <div>
                        <p style={{opacity: 0.53, fontSize: '18px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.08, letterSpacing: '0.48px', textAlign: 'left', color: '#242A38' }}>
                            {industry}
                        </p>
                    </div>
                        
                    {(isCrypto || !prevCloseChange || !dollarChange ) ? null :
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center',}}>
                        <ChangeSinceYest changePercent={prevCloseChange} dollarChange={dollarChange} textColor={textColor} />
                    </div>}
                    <div style={{height: '20%', width: '100%', padding:20, marginTop:'5%'}}>
                        <RiskNum cvar={this.props.cvar}/>
                    </div>
                </div>
                <div style={{ width: 0,height: '90%',border: 'solid 2px #BDC3D1',}}/>
                {this.props.isSmallScreen? null :
                    <div style={{display:'flex', flex:1}}>
                        <div class='gauge-meter-legend'>
                            <div class='legend-container'>
                                <label style={{fontWeight:'bold', textAlign:'center', color:'#242A38', width:'100%'}}>Guide</label>
                                <ul class='legend-meter' style={{marginRight:10,listStyleType:'none'}}>
                                    <li style={{position:'relative', margin:0, padding:0, }}><span style={{margin:0, color:'rgb(255,255,255)',display: 'block', textAlign: 'center', width: '90px', padding: '5px', backgroundColor:'#7bc0c3',borderTopLeftRadius: '7px',borderTopRightRadius: '7px',}} class='riskbg1'>0</span><label style={{ color:'#242A38',display: 'block', width: '90px', padding: '5px',textTransform: 'none',fontWeight: 'normal',fontSize: '12px',display: 'block',position: 'absolute',top: 0,right: '-56px',lineHeight: '100%',transform: 'translate(-50%, -50%)',transform: 'rotate(-90deg)',/* Safari */WebkitTransform: 'rotate(-90deg)',/* Firefox */ MozTransformOrigin: 'rotate(-90deg)', /* IE */msTransform: 'rotate(-90deg)',/* Opera */oTransform: 'rotate(-90deg)',/* Internet Explorer */filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)', }}>Low</label></li>
                                    <li style={{position:'relative', margin:0, padding:0}}><span style={{margin:0, color:'rgb(255,255,255)',display: 'block', textAlign: 'center', width: '90px', padding: '5px', backgroundColor:'#7bc0c3', }} class='riskbg2'>2</span></li>
                                    <li style={{position:'relative', margin:0, padding:0}}><span style={{margin:0, color:'rgb(255,255,255)',display: 'block', textAlign: 'center', width: '90px', padding: '5px', backgroundColor:'#f2ab25'}} class='riskbg34'>&nbsp;</span></li>
                                    <li style={{position:'relative', margin:0, padding:0}}><span style={{margin:0, color:'rgb(255,255,255)',display: 'block', textAlign: 'center', width: '90px', padding: '5px', backgroundColor:'#f2ab25'}} class='riskbg34'>&nbsp;</span><label style={{ color:'#242A38',display: 'block', width: '90px', padding: '5px',textTransform: 'none',fontWeight: 'normal',fontSize: '12px',display: 'block',position: 'absolute',top: 0,right: '-56px',lineHeight: '100%',transform: 'translate(-50%, -50%)',transform: 'rotate(-90deg)',/* Safari */WebkitTransform: 'rotate(-90deg)',/* Firefox */ MozTransformOrigin: 'rotate(-90deg)', /* IE */msTransform: 'rotate(-90deg)',/* Opera */oTransform: 'rotate(-90deg)',/* Internet Explorer */filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)', }}>Mid</label></li>
                                    <li style={{position:'relative', margin:0, padding:0}}><span style={{margin:0, color:'rgb(255,255,255)',display: 'block', textAlign: 'center', width: '90px', padding: '5px', backgroundColor:'#f28425'}} class='riskbg56'>&nbsp;</span></li>
                                    <li style={{position:'relative', margin:0, padding:0}}><span style={{margin:0, color:'rgb(255,255,255)',display: 'block', textAlign: 'center', width: '90px', padding: '5px', backgroundColor:'#f28425'}} class='riskbg56'>&nbsp;</span></li>
                                    <li style={{position:'relative', margin:0, padding:0}}><span style={{margin:0, color:'rgb(255,255,255)',display: 'block', textAlign: 'center', width: '90px', padding: '5px', backgroundColor:'#f26425'}} class='riskbg78'>&nbsp;</span><label style={{ color:'#242A38',display: 'block', width: '90px', padding: '5px',textTransform: 'none',fontWeight: 'normal',fontSize: '12px',display: 'block',position: 'absolute',top: 0,right: '-56px',lineHeight: '100%',transform: 'translate(-50%, -50%)',transform: 'rotate(-90deg)',/* Safari */WebkitTransform: 'rotate(-90deg)',/* Firefox */ MozTransformOrigin: 'rotate(-90deg)', /* IE */msTransform: 'rotate(-90deg)',/* Opera */oTransform: 'rotate(-90deg)',/* Internet Explorer */filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)', }}>High</label></li>
                                    <li style={{position:'relative', margin:0, padding:0}}><span style={{margin:0, color:'rgb(255,255,255)',display: 'block', textAlign: 'center', width: '90px', padding: '5px', backgroundColor:'#f26425'}} class='riskbg78'>&nbsp;</span></li>
                                    <li style={{position:'relative', margin:0, padding:0}}><span style={{margin:0, color:'rgb(255,255,255)',display: 'block', textAlign: 'center', width: '90px', padding: '5px', backgroundColor:'#f24c25'}} class='riskbg9'>9</span></li>
                                    <li style={{position:'relative', margin:0, padding:0}}><span style={{margin:0, color:'rgb(255,255,255)',display: 'block', textAlign: 'center', width: '90px', padding: '5px', backgroundColor:'#f22525',borderBottomLeftRadius: '7px',borderBottomRightRadius: '7px',}} class='riskbg10'>10+</span><label style={{ color:'#242A38',display: 'block', width: '90px', padding: '5px',textTransform: 'none',fontWeight: 'normal',fontSize: '12px',display: 'block',position: 'absolute',top: -26,right: '-56px',lineHeight: '100%',transform: 'translate(-50%, -50%)',transform: 'rotate(-90deg)',/* Safari */WebkitTransform: 'rotate(-90deg)',/* Firefox */ MozTransformOrigin: 'rotate(-90deg)', /* IE */msTransform: 'rotate(-90deg)',/* Opera */oTransform: 'rotate(-90deg)',/* Internet Explorer */filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)', }}>Extreme</label></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}


