import React, { Component } from "react";
import Header from "../layout/Header"
import { Container, Card, Image } from "react-bootstrap";
import Amplify, { Auth} from 'aws-amplify'
import awsconfig from '../../aws-config';
import { withAuthenticator } from 'aws-amplify-react';
import StripeCheckout from 'react-stripe-checkout'
import axios from 'axios'
import ENDPOINT from '../Endpoint'



Amplify.configure(awsconfig.prod)


class AccountRegistrationForm extends Component{
    constructor(props){
        super(props)
        this.state={
            stage: 1,
            email: '',
            password: '',
            confirmPassword: '',
            phoneNumber: '',
            verificationCode: '',
            isLoading: false,
            errorMessage: null,
            shouldShowPaymentForm: false,
            subSuccessful: false,
            password: '',
            userStatus: 'customer_exists',
            isSmallScreen: false,
            screenWidth: 0
        }
    }

    componentDidMount = async () => {
        //temp password
        var password = this.state.password
        let characters = 'abcdefghijklmnopqrstuvwxyz'

        for(var i = 0; i < 10; i++){
           password += characters.charAt(Math.floor(Math.random() * characters.length))
        }

        this.setState({password, confirmPassword: password})
        this.updateWindowDimensions()
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        if (window.innerWidth < 800) {
            this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
        }else{
            this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
        }
    }

    submitEmail = async () =>{
        const{email, stage} = this.state
        var errorMessage = null
        if(email === ''){
            errorMessage = 'Please enter an email.'
            this.setState({errorMessage})
            return
        }

        let url = `${ENDPOINT}/users/customer`
        let body = {
            email: email.replace(' ', '').toLowerCase()
        }

        this.setState({isLoading: true})
        fetch(url, {
            method: 'POST',
            'Access-Control-Allow-Headers': { 
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Access-Control-Request-Headers': "*",
                'Access-Control-Allow-Origin': '*',
                "Access-Control-Allow-Methods": "*",
            },
            body: JSON.stringify(body),
        })
        .then(res => res.json())
        .then(responseJson => {
            // console.log(responseJson)
            const{status}=responseJson
            
            switch (status){
                case 'customer_okay':
                    this.setState({stage: stage + 1, userStatus: status})
                    errorMessage = null
                    break;
                case 'customer_exists':
                    // errorMessage = 'This email has already been registered, please try a different email.'
                    errorMessage = null
                    this.setState({stage: stage + 3, userStatus: status})
                    break
                case 'customer_none':
                    errorMessage = 'This email address doesn\'t exist in either our or partners\' databases, please try a different email.'
                    break
                default:
                    errorMessage = 'Something went wrong, please try again.'
            }

            this.setState({isLoading: false, errorMessage})
        })
        .catch(error => {
            // console.log(error)
            this.setState({isLoading: false})
        })
    }

    submitPassword = () => {
        // const{ confirmPassword, password, stage } = this.state
        // var errorMessage = null

        // if( confirmPassword === '' || password === ''){
        //     errorMessage = 'Please complete the requried fields.'
        // }else if ( password.length < 6){
        //     errorMessage = 'Password must be at least 6 charaters.'
        // }else if ( confirmPassword !== password ){
        //     errorMessage = 'Both passwords are not matched.'
        // }else{
            this.signup()
            // this.setState({stage: stage + 1})
        // }
        // this.setState({errorMessage})
    }

    submitPhoneNumber = () => {
        const{phoneNumber, stage }=this.state
        var errorMessage = null

        if( phoneNumber === '' ){
            errorMessage = 'Please enter your phone number.'
        }else if( phoneNumber.match(/^[0-9]+$/) === null){
            errorMessage = 'Phone number should only be a 11-digit number.'
        }else if( phoneNumber.length !== 10){
            errorMessage = 'Phone number should only be a 11-digit number.'
        }else{
            this.signup()
        }

        this.setState({errorMessage})
    }

    signup = () => {
        const{ password, stage}=this.state
        let email = this.state.email.toLowerCase()
        let username = email
        this.setState({isLoading: true})

        Auth.signUp({
            username,
            password,
            attributes: {
                email
            }
        }).then( async (result)=>{
            alert('Sign up successful! A confirmation code has been sent to your email address.')

            this.setState({stage: stage + 1, errorMessage: null, isLoading: false})

        })
        .catch(error => {
            // console.log('sign up: ',error)
            var errorMessage = 'Something went wrong while signing up, please try again.'

            if( error.message){
                errorMessage = error.message.replace('Username', 'Email').replace('username','email')
            }
            this.setState({errorMessage, isLoading: false})
        })
    }

    subscribe = () => {
        const{shouldShowPaymentForm} = this.state
        this.setState({shouldShowPaymentForm: !shouldShowPaymentForm})
    }

    _sendUpdateSubscribeToBackend = async (token) => {
        const{email} = this.state
        // console.log(token.id)
        const body = { 
            sourceId: token.id,
            email,
            origin:'web'
            // phone: phoneNumber
        };
        // console.log(this.state.userToken)
        fetch(`${ENDPOINT}/stripe/customer-sources/web`, {
            method: 'POST',
            'Access-Control-Allow-Headers': { 
                'Access-Control-Request-Headers': "*",
                'Access-Control-Allow-Origin': "*",
                "Access-Control-Allow-Methods": "*",
                'Access-Control-Allow-Credentials' : true,
            },
            headers: {
                Authorization: "Bearer " + this.state.userToken,
            //     'Content-Type': 'application/json',
            //     'Cache-Control': 'no-cache',
            //     'Access-Control-Allow-Origin': "*",
            },
            body: JSON.stringify(body)
        })
        .then(response => {
                return response.json();
            })
            .then(responseJson => {
                // console.log('Update Backend',responseJson)

                var succeed = responseJson.status

                if(succeed){
                    this.setState({subSuccessful: true})
                }else{
                    alert('Something went wrong, please double check your card info or try a different card.')
                }

            })
            .catch(err =>{
                alert(
                    "Please check your card information and try again."
                )
            });
    }; // end _sendSubscribeToBackend

    resendVerificationCode = async () => {
        let username = this.state.email
        await Auth.resendSignUp(username)
            .then(() => {
                alert(
                "Another verification code has been sent to your email address."
                )
            })
            .catch(err => {
                if (!err.message) {
                    alert("Error requesting new verification code: ", err);
                } else {
                    alert("Error requesting new verification code: ", err.message);
                }
            });
    }

    confirmRegistration = () => {
        const{password, userToken} = this.state

        let url = `${ENDPOINT}/users/customer/confirm`
        let body = {
            password
        }
        fetch(url, 
        {
            // 'Access-Control-Allow-Headers': { 
            // 'Content-Type': 'application/json',
            // 'Cache-Control': 'no-cache',
            // 'Access-Control-Request-Headers': "*",
            // 'Access-Control-Allow-Origin': '*',
            // "Access-Control-Allow-Methods": "*",
            // },
            method: 'POST',
            headers: {
                Authorization: "Bearer " + userToken,
                'Content-Type': 'application/json',
                // 'Cache-Control': 'no-cache',
                // 'Access-Control-Allow-Origin': "*",
            },
            body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(responseJson => {
            // console.log(responseJson)
        })
        .catch(error=>console.log(error))
    }

    vervifyEmail = async () => {
        const{ verificationCode, email, phoneNumber, password } = this.state
        let username = email.toLowerCase()
        var errorMessage = null
        // console.log(verificationCode)

        if(verificationCode === ''){
            errorMessage = 'Please enter the verification code'
            this.setState({errorMessage})
            return
        }
        this.setState({isLoading: true})
        await Auth.confirmSignUp(username, verificationCode)
            .then((result) => {

                // console.log(result)
                
                Auth.signIn(username, password)
                .then(user => {
                    
                    // Auth.updateUserAttributes(user,{
                    //     'phone_number': '+1'+phoneNumber
                    // })

                    const userToken = user.signInUserSession.idToken.jwtToken
                    // console.log('token: ', userToken)
                    const{stage}=this.state
                    this.setState({stage: stage + 1, errorMessage, userToken, isLoading: false},()=>{
                        this.confirmRegistration()
                    })
                })
                .catch(err => {
                    // console.log('Sign in: ', err)
                    if (!err.message) {
                      alert("Errors occured when verifying code: ", err);
                    } else {
                      alert("Errors occured when verifying code: ", err.message);
                    }
                    this.setState({isLoading: false})
                })
            })
            .catch(err => {
                // console.log('Verification: ', err)
                if (!err.message) {
                  alert("Errors occured when verifying code, please double check the code.");
                } else {
                  alert("Errors occured when verifying code: ", err.message);
                }
                this.setState({isLoading: false})
              })
    }

    buildIntroCard = () =>{
        const{isSmallScreen}=this.state
        return(
            <Card className="registration-card" >
                <div style={{paddingTop: 10, paddingBottom: 10, backgroundColor: '#00A99D', width: '100%', }}>
                    <h1 style={{ color: '#FFF', textAlign: 'center'}}>Registration</h1>
                </div>
                <div style={{marginLeft: 20, marginRight: 20}}>
                    <p style={{marginTop: 50, fontSize: isSmallScreen?16:20}}>Planner Securities is partnering with AllocateRite to deliver risk analytics and wealth management tools, along with a new trading platform.</p>
                    <p style={{marginTop: 8, fontWeight: '600', fontSize: isSmallScreen?16:20}}>Please fill in a quick form below to gain an immediate access to AllocateRite application.</p>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <p style={{marginLeft: 8, marginRight: 8}}>(Notes: </p>
                        <h6 style={{color: 'red'}}>*</h6>
                        <p style={{marginLeft: 8}}>Requied)</p>
                    </div>
                </div>
            </Card>
        )
    }

    buildEmailCard = () =>{
        const{isSmallScreen} = this.state
        return(
            <Card className="registration-card" style={{marginTop: 50, padding: 20}}>
                <div style={{
                        marginLeft: 20, 
                        marginRight: 20, 
                        // display: "flex",
                        // justifyContent: "center", 
                        alignItems: 'center',
                        padding: 20,
                        width: isSmallScreen ? '100%' : '75%'
                    }}
                >
                    <p style={{marginTop: 8, fontWeight: '600', textAlign: 'left', fontSize: isSmallScreen?16:20}}>Enter your Planner Securities email:</p>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <h6 style={{color: 'red', marginRight: 8}}>*</h6>
                        <input style={{width: '100%', paddingRight: 20, paddingLeft: 20, paddingTop: 5, paddingBottom: 5,}} placeholder='Enter your email here' 
                            onChange={e => this.setState({email: e.target.value})}
                        />
                    </div>
                </div>
            </Card>
        )
    }

    buildPasswordCard = () =>{
        const{stage, password, userStatus, isSmallScreen}=this.state
        if(stage > 1 && userStatus !== 'customer_exists'){
            return(
                <Card className="registration-card" style={{marginTop: 50, padding: 20}}>
                    <div style={{
                            marginLeft: 20, 
                            marginRight: 20, 
                            // display: "flex",
                            // justifyContent: "center", 
                            alignItems: 'center',
                            padding: 20,
                            width: isSmallScreen ? '100%' : '75%'
                        }}
                    >
                        {/* <p style={{marginTop: 8, fontWeight: '600', textAlign: 'left', fontSize: isSmallScreen?16:20}}>Create an account with AllocateRite:</p>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <h6 style={{color: 'red', marginRight: 8}}>*</h6>
                            <input type="password" style={{width: '100%', paddingRight: 20, paddingLeft: 20, paddingTop: 5, paddingBottom: 5,}} placeholder='Create Password'
                                defaultValue={password}
                                onChange={e => this.setState({password: e.target.value})}
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <h6 style={{color: 'red', marginRight: 8}}>*</h6>
                            <input type="password" style={{width: '100%', paddingRight: 20, paddingLeft: 20, paddingTop: 5, paddingBottom: 5, marginTop: 10}} placeholder='Repeat Password'
                                defaultValue={confirmPassword}
                                onChange={e => this.setState({confirmPassword: e.target.value})}
                            />
                        </div> */}
                        <p style={{marginTop: 8, fontWeight: '600', textAlign: 'left', fontSize: isSmallScreen?16:20}}>We've generated a temporary password for you:</p>
                        <h3 style={{color: '#00A99D', display: 'flex', alignSelf: 'center'}}>{password}</h3>
                        <h6 style={{color: '#bababa'}}>You can alway reset it in our mobile apps.</h6>
                    </div>
                </Card>
            )
        }
    }

    // buildPhoneNumberCard = () =>{
    //     const{stage}=this.state
    //     if(stage > 2){
    //         return(
    //             <Card className="registration-card" style={{marginTop: 50, padding: 20}}>
    //                 <div style={{
    //                         marginLeft: 20, 
    //                         marginRight: 20, 
    //                         // display: "flex",
    //                         // justifyContent: "center", 
    //                         alignItems: 'center',
    //                         padding: 20,
    //                         width: isSmallScreen ? '100%' : '75%'
    //                     }}
    //                 >
    //                     <p style={{marginTop: 8, fontWeight: '600', textAlign: 'left', fontSize: isSmallScreen?16:20}}>Enter your phone number:</p>
    //                     <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
    //                         <h6 style={{color: 'red', marginRight: 8}}>*</h6>
    //                         <h6 style={{ marginRight: 8}}>+1</h6>
    //                         <input style={{width: '100%', paddingRight: 20, paddingLeft: 20, paddingTop: 5, paddingBottom: 5,}} placeholder='Phone number'
    //                             onChange={e => this.setState({phoneNumber: e.target.value})}
    //                         />
    //                     </div>
    //                 </div>
    //             </Card>
    //         )
    //     }
    // }

    buildVerificationCard = () =>{
        const{stage, userStatus, isSmallScreen}=this.state
        // if(stage > 3){
        if(stage > 2 && userStatus !== 'customer_exists'){
            return(
                <Card className="registration-card" style={{marginTop: 50, padding: 20}}>
                    <div style={{
                            marginLeft: 20, 
                            marginRight: 20, 
                            // display: "flex",
                            // justifyContent: "center", 
                            alignItems: 'center',
                            padding: 20,
                            width: isSmallScreen ? '100%' : '75%'
                        }}
                    >
                        <p style={{marginTop: 8, fontWeight: '600', textAlign: 'left', fontSize: isSmallScreen?16:20}}>Enter the verification code:</p>
                        <p style={{ marginRight: 8}}>A verification code has been sent to your email, please check you inbox.</p>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <h6 style={{color: 'red', marginRight: 8}}>*</h6>
                            <input style={{width: '100%', paddingRight: 20, paddingLeft: 20, paddingTop: 5, paddingBottom: 5,}} placeholder='Verification code'
                                onChange={e => this.setState({verificationCode: e.target.value})}
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center'}}>
                            <p style={{marginTop: 10}}>Didn't receive the code? </p>
                            <button style={{ color: '#00A99D', paddingLeft: 30, paddingRight: 30, paddingTop: 5, paddingBottom: 5, marginLeft: 8}}
                                onClick={this.resendVerificationCode}
                            >
                                Resend
                            </button>
                        </div>
                    </div>
                </Card>
            )
        }
    }

    buildSubscriptionCard = () => {
        const{stage, shouldShowPaymentForm, subSuccessful, isSmallScreen}=this.state
        // if(stage > 4){
        if(stage > 3){
            return(
                <Card className="registration-card" style={{marginTop: 50, padding: 20}}>
                    <div style={{
                            marginLeft: 20, 
                            marginRight: 20, 
                            // display: "flex",
                            // justifyContent: "center", 
                            alignItems: 'center',
                            padding: 20,
                            width: isSmallScreen ? '100%' : '75%'
                        }}
                    >
                        <p style={{marginTop: 8, fontWeight: '600', textAlign: 'left', fontSize: isSmallScreen?16:20}}>Subscribe to AllocateRite:</p>
                        <h5 style={{color: '#00A99D'}}>Enjoy UNLIMITED AllocateRite access FOR LIFE and get COMMISSION-FREE Planner Securities trades.</h5>
                        <h2 style={{color: '#00A99D', fontWeight: 'bold', textAlign: 'center'}}>For only $9.99 per month.</h2>

                        <div style={{display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'row'}}>
                            { subSuccessful ? 
                                <h5 style={{color: '#00A99D', marginTop: 50}}>(You have successfully subscribed.)</h5>
                                : shouldShowPaymentForm ? 
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <button style={{ color: '#00A99D', paddingLeft: 30, paddingRight: 30, paddingTop: 5, paddingBottom: 5, marginRight: 20}}
                                        onClick={this.subscribe} 
                                    >
                                        Cancel
                                    </button>
                                    <StripeCheckout 
                                        stripeKey={'pk_live_Z5TBzWXmsRTQXelPUooM7GhI'}
                                        // stripeKey={'pk_test_7gJdr9OXIGK3QCgELwzeE3dd'}
                                        token={(token)=>{
                                            this._sendUpdateSubscribeToBackend(token)
                                        }}
                                        billingAddress
                                        amount={9.99 * 100}
                                        name={'Monthly Subscription'}
                                    />
                                </div> : 
                                <button style={{backgroundColor: '#00A99D', color: '#FFF', paddingLeft: 30, paddingRight: 30, paddingTop: 5, paddingBottom: 5,}}
                                    onClick={this.subscribe} 
                                >
                                    Subscribe Now
                                </button>
                            }
                        </div>
                    </div>
                </Card>
            )
        }
    }

    buildStoresCard = () => {
        const{stage, isSmallScreen}=this.state
        // if(stage > 5){
        if(stage > 4){
            return(
                <Card className="registration-card" style={{marginTop: 50, padding: 20}}>
                    <div style={{
                            marginLeft: 20, 
                            marginRight: 20, 
                            // display: "flex",
                            // justifyContent: "center", 
                            alignItems: 'center',
                            padding: 20,
                            width: isSmallScreen ? '100%' : '75%'
                        }}
                    >
                        <p style={{marginTop: 8, fontWeight: '600', textAlign: 'left', fontSize: isSmallScreen?16:20, color: '#00A99D'}}>You are all set, thank you so much!</p>
                        <p>Please download our mobile apps to enjoy AllocateRite.</p>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <button onClick={()=>{
                                window.open('https://apps.apple.com/us/app/allocaterite/id1478461135', "_blank")
                            }}><Image src={require("../../assets/download-on-app-store.png")} fluid style={{width: 150, height: 50,}}/></button>
                            <div style={{width: 10}}/>
                            <button onClick={()=>{
                                window.open('https://play.google.com/store/apps/details?id=com.allocaterite&hl=en_US', "_blank")
                            }}><Image src={require("../../assets/get-from-play-store.png")} fluid style={{width: 150, height: 50,}}/></button>
                        </div>
                        {/* <h2 style={{fontWeight: 'bold', color: '#00A99D'}}>Thank you so much!</h2> */}
                    </div>
                </Card>
            )
        }
    }

    increState = () => {
        const{stage} = this.state
        
        switch (stage) {
            case 1:
                this.submitEmail()
                break
            case 2:
                this.submitPassword()
                break
            // case 3: 
            //     this.submitPhoneNumber()
            //     break
            case 3:
                this.vervifyEmail()
                break
            default: this.setState({stage: stage + 1})
                break
        }
    }

    render(){
        const{ isLoading, errorMessage, stage} = this.state

        return(
            <div>
                <Header />
                <div className="registration-container" style={containerStyle}>
                    <Container>
                        <div style={{height: 100}}/>

                        {this.buildIntroCard()}
                        {this.buildEmailCard()}
                        {this.buildPasswordCard()}
                        {/* {this.buildPhoneNumberCard()} */}
                        {this.buildVerificationCard()}
                        {this.buildSubscriptionCard()}
                        {this.buildStoresCard()}
                        <h6 style={{color: 'red', marginTop: 10}}>{errorMessage ? errorMessage : ''}</h6>
                        <div 
                            style={{ 
                                display: "flex",
                                justifyContent: "flex-end", 
                                padding: 20,
                            }}
                        >
                            {stage > 4 ? null : <button style={{backgroundColor: '#00A99D', color: '#FFF', paddingLeft: 30, paddingRight: 30, paddingTop: 5, paddingBottom: 5}}
                                onClick={this.increState} disabled={isLoading}
                            >
                                {isLoading ? 'Loading...' : 'Next'}
                            </button> }
                        </div>
                        <div style={{height: 100}}/>
                    </Container>
                </div>
            </div>

        )
    }
}

export default AccountRegistrationForm

const containerStyle = {
    backgroundColor: 'rgba(0,0,0,0.05)',
    alignItems:'center',
    marginTop: 0
}