import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterPageTitle from "../layout/FooterPageTitle";
import ChineseFooter from "../layout/ChineseFooter";
import Header from "../layout/Header"


export class ChinesePrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <div>
        <FooterPageTitle title="隐私政策" />
        <Container>
          <Row className="terms-top-paragraph">
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                此份隐私权政策所声明的隐私权条例均适用于www.allocaterite.com网页。此隐私权政策仅适用于从该站点获取的信息。协议将会向您说明以下几点：
              </p>
            </Col>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                <li>
                  该网页向您收集的个人信息，以及这些信息的使用方向和信息分享及使用的对象。
                </li>
              </p>
            </Col>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                <li>选择如何使用您的数据。</li>
              </p>
            </Col>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                <li>防止您的个人信息被非法使用的安全程序。</li>
              </p>
            </Col>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                <li>如何修正错误信息。</li>
              </p>
            </Col>
            <p className="terms-text">
              收到或转移到我们网站的个人数据受美国联邦贸易委员会的监管执法权限的约束。
              在某些特殊情况下，我们可能会被要求披露个人数据以响应公共机构的合法要求，以满足国家安全和执法要求。
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>信息收集，使用和共享条例</h3>
            <p className="terms-text">
              网页用户需要意识到，当您向AllocateRite或相关机构提供您的个人信息（例如姓名，地址，社会安全号，资产信息）时，说明您已经了解并接受您的信息可能会被透露给以下对象：(1)
              公司的管理人、普通合伙人、投资经理、律师、会计师和审计师或其他业务合作方(例如经纪商)(当在合作服务的过程中需要使用个人信息的情况下)(2)
              第三方服务提供方或金融机构。信息提供的前提是第三方必须承诺对用户信息进行保密并且信息仅用于第三方和AllocateRite之间的合作服务中(3)
              以及法律允许或要求情况下的其他对象。公司、普通合伙人和投资经理需要对员工接触到信息的渠道进行限制。员工仅在向公司提供服务以及遵守美国联邦标准情况下维护实体、电子和程序上保障时才能获取信息。
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>您对信息的访问和控制</h3>
            <p className="terms-text">
              您可以随时选择退出我们的服务。
              您也可以随时通过我们网站上提供的电子邮件地址或电话号码与我们联系，以便进行以下操作：
            </p>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                <li>查询本公司记录中关于您的数据信息。</li>
              </p>
            </Col>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                <li>修改或修正本公司记录中关于您的数据信息。</li>
              </p>
            </Col>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                <li>要求本公司删除记录中关于您的数据信息。</li>
              </p>
            </Col>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                <li>对本公司使用您个人信息的途径提出建议。</li>
              </p>
            </Col>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>注册事项</h3>
          </Row>
          <Row>
            <p className="terms-text">
              AllocateRite建议您在使用和浏览本公司网页前先完成注册登记表。在注册的过程中，使用者需要向AllocateRite提供某些个人信息（例如姓名和邮箱地址）。当您有兴趣深入了解AllocateRite网页上提供的产品或者服务时，这些个人信息将方便本公司与您取得联系。
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>订阅</h3>
          </Row>
          <Row>
            <p className="terms-text">
              当您需要订阅AllocateRite的资讯时，希望您能提供您的个人信息以完成订阅登记表。当您需要向AllocateRite购买此项服务时，您需要向本公司提供个人信息(例如姓名和寄送地址)和账单信息(例如信用卡号码和到期日期)。这些信息将用于完成订单交易和账单邮寄。如果AllocateRite在完成订单的过程中遇到任何问题，可以通过这些信息联系您。
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>您的权利</h3>
          </Row>
          <Row>
            <p className="terms-text">我们始终会保证您数据保护法规定的权利。</p>
            <p className="terms-text">
              根据您所居住国家/地区的法律，您可能拥有与您的个人信息相关的其他权利。
              如果您希望对您的个人信息行使任何合法权利，请与我们联系。
            </p>
            <p className="terms-text">
              在某些情况下，我们可能有权根据申请法拒绝您对您的个人信息的请求。
              我们将解释是否是这种情况。 否则，我们会尽快满足您的要求。
              如果您已要求我们停止向您发送营销信息，请注意您在处理请求时可能会在短时间内收到这些信息。
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>COOKIES</h3>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                本网站使用cookie和其他跟踪技术来协助导航，使用，授权，访问内容和网站使用实践。
              </p>
            </Col>
          </Row>
          <Row className="terms-subparagraph-row">
            <Col className="terms-col" sm={12}>
              <h5>什么是COOKIES?</h5>
            </Col>
            <p className="terms-text">
              Cookie是由您的计算机或手机存储的小型跟踪文件。
              它们允许网站存储用户习惯和偏好。
            </p>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                用户需要一些cookie才能登录我们的网站。
              </p>
            </Col>
            <p className="terms-text">
              我们在本网站上使用“cookies”。
              Cookie是存储在网站访问者硬盘上的一段数据，可帮助我们改善您对我们网站的访问权限，并识别我们网站的重复访问者。
              例如，当我们使用cookie识别您时，您不必多次登录密码，从而节省了在我们网站上的时间。
              Cookie还可以使我们能够跟踪和定位用户的兴趣，以增强我们网站的体验。
              使用cookie绝不会与我们网站上的任何个人身份信息相关联。
              我们的一些业务合作伙伴可能会在我们的网站上使用Cookie（例如，广告客户）。
              但是，我们无权访问或控制这些cookie。
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>链接</h3>
            <p className="terms-text">
              此网页包含了与其他网页的链接。请注意AllocateRite不对其他网页的内容及隐私权条例负责。AllocateRite强烈建议用户在离开AllocateRite网页进而访问其他网页的时候，请详细阅读该网页关于个人信息收集的隐私权政策。
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>安全</h3>
            <Col className="terms-col" sm={12}>
              <p className="terms-text">
                AllocateRite会采取预防措施来保护您的信息安全。当您通过网页提交敏感信息时，无论在线上或线下，您的信息都将受到保护。
              </p>
            </Col>
            <p className="terms-text">
              无论AllocateRite通过什么渠道收集敏感信息(例如信用卡数据信息)，这些信息都将被加密并以安全的方式传输给本公司。您可以通过在Web浏览器的底部寻找一个闭锁图标，或者寻找“
              https”开头的网页地址以验证这一点。
            </p>
            <p className="terms-text">
              AllocateRite在使用加密功能来保护网上传输的敏感信息的同时，也会保护您的信息在线下(本地)的安全。只有当AllocateRite的员工需要信息来完成某个特定项目时（例如记账或提供客户服务），才会被授予访问个人身份信息的权力。本公司用于储存个人信息的计算机/服务器都被保存在一个安全的环境中。
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>更新</h3>
          </Row>
          <Row>
            <p className="terms-text">
              AllocateRite的隐私权政策将持续更新并将最新的消息公布在此页面。
            </p>
            <p className="terms-text">
              如果您发现本公司违反此隐私权政策的内容，请您联系AllocateRite的客服部门。您可以致电(212)995-9191或发送电子邮件
              <a
                className="terms-contact-us"
                href={`mailto:help[@allocaterite.com`}
              >
                {" "}
                help@allocaterite.com
              </a>
              与本公司取得联系。
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <Col className="terms-bottom-row">
              <p className="terms-bottom-date">此版本更新于: 2018年5月29日</p>
            </Col>
          </Row>
        </Container>
        <ChineseFooter />
      </div>
      </div>
    );
  }
}

export default ChinesePrivacyPolicy;
