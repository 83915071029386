import React from 'react'
// import { Container, Row, Col } from 'react-bootstrap'
// import investmentRisk from '../investmentRisk'
// import GaugeChart from "react-gauge-chart"
import DoubleLine from "./DoubleLine"
import PieChart from "./PieChart"
import RiskGuage from './RiskGuage'
import TickersTable from './TickersTable'
import RiskByStock from './RiskByStock'

import { FaArrowLeft, /*FaSave*/ } from 'react-icons/fa'
import SubModal from "../SubModal"
import MultiTrades from './MultiTrades'
import { NavLink } from 'react-router-dom'

import ENDPOINT from '../Endpoint'


const uncolored = [
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
];
const colored = [
    "rgb(41,253,47)",
    "rgb(51,253,47)",
    "rgb(69,253,47)",
    "rgb(69,253,47)",
    "rgb(90,253,47)",
    "rgb(90,253,47)",
    "rgb(254,187,44)",
    "rgb(254,187,44)",
    "rgb(253,160,40)",
    "rgb(253,160,40)",
    "rgb(253,160,40)",
    "rgb(253,134,36)",
    "rgb(253,134,36)",
    "rgb(253,100,33)",
    "rgb(253,82,31)",
    "rgb(252,57,29)",
    "rgb(252,32,28)",
    "rgb(252,32,28)",
    "rgb(252,32,28)",
    "rgb(252,32,28)",
];

const brandColor = "#00A99D"

export default class Portfolio extends React.Component {

    state={
        portName: '',
        portNameError: false,
        // actual: false,
        showSaveModal:false,
        saved: false,
        saveWhich: 'actual',
        subModal: false,
        exModal:false,
        step:0,
        pcntTotal:.05,
        tradesNecessary:[],
        prices:{'CASH':1,'$$':1},
        bulkResp:{},
        canTrade: true,
        loadingTimeOut:false,
        showDontDoIt:false,
        clickedSubmitWithoutSure:false,
    }

    componentDidMount(){
        this.getMarketStatus()
        this.shouldWe()
    }

    getMarketStatus = ()=>{
        fetch(`${ENDPOINT}/search/istradinghour`,{
            method:"GET"
        })
        .then((res) => res.json())
        .then((responseJson) => {
            this.setState({canTrade:responseJson.status})
        })
    }

    saveOne = (body)=>{
        let URL = `${ENDPOINT}/risk-monkey/portfolios/save`
            fetch(URL,{
                method: "POST",
                headers:{
                  Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
              })
            .then(response=>response.json())
            .then(responseJson=>{
                console.log('responseJson from save: ', responseJson)
                if(responseJson.status === 'duplicate'){
                    console.log('hit duplicate')
                    this.setState({portNameError:'duplicate'})
                }
                else if (responseJson.status === 'error'){
                    this.setState({portNameError:'error'})
                }
                else{
                    console.log(body)
                    console.log('successful save')
                    setTimeout(this.props.fetchCustomStrategies() , 3000)
                    // this.props.fetchCustomStrategies()
                }
        })
        .catch(err=>console.log('save err: ', err))
    }

    savePortfolio = () => {        
            if(this.state.portName.length > 0 && !(this.state.portName.indexOf(' ') >= 0)){
                let body = {
                    "holdings" : this.state.saveWhich === 'actual' ? this.bodyForSaveActual() :this.bodyForSaveOpt(),
                    "portfolioId" : `${this.state.portName}${this.state.saveWhich === 'both' ?'--Optimized':''}`
                } 
                this.saveOne(body)
                if(this.state.saveWhich === 'both'){
                    let bodyTwo = {
                        "holdings" : this.bodyForSaveActual(),
                        "portfolioId" : `${this.state.portName}${this.state.saveWhich === 'both' ?'--Actual':''}`
                    }
                    this.saveOne(bodyTwo)
                }
            }else{
                this.setState({portNameError:'no name'})
            }
    }

    bodyForSaveActual = ()=>{
        let data = this.props.userInputs
        let holdings = []
        Object.keys(data).forEach(ele => {
            if(ele!== 'BIL'){
                holdings.push({
                    "symbol": ele,
                    "quantity": data[ele].shares
                })
            }
        })
        return holdings
    }

    bodyForSaveOpt = () =>{
        let data = this.props.data.optimizedPortfolioShare
        let holdings = []
        Object.keys(data).forEach(ele => {
            if(ele!== 'BIL'){
            holdings.push({
                "symbol": ele,
                "quantity": data[ele]
                })
            }
        })
        return holdings
    }

    textColor(input) {
        let num = ((Math.abs(input) * 2) / 5) * 100
        if (colored[num.toFixed(0)]) {
            return colored[num.toFixed(0)];
        } else {
            return colored[19]
        }
    }

    setColoredGauges(input) {
        let pivotPoint = ((Math.abs(input) * 2) / 5) * 100;
        let answer = [...uncolored];
        return answer.map((ele, i) => {
            if (i <= pivotPoint) {
                return colored[i];
            } else {
                return ele;
            }
        });
    }

    setGaugeText(cvar) {
        if (cvar <= 0.15) {
            return 'low risk'
        }
        if (cvar > 0.15 && cvar < 0.3) {
            return 'medium risk'
        }
        if (cvar >= 0.3) {
            return 'high risk'
        }
    }

    riskNum(cvar,ticker){
        if(ticker === 'BIL' || ticker === "TLT"){
            return '0'
        }
        else{
           if( Math.abs(cvar*200/10) > 10){
                return '10+'
           }else if (Math.abs(cvar*200/10) < 1){
               return '1'
           }
           else{
               return Math.abs(cvar*200/10).toFixed(0)
           }
        }
    }

    createRow(symbol, yours, ours, index){
        return(
            <div style={{display:'flex',justifyContent:'space-around', alignItems:'flex-start', width:'100%', borderBottom:'solid',}}>
                <div style={{display: 'flex', justifyContent:'center', alignItems:'center', width: '33%',}}>
                    <p style={{margin:0, marginLeft: 'auto', marginRight:'auto', display:'flex', alignItems:'flex-end' }}>
                        {symbol}
                    </p>
                </div>
                <div style={{display: 'flex', justifyContent:'center',alignItems:'center',width: '33%', borderLeft:'solid', borderRight:'solid', padding: 3}}>
                    <p style={{margin:0, margin: 'auto'}}>
                        {yours}{index !== 0 ? "%" : null }
                    </p>
                </div>
                <div style={{display: 'flex', justifyContent:'center',alignItems:'center',width: '33%'}}>
                    <p style={{margin:0, margin: 'auto'}}>
                        {ours}{index !== 0 ? "%" : null }
                    </p>
                </div>
            </div>
        )
    }

    tickers2 = ()=>{
        const{optimizedPortfolio, optimizedPortfolioShare,riskAllocation} = this.props.data
        const {userInputs} = this.props
        let  data = []
        // console.log("tickers2",userInputs)
        Object.keys(optimizedPortfolio).map(ele=>{
            // console.log("tickers2",riskAllocation["new"][ele],ele)
            data.push([ele, Math.abs(userInputs[ele].allocation).toFixed(), Math.abs(100 * optimizedPortfolio[ele]).toFixed(), userInputs[ele].shares,optimizedPortfolioShare[ele],,riskAllocation["new"][ele]])
        })
        // return (
        //     <div style={{display:'flex', flexDirection:'column', width: '100%'}}>
        //         {data.map((ele,i)=>this.createRow(ele[0],ele[1],ele[2],i))}
        //     </div>
        // )
        return data
    }

    tickers = (location)=>{
        let tickers
        const{optimizedPortfolio} = this.props.data
        if(location === 'tickers'){
        tickers = Object.keys(optimizedPortfolio).map(ele=>{
            return(
                <p>{ele}</p>
            )
        })}
        else if(location === 'optimized'){
            tickers = Object.values(optimizedPortfolio).map(ele=>{
                return(
                    <p style={{color: ele < 0 ? 'rgb(233,89,74)':'rgb(17,17,17)', fontWeight: 'bold'}}>{Math.abs(100*ele).toFixed()}%</p>
                )
            })
        }
        else if(location === 'userInputs'){
            tickers = this.props.userInputs.map(ele=>{
                return(
                    <p style={{color: 'rgb(17,17,17)', fontWeight: 'bold'}}>{ele.allocation}%</p>
                )
            })
        }
        return tickers
    }

    saveModal = ()=>{
        if(this.state.showSaveModal){
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%'}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Save Portfolio</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({showSaveModal:false})}> X </p>
                        </div>
                                
                        <div style={{ width:'95%', margin:'auto',marginTop:25}}>
                           <p style={{fontSize:20}}>
                               Name your Portfolio (names may not include spaces)
                            </p>
                            <div style={{ width:'95%', margin:'auto', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <input style={{width:'60%', backgroundColor:this.state.portNameError?'rgb(253,180,181)':'', paddingLeft:'1%'}} placeholder="Portfolio-Name" type="text" value={this.state.portName} onChange={(e)=>this.setState({portName:e.target.value, portNameError:false})}/>
                            </div>
                        </div>
                        <div style={{width:'95%', margin:'auto',marginTop:25}}>
                            <p style={{fontSize:20}}>Which Portfolio would you like to save?</p>
                            <div style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'flex-start'}}>
                                <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center', width:'50%',margin:'auto'}}>
                                    <input style={{cursor:'pointer',}}type="radio" checked={this.state.saveWhich === 'optimized'} onChange={this.state.saveWhich === 'optimized'? ()=>this.setState({saveWhich:''}) :()=>this.setState({saveWhich:'optimized'})}/>
                                    <label style={{margin:0, fontSize: '1em', marginLeft: 4}}>Optimized Portfolio</label>
                                </div>
                                <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center', width:'50%',margin:'auto'}}>
                                    <input style={{cursor:'pointer'}}type="radio" checked={this.state.saveWhich === 'actual'} onChange={this.state.saveWhich === 'actual'? ()=>this.setState({saveWhich:''}) :()=>this.setState({saveWhich:'actual'})} />
                                    <label style={{margin:0, fontSize: '1em', marginLeft: 4}}>Actual Portfolio</label>
                                </div>
                                <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center', width:'50%',margin:'auto'}}>
                                    <input style={{cursor:'pointer'}}type="radio" checked={this.state.saveWhich === 'both'} onChange={this.state.saveWhich === 'both'? ()=>this.setState({saveWhich:''}) :()=>this.setState({saveWhich:'both'})} />
                                    <label style={{margin:0, fontSize: '1em', marginLeft: 4}}>Both Portfolios</label>
                                </div>
                            </div>
                        </div>
                        
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 20, marginBottom:20}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '20%', cursor:'pointer'}} 
                            onClick={()=>{
                                this.savePortfolio()
                                this.setState({showSaveModal:false, saved:true})
                            }}>
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                Save
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
            )
        }
    }

    statTable = ()=>{
        // console.log("Total cash",this.props.data)
        let {CVaR, dispersion, divYield, sharpeRatio,drawDownChart, maxDrawdown, returns, correlation, assetBreakDown} = this.props.data
        return(
            <div style={{width:'100%'}}>
                        {/* <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width: '100%', }}>
                            <div 
                                style={{width:'20%', display:'flex', justifyContent:'space-around', alignItems:'center', padding:10, borderRadius:8, backgroundColor:brandColor, cursor:'pointer'}}
                                onClick={()=>this.props.toPc()}
                            >
                                <FaArrowLeft color={'rgb(255,255,255)'}/>
                                <p style={{margin:0, color:'rgb(255,255,255)'}}>Back</p>
                            </div>
                            <div 
                                style={{width:'20%', display:'flex', justifyContent:'space-around', alignItems:'center', padding:10, borderRadius:8, backgroundColor:this.state.saved?'rgb(100,100,100)' :brandColor, cursor:'pointer', marginRight:5}}
                                onClick={this.state.saved? null :()=>this.setState({showSaveModal:true})}
                            >
                                <p style={{margin:0, color:'rgb(255,255,255)'}}>{this.state.saved? 'Saved':'Save'}</p>
                            </div>
                         
                        </div> */}
                        <div style={{paddingLeft: 5, paddingRight: 5, }}>
                            <div style={{display: 'flex', justifyContent: 'flex-start',}}>
                                <p style={{ fontSize:24,fontWeight:'bold'}}>Portfolio Statistics</p>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div style={{width:'50%', display:'flex', justifyContent:'flex-start', padding:5,backgroundColor:'rgb(127,130,137'}}>
                                    <p style={{margin:0, color:'rgb(255,255,255)'}}>Category</p>
                                </div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end',padding:5,backgroundColor:'rgb(33,37,49)'}}>
                                    <p style={{margin:0, color:'rgb(255,255,255)'}}>Actual</p>
                                </div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end',padding:5,backgroundColor:'rgb(52,131,129)'}}>
                                    <p style={{margin:0, color:'rgb(255,255,255)'}}>Optimized</p>
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',borderBottom:'solid', borderColor:'rgb(244,244,248)'}}>
                                <div style={{display:'flex', flexDirection:'column',backgroundColor:'rgb(240,240,245)', width:'50%', padding: 8}}>
                                    <p style={{margin: 0, fontWeight: 'bold'}}>AR Risk Number</p>
                                    <p style={{margin: 0, fontSize:'smaller' }}>the lower the better</p>
                                </div>
                                <div style={{color:this.textColor(CVaR.old), borderRadius: 8, padding: 10, width: 80, display:'flex', justifyContent:'flex-end', alignItems:'center', color:'rgb(33,37,49)'}}>
                                    <h1 style={{margin:0}}> {this.riskNum(CVaR.old)}</h1>
                                </div>
                                <div style={{color:this.textColor(CVaR.new), borderRadius: 8, padding: 10, width: 80, display:'flex', justifyContent:'flex-end', alignItems:'center', color:'rgb(52,131,129)'}}>
                                    <h1 style={{margin:0}}> {this.riskNum(CVaR.new)}</h1>
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',borderBottom:'solid', borderColor:'rgb(244,244,248)' }}>
                                <div style={{width:'50%', backgroundColor:'rgb(240,240,245)',padding:8}}><p style={{margin:0,padding:0}}>Return</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end',padding:8, alignItems:'center'}}><p style={{margin:0,padding:0,color:'rgb(33,37,49)'}}>{this.negZeroCheck(returns.old*100).toFixed(2)}%</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{color:'rgb(52,131,129)',margin:0,padding:0}}>{this.negZeroCheck(returns.new*100).toFixed(2)}%</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',borderBottom:'none', borderColor:'rgb(244,244,248)' }}>
                                <div style={{width:'50%', backgroundColor:'rgb(240,240,245)',padding:8}}><p style={{margin:0,padding:0}}>Dividend Yield</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end',padding:8, alignItems:'center'}}><p style={{margin:0,padding:0,color:'rgb(33,37,49)'}}>{divYield.old.toFixed(2)}%</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{color:'rgb(52,131,129)',margin:0,padding:0}}>{divYield.new.toFixed(2)}%</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',borderBottom:'solid', borderColor:'rgb(244,244,248)' }}>
                                <div style={{width:'50%', backgroundColor:'rgb(240,240,245)',padding:8}}><p style={{margin:0,padding:0}}>Sharpe Ratio</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end',padding:8, alignItems:'center'}}><p style={{margin:0,padding:0,color:'rgb(33,37,49)'}}>{this.negZeroCheck(sharpeRatio.old).toFixed(2)}</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{color:'rgb(52,131,129)',margin:0,padding:0}}>{this.negZeroCheck(sharpeRatio.new).toFixed(2)}</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',borderBottom:'solid', borderColor:'rgb(244,244,248)' }}>
                                <div style={{width:'50%', backgroundColor:'rgb(240,240,245)',padding:8}}><p style={{margin:0,padding:0}}>Correlation to SPX</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end',padding:8, alignItems:'center'}}><p style={{margin:0,padding:0,color:'rgb(33,37,49)'}}>{(correlation.old).toFixed(2)}</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{color:'rgb(52,131,129)',margin:0,padding:0}}>{(correlation.new).toFixed(2)}</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',borderBottom:'solid', borderColor:'rgb(244,244,248)' }}>
                                <div style={{width:'50%', backgroundColor:'rgb(240,240,245)',padding:8}}><p style={{margin:0,padding:0}}>Dispersion</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end',padding:8, alignItems:'center'}}><p style={{margin:0,padding:0,color:'rgb(33,37,49)'}}>{assetBreakDown.old.Cash ===1 ? '0.00':dispersion.old.toFixed(2)}</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{color:'rgb(52,131,129)',margin:0,padding:0}}>{dispersion.new.toFixed(2)}</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',borderBottom:'solid', borderColor:'rgb(244,244,248)' }}>
                                <div style={{width:'50%', backgroundColor:'rgb(240,240,245)',padding:8}}><p style={{margin:0,padding:0}}>Cvar</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end',padding:8, alignItems:'center'}}><p style={{margin:0,padding:0,color:'rgb(33,37,49)'}}>{(CVaR.old *100).toFixed(0)}%</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{color:'rgb(52,131,129)',margin:0,padding:0}}>{(CVaR.new *100).toFixed(0)}%</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',borderBottom:'solid', borderColor:'rgb(244,244,248)' }}>
                                <div style={{width:'50%', backgroundColor:'rgb(240,240,245)',padding:8}}><p style={{margin:0,padding:0}}>Max Drawdown</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end',padding:8, alignItems:'center'}}><p style={{margin:0,padding:0,color:'rgb(33,37,49)'}}>{(maxDrawdown.old*100).toFixed(0)}%</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{color:'rgb(52,131,129)',margin:0,padding:0}}>{(maxDrawdown.new*100).toFixed(0)}%</p></div>
                            </div>
                        </div>
                    </div>
        )
    }

    subModal = ()=>{
        if(this.state.subModal){
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{width:'20%',display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                            <div style={{backgroundColor:'rgb(253,234,234)', borderRadius: 10, width: 40, display: 'flex', justifyContent:'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>this.setState({subModal:false})}>
                                <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(187,58,60)'}}>
                                    X
                                </p>
                            </div>
                        </div>
                        <div>
                            <SubModal userEmail={this.props.userEmai} updatePaymentStatus={this.props.updatePaymentStatus} subStatus={this.props.subStatus} activeTab={this.props.activeTab} user={this.props.user} subLevel={this.props.subLevel}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    shouldWe = ()=>{
        
        if(this.props.data.returns && this.props.data.returns.new < 0){
            this.setState({showDontDoIt:true})
        }
    }

    dontDoItModal = ()=>{
        if(this.state.showDontDoIt){
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'50%'}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Warning</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} 
                                onClick={()=>{
                                    this.setState({showDontDoIt:false, step:0})}
                                }
                            > X </p>
                        </div>
                        <div style={{padding:25}}>
                            {/* <p style={{fontSize:24}}>We do NOT recommend that you execute this portfolio. Our analysis projects that this portfolio will lose value over the next 30 days.</p> */}
                            <p style={{fontSize:24}}>Based on our optimizer and risk forecast model, your portfolio will have low or negative risk adjusted return over the next 30 days.</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    useLinked = ()=>{
        let mapped = [
            <div
                style={{
                    padding: 25,
                    width: '100%',
                    borderRadius: 8,
                    margin: 10,
                    height: "85%",
                    textDecoration: "none",
                    cursor: 'pointer',
                }}
            >
                <div style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                    textDecoration: "none",
                }}>
                    <div style={{width:this.props.isSmallScreen?'33%':'30%', display:'flex', justifyContent:"flex-start", alignItems:'center'}}>
                        <p style={{margin:0, fontSize:20}}>{this.props.isSmallScreen? "Inst": "Institution"}</p>
                    </div>{}
                    <div style={{width:this.props.isSmallScreen?'33%':'30%', display:'flex', justifyContent:"flex-start", alignItems:'center'}}>
                        <p style={{margin:0, fontSize:20}}>{this.props.isSmallScreen? "Name": "Account Name"}</p>
                    </div>
                    {/* <div style={{width:this.props.isSmallScreen?'33%':'20%', display:'flex', justifyContent:"center", alignItems:'center'}}>
                        <p style={{margin:0, fontSize:20}}>{this.props.isSmallScreen? "Acc # ": "Account number"}</p>
                    </div> */}
                    <div style={{width:this.props.isSmallScreen?'33%':'20%', display:'flex', justifyContent:"flex-end", alignItems:'center'}}>
                        <p style={{margin:0, fontSize:20}}>{this.props.isSmallScreen? "Value" : " Value"}</p>
                    </div>
                </div>
            </div>,
        ]
        this.props.accounts.forEach(acc=>{
            if(acc.inst === 'DriveWealth' && !acc.product && !acc.ria){
                mapped.push(
                    <div
                        style={{
                            padding: 25,
                            borderRadius: 8,
                            margin: 10,
                            height: "85%",
                            backgroundColor: this.state.hover === acc.itemId ? '#F8F9FA': "rgb(248,249,250)",
                            textDecoration: "none",
                            cursor: 'pointer',
                        }}
                        onClick={()=>{
                            this.findChanges(acc.balances[0].current,acc.holdings)
                            this.setState({selectedAcc:acc, step:1})
                        }
                    }
                    >
                        <div style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 8,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 8,
                            textDecoration: "none",
                        }}>
                            <div style={{width:'30%', display:'flex', justifyContent:"flex-start", alignItems:'center'}}>
                                <p style={{margin:0}}>{acc.inst}</p>
                            </div>
                            <div style={{width:'30%', display:'flex', justifyContent:"flex-start", alignItems:'center'}}>
                                <p style={{margin:0}}>{acc.mask_full}</p>
                            </div>
                            {/* {this.props.isSmallScreen?null: 
                            <div style={{width:'10%', display:'flex', justifyContent:"flex-end", alignItems:'center'}}>
                                <p style={{margin:0}}>{inner.mask}</p>
                            </div>} */}
                            <div style={{width:'30%', display:'flex', justifyContent:"flex-end", alignItems:'center'}}>
                                <p style={{margin:0}}><strong style={{fontSize:20}}>${acc.balances[0].current? this.addCommas(acc.balances[0].current.toFixed()):'0.00'}</strong></p>
                            </div>
                        </div>
                    </div>
                )
            }
        })
        return(
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{width:'90%', margin:'auto',marginBottom:25}}>
                    <h4 style={{color:'rgb(17,17,17)'}}>Choose account</h4>
                </div>
                <div style={{width:'95%', margin:'auto', backgroundColor:'rgb(255,255,255)',borderRadius:12 }}>
                    {mapped}
                </div>
            </div>
        )
    }


    exModal = ()=>{
        if(this.state.exModal){
            switch(this.state.step) {
                case 0:
                    return this.chooseAcc()
                case 1:
                    return this.showChanges()
                case 2:
                    return this.tradeSummary()
                case 3:
                    return this.ordersPlaced()
            }
        }
    }
    
    tradeSummary = ()=>{
        // console.log('state.bulkResp', this.state.bulkResp)
        // console.log('state.tradesNecessary', this.state.tradesNecessary)
        let trades = []
        for (let i = 0; i < this.state.bulkResp.length; i++) {
            trades.push({...this.state.bulkResp[i],...this.state.tradesNecessary[i]})
        }
        console.log('trades', trades)
        let numTrades = this.state.tradesNecessary.length-1
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'50%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Preview Each Trade</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} 
                            onClick={()=>{
                                this.setState({exModal:false, step:0})}
                            }
                        > X </p>
                    </div>
                    <div style={{paddingTop:25}}>
                        <MultiTrades user={this.props.user} trades={trades}/>
                    </div>
                    <div style={{width:'60%',margin:'auto'}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start',marginBottom:5 }}>
                            <input type="radio" checked={this.state.yn==='Yes'} onChange={()=>this.setState({yn:'Yes'})}/> 
                            <p style={{margin:0, marginLeft:10, color:this.state.clickedSubmitWithoutSure ? 'rgb(255,0,0)':'',}}>Yes, I am sure I want to execute {numTrades === 1? 'this':'these'} {numTrades} {numTrades === 1? 'trade':'trades'}.</p>
                        </div>
                    </div>
                    <div style={{ width:'100%', }}>
                        <div style={{marginBottom:10,display:'flex',justifyContent:'space-evenly', alignItems:'center'}}>
                            <div onClick={()=>this.setState({step:1})} style={{width:'40%', margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(52,131,129)', paddingTop: 7, paddingBottom:7, borderRadius: 8, cursor:'pointer'}}>
                                <p style={{margin:0, color:'rgb(52,131,129)',}}>
                                   Go Back
                                </p>
                            </div>
                            <div onClick={this.state.yn === 'Yes'? ()=>this.tradeAll():()=>this.setState({clickedSubmitWithoutSure:true})} style={{width:'40%', margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(52,131,129)', paddingTop: 7, paddingBottom:7, borderRadius: 8, cursor:'pointer'}}>
                                <p style={{margin:0, color:'rgb(52,131,129)',}}>
                                    Submit Trades
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    findAfter(sym){
        if(this.props.data.optimizedPortfolio[sym]){
            if(sym === 'CASH'){
                return this.props.data.optimizedPortfolio['$$']
            }else{
                return this.props.data.optimizedPortfolio[sym]
            }
        }
        else{
            return 0
        }
    }

    findChanges = (total,holdings)=>{
        let body = []
        let prices = {...this.state.prices}
        let hldings = []
        let tradesNecessary = []
        holdings.forEach(stock=>{
            if (!(stock.symbol === 'CASH' || stock.symbol === '$$')){
                body.push(stock.symbol)
                let aQty = parseFloat(this.findAfter(stock.symbol)*total)
                let cQty = aQty - stock.quantity
                hldings.push(stock.symbol)
                tradesNecessary.push({ticker: stock.symbol, qty: Math.abs(cQty), buy:cQty > 0 ? true:false})
            }
        })
        Object.keys(this.props.data.optimizedPortfolio).forEach(ele=>{
            if(!hldings.includes(ele)){
                if(!(ele === 'CASH' || ele === '$$')){
                    body.push(ele)
                    let aQty = parseFloat(this.findAfter(ele)*total)
                    tradesNecessary.push({ticker: ele, qty:Math.abs(aQty), buy:aQty > 0 ? true:false})
                }
            }
        })
        console.log('body',body)
        fetch(`${ENDPOINT}/risk-monkey/momentum/bulk?realtime=true?full=true`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .catch(err=> console.log('catching: ', err))
        .then(resp=>resp.json())
        .then(responseJson=>{
            responseJson.forEach((ele)=>{
                prices = {...prices,[ele.symbol]:ele.priceUSD}
            })
            this.setState({tradesNecessary, prices:prices,bulkResp:responseJson,} )
        })
    }

    showChanges = ()=>{
        let mapped = [ 
            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                <div style={{width:'25%'}}>
                    <p style={{margin:0,padding:8, fontWeight:'bold'}}>SYMBOL</p>
                </div>
                <div style={{width:'25%'}}>
                    <p style={{margin:0,padding:8, fontWeight:'bold'}}>BUY/SELL</p>
                </div>
                <div style={{width:'25%'}}>
                    <p style={{margin:0,padding:8, fontWeight:'bold',textAlign:'right'}}>QUANTITY</p>
                </div>
                <div style={{width:'25%'}}>
                    <p style={{margin:0,padding:8, fontWeight:'bold',textAlign:'right'}}>VALUE</p>
                </div>
            </div>,
        ]
        this.state.tradesNecessary.forEach(stock=>{
            if(!(stock.ticker === '$$' || stock.ticker === 'CASH'|| stock.ticker === 'BIL')){
                mapped.push(
                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                        <div style={{width:'25%'}}>
                            <p style={{margin:0,padding:8, }}>{stock.ticker}</p>
                        </div>
                        <div style={{width:'25%'}}>
                            <p style={{margin:0,padding:8, }}>{stock.buy?'BUY':'SELL'}</p>
                        </div>
                        <div style={{width:'25%'}}>
                            <p style={{margin:0,padding:8,textAlign:'right' }}>{this.addCommas(parseFloat((stock.qty*this.state.pcntTotal).toFixed(8)).toString())}</p>
                        </div>
                        <div style={{width:'25%'}}>
                            <p style={{margin:0,padding:8,textAlign:'right' }}>${Object.keys(this.state.prices).length > 2? this.addCommas((stock.qty*this.state.pcntTotal* this.state.prices[stock.ticker]).toFixed(2)):null}</p>
                        </div>
                    </div>
                )
            }
        })

        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'60%', height:'95%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Submit Orders</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({exModal:false, step:0})}> X </p>
                    </div>
                    <div style={{width:'100%',overflowY:'scroll'}}>
                        <div style={{paddingTop:25, width:'95%', margin:'auto'}}>
                            {mapped}
                        </div>
                        <div style={{width:'90%', margin:'auto', marginTop:25,marginBottom:25}}>
                            <p>How much of your total Portfolio Value would you like to direct to this newly constructed portfolio?</p>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5,}}>
                                    <input 
                                        style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent'}}
                                        type="number" 
                                        value={this.state.pcntTotal*100} 
                                        min=".01" 
                                        max="1" 
                                        onChange={(e)=>this.setState({pcntTotal:e.target.value})}
                                    />
                                    <p style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent'}}>%</p>
                                </div>
                            </div>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', }}>
                                <input type="range" value={this.state.pcntTotal} min=".01" max="1" step=".01" onChange={(e)=>this.setState({pcntTotal:e.target.value})}/>
                            </div>
                        </div>
                       
                        
                        <div style={{width:'100%', marginBottom:10}}>
                            <div onClick={()=>this.setState({step:2})} style={{ margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(52,131,129)', paddingTop: 7, paddingBottom:7, paddingLeft:15, paddingRight:15, borderRadius: 8, cursor:'pointer', width:'fit-content'}}>
                                <p style={{margin:0, color:'rgb(52,131,129)', }}>
                                    Preview Trades
                                </p>
                            </div>
                        </div>
                        <div style={{width:'95%', margin:'auto'}}>
                            <p style={{margin:0, fontSize:14}}>*SUBMITTING TRADES WILL BUY AND SELL STOCKS ACCORDING TO THE CHANGE COLUMN ABOVE*</p>
                            <p style={{margin:0, fontSize:14}}>*YOU WILL RETAIN {this.state.pcntTotal*100}% OF YOUR TOTAL VALUE IN CASH*</p>
                            <p style={{margin:0, fontSize:14}}>*DO TO CHANGES IN THE MARKET YOU MAY PURCHASE OR SELL STOCK AT SLIGHTLY DIFFERENT PRICES THAN ARE SEEN ABOVE*</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }



    addCommas = (x)=>{
        if(x === ''){
            return ''
        }else{
            let arr = x.toString().split(".")
            if(arr[1]){
                return (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))+ '.' + arr[1]
            }
            else{
                return  (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            }
        }
    }

    tradeAll = ()=>{
        let body ={
            "dw_account_id": this.state.selectedAcc.accountId,
            "SELL": [],
            "BUY": []
        }
        this.state.tradesNecessary.forEach(stock=>{
            console.log(stock.ticker, (stock.ticker === '$$' || stock.ticker === 'CASH' || stock.ticker === 'BIL')?'skip':' dont skip')
            if(stock.ticker === '$$' || stock.ticker === 'CASH' || stock.ticker === 'BIL'){
                console.log('skipped', stock.ticker)
            }
            else{
                if(stock.buy){
                    body.BUY.push({ticker:stock.ticker, price:(stock.qty*this.state.pcntTotal* this.state.prices[stock.ticker]).toFixed(2)})
                    // body.BUY.push({ticker:stock.ticker, qty:stock.qty.toFixed(8)})
                }
                else{
                    body.SELL.push({ticker:stock.ticker, qty:stock.qty.toFixed(8)})
                }
            }
        })
        console.log('multi-Ex body: ', body)
        
        fetch(`${ENDPOINT}/mobile/multi-execute`,{
          method: "POST",
          headers: {
            "Content-Type":"application/json",
            Authorization: "Bearer " + this.props.user.idToken.jwtToken,
        },
          body: JSON.stringify(body)
        })
        .then((res)=>res.json())
        .then((tradeResp)=>console.log(' trade all tradeResp',tradeResp))
        this.setState({step:3})
    }

    ordersPlaced = ()=>{
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'60%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Execute Portfolio</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({exModal:false, step:0})}> X </p>
                    </div>
                    <div style={{paddingTop:25,paddingBottom:25, width:'95%', margin:'auto'}}>
                        <p style={{fontSize:32}}>
                            Orders Placed!
                        </p>
                        <p style={{fontSize:24}}>
                            You have placed {this.state.tradesNecessary.length-1} orders! Please check your order history in trade center to see the status of each individual order. 
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    chooseAcc = ()=>{
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Submit Orders</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} 
                            onClick={()=>{
                                this.setState({exModal:false, step:0})}
                            }
                        > X </p>
                    </div>
                    <div style={{paddingTop:25}}>
                        {this.useLinked()}
                    </div>
                </div>
            </div>
        )
    }

    negZeroCheck(num){
        if(num < 0 && num > -0.01){
            return '0.00'
        }else{
            return num
        }
        
    }

    removeCommas = (x)=>{
        return parseFloat(x.replace(/,/g, ''))
    }

    render() {
        if(Object.keys(this.props.data).length < 2){
            console.log('something didnt work, lets check out the data:',this.props.data)
            return(
                <div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                       {this.props.data.constraintError === "Illegal Constraints."?
                            <div>
                                <NavLink to={'/Dashboard/Constructor'} exact={true}><h2 style={{color:'rgb(110,188,186)'}} >Something went wrong click here to go back and try again</h2></NavLink>
                            </div>
                       :
                            <h2>Loading... </h2>
                       }
                    </div>
                </div>
            )
        }
        let {CVaR, dispersion, divYield, sharpeRatio,drawDownChart, maxDrawdown, returns, correlation, assetBreakDown} = this.props.data
        if(!this.props.isSmallScreen){
            return(
                <div style={{width:'100%', display:'flex',flexDirection:'column'}}>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', marginBottom:20}}>
                        
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:12, borderWidth:'medium', borderColor: 'rgb(74,129,128)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                        onClick={()=>this.props.toPc()}>
                            <FaArrowLeft color={'rgb(74,129,128)'}/>
                            <p style={{margin:0,fontWeight:'', marginLeft:5,color:'rgb(74,129,128)',fontWeight:'bold'}}>Back</p>
                        </div>
                        <div style={{display:'flex', width:'30%', justifyContent:'flex-end', marginRight:10}}>
                            
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:12, borderWidth:'medium', borderColor: 'rgb(74,129,128)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                onClick={this.state.saved? null :()=>this.setState({showSaveModal:true})}
                            >
                                <p style={{margin:0,fontWeight:'',color:'rgb(74,129,128)',fontWeight:'bold'}}>{this.state.saved? 'Saved':'Save'}</p>
                            </div>
                            {this.state.canTrade? 
                            
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:12, borderWidth:'medium', borderColor: 'rgb(74,129,128)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                onClick={()=>this.setState({exModal:true})}
                            >
                                <p style={{margin:0,fontWeight:'', color:'rgb(74,129,128)',fontWeight:'bold'}}>Submit Orders</p>
                            </div>
                            :null}
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:"space-evenly", width:'100%', marginBottom:20}}>
                            <div style={{paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:20, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'50%'}}>
                                <div style={{width:'100%'}}>
                                    {this.statTable()}
                                </div>
                            </div>
                            <div style={{ display:'flex', flexDirection: 'column', width:'40%', justifyContent:'center', marginTop:10, justifyContent:'center'}}>
                                <div style={{height: 225, paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%',}}>
                                    <div style={{display:'flex',justifyContent:'center', alignItems:'center', }}>
                                        <RiskGuage cvar={CVaR.new} before={false}/>
                                    </div>
                                </div>
                                <div style={{height: 225, paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%', marginTop:30, }}>
                                    <div style={{display:'flex',justifyContent:'center', alignItems:'center', }}>
                                        <RiskGuage cvar={CVaR.old} before={true}/>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div style={{display:'flex', justifyContent:"space-evenly", width:'100%', marginBottom:20}}>
                            <div style={{ display:'flex', alignItems:'flex-start', paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:10, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%', height:'fit-content'}}>
                                <TickersTable data={this.tickers2()} isSmallScreen={this.props.isSmallScreen}/>
                            </div>
                    </div>
                    <div style={{display:'flex', justifyContent:"space-evenly", width:'100%', marginBottom:20}}>
                        <div style={{ paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:10, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%', }}>
                            <div style={{display: 'flex', justifyContent: 'flex-start',}}>
                                <p style={{ fontSize:24,fontWeight:'bold'}}>Where is my risk?</p>
                            </div>
                            <div style={{display:'flex',justifyContent:'center', alignItems:'center', width:'100%' }}>
                                <RiskByStock actual={this.props.data.riskAllocation.old} optimized={this.props.data.riskAllocation.new}/>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent:"space-between", width:'100%', marginBottom:20}}>
                        <div style={{width:'48%',paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:30, borderRadius: '50px', backgroundColor:'#FFFFFF', marginBottom:20}}>
                            <PieChart data={assetBreakDown.new} optPc={true}/>
                        </div>
                        <div style={{width:'48%',paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:30, borderRadius: '50px', backgroundColor:'#FFFFFF', marginBottom:20}}>
                            <PieChart data={assetBreakDown.old} optPc={false}/>
                        </div>

                    </div>
                    <div style={{display:'flex', justifyContent:"space-evenly", width:'100%', marginBottom:20}}>
                            <div style={{paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:20, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%', display:'flex',flexDirection:'column'}}>
                                <div style={{display: 'flex', justifyContent: 'flex-start',}}>
                                    <p style={{ fontSize:24,fontWeight:'bold'}}>Stress Test</p>
                                </div>
                                <div style={{width:'100%'}}>
                                    <DoubleLine data={drawDownChart} title={'stress'}/>
                                </div>
                            </div>
                    </div>
                    {this.saveModal()}
                    {this.subModal()}
                    {this.exModal()}
                    {this.dontDoItModal()}
                </div>
            )}
        else{
            return(
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', marginBottom:20}}>
                        <div 
                            style={{width:'45%', display:'flex', justifyContent:'space-around', alignItems:'center', padding:10, borderRadius:8, backgroundColor:'rgb(240,240,245)', cursor:'pointer'}}
                            onClick={()=>this.props.toPc()}
                        >
                            <FaArrowLeft color={'rgb(17,17,17)'}/>
                            <p style={{margin:0, color:'rgb(17,17,17)'}}>Back</p>
                        </div>
                        <div 
                            style={{width:'45%', display:'flex', justifyContent:'space-around', alignItems:'center', padding:10, borderRadius:8, backgroundColor:this.state.saved?'rgb(100,100,100)' :'rgb(240,240,245)', cursor:'pointer', marginRight:5}}
                            onClick={this.state.saved? null :()=>this.setState({showSaveModal:true})}
                        >
                            <p style={{margin:0, color:'rgb(17,17,17)'}}>{this.state.saved? 'Saved':'Save'}</p>
                        </div>
                    </div>
                    <hr></hr>
                    <div style={{width:'100%',marginBottom:20}}>
                        {this.statTable()}
                    </div>
                    <hr></hr>
                    <div style={{width: '100%', display:'flex', flexDirection:'column',}}>
                        <div style={{ display: 'flex', justifyContent:'center', alignItems:'center',width:'90%',margin:'auto'}}>
                            <TickersTable data={this.tickers2()} isSmallScreen={this.props.isSmallScreen}/>
                        </div>
                    </div>
                    <hr></hr>
                    <div style={{display:'flex', flexDirection: 'column', width:'100%', marginTop: 20}}>
                        <div style={{height: 225, paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%',}}>
                            <div style={{display:'flex',justifyContent:'center', alignItems:'center', }}>
                                <RiskGuage cvar={CVaR.new} before={false}/>
                            </div>
                        </div>
                        <div style={{height: 225, paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%', marginTop:30, }}>
                            <div style={{display:'flex',justifyContent:'center', alignItems:'center', }}>
                                <RiskGuage cvar={CVaR.old} before={true}/>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div style={{display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column', width:'100%'}}>
                        <div style={{width:'100%',paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:30, borderRadius: '50px', backgroundColor:'#FFFFFF', }}>
                            <PieChart data={assetBreakDown.new} optPc={true}/>
                        </div>
                        <div style={{width:'100%',paddingLeft:'2%',paddingRight:'2%',paddingTop:20,paddingBottom:30, borderRadius: '50px', backgroundColor:'#FFFFFF', }}>
                            <PieChart data={assetBreakDown.old} optPc={false}/>
                        </div>
                    </div>
                    <hr></hr>
                    <div style={{display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column',width:'100%' }}>
                        
                        <div style={{zIndex:0, marginRight: 0 }}>
                            <DoubleLine data={drawDownChart} title={'stress'} isSmallScreen={this.props.isSmallScreen}/>
                        </div>
                    </div>
                    {this.saveModal()}
                    {this.subModal()}
                    {this.dontDoItModal()}
                </div>
            )
        }
    }
}

/**
     <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width: '100%', }}>
        <div 
            style={{width:'20%', display:'flex', justifyContent:'space-around', alignItems:'center', padding:10, borderRadius:8, backgroundColor:brandColor, cursor:'pointer'}}
            onClick={()=>this.props.toPc()}
        >
            <FaArrowLeft color={'rgb(255,255,255)'}/>
            <p style={{margin:0, color:'rgb(255,255,255)'}}>Back</p>
        </div>
        <div 
            style={{width:'20%', display:'flex', justifyContent:'space-around', alignItems:'center', padding:10, borderRadius:8, backgroundColor:this.state.saved?'rgb(100,100,100)' :brandColor, cursor:'pointer', marginRight:5}}
            onClick={this.state.saved? null :()=>this.setState({showSaveModal:true})}
        >
            <p style={{margin:0, color:'rgb(255,255,255)'}}>{this.state.saved? 'Saved':'Save'}</p>
        </div>
        
    </div>
 */