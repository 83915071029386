import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterPageTitle from "../layout/FooterPageTitle";
import Footer from "../Footer";
import Header from "../PublicHeader"

class TermsOfUse extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <div>
        <FooterPageTitle title="TERMS OF USE" />
        <Container>
          <Row />
          <Row className="terms-top-paragraph">
            <p className="terms-text">
              By accessing this site, you signify your agreement with, and understanding of, the following terms of use and legal information pertaining to both this site and any material on it. This site is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein. Your use of this site constitutes your agreement to all such terms, conditions and notices. ForeScite reserves the right to change the terms, conditions, and notices under which this site is offered.
            </p>
            <p className="terms-text">
              All materials on this site are meant to be reviewed in their entirety, including any footnotes, legal disclaimers, restrictions or disclosures, and any copyright or proprietary notices. Any disclaimers, restrictions, disclosures or hedge clauses apply to any partial document or material in the same manner as they do the whole, and will be deemed incorporated in the portion of any material or document that you consult or download.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>ARBITRATION NOTICE</h3>
            <p className="terms-text">
              With the exception of disputes arising from the brokerage service, you agree that disputes arising under these terms, will be resolved by binding individual arbitration, and by accepting these terms, you and ForeScite are each waiving the right to trial by jury or to participate in any class action or representative proceeding. You agree to give up your right to go to court to assert or defend your rights under this contract except for matters that may be taken to small claims court. Your rights will be determined by neutral arbitrator and not a judge or jury.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>OWNERSHIP OF SITE AND COPYRIGHT</h3>
            <p className="terms-text">
              The ForeScite website is owned by ForeScite LLC. The ForeScite website and any and all accompanying screens, information, materials, user documentation, user interfaces, images, arrangements of information, related software and other proprietary property of ForeScite or its licensors accessible via the web site is and shall remain the exclusive property of ForeScite and its licensors, as the case may be. All rights to the website remain with ForeScite or its licensors. This site is for your personal and non-commercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell any information, software, products or services obtained from this site.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>ELIGIBILITY</h3>
            <p className="terms-text">
            You must be at least 18 years old to use the Services. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 18 years old; (b) you have not previously been suspended or removed from the Services; and (c) your registration and your use of the Services is in compliance with any and all applicable laws and regulations. You may not authorize another user to use the Services on your behalf.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>ACCOUNTS AND REGISTRATION</h3>
            <p className="terms-text">
              To access most features of the Services, you must register for an account. When you register for an account, you may be required to provide us with some information about yourself, such as your name, email address, phone number, or other contact information. You agree that the information you provide to us is accurate and that you will keep it accurate and up-to-date at all times. When you register, you will be asked to provide a password. You are solely responsible for maintaining the confidentiality of your account and password, and you accept responsibility for all activities that occur under your account. 
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>GENERAL PAYMENT TERMS</h3>
            <p className="terms-text">
              Certain features of the Services may require you to pay fees. Before you pay any fees, you will have an opportunity to review and accept the fees that you will be charged. All fees are in U.S. Dollars and are non-refundable. The fees set forth in this section are independent from all brokerage fees.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>LICENSES</h3>
            <p className="terms-text">
            <strong>Limited License.</strong> Subject to your complete and ongoing compliance with these Terms, ForeScite.com grants you, solely for your personal, non-commercial use, a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to: (a) install and use one object code copy of any mobile application associated with the Services obtained from a legitimate marketplace (whether installed by you or pre-installed on your mobile device by the device manufacturer) on a mobile device that you own or control; and (b) access and use of the Services.

            <strong>License Restrictions.</strong> Except and solely to the extent such a restriction is impermissible under applicable law, you may not: (a) reproduce, distribute, publicly display, or publicly perform the Services; (b) make modifications to the Services; or (c) interfere with or circumvent any feature of the Services, including any security or access control mechanism. If you are prohibited under applicable law from using the Services, you may not use it.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>UNAUTHORIZED ACCESS</h3>
            <p className="terms-text">
              This site is not absolutely protected against unauthorized third parties. You acknowledge that any information provided through the internet may be potentially accessed by unauthorized third parties. Although ForeScite will make reasonable efforts to protect the privacy of users of this site, no guarantee can be made that unauthorized third parties will not access the information contained on the website. You acknowledge that ForeScite is not responsible for notifying you that unauthorized third parties have gained such access or that any data has been otherwise compromised during transmission across computer networks or telecommunications facilities, including, but not limited to, the internet.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>PASSWORDS AND SECURITY</h3>
          </Row>
          <Row>
            <p className="terms-text">
              You are responsible for the confidentiality and use of your
              password. Your password is an important means of protection for
              you. You agree to contact us immediately if you believe that an
              unauthorized person has obtained access to your password.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>LINKS</h3>
          </Row>
          <Row>
            <p className="terms-text">
              ForeScite has not reviewed unaffiliated sites linked to this site, if any, and is not responsible for the content of off-site pages or any other site linked or linking to the site. Your linking to any off-site pages or other sites is at your own risk. ForeScite makes no representations whatsoever about the opinions of any third party appearing on a linked site, neither regularly monitors nor has control over the contents of such sites, and does not endorse, and disclaims all responsibility for, the content of such statements or websites.
            </p>
            <p className="terms-text">
              Our Service may contain links to third-party web sites or services that are not owned or controlled by ForeScite LLC.
            </p>
            <p className="terms-text">
              ForeScite LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that ForeScite LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
            </p>
            <p className="terms-text">
              We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>NO WARRANTY OR RELIANCE</h3>
          </Row>
          <Row>
            <p className="terms-text">
              The website, including information and materials contained in the website, text, graphics, software, links and other items are provided “as is,” “as available” without warranty of any kind, either express or implied, to the fullest extent permissible pursuant to applicable law. Without limitation, ForeScite does not warrant the accuracy, adequacy, completeness, reliability, timeliness or availability of the website or any information on this site, and expressly disclaims liability for errors or omissions in the web site. There is no warranty of merchantability, no warranty of fitness for a particular purpose, no warranty of non-infringement, no warranty of any kind, implied, express or statutory, in conjunction with the website. Any contents on this site are subject to change without notice. ForeScite further assumes no responsibility for, and makes no warranties that, functions contained at this site will be uninterrupted or error-free, that defects will be corrected, or that the site or the server that makes it available will be free of viruses or other harmful components. Please note that some jurisdictions do not allow the exclusion of certain warranties, so some or all of the above exclusions may not apply to you.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>LIMITATION OF LIABILITY</h3>
            <p className="terms-text">
              In no event will ForeScite be liable for any damages, or for repairs or corrections that must be performed, to or on your computer, person or other property, including, without limitation, direct or indirect, special, incidental, or consequential damages, losses or expenses arising in connection with the web site or use thereof or the inability by any party to use such site, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if ForeScite, or representatives thereof, are advised of the possibility of such damages, losses or expenses.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>ASSISTANCE</h3>
            <p className="terms-text">
              If you need assistance with the website, or if you need to communicate with ForeScite, you may contact ForeScite Client Services at info@ ForeScite.com
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>TERMINATION</h3>
          </Row>
          <Row>
            <p className="terms-text">
              We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
            </p>
            <p className="terms-text">
              All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>GOVERNING LAW</h3>
          </Row>
          <Row>
            <p className="terms-text">
              These Terms shall be governed and construed in accordance with the laws of the United States of America, without regard to its conflict of law provisions.
            </p>
            <p className="terms-text">
              Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>CONTACT US</h3>
          </Row>
          <Row>
            <p className="terms-text">
              If you have any questions about these Terms, please
              <a
                className="terms-contact-us"
                href={`mailto:help@allocaterite.com`}
              >
                {" "}
                contact us
              </a>
              .
            </p>
            <p className="terms-text" />
          </Row>
          <Row className="terms-paragraph-row">
            <Col className="terms-bottom-row">
              <p className="terms-bottom-date">Last updated: March 15, 2021.</p>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
      </div>
    );
  }
}

export default TermsOfUse;
