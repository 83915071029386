import React from 'react'
import Header from '../layout/Header';
// import Footer from "../layout/Footer";
import Portfolio from '../dashboard/Portfolio2'
import PortConstructor from '../dashboard/PortConstructor2'
import References from '../dashboard/References'
import PriceTrendPredictor from '../dashboard/PriceTrendPredictor2'
import MyStrats from '../dashboard/MyStrats2'
import Watchlist from "../dashboard/Watchlist2"
import WealthManagement from '../dashboard/WealthManagement2'
// import Invest from '../dashboard/WealthManagement2'
import Invest from '../dashboard/Invest'
import Invested from '../dashboard/Accounts'
// import Invested from '../dashboard/Invested2'
// import Invest from '../dashboard/Invested'
import Budgeting from '../dashboard/Budgeting'
import Account from '../dashboard/Account'
import LoginHalf from "../loginHalf"
import SubModal from "../SubModal"
import { /**FaBars,*/ FaVideo }  from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import ARlogo from "../../assets/ar_logo.png"
import PrivateHeader from "../PrivateHeader"
import InSCITELogo from "../../assets/newUI/InSCITE_Final_Logo_Dark.svg"
import ForeSCITELogo from "../../assets/newUI/ForeSCITE_Final_Logo_Dark.png"
import BudgetingActive from "../../assets/newUI/budgetingActive.svg"
import BudgetingInactive from "../../assets/newUI/budgetingInactive.svg"
import ForeSciteNoText from "../../assets/newUI/ForesciteNoText.png"
import Footer from '../Footer';
import GetApp from "../../assets/newUI/getApp.png"

import ConstructorActive from "../../assets/newUI/constructor-active.svg"
import ConstructorInactive from "../../assets/newUI/constructor-inactive.png"
import MypsActive from "../../assets/newUI/myps-active.svg"
import MypsInactive from "../../assets/newUI/myps-inactive.png"
import PtrendActive from "../../assets/newUI/ptrend-active.svg"
import PAccountActive from "../../assets/newUI/ptrend-active.svg"
import PAccountInactive from "../../assets/newUI/watchlist-inactive.png"

import PtrendInactive from "../../assets/newUI/ptrend-inactive.png"
import WatchlistActive from "../../assets/newUI/watchlist-active.svg"
import WatchlistInactive from "../../assets/newUI/watchlist-inactive.png"
import WmActive from "../../assets/newUI/wm-active.svg"
import WmInactive from "../../assets/newUI/wm-inactive.png"
import AppStore from "../../assets/newUI/apple-download@2x.png"
import GpStore from "../../assets/newUI/android-download@2x.png"

import ENDPOINT from '../Endpoint'

import history from '../History'

const brandColor = "#00A99D"

export default class UserDashboard extends React.Component{
    state={
        activeTab: 'Watchlist',
        // activeTab: 'Portfolio Constructor',
        myWatchlist: [],
        presetTickers: [],
        optimizedResults:{},
        userInputs: [],
        showListOptions: false,
        prevSet: [],
        subLevel:null,
        customPorts:{},
        userEmail: '',
        itemsResp: 'loading',
        accounts: [],
        addAcc: false,
        holdings:[],
        optCash:0,
        invStep:-1,
        prod:'',
        allAccounts:[],
    }

    async componentDidMount(){
        if(window.location.pathname !== `/Dashboard/${this.state.activeTab}`){
            console.log('inside CDM if')
            let tab = window.location.pathname.split("/")[2]
            this.setState({activeTab:tab})
        }
        if(this.props.user){
            this.subStatus()
            this.fetchWatchList()
            // this.getPlaidItems()
            // this.getPlaidHoldings()
            this.getAllAccountInfo()
            this.fetchCommonStocks()
            this.fetchCustomStrategies()
            // this.getPlaidAccounts()
        }

        this.updateWindowDimensions()
        document.title = `Dashboard`;
    }

    fetchCommonStocks = ()=>{
        // let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
        let url = `${ENDPOINT}/search/ticker-list`
        fetch(url)
            .then((res) => res.json())
            .then((responseJson)=>{
                this.setState({presetTickers: responseJson.lists})
            })
        
    }

    fetchCustomStrategies(){
        fetch(`${ENDPOINT}/risk-monkey/portfolios/ALL_PORTFOLIOS`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .catch(err => console.log('custom strategy error message: ', err))
          .then(responseJson => {
                    // console.log('custom strategy success resp: ',responseJson)
                    this.setState({customPorts:{...responseJson}}
                )
            }
            )
        
    }
    
    toInvest = ()=>{
        new Promise(()=>this.getPlaidItems())
        .then(this.setState({addAcc:false}))
    }

    addAcc = (step,prod)=>{
        console.log('stepVest: ',step)
        console.log('prodVest: ',prod)
        let invStep = -1
        if (step){
            invStep = step
        }
        this.setState({addAcc:true,prod,invStep})
    }

    updateWindowDimensions = () => {
        if (window.innerWidth < 1000) {
          this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
        } else {
          this.setState({ isSmallScreen: false, screenWidth: window.innerWidth });
        }
      };


    subStatus = ()=>{
        let URL = `${ENDPOINT}/users?details=true`
        fetch(URL, {
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
        })
        .then(res=>res.json())
        .then(responseJson =>{
            // console.log('broken resp',responseJson)
            // let userEmail = responseJson.email
            // console.log('emailX:', userEmail)
            this.setState({
                // subLevel: responseJson.accountSub.productId, 
                subStatus: responseJson.validSub, 
                freeTrial: responseJson.validSub,
                userEmail: responseJson.email,
            })
        })
        .catch(err=>console.log('err sub status: ', err))
    }

    alterWatchlist(symbol, addRemove){
        fetch(`${ENDPOINT}/risk-monkey/watchlist`,{
          method: "POST",
          headers:{
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken
          },
          body: JSON.stringify({[addRemove]:[symbol]})
        }).then(this.fetchWatchList())
      }

    fetchWatchList = () => {
        // fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
        fetch(`${ENDPOINT}/mobile/watchlist`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + this.props.user.idToken.jwtToken
            },
        }).then(response => response.json())
            .then(responseJson => {
                // console.log('wl resp', responseJson)
                let myWatchlist = responseJson.watchlist;
                // let predefinedLists = responseJson.predefinedLists
                // let watchlistLastUpdated = (new Date(responseJson.lastUpdated * 1000)).toString()
                // let sortedPredefinedLists = this.arrayToObject(predefinedLists)
                // this.setState({ myWatchlist: watchListData, presetTickers: sortedPredefinedLists, watchlistLastUpdated })
                this.setState({ myWatchlist })
            })
            .catch(error => console.log(error))
        
    }

    getPlaidHoldings = ()=>{ // all holdings of all linked accounts combined
        fetch(`${ENDPOINT}/plaid/holdings`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => this.setState({holdings: responseJson}))
          .catch(err=>console.log(`catching errors`, err))
    }

    getAllAccountInfo = ()=>{
        console.log('getting it all')
        fetch(`${ENDPOINT}/mobile/user/holdings`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
        //   .then(responseJson => this.setState({holdings: responseJson}))
          .then(responseJson =>{
              let allAccounts = []
            //   console.log('neten all resp: ',responseJson)
              responseJson.items.forEach(inst=>inst.accounts.forEach(acc=>{
                //   console.log(acc.name,acc.type )
                  if(acc.type === 'depository' || acc.type === 'investment' || acc.type === 'credit'){
                      allAccounts.push({...acc, inst:inst.instName})
                  }
              }))
            //   console.log('neten all parsed: ',allAccounts)
              this.setState({allAccounts, itemsResp:responseJson.items})
            })
          .catch(err=>console.log(`catching errors`, err))
    }

    getPlaidItems = ()=>{ // an Item is all the accounts at one place (multiple accounts at robinhood)
        fetch(`${ENDPOINT}/plaid/items`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => {
            //   console.log('plaid items resp: ',responseJson)
              this.setState({itemsResp: responseJson})
            })
          .catch(err=>console.log(`catching errors`, err))
    }

    getPlaidAccounts = ()=>{ // an Account is one account at one place (ie. one account at robinhood)
        fetch(`${ENDPOINT}/plaid/accounts`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => {
              console.log('plaid Accounts response', responseJson)
              this.setState({accounts:responseJson})
          })
          .catch(err=>console.log(`catching errors`, err))
    }

    updatePaymentStatus = ()=>{
        this.setState({subLevel:'Wealth Management', freeTrial: false})
    }

    arrayToObject = (arr) => {
        let result = {};
        for (let i = 0; i < arr.length; i++) {
            result[arr[i].listTitle] = arr[i].tickers;
        }
        return result
    }

    optimizeButton = (newData, userInputs, prevSet, excluded, optCash)=>{
        console.log('optimizeButton = running')
        // let sorted = userInputs.sort((a, b) => (a.symbol > b.symbol) ? 1 : -1)
        if (window.location.pathname !== `/Dashboard/Portfolio`){
            history.push(`/Dashboard/Portfolio`)
        }
        this.setState({activeTab:'Portfolio', optimizedResults: {...newData}, userInputs:{...userInputs}, prevSet:[...prevSet], excluded:[...excluded],optCash})
    }

    resetPrevSet = ()=>{
        this.setState({prevSet:[],optCash:0})
    }
    
    toPc = ()=>{
        window.scrollTo(0, 325)
        if (window.location.pathname !== `/Dashboard/PortfolioConstructor`){
            history.push(`/Dashboard/PortfolioConstructor`)
        }
        this.setState({activeTab:'PortfolioConstructor'})
    }
    toMs = ()=>{
        window.scrollTo(0, 325)
        if (window.location.pathname !== `/Dashboard/MyStrategies`){
            history.push(`/Dashboard/MyStrategies`)
        }
        this.setState({activeTab:'MyStrategies'})
    }

    toWm = ()=>{
        window.scrollTo(0, 325)
        if (window.location.pathname !== `/Dashboard/WealthManagement`){
            history.push(`/Dashboard/WealthManagement`)
        }
        this.setState({activeTab:'WealthManagement'})
    }

    updateCustoms=(customPorts)=>{
        this.setState({customPorts})
    }
   

      componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
      }
      updateWindowDimensions = () => {
        if (window.innerWidth < 850) {
            this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
        } else {
            this.setState({ isSmallScreen: false, screenWidth: window.innerWidth });
        }
    };

    

    showAllTabs = ()=>{
        // console.log('made it to all tabs')
        if(this.props.user){
            // switch (this.state.activeTab) {
            let path = window.location.pathname.split("/")[2]
            switch (path) /** PREVIOUSLY THE SWITCH WAS BASED ON ACTIVETAB. DUE TO LACK OF REDUX OR MOBX WE HAVE HACKED THIS UP TO CHANGE THE PATHNAME AND STATE   */ {
                case 'PortfolioConstructor':
                    return <PortConstructor resetPrevSet={this.resetPrevSet} linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} optCash={this.state.optCash} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                case 'Constructor':
                    return <PortConstructor resetPrevSet={this.resetPrevSet} linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} optCash={this.state.optCash} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                case 'Portfolio':
                    return <Portfolio accounts={this.state.allAccounts} linkedPorts={this.state.itemsResp} userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus}  subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel} fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies}  toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                case 'WealthManagement':
                    return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                case 'Invest':
                    if(this.state.itemsResp === 'loading'){
                        return(
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%', marginBottom:100}}>
                                <p style={{fontSize:28, fontWeight:'bold'}}>Loading...</p>
                            </div>
                        )
                    }
                    if(this.state.itemsResp.length > 0 && this.state.addAcc === false){
                        //MY ACCOUNTS
                        return <Invested updateAccs={this.getAllAccountInfo} updateAccs={this.getAllAccountInfo} presetTickers={this.state.presetTickers} addAcc={this.addAcc} toMs={this.toMs} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} accounts={this.state.allAccounts} />
                    }else{
                        return <Invest updateAccs={this.getAllAccountInfo}pdateAccs={this.getAllAccountInfo} presetTickers={this.state.presetTickers} prod={this.state.prod} invStep={this.state.invStep} accounts={this.state.allAccounts} toInvested={this.toInvest} toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} linkedPorts={this.state.itemsResp}/>
                    }
                // case 'TradeCenter':
                //     return <Invest p updateAccs={this.getAllAccountInfo}resetTickers={this.state.presetTickers} prod={this.state.prod} invStep={this.state.invStep} accounts={this.state.allAccounts} toInvested={this.toInvest} toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} linkedPorts={this.state.itemsResp}/>
                case 'References':
                    return <References isSmallScreen={this.state.isSmallScreen}/>
                case 'PriceTrendPredictor':
                    return <PriceTrendPredictor presetTickers={this.state.presetTickers} dash={false} isSmallScreen={this.state.isSmallScreen}/>
                case 'MyStrategies':
                    return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                case 'MyWatchlist':
                    return <Watchlist refreshWl={this.fetchWatchList}  myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} addWl={this.addToWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                case 'Budgeting':
                    return <Budgeting bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                case 'Account':
                    this.fetchCustomStrategies()
                    return <Account toPc={this.toPc} updateCustoms={this.updateCustoms} customPorts={this.state.customPorts} accounts={this.state.allAccounts} userEmail={this.state.userEmail} subStatus={this.state.subStatus} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                default:
                    return <Watchlist refreshWl={this.fetchWatchList} myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} addWl={this.addToWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
            }
        }
        else{
            switch (this.state.activeTab) {
                // case 'Portfolio Constructor':
                //     return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                // case 'WealthManagement':
                //     return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                case 'PriceTrendPredictor':
                    return <PriceTrendPredictor dash={false} isSmallScreen={this.state.isSmallScreen}/>
                default:
                    return <LoginHalf errors={this.props.errors} signIn={this.props.signIn} signOut={this.props.signOut} user={this.props.user}/>
            }
        }
    }

    showTabsPaywall = ()=>{
        if(this.props.user){
            console.log('paywall freeTrial',this.state.freeTrial)
            console.log('paywall sublevel',this.state.subLevel)
            console.log('paywall substatus',this.state.subStatus)
            // if(this.state.freeTrial === true || this.state.subLevel === "arapp_monthly" || this.state.subLevel === "prod_FaN0loj0oRP7de"){
            if(this.state.freeTrial === true || this.state.subStatus === "subscribed" ){
                this.showAllTabs()
            }
            else{
                console.log('basic')
                let path = window.location.pathname.split("/")[2]
            switch (path) /** PREVIOUSLY THE SWITCH WAS BASED ON ACTIVETAB. DUE TO LACK OF REDUX OR MOBX WE HAVE HACKED THIS UP TO CHANGE THE PATHNAME AND STATE   */ {
                // case 'PortfolioConstructor':
                //     return <PortConstructor resetPrevSet={this.resetPrevSet} linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} optCash={this.state.optCash} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                // case 'Constructor':
                //     return <PortConstructor resetPrevSet={this.resetPrevSet} linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} optCash={this.state.optCash} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                // case 'Portfolio':
                //     return <Portfolio accounts={this.state.allAccounts} linkedPorts={this.state.itemsResp} userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus}  subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel} fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies}  toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                // case 'WealthManagement':
                //     return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                case 'Invest':
                    if(this.state.itemsResp.length > 0 && this.state.addAcc === false){
                        return <Invested updateAccs={this.getAllAccountInfo} presetTickers={this.state.presetTickers} addAcc={this.addAcc} toMs={this.toMs} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} accounts={this.state.allAccounts} />
                    }else{
                        return <Invest updateAccs={this.getAllAccountInfo} presetTickers={this.state.presetTickers} prod={this.state.prod} invStep={this.state.invStep} accounts={this.state.allAccounts} toInvested={this.toInvest} toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} linkedPorts={this.state.itemsResp}/>
                    }
                // case 'References':
                //     return <References isSmallScreen={this.state.isSmallScreen}/>
                case 'PriceTrendPredictor':
                    return <PriceTrendPredictor presetTickers={this.state.presetTickers} dash={false} isSmallScreen={this.state.isSmallScreen}/>
                // case 'MyStrategies':
                //     return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                case 'MyWatchlist':
                    return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} addWl={this.addToWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                case '':
                    return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} addWl={this.addToWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                // case 'Budgeting':
                //     return <Budgeting bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                case 'Account':
                    return <Account toPc={this.toPc} updateCustoms={this.updateCustoms} customPorts={this.state.customPorts} accounts={this.state.allAccounts} userEmail={this.state.userEmail} subStatus={this.state.subStatus} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                default:
                    return <SubModal userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
                }
            }
        }
    }

    showTabsPaywallOLD = () => {
        if(this.props.user){
            // console.log('this.state.freeTrial',this.state.freeTrial)
            // console.log('this.state.subLevel',this.state.subLevel)
            if (this.state.freeTrial === true) {
                // console.log('free trial')
                //Free Trial
                switch (this.state.activeTab) {
                    case 'PortfolioConstructor':
                        return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'Portfolio':
                        return <Portfolio userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel} fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies} toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'WealthManagement':
                        return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                    case 'References':
                        return <References isSmallScreen={this.state.isSmallScreen}/>
                    case 'PriceTrendPredictor':
                        return <PriceTrendPredictor dash={false} isSmallScreen={this.state.isSmallScreen}/>
                    case 'Invest':
                        return <Invest subStatus={this.state.subStatus} toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} linkedPorts={this.state.itemsResp}/>
                    case 'Budgeting':
                        return <Budgeting bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'Account':
                        return <Account bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'MyStrategies':
                        return <SubModal userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
                        // return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                    case 'MyWatchlist':
                        return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    default:
                        return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                }
            }
            else if(this.state.subLevel === "arapp_monthly" || this.state.subLevel === "prod_FaN0loj0oRP7de"){
                // BASIC SUB
                // console.log('basic sub')

                switch (this.state.activeTab) {
                    case 'MyStrategies':
                        return <SubModal userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
                        // return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                    case 'PortfolioConstructor':
                        return <SubModal userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
                        // return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'Portfolio':
                        return <SubModal userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
                        // return <Portfolio userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies} toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'WealthManagement':
                        return <SubModal userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
                        // return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                    case 'Invest':
                        return <Invest toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} linkedPorts={this.state.itemsResp}/>
                    case 'Budgeting':
                        return <Budgeting bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'Account':
                        return <Account bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'References':
                        return <References isSmallScreen={this.state.isSmallScreen}/>
                    case 'PriceTrendPredictor':
                        return <PriceTrendPredictor dash={false} isSmallScreen={this.state.isSmallScreen}/>
                    case 'MyWatchlist':
                        return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    default:
                        return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                }
            }
            else if (!this.state.subLevel){ 
                // No Sub
                // console.log('nosub')
                return <SubModal userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subStatus={this.state.subLevel}/>
                // switch (this.state.activeTab) {
                //     case 'Portfolio Constructor':
                //         return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                //     case 'Portfolio':
                //         return <Portfolio userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies} toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                //     case 'Wealth Management':
                //         return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                //     case 'References':
                //         return <References isSmallScreen={this.state.isSmallScreen}/>
                //     case 'Price Trend Predictor':
                //         return <PriceTrendPredictor dash={false} isSmallScreen={this.state.isSmallScreen}/>
                //     case 'My Watchlist':
                //         return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                //     default:
                //         return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                // }
            }
            else{
                // console.log('WMsub')
                // WM Sub
                switch (this.state.activeTab) {
                    case 'Account':
                        return <Account toPc={this.toPc} updateCustoms={this.updateCustoms} customPorts={this.state.customPorts} accounts={this.state.allAccounts} userEmail={this.state.userEmail} subStatus={this.state.subStatus} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>    
                    case 'PortfolioConstructor':
                        return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'Portfolio':
                        return <Portfolio userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}wm={true} fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies} toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'WealthManagement':
                        return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                    case 'References':
                        return <References isSmallScreen={this.state.isSmallScreen}/>
                    case 'PriceTrendPredictor':
                        return <PriceTrendPredictor presetTickers={this.state.presetTickers} dash={false} isSmallScreen={this.state.isSmallScreen}/>
                    case 'Invest':
                        return <Invest presetTickers={this.state.presetTickers} toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} linkedPorts={this.state.itemsResp}/>
                    case 'Budgeting':
                        return <Budgeting bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'Account':
                        return <Account bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    case 'MyStrategies':
                        return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                    case 'MyWatchlist':
                        return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                    default:
                        return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                }
            }
        }
        else{
            switch (this.state.activeTab) {
                // case 'Portfolio Constructor':
                //     return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
                case 'WealthManagement':
                    return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
                case 'PriceTrendPredictor':
                    return <PriceTrendPredictor dash={false} isSmallScreen={this.state.isSmallScreen}/>
                default:
                    return <LoginHalf errors={this.props.errors} signIn={this.props.signIn} signOut={this.props.signOut} user={this.props.user}/>
            }
        }
    }
    cutURL(){
        return window.location.pathname.split("/Dashboard")[1]
      }

    showLists = () =>{
        if(this.state.showListOptions){
            let categories = ['Price Trend Predictor','My Watchlist', 'Portfolio Constructor', 'Wealth Management','Account']
            // categories = categories.filter(ele => ele !== this.state.activeTab)
            let mapped = categories.map(ele=><li style={{marginBottom: 5, cursor:'pointer', backgroundColor: this.state.activeTab !== ele ? 'rgb(146,146,146)':brandColor , padding: 7, borderRadius:5, width:'100%'}} onClick={()=>this.setState({activeTab:ele, showListOptions:false})}>{ele}</li>)
            return(
                <ul style={{ backgroundColor: 'rgb(248,248,250)', listStyleType: 'none', padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '60%', borderRadius: 12, marginTop: -10, zIndex: 100, }}>
                    {mapped}
                </ul>
            )
        }
    }

    showHeader = ()=>{
        if(!this.state.isSmallScreen){
            return(
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '98%', margin:'auto', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)'}}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius:10, flexDirection:'column'  }}>
                            <div 
                              style={{ width: '100%', cursor:'pointer',margin:'auto', display: 'flex', justifyContent: 'flex-start',marginBottom:0, alignItems: 'center', borderRadius:10, marginTop: -20}} 
                            >
                                <img
                                    src={ForeSCITELogo}
                                    style={{width: '100%'}}
                                />
                            </div>
                            {/* <div>
                                <p style={{fontWeight:'200', fontSize:28}}>Personal Finance</p>
                            </div> */}
                            <NavLink style={{width:'100%',textDecoration: 'none'}} to={`/Dashboard/Watchlist`} exact={true}>
                                <div 
                                    style={{ width: '100%', cursor:'pointer',margin:'auto' ,display: 'flex', justifyContent: 'flex-start',paddingBottom:25,paddingTop:25,paddingLeft:45, alignItems: 'center', boxShadow: '-1px 1px 1px 1px rgba(0, 0, 0, 0.16)', backgroundColor:this.state.activeTab === 'Watchlist' || window.location.pathname === '/Dashboard' || window.location.pathname === '/Dashboard/'?'#f3f1fa':''}} 
                                    onClick={() => {
                                        this.fetchWatchList()
                                        // if (window.location.pathname !== `/Dashboard/Watchlist`){
                                        //     history.push(`/Dashboard/Watchlist`)
                                        // }
                                        this.setState({ activeTab:'Watchlist' })}
                                    }
                                >
                                    <img
                                        src={(this.state.activeTab === 'Watchlist'||window.location.pathname === '/Dashboard' || window.location.pathname === '/Dashboard/')?WatchlistActive:WatchlistInactive}
                                        // src={WatchlistInactive}
                                        style={{width: 20, marginRight: 10}}
                                    />
                                    {/* <p style={{margin:0, fontSize: this.state.isSmallScreen?14:16, color:(this.state.activeTab === 'My Watchlist')?'#53b9b6':'rgba(146, 149, 161, 0.65)'}}>My Watchlist</p> */}
                                    <p style={{margin:0, fontSize: this.state.isSmallScreen?14:16,fontWeight:(this.state.activeTab === 'Watchlist'||window.location.pathname === '/Dashboard' || window.location.pathname === '/Dashboard/')?'bold':'', color:(this.state.activeTab === 'Watchlist'||window.location.pathname === '/Dashboard' || window.location.pathname === '/Dashboard/')?'#595e6c':'rgba(146, 149, 161, 0.65)'}}>My Watchlist</p>
                                </div>
                            </NavLink>
                            <NavLink style={{width:'100%',textDecoration: 'none'}} to={`/Dashboard/PriceTrendPredictor`} exact={true}>
                                <div 
                                    style={{ width: '100%', cursor:'pointer',margin:'auto' ,display: 'flex', justifyContent: 'flex-start',paddingBottom:25,paddingTop:25,paddingLeft:45, alignItems: 'center', boxShadow: '-1px 1px 1px 1px rgba(0, 0, 0, 0.16)', backgroundColor:this.state.activeTab === 'PriceTrendPredictor'?'#f3f1fa':''}} 
                                    onClick={() => {
                                        
                                        // if (window.location.pathname !== `/Dashboard/PriceTrendPredictor`){
                                        //     history.push(`/Dashboard/PriceTrendPredictor`)
                                        // }
                                        this.setState({ activeTab:'PriceTrendPredictor' })}
                                    }
                                >   
                                    <img
                                        src={(this.state.activeTab === 'PriceTrendPredictor')?PtrendActive:PtrendInactive}
                                        // src={PtrendInactive}
                                        style={{width: 20, marginRight: 10}}
                                    />
                                    {/* <p style={{margin:0, fontSize: this.state.isSmallScreen?14:16, color:(this.state.activeTab === 'Price Trend Predictor')?'#53b9b6':'rgba(146, 149, 161, 0.65)'}}>Price Trend Forecaster</p> */}
                                    <p style={{margin:0, fontSize: this.state.isSmallScreen?14:16,fontWeight: (this.state.activeTab === 'PriceTrendPredictor')?'bold':'', color:(this.state.activeTab === 'PriceTrendPredictor')?'#595e6c':'rgba(146, 149, 161, 0.65)'}}>Price Trend Forecaster</p>
                                </div>
                            </NavLink>
                            <NavLink style={{width:'100%',textDecoration: 'none'}} to={`/Dashboard/Invest`} exact={true}>
                                <div 
                                    style={{ width: '100%', cursor:'pointer',margin:'auto' ,display: 'flex', justifyContent: 'flex-start',paddingBottom:25,paddingTop:25,paddingLeft:45, alignItems: 'center', boxShadow: '-1px 1px 1px 1px rgba(0, 0, 0, 0.16)', backgroundColor:this.state.activeTab === 'Invest'?'#f3f1fa':''}} 
                                    onClick={() => {
                                        // if (window.location.pathname !== `/Dashboard/Invest`){
                                        //     history.push(`/Dashboard/Invest`)
                                        // }
                                        this.setState({ activeTab:'Invest', addAcc:false})}
                                    }
                                >
                                    <img
                                        src={(this.state.activeTab === 'WealthManagement' || this.state.activeTab === 'Invest')?WmActive:WmInactive}
                                        // src={WmInactive}
                                        style={{width: 20, marginRight: 10}}
                                    />
                                    {/* <p style={{margin:0, fontSize: this.state.isSmallScreen?14:16, color:(this.state.activeTab === 'Invest')?'#53b9b6':'rgba(146, 149, 161, 0.65)'}}>ForeSCITE Invest</p> */}
                                    <p style={{margin:0, fontSize: this.state.isSmallScreen?14:16,fontWeight:(this.state.activeTab === 'Invest') ?'bold':'', color:(this.state.activeTab === 'Invest')?'#595e6c':'rgba(146, 149, 161, 0.65)'}}>My Accounts</p>
                                </div>
                            </NavLink>

                            {/* <div 
                                style={{ width: '100%', cursor:'pointer',margin:'auto' ,display: 'flex', justifyContent: 'flex-start',paddingBottom:25,paddingTop:25,paddingLeft:45, alignItems: 'center', boxShadow: '-1px 1px 1px 1px rgba(0, 0, 0, 0.16)', backgroundColor:this.state.activeTab === 'TradeCenter'?'#f3f1fa':''}} 
                                onClick={() => {
                                    if (window.location.pathname !== `/Dashboard/TradeCenter`){
                                        history.push(`/Dashboard/TradeCenter`)
                                    }
                                    this.setState({ activeTab:'TradeCenter', addAcc:false})}
                                }
                            >
                                <img
                                    src={this.state.activeTab === 'TradeCenter'?WmActive:WmInactive}
                                    // src={WmInactive}
                                    style={{width: 20, marginRight: 10}}
                                />
                                <p style={{margin:0, fontSize: this.state.isSmallScreen?14:16,fontWeight:(this.state.activeTab === 'TradeCenter') ?'bold':'', color:(this.state.activeTab === 'TradeCenter')?'#595e6c':'rgba(146, 149, 161, 0.65)'}}>Trade Center</p>
                            </div> */}
                            
                            
                            {/* <div 
                                style={{ width: '100%', cursor:'pointer',margin:'auto' ,display: 'flex', justifyContent: 'flex-start',paddingBottom:25,paddingTop:25,paddingLeft:45, alignItems: 'center',boxShadow: '-1px 1px 1px 1px rgba(0, 0, 0, 0.16)',   backgroundColor:this.state.activeTab === 'My Strategies'?'#f3f1fa':''}} 
                                onClick={ (this.state.activeTab === 'My Strategies')? null :() => this.setState({ activeTab:'My Strategies' })}
                            >
                                <img
                                    src={(this.state.activeTab === 'My Strategies')?MypsActive:MypsInactive}
                                    // src={MypsInactive}
                                    style={{width: 20, marginRight: 10}}
                                />
                                <p style={{margin:0, fontSize: this.state.isSmallScreen?14:16,fontWeight: (this.state.activeTab === 'My Strategies')?'bold':'', color:(this.state.activeTab === 'My Strategies')?'#595e6c':'rgba(146, 149, 161, 0.65)'}}>My Complete Financial Picture</p>
                            </div> */}
                            
                            <NavLink style={{width:'100%',textDecoration: 'none'}} to={`/Dashboard/Constructor`} exact={true}>
                                <div 
                                    style={{ width: '100%', cursor:'pointer',margin:'auto' ,display: 'flex', justifyContent: 'flex-start',paddingBottom:25,paddingTop:25,paddingLeft:45, alignItems: 'center', boxShadow: '-1px 1px 1px 1px rgba(0, 0, 0, 0.16)' ,backgroundColor:this.state.activeTab === 'PortfolioConstructor' || this.state.activeTab === 'Portfolio'?'#f3f1fa':''}} 
                                    onClick={ (this.state.activeTab === 'Portfolio Constructor' || this.state.activeTab === 'Portfolio')? null :() => {
                                        // if (window.location.pathname !== `/Dashboard/Constructor`){
                                        //     history.push(`/Dashboard/Constructor`)
                                        // }
                                        this.setState({ activeTab:'PortfolioConstructor' })}
                                    }
                                >
                                    <img
                                        src={(this.state.activeTab === 'PortfolioConstructor' || this.state.activeTab === 'Portfolio') ?ConstructorActive:ConstructorInactive}
                                        // src={ConstructorInactive}
                                        style={{width: 20, marginRight: 10}}
                                    />
                                    <p style={{margin:0, fontSize: this.state.isSmallScreen?14:16,fontWeight:(this.state.activeTab === 'PortfolioConstructor' || this.state.activeTab === 'Portfolio') ?'bold':'', color:(this.state.activeTab === 'PortfolioConstructor' || this.state.activeTab === 'Portfolio')?'#595e6c':'rgba(146, 149, 161, 0.65)'}}>Construct a Portfolio</p>
                                </div>
                            </NavLink>
                            <NavLink style={{width:'100%',textDecoration: 'none'}} to={`/Dashboard/Budgeting`} exact={true}>
                                <div 
                                    style={{ width: '100%', cursor:'pointer',margin:'auto' ,display: 'flex', justifyContent: 'flex-start',paddingBottom:25,paddingTop:25,paddingLeft:45, alignItems: 'center', boxShadow: '-1px 1px 1px 1px rgba(0, 0, 0, 0.16)' ,backgroundColor:window.location.pathname === `/Dashboard/Budgeting`?'#f3f1fa':''}} 
                                    onClick={ (this.state.activeTab === 'Budgeting')? null :() => {
                                        // if (window.location.pathname !== `/Dashboard/Budgeting`){
                                        //     history.push(`/Dashboard/Budgeting`)
                                        // }
                                        this.setState({ activeTab:'Budgeting' })}
                                    }
                                >
                                    <img
                                        src={(window.location.pathname === `/Dashboard/Budgeting`)?BudgetingActive:BudgetingInactive}
                                        // src={ConstructorInactive}
                                        style={{width: 20, marginRight: 10}}
                                    />
                                    <p style={{margin:0, fontSize: this.state.isSmallScreen?14:16,fontWeight:(window.location.pathname === `/Dashboard/Budgeting`) ?'bold':'', color:( window.location.pathname === `/Dashboard/Budgeting`)?'#595e6c':'rgba(146, 149, 161, 0.65)'}}>Budgeting</p>
                                </div>
                            </NavLink>
                           
                            <div 
                              style={{ width: '100%',margin:'auto', display: 'flex', justifyContent: 'center',marginBottom:50, alignItems: 'center',  marginTop: 25, flexDirection:'column'}} 
                            >
                                <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start', backgroundColor:'#4fbebb', width: '75%', borderRadius:8, padding: 20, height: 350}}>
                                    <p style={{color:'rgb(255,255,255)', fontSize:22, fontWeight:'bolder'}}>DOWNLOAD</p>
                                    <p style={{fontSize:12, fontWeight:'bold'}}>Download the app from</p>
                                    <div style={{display:'flex', justifyContent:'space-evenly', width:'100%'}}>
                                        <a style={{margin:0, display:'flex',justifyContent:'center',alignItems:'center'}} href='https://apps.apple.com/us/app/allocaterite/id1478461135' target='_blank'>
                                            <img
                                                src={AppStore}
                                                style={{width:'75%'}}
                                            />
                                        </a>
                                        <a style={{margin:0, display:'flex',justifyContent:'center',alignItems:'center'}} href='https://play.google.com/store/apps/details?id=com.allocaterite&hl=en_US&gl=US' target='_blank'>
                                            <img
                                                src={GpStore}
                                                style={{width:'75%'}}
                                            />
                                        </a>
                                        
                                    </div>
                                    
                                </div>
                                <div style={{marginTop: '-30%'}}>
                                        <img
                                            src={GetApp}
                                            style={{width: '70%'}}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
            )
        }else if(this.state.showListOptions){
            return(
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', margin:'auto',height:'100%'}}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between',  borderRadius:10, flexDirection:'column'  }}>
                    <div 
                      style={{ width: '100%', cursor:'pointer',margin:'auto', display: 'flex', justifyContent: 'flex-start',marginBottom:50, alignItems: 'center', borderRadius:10,}} 
                    >
                        <img
                            src={ForeSciteNoText}
                            style={{width: '50%'}}
                        />
                    </div>
                    <div 
                        style={{ width: '100%', cursor:'pointer',margin:'auto',marginLeft:'10%' ,display: 'flex', justifyContent: 'flex-start',marginBottom:50, alignItems: 'center',borderRadius:10}} 
                        onClick={() => {
                            this.fetchWatchList()
                            if (window.location.pathname !== `/Dashboard/Watchlist`){
                                history.push(`/Dashboard/Watchlist`)
                            }
                            this.setState({ showListOptions:false ,activeTab:'Watchlist' })}
                        }
                    >
                        <img
                            src={(this.state.activeTab === 'Watchlist'||window.location.pathname === '/Dashboard' || window.location.pathname === '/Dashboard/')?WatchlistActive:WatchlistInactive}
                            style={{width: '30%', marginRight: 10}}
                        />
                    </div>
                    <div 
                        style={{ width: '100%', cursor:'pointer',margin:'auto',marginLeft:'10%' ,display: 'flex', justifyContent: 'flex-start',marginBottom:50, alignItems: 'center', borderRadius:10}} 
                        onClick={() => {
                            if (window.location.pathname !== `/Dashboard/PriceTrendPredictor`){
                                history.push(`/Dashboard/PriceTrendPredictor`)
                            }
                            this.setState({ showListOptions:false ,activeTab:'Price Trend Predictor' })}
                        }
                            >   
                        <img
                            src={(this.state.activeTab === 'Price Trend Predictor')?PtrendActive:PtrendInactive}
                            style={{width: '30%', marginRight: 10}}
                        />
                    </div>
                    <div 
                        style={{ width: '100%', cursor:'pointer',margin:'auto',marginLeft:'10%' ,display: 'flex', justifyContent: 'flex-start',marginBottom:50, alignItems: 'center',borderRadius:10}} 
                        onClick={() => {
                            if (window.location.pathname !== `/Dashboard/Invest`){
                                history.push(`/Dashboard/Invest`)
                            }
                            this.setState({ showListOptions:false ,activeTab:'Invest' })}
                        }
                    >
                        <img
                            src={(this.state.activeTab === 'Invest')?WmActive:WmInactive}
                            style={{width: '30%', marginRight: 10}}
                        />
                    </div>
                    
                    
                    {/* <div 
                        style={{ width: '100%', cursor:'pointer',margin:'auto',marginLeft:'10%' ,display: 'flex', justifyContent: 'flex-start',marginBottom:50, alignItems: 'center',  borderRadius:10}} 
                        onClick={ (this.state.activeTab === 'My Strategies')? null :() => this.setState({ showListOptions:false ,activeTab:'My Strategies' })}
                    >
                        <img
                            src={(this.state.activeTab === 'My Strategies')?MypsActive:MypsInactive}
                            style={{width: '30%', marginRight: 10}}
                        />
                    </div> */}
                   
                    <div 
                        style={{ width: '100%', cursor:'pointer',margin:'auto',marginLeft:'10%' ,display: 'flex', justifyContent: 'flex-start',marginBottom:50, alignItems: 'center', borderRadius:10}} 
                        onClick={ (this.state.activeTab === 'Portfolio Constructor' || this.state.activeTab === 'Portfolio')? null :() => {
                            if (window.location.pathname !== `/Dashboard/Constructor`){
                                history.push(`/Dashboard/Constructor`)
                            }
                            this.setState({ activeTab:'PortfolioConstructor',showListOptions:false })}
                        }
                    >
                        <img
                            src={(this.state.activeTab === 'Portfolio Constructor' || this.state.activeTab === 'Portfolio')?ConstructorActive:ConstructorInactive}
                            style={{width: '30%', marginRight: 10}}
                        />
                    </div>
                    
                    
                    
                    <div 
                        style={{ width: '100%', cursor:'pointer',margin:'auto',display: 'flex', justifyContent: 'flex-start',marginBottom:50, alignItems: 'center',borderRadius:10}} 
                    >
                       <a style={{margin:0, display:'flex',justifyContent:'flex-start',alignItems:'center'}} href='https://apps.apple.com/us/app/allocaterite/id1478461135' target='_blank'>
                                    <img
                                        src={AppStore}
                                        style={{width: '60%', marginRight: 10}}
                                        />
                                </a>
                    </div>
                    <div 
                        style={{ width: '100%', cursor:'pointer',margin:'auto',display: 'flex', justifyContent: 'flex-start',marginBottom:60, alignItems: 'center',borderRadius:10}} 
                    >
                       <a style={{margin:0, display:'flex',justifyContent:'flex-start',alignItems:'center'}} href='https://apps.apple.com/us/app/allocaterite/id1478461135' target='_blank'>
                            <img
                                src={GpStore}
                                style={{width: '60%', marginRight: 10}}
                                />
                        </a>
                    </div>
                        
                </div>
            </div>
                    
            )
        }
        else{
            return null
        }
    }

    toAcc = ()=>{
        if (window.location.pathname !== `/Dashboard/Account`){
            history.push(`/Dashboard/Account`)
        }
        this.setState({ showListOptions:false ,activeTab:'Account' })
    }

    addToWl = (item) => {
        this.alterWatchlist(item.symbol, 'add')

        // let myWatchlist = [item,...this.state.myWatchlist]
        let myWatchlist = [...this.state.myWatchlist]
        this.setState({myWatchlist})
    }

    removeFromWl = (symbol) => {
        this.alterWatchlist(symbol, 'delete')
        let filtered = this.state.myWatchlist.filter(ele => ele.symbol !== symbol)
        this.setState({ myWatchlist: filtered })
    }

    combineAccountsAndHoldings = ()=>{
        let holdings = {}
        this.state.holdings.forEach(ele=>{
            if(holdings[ele.accountId]){
                holdings[ele.accountId].push(ele)
            }
            else{
                holdings[ele.accountId] = [ele]
            }
        })
        let accounts = []
        this.state.itemsResp.forEach(inst=>{
            inst.accounts.forEach(account=>{
                accounts.push({...account,inst:inst.instName, holdings:holdings[account.accountId]? holdings[account.accountId]: []})
            })
        })
        return accounts
    }


    render(){
        // if(this.props.user){
        //     console.log('token: ',this.props.user.idToken.jwtToken,)
        //     // console.log('holdings: ',this.state.holdings,)
        // }
        // FOR Selenium 
        // S3 Bucket => AI-DEV or prod.allocaterite.com
        // URL http://allocaterite-ai-dev.s3-website-us-east-1.amazonaws.com
        return(
            <div style={{width:`100%`,}}>
                <p id='JwtToken' style={{fontSize:28, color:"transparent"}}>
                    {this.props.user? this.props.user.idToken.jwtToken:null}
                </p>

            </div>
        )

        return(
            <div style={{width:'100%',display:'flex', height:'100%'}}>
                {((this.state.isSmallScreen && this.state.showListOptions) || !this.state.isSmallScreen)?
                    <div style={{height:'100%',  position:'fixed', width:'20%', top:0, left:0,}}>
                        {this.showHeader()}
                    </div>
                    :null
                }
                <div onClick={this.state.showListOptions? ()=>this.setState({showListOptions:false}):null} style={{width:this.state.isSmallScreen? '100%':'80%', height:'100%', backgroundColor:'#f7f6fd', position:'fixed', left:((this.state.isSmallScreen && this.state.showListOptions) || !this.state.isSmallScreen)? '20%':'0%',overflowY:'scroll'}}>
                    <div style={{width:'100%', backgroundColor:'#f7f6fd',paddingLeft:'1.5%',paddingRight:'1.5%', paddingTop:'1.5%', }}>
                        <div style={{width:'100%'}}>
                            <PrivateHeader toAcc={this.toAcc} isSmallScreen={this.state.isSmallScreen} toggleMenu={()=>this.setState({showListOptions:!this.state.showListOptions})} activeTab={window.location.pathname.split("/")[2]} hiddenSearch={this.state.activeTab === 'Price Trend Predictor'?true:false} user={this.props.user} signOut={this.props.signOut}/>
                        </div>
                        <div style={{width:'100%',marginTop:this.state.isSmallScreen? 20:50, marginBottom:50}}>
                            {/* {this.showTabsPaywall()} */}
                            {this.showAllTabs()}
                        </div>
                    </div>
                    <div style={{width:'100%',height:200, marginTop: 20}}>
                        <Footer  isSmallScreen={this.state.isSmallScreen}/>
                    </div>
                </div>
            </div>
        )
    } 
}