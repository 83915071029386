import React from 'react'
import { NavLink } from 'react-router-dom';

import upArrow from "../assets/single_up.svg";
import downArrow from "../assets/single_down.svg";
import strongUpArrow from "../assets/double_up.svg";
import strongDownArrow from "../assets/double_down.svg";
import flatArrow from "../assets/flat.svg";

export default class HeaderDropdown extends React.Component {

    state={
        hover:false
    }

    getArrow = (direction) => {
        switch (direction) {
          case -1:
            return downArrow;
          case 1:
            return upArrow;
          case 2:
            return strongUpArrow;
          case -2:
            return strongDownArrow;
          default:
            return flatArrow;
        }
      };


      checkLogo = (symbol)=>{
        // console.log('symbol', symbol)
        let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        if (http.status != 404){
            return (
                    <img 
                        // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
                        src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
                        onerror="this.onerror=null; this.remove();" 
                        style={{objectFit:'contain', width:70,height:70,marginLeft:5 }}
                    />
            )
        }else{
            return(
                <div style={{width:70,height:70, backgroundColor:'transparent'}}/>
            )
        }
    }

    createBox = ()=>{
        if(this.props.img === "not-found") {
            return(
                <div style={{objectFit:'contain', width:50,height:50,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(17,17,17)',borderRadius:10 }}>
                    <p style={{margin:0, fontSize:24,fontWeight:'bold', color:'rgb(255,255,255)'}}>{this.props.symbol[0]}</p>
                </div>
            )
        }else{
            return (
                <img 
                    // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
                    src={this.props.img}
                    style={{objectFit:'contain', width:70,height:70,marginLeft:5 }}
                />
            )   
        }
      
       
    }

    addCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
      
    createRow = (ele)=>{
        return(
            <NavLink 
            exact to={`/CompanyPage/${ele.symbol}`}
            >
                
                <div style={{display:'flex', borderBottom:'solid', borderWidth:.25, borderColor:'#ddd',width:'100%' }}>
                    <div style={{width:'25%'}}>
                        <p style={{margin:0}}>
                            {ele.symbol}
                        </p>
                    </div>
                    <div style={{width:'25%'}}>
                        <img
                            src={this.getArrow(ele.direction)}
                            style={{
                            // marginTop: 5,
                            width: 20,
                            height: 20,
                            }}
                        />
                    </div>
                    <div style={{width:'25%'}}>
                        <img
                            src={this.getArrow(ele.directionPrev)}
                            style={{
                        //    marginTop: 5,
                            width: 20,
                            height: 20,
                            }}
                        />
                    </div>
                    <div style={{width:'25%'}}>
                        <p style={{margin:0}}>
                            {ele.priceUSD}
                        </p>
                    </div>
                </div>
            </NavLink>
        )
    }
    // render(){
    //     let mapped = this.props.data.map((ele, i) => this.createRow(ele,i))
    //     return(
    //         <div style={{display:'flex', flexDirection:'column'}}>
    //             {mapped}
    //         </div>
    //     )
    // }
    render(){
        
        // console.log('HD props',this.props)
        
        if(this.props.trendKey){
            return(
            <div style={{width:'100%', backgroundColor:'rgb(255,255,255)',minWidth: 205, textDecoration:'none', cursor:'default', display:'flex', flexDirection:'column'}}>
                    <div style={{display:"flex",justifyContent:'center', alignItems:'center', }}>
                        <p style={{margin:0, color:'rgb(17,17,17)'}}>Price Trend Key</p>
                    </div>
                    <div style={{display:'flex', borderBottom:'solid', borderWidth:.25, borderColor:'#ddd', width:'100%', alignItems:'flex-end' }}>
                        <div style={{width:'20%', display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:this.props.size ==='small'? 'column': 'row'}}>
                                <p style={{margin:0, color:'rgb(17,17,17)', marginRight: 4}}>
                                    Strong
                                </p>
                                <p style={{margin:0, color:'rgb(17,17,17)'}}>
                                    Up
                                </p>
                            </div>
                            <div>
                                <img
                                    src={strongUpArrow}
                                    style={{
                                    marginBottom: 5,
                                    width: 20,
                                    height: 20,
                                    }}
                                />
                            </div>
                            
                        </div>
                        <div style={{width:'20%', display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                            <div>
                                <p style={{margin:0, color:'rgb(17,17,17)'}}>
                                    Up
                                </p>
                            </div>
                            <div>
                                <img
                                    src={upArrow}
                                    style={{
                                    marginBottom: 5,
                                    width: 20,
                                    height: 20,
                                    }}
                                />
                            </div>
                            
                        </div>
                        <div style={{width:'20%', display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                            <div>
                                <p style={{margin:0, color:'rgb(17,17,17)'}}>
                                    Flat
                                </p>
                            </div>
                            <div>                                
                                <img
                                    src={flatArrow}
                                    style={{
                                    marginBottom: 5,
                                    width: 20,
                                    height: 20,
                                    }}
                                />
                            </div>
                            
                        </div>
                        <div style={{width:'20%', display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                            <div>
                                <p style={{margin:0, color:'rgb(17,17,17)'}}>
                                    Down
                                </p>
                            </div>
                            <div>
                                <img
                                    src={downArrow}
                                    style={{
                                    marginBottom: 5,
                                    width: 20,
                                    height: 20,
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{width:'20%', display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:this.props.size ==='small'? 'column': 'row'}}>
                                <p style={{margin:0, color:'rgb(17,17,17)', marginRight: 4}}>
                                    Strong
                                </p>
                                <p style={{margin:0, color:'rgb(17,17,17)'}}>
                                    Down
                                </p>
                            </div>
                            <div>
                                <img
                                    src={strongDownArrow}
                                    style={{
                                    marginBottom: 5,
                                    width: 20,
                                    height: 20,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if(this.props.editMode && this.props.columnTitle){
            return(
                <div style={{width:'100%', backgroundColor:'rgb(255,255,255)',minWidth: 205, textDecoration:'none', cursor:'default', height:50}}>
                    <div style={{display:'flex',  width:'100%', height:'100%', padding:8 }}>
                        <div style={{width:'25%',display:'flex',alignItems:'center'}}>
                            <p style={{margin:0, color:'rgb(17,17,17)', verticalAlign:'center',fontSize:20,marginLeft:30}}>
                                Symbol
                            </p>
                        </div>
                        <div style={{width:'40%',display:'flex',alignItems:'center'}}>
                            <p style={{margin:0, color:'rgb(17,17,17)', verticalAlign:'center',fontSize:20,}}>
                                Company Title
                            </p>
                        </div>
                        <div style={{width:'35%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                            <p style={{margin:0, color:'rgb(17,17,17)', verticalAlign:'center',fontSize:20,marginRight:30, textAlign:'right'}}>
                                Remove
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
        if(this.props.columnTitle){
            return(
                <div style={{width:'100%', backgroundColor:'rgb(255,255,255)',minWidth: 205, textDecoration:'none', cursor:'default', height:75,borderBottom:'solid', borderWidth:.25, borderColor:'#ddd',marginBottom:10}}>
                    <div style={{display:'flex', width:'100%', }}>
                    <div style={{width:'30%',display:'flex',justifyContent:'center', alignItems:'flex-end'}}>
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 20, marginLeft:30, textAlign:'center'}}>
                            Symbol
                        </p>
                    </div>
                    <div style={{width:'30%',display:'flex',justifyContent:'flex-start', alignItems:'flex-end'}}>
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 20}}>
                            Company Name
                        </p>
                    </div>
                    <div style={{width:'15%', display:'flex',justifyContent:'flex-end', alignItems:'center', flexDirection:'column'}}>
                        {this.props.size !== 'small'? 
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 20, textAlign:'right'}}>
                            Current
                        </p>
                        :null}
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 20, textAlign:'right'}}>
                            Price
                        </p>
                    </div>
                    <div style={{width:'10%', display:'flex',justifyContent:'flex-end', alignItems:'center', flexDirection:'column'}}>
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 20, textAlign:'center'}}>
                            Current
                        </p>
                        {this.props.size !== 'small'? 
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 20, textAlign:'center'}}>
                            Trend*
                        </p>
                        :null}
                    </div>
                    <div style={{width:'10%', display:'flex',  justifyContent:'flex-end', alignItems:'center', flexDirection:'column'}}>
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 20, textAlign:'center'}}>
                            Prior
                        </p>
                        {this.props.size !== 'small'? 
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 20, textAlign:'center'}}>
                            Trend*
                        </p>
                        :null}
                    </div>
                    <div style={{width:'15%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                        {this.props.size !== 'small'? 
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 20, textAlign:'center'}}>
                            30 Day 
                        </p>
                        :null}
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 20, textAlign:'center'}}>
                            Forecast*
                        </p>
                    </div>
                </div>
                </div>
            )
        }
        if(this.props.subheading){
            return(
                <div style={{width:'100%', backgroundColor:'rgb(255,255,255)',minWidth: 205, textDecoration:'none', cursor:'default',borderTop:'none', borderWidth:.25, borderColor:'#ddd',}}>
                    <div style={{display:'flex', width:'100%',marginBottom:10 }}>
                    <div style={{width:'30%',display:'flex',justifyContent:'center', alignItems:'flex-end'}}>
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 16, marginLeft:30, textAlign:'center'}}>
                            &nbsp;
                        </p>
                    </div>
                    <div style={{width:'30%',display:'flex',justifyContent:'flex-start', alignItems:'flex-end'}}>
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 16}}>
                            &nbsp;
                        </p>
                    </div>
                    <div style={{width:'15%', display:'flex',justifyContent:'flex-end', alignItems:'center', flexDirection:'column'}}>
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 16, textAlign:'right'}}>
                        &nbsp;
                        </p>
                    </div>
                    <div style={{width:'20%', display:'flex',justifyContent:'flex-end', alignItems:'center', flexDirection:'column'}}>
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 16, textAlign:'center'}}>
                            Short Term
                        </p>
                        
                    </div>
                    <div style={{width:'15%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                        <p style={{margin:0, color:'rgb(17,17,17)', fontSize: 16, textAlign:'center'}}>
                            Medium Term 
                        </p>
                    </div>
                </div>
            </div>
            )
        }
        if(this.props.editMode){
            return(
                <div style={{width:'100%',cursor:'pointer',backgroundColor:this.state.hover? 'rgb(253,180,181)' :'rgb(255,255,255)',minWidth: 205, textDecoration:'none'}}
                    onMouseEnter={()=>this.setState({hover:true})}
                    onMouseLeave={()=>this.setState({hover:false})}
                    onClick={()=>this.props.remove(this.props.symbol)}
                >
                   
                    <div style={{display:'flex', borderBottom:'solid', borderWidth:.25, borderColor:'#ddd', width:'100%', height:'100%', padding:12 }}>
                        <div style={{width:'25%',display:'flex',alignItems:'center'}}>
                            <p style={{margin:0, color:'rgb(17,17,17)', verticalAlign:'center',fontSize:18,marginLeft:30}}>
                                {this.props.symbol}
                            </p>
                        </div>
                        <div style={{width:'40%',display:'flex',alignItems:'center'}}>
                            <p style={{margin:0, color:'rgb(17,17,17)', verticalAlign:'center',fontSize:18,}}>
                                {this.props.companyName}
                            </p>
                        </div>
                        <div style={{width:'35%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                            <p style={{margin:0, color:'rgb(17,17,17)', verticalAlign:'center',fontSize:18,marginRight:30, textAlign:'right'}}>
                                {`Remove ${this.props.symbol} from Watchlist`}
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
        
        return(
            
            <NavLink
                exact={true}
                to={`/CompanyPage/${this.props.symbol}`}
                style={{width:'100%', backgroundColor:this.state.hover? 'rgb(220,220,220)' :'rgb(255,255,255)', textDecoration:'none', height:80}}
                onMouseEnter={()=>this.setState({hover:true})}
                onMouseLeave={()=>this.setState({hover:false})}
            >
                <div style={{display:'flex', borderBottom:'solid', borderWidth:.25, borderColor:'#ddd', width:'100%', height:'100%' }}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center', width:'15%'}}>
                        {/* {this.props.symbol? this.checkLogo(this.props.symbol):null} */}
                        {this.createBox()}
                    </div>
                    <div style={{width:'15%',display:'flex',alignItems:'center'}}>
                        <p style={{margin:0, color:'rgb(17,17,17)', verticalAlign:'center',fontSize:18,marginLeft:30}}>
                            {this.props.symbol}
                        </p>
                    </div>
                    <div style={{width:'30%',display:'flex',alignItems:'center',whiteSpace: 'nowrap', overflow: 'hidden', }}>
                        <p style={{margin:0, color:'rgb(17,17,17)', verticalAlign:'center',fontSize:18,textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',}}>
                            {this.props.companyName}
                        </p>
                    </div>
                    <div style={{width:'15%',color:'rgb(17,17,17)', display:'flex',alignItems:'center',justifyContent:'center', }}>
                        <p style={{margin:0,verticalAlign:'center',fontSize:18, textAlign:'right', marginRight:30}}>
                            ${this.props.priceUSD? this.addCommas(this.props.priceUSD.toFixed(2)):null}
                        </p>
                    </div>
                    <div style={{width:'10%', display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <img
                            src={this.getArrow(this.props.direction)}
                            style={{
                            // marginBottom: 5,
                            width: 50,
                            height: 50,
                            }}
                        />
                    </div>
                    <div style={{width:'10%', display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <img
                            src={this.getArrow(this.props.directionPrev)}
                            style={{
                        //    marginBottom: 5,
                            width: 50,
                            height: 50,
                            }}
                        />
                    </div>
                    <div style={{width:'15%',color:'rgb(17,17,17)', display:'flex',alignItems:'center',justifyContent:'center', }}>
                        <p style={{margin:0,verticalAlign:'center',fontSize:18, textAlign:'right', marginRight:30}}>
                            {/* ${(this.props.rf*this.props.priceUSD+this.props.priceUSD).toFixed(0)} */}
                            {this.props.needOptimization ?
                            `$${ this.props.rf?this.addCommas((this.props.rf).toFixed(2)):null}`:'HIGH RISK'}
                        </p>
                    </div>
                </div>
            </NavLink>
        )
    }
    
}