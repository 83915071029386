import React from 'react'
import GaugeChart from "react-gauge-chart"

const uncolored = [
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
];
const colored = [
    "#7bc0c3",
    "#7bc0c3",
    "#7bc0c3",
    "#f2ab25",
    "#f2ab25",
    "#f2ab25",
    "#f2ab25",
    "#f28425",
    "#f28425",
    "#f28425",
    "#f26425",
    "#f26425",
    "#f26425",
    "#f24c25",
    "#f24c25",
    "#f24c25",
    "#f24c25",
    "#f22525",
    "#f22525",
    "#f22525",
    ];
export default class RiskForecast extends React.Component{

    textColor(input){
        let num =((Math.abs(input) * 2) / 5) * 100
         if (colored[num.toFixed(0)]){
           return colored[num.toFixed(0)];
         } else{
           return colored[19]
         }
      }
      
    riskNum(cvar){
        if( Math.abs(cvar*200/10) > 10){
            return '10+'
        }
        else if (Math.abs(cvar*200/10) < 1){
            return '1'
        }
        else{
            return Math.abs(cvar*200/10).toFixed(0)
        }
    }

      setColoredGauges(input) {
      let pivotPoint = ((Math.abs(input) * 2) / 5) * 100;
      let answer = [...uncolored];
      return answer.map((ele, i) => {
          if (i <= pivotPoint) {
          return colored[i];
          } else {
          return ele;
          }
      });
      }
    //   riskText = ()=>{
    //       switch (this.props.cvar){
    //           case:
    //       }
    //   }

    numText = (cvar,ticker)=>{
        let num = this.num(cvar,ticker)
        if (num < 3){
            return 'Low'
        }
        else if (num < 6){
            return 'Medium'
        }
        else if (num < 8){
            return 'High'
        }
        else{
            return 'Extreme'
        }
    }
    num(cvar,ticker){
        if(ticker === 'BIL' || ticker === "TLT"){
            return '0'
        }
        else{
           if( Math.abs(cvar*200/10) > 10){
                return '10+'
           }else if (Math.abs(cvar*200/10) < 1){
               return '1'
           }
           else{
               return Math.abs(cvar*200/10).toFixed(0)
           }
        }
    }
    numColor = (cvar,ticker)=>{
        let num = this.num(cvar,ticker)
        if (num < 3){
            return 'rgb(112,184,187)'
        }
        else if (num < 6){
            return 'rgb(240,161,33)'
        }
        else if (num < 8){
            return 'rgb(240,121,33)'
        }
        else{
            return 'rgb(240,33,33)'
        }
    }

    render(){
        let pcnt 
        if(this.props.cvar <= .79){
            console.log('up top')
            pcnt = parseFloat(this.props.cvar.toFixed(2))*2
        }else{
            console.log('dwon below')
            pcnt = 1
        }
        if(this.props.isSmallScreen){
            return(
                <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                    <div>
                        <GaugeChart 
                            style={{ backgroundColor: "hsla(0, 100%, 90%, 0.0)" }}
                            id="gauge-chart3"
                            nrOfLevels={20}
                            colors={this.setColoredGauges(this.props.cvar)}
                            percent={pcnt}
                            textColor="hsla(0, 100%, 90%, 0.0)"
                            // needleColor="hsla(0, 100%, 90%, 0.0)"
                            needleColor="#242a38"
                            needleBaseColor="#242a38"
                            // needleBaseColor="hsla(0, 100%, 90%, 0.0)"
                            animate={false}
                            cornerRadius={8}
                            formatTextValue={(value) => value + "%"}
                            hideText={true}
                        />
                    </div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                        <p style={{fontSize: 48, margin:0, fontWeight:'bolder',color:this.textColor(this.props.cvar)}}>
                            {(100* this.props.cvar).toFixed(0)}%
                        </p>
                        <p style={{fontWeight:'bold',color: "#a3a5a8", fontSize:36, marginTop:-5, textAlign:'center'}}>
                            {this.numText(this.props.cvar, this.props.symbol)} Risk
                        </p>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div style={{display:'flex', justifyContent:'space-around', }}>
                    <div>
                        <GaugeChart 
                            style={{ backgroundColor: "hsla(0, 100%, 90%, 0.0)" }}
                            id="gauge-chart3"
                            nrOfLevels={20}
                            colors={this.setColoredGauges(this.props.cvar)}
                            percent={pcnt}
                            textColor="hsla(0, 100%, 90%, 0.0)"
                            // needleColor="hsla(0, 100%, 90%, 0.0)"
                            needleColor="#242a38"
                            needleBaseColor="#242a38"
                            // needleBaseColor="hsla(0, 100%, 90%, 0.0)"
                            animate={false}
                            cornerRadius={8}
                            formatTextValue={(value) => value + "%"}
                            hideText={true}
                        />
                    </div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', marginTop:this.props.myStrats? -25:0}}>
                       
                           
                        <div style={{display:'flex', alignItems:'center'}}>
                            
                            <p style={{fontSize: 36, margin:0, fontWeight:'bold',color:"#a3a5a8", marginRight:8}}>
                                AR Risk Number: 
                            </p>
                            <p style={{fontSize: 48, margin:0, fontWeight:'bolder',color:this.textColor(this.props.cvar)}}>
                               {this.riskNum(this.props.cvar)}
                               {/* ${(100* this.props.cvar).toFixed(0)}% */}
                            </p>
                        </div>
                        <p style={{fontWeight:'bold',color: this.textColor(this.props.cvar), fontSize:36, marginTop:-5, textAlign:'center'}}>
                            {this.numText(this.props.cvar, this.props.symbol)} Risk
                        </p>
                        <p style={{fontSize: 18, margin:0, fontWeight:'bolder',color:this.textColor(this.props.cvar)}}>
                            AR Risk Number ranges risk from 1-10 
                        </p>
                        <p style={{fontSize: 18, margin:0, fontWeight:'bolder',color:this.textColor(this.props.cvar)}}>
                            1 being low risk and 10 being extreme risk
                        </p>
                    </div>
                </div>
            )
        }
    }
}
