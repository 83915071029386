import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CalendarCommentary from "../layout/CalendarCommentary";
import PressAndArticles from "../layout/PressAndArticles";
import Footer from "../layout/Footer";
import Header from "../layout/Header"

export class Insights extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <div>
        <Container>
          <Row className="page-blank-row-md" />
          <Row>
            <Col sm={3} className="insights-calendar-commentary">
              <p className="insight-page-title">COMMENTARY</p>
              <CalendarCommentary />
            </Col>
            <Col sm={9}>
              <PressAndArticles />
            </Col>
          </Row>
          <Row className="page-blank-row" />
        </Container>
        <Footer />
      </div>
      </div>
    );
  }
}

export default Insights;
