import React from 'react'
import LinkToPlaid from '../LinkToPlaid'
import RiskForecast from '../companyPage3/RiskForecast'
import DdChart from '../companyPage3/DdChart'
import ENDPOINT from '../Endpoint'

import { FaEye, FaEyeSlash } from 'react-icons/fa';

const brandColor = "#00A99D"

export default class myStrats extends React.Component{
    state={
        customPorts:this.props.customPorts ? this.props.customPorts : {},
        selectedStrat: '',
        portHoldings: [],
        showLinkModal: false,
        selectedInst: '',
        sameInst: {},
        hover:false,
        showStats:false,
        loading:false,
        showVal: true
    }

    componentDidMount(){
        // this.getPlaidAccounts()
        this.getPlaidHoldings()
        this.fetchCustomStrategies()
    }

    findOtherPortfoliosSameInstitution = (instName)=>{
        console.log('looking for others')
        let itemId = this.getItemId(instName)
        fetch(`${ENDPOINT}/plaid/accounts?unconnectedAccounts=true&itemId=${itemId}`,{
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
              },
        })
        .then(res => res.json())
        .then((respJSON)=> {
            console.log('respJSON: ', respJSON)
            this.setState({sameInst:{...this.state.sameInst, [this.state.selectedInst]:respJSON}})
        })
        .catch(err=>console.log('catching: ', err))

    }

    getItemId = (instName)=>{
        let id = ''
        this.props.linkedPorts.forEach(ele=>{
            if (ele.instName === instName){
                id = ele.itemId
            }
        })
        return id
    }

    addOtherPortSameInstituion = ()=>{
        let getbody = []
        this.state.sameInst[this.state.selectedInst].forEach(ele=>{
            if(ele.checked){
                getbody.push({
                    "id": ele.itemId,
                    "itemId": this.getItemId(),
                    "mask" : ele.mask,
                    "name" : ele.name
                })
            }
        })
        fetch(`${ENDPOINT}/plaid/accounts/add`,{
            method: "POST",
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
              },
            body:JSON.stringify(getbody)
        }) 
        .then(res => res.json())
        .then(responseJson => console.log(responseJson))
        .catch(err=>console.log(`catching errors`, err))
    }

    getPlaidHoldings = ()=>{ // all holdings of all linked accounts combined
        console.log("jwtTokens",this.props.user.idToken.jwtToken)
        fetch(`${ENDPOINT}/plaid/holdings`,{
            
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => this.setState({holdings: responseJson}))
          .catch(err=>console.log(`catching errors`, err))
    }

    checkOne = (id)=>{
        let instName = this.state.selectedInst
        let updated = this.state.sameInst[instName].map(ele=>{
            if(ele.id === id){
                return {...ele, checked:!ele.checked}
            }else{
                return ele
            }
        })
        let sameInst = this.state.sameInst
        sameInst[instName] = updated
        this.setState({sameInst})
    }

    addCommas(flt){
        return flt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    showOthers = (name)=>{
        let arr = []
        if(name === this.state.selectedInst){

        
        if(this.state.sameInst[this.state.selectedInst]){
            this.state.sameInst[this.state.selectedInst].forEach(ele=>{
                arr.push(
                <div style={{display:'flex', justifyContent:'space-between',borderTop:'solid', borderWidth:.25, borderColor:'#ddd', backgroundColor:ele.checked?'#eeeeee':''}}>
                    <div style={{display:'flex', alignItems:'center', width:'5%'}}>
                        <input type="checkbox" value={ele.checked} onChange={()=>this.checkOne(ele.id)}/>
                    </div>
                    <div style={{display:'flex', alignItems:'center', width: '60%',justifyContent:'flex-start'}}>
                        <p style={{margin:0}}>{ele.name}-{ele.mask}</p>
                    </div>
                    <div style={{display:'flex', alignItems:'center', width: '35%',justifyContent:'flex-end', marginRight: 1}}>
                        {/* <p style={{margin:0}}>${ele.balance.toFixed(2)}</p> */}
                        <p style={{margin:0}}>${this.addCommas(ele.balance)}</p>
                    </div>
                </div>
                )
            })
            return(
                <div style={{display:"flex",flexDirection:'column',}}>
                  {arr}  
                </div>
            )
        }
    }}

    linkChecked = ()=>{
        let linkXmore = 0
        if ( this.state.sameInst[this.state.selectedInst]){
            this.state.sameInst[this.state.selectedInst].forEach(ele=>{
                if(ele.checked){
                    linkXmore +=1
                }
            })
        }
        return(
            <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '45%', cursor:'pointer'}} 
                            onClick={()=>{
                                if(linkXmore > 0){
                                    // console.log('this.state.massAddTable', this.state.massAddTable)
                                    this.addOtherPortSameInstituion()
                                    this.setState({showLinkModal:false})
                                }
                            }}>
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                {linkXmore > 0 ? `Link ${linkXmore} portfolio${linkXmore === 1 ? '':'s'}`:'Select Accounts Above'}
                            </p>
                        </div>
                    </div>
        )
    }

    linkModal = ()=>{
        if(this.state.showLinkModal){
            let arr = []
            this.props.linkedPorts.forEach(ele=>{
                arr.push(
                    <div style={{width:'100%', padding:4,borderTop:'solid', borderWidth:.5, borderColor:'#ddd'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center', width:'100%', cursor:'pointer'}} 
                        onClick={()=>{
                            if(!this.state.sameInst[ele.instName]){
                                this.findOtherPortfoliosSameInstitution(ele.instName)
                            }
                            if(this.state.selectedInst !== ele.instName){
                                this.setState({selectedInst:ele.instName})
                            }else{
                                this.setState({selectedInst:''})
                            }
                        }}>
                            {ele.instName}
                        </div>
                        <div >
                            {this.showOthers(ele.instName)}
                        </div>
                    </div>
                    )
            })
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Link</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({showLinkModal:false})}> X </p>
                    </div>
                        
                        <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', justifyContent:'center', alignItems:'center', marginBottom:20, marginTop:20}}>
                            <LinkToPlaid button={true} user={this.props.user} numAccounts={this.props.linkedPorts.length}/>
                        </div>
                        <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', justifyContent:'center', alignItems:'center'}}>
                            <p style={{margin:'auto'}}>or</p>
                        </div>
                        <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', justifyContent:'center', alignItems:'center', marginTop:20}}>
                            <p style={{margin:'auto'}}>Another account from:</p>
                        </div>
                        <div style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'column' ,justifyContent:'space-evenly', border: 'solid', borderWidth: .25, borderColor: '#ddd', marginTop:10, marginBottom: 10}}>
                            {arr}
                        </div>
                        <div style={{marginBottom:20}}>
                            {this.linkChecked()}
                        </div>
                    </div>
                </div>
            )
        }
    }

    showPortData = (title, data, location)=>{
        let arr = []
        if(this.state.selectedStrat===title){
            data.forEach(ele=>{
                // USE THIS TO MIRROR HOLDINGS
                arr.push(
                    <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', borderBottom: 'solid', borderWidth: .25, borderColor: '#ddd'}}>
                        <div style={{width:Array.isArray(ele)?'25%':'50%', borderRight: 'none', borderWidth: .25, borderColor: '#ddd',display:'flex', justifyContent:'flex-start', alignItems:'center', padding:12}}>
                            {Array.isArray(ele)?
                                <a style={{textDecoration:'none', color:brandColor, cursor:'pointer'}} href={ele.symbol === '$$'?null :`/CompanyPage/${ele[0]}`} target='_blank'>{ele[0]}</a>
                                :
                                <a style={{textDecoration:'none', color:brandColor, cursor:'pointer'}} href={ele.symbol === '$$'?null :`/CompanyPage/${ele.symbol}`} target='_blank'>{ele.symbol}</a>
                            }
                        </div>
                        <div style={{width:Array.isArray(ele)?'25%':'50%',display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                            {Array.isArray(ele)?
                                <p style={{margin:0}}>{ele.symbol==='$$' ? `$${this.addCommas(parseFloat(ele[1]).toFixed(2))}` : this.addCommas(ele[1])}</p>
                                :
                                <p style={{margin:0}}>{ele.symbol==='$$' ? this.addCommas(parseFloat(ele.quantity).toFixed(2)) : this.addCommas(ele.quantity)}</p>
                            }
                        </div>
                        {Array.isArray(ele)?
                            <div style={{width:'25%',display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                                <p style={{margin:0}}>{this.addCommas(parseFloat(ele[2]).toFixed(2))}%</p>
                            </div>
                            :null
                        }
                        {Array.isArray(ele)?
                            <div style={{width:'25%',display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                                <p style={{margin:0}}>${(parseFloat(ele[3])).toFixed(2)}</p>
                            </div>
                            :null
                        }
                    </div>
                )
            })
            return(
                <div>
                    <div style={{display:"flex", flexDirection:'row', width:'100%', width:'100%'}} onClick={()=>console.log(`clicked header`)}>
                        <div style={{width:location==='linked'?'25%':'50%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-start',padding:12,borderRight:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                            <p style={{fontWeight:'bold', margin:0,}}>
                                Symbol
                            </p>
                        </div>
                        <div style={{width:location==='linked'?'25%':'50%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12}}>
                            <p style={{fontWeight:'bold', margin:0,}}>
                                Number of Shares
                            </p>
                        </div>
                        {location === 'linked' ? 
                        <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12, borderLeft:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                            <p style={{fontWeight:'bold', margin:0,}}>
                            Percentage
                            </p>
                        </div>:null
                        }
                        {location === 'linked' ? 
                        <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12, borderLeft:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                            <p style={{fontWeight:'bold', margin:0,}}>
                                Position Value
                            </p>
                        </div>:null
                        }
                    </div>
                    <div style={{width:'100%',maxHeight:600, overflowY:'scroll'}}>
                        <div>
                            {arr}
                        </div>
                    </div>
                </div>
            )
        }
    }

    showOneCustom =(title,data)=>{
        return(
            <div style={{display:'flex', flexDirection:'column'}} >
                <div 
                    onMouseEnter = {()=>this.setState({hover:title})}
                    onMouseLeave = {()=>this.setState({hover:''})}
                    onClick={()=>this.setState({selectedStrat:this.state.selectedStrat === title? '':title})}
                    style={{width:'100%', display:'flex' , cursor:'pointer', justifyContent:'space-between', alignItems:'center', borderBottom: 'solid', borderWidth: .25, borderColor: '#ddd', height:'100%', padding:12, backgroundColor: this.state.hover === title ? '#f7f7f7':null}}
                >
                    <div style={{display:'flex',justifyContent:'flex-start' }}>
                        <p style={{margin:0, fontWeight:'bold'}}>
                        {title}
                        </p>
                    </div>
                    <div
                        style={{ height:'100%', borderLeft:'solid', borderWidth:.25, borderColor:'#ddd', display:'flex',alignItems:'center', justifyContent:'center', cursor:'pointer', /*backgroundColor:'rgb(253,180,181)',LIGHTER */ backgroundColor:'#ee5e68', paddingTop:5, paddingBottom:5,paddingLeft:15,paddingRight:15}}
                        onClick={()=>this.deleteOneStrategy(title)}
                    >
                        <p style={{margin:0, color:'rgb(255,255,255)'}}>
                            Delete
                        </p>
                    </div>
                </div>
                <div>
                    {this.showPortData(title,data, false)}
                </div>
            </div>
        )
    }

    deleteOneStrategy = (id)=>{
        fetch(`${ENDPOINT}/risk-monkey/portfolios/delete/${id}`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => console.log('responseJson:', responseJson))
          .then(()=>{
              let strats = {...this.state.customPorts}
              delete strats[id]
              this.setState({customPorts:strats})
          })
    }

    fetchCustomStrategies(){
        fetch('${ENDPOINT}/risk-monkey/portfolios/ALL_PORTFOLIOS',{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .catch(err => console.log('custom strategy error message: ', err))
          .then(responseJson => {
                    // console.log('custom strategy success resp: ',responseJson)
                    this.setState({customPorts:responseJson}
                )
            }
            )
    }



    getStats = (id, type)=>{
        this.setState({showStats:'loading'})
        let body = {
            "portfolio":[],
            "data":{},
            "request":{
                "type":type,
                "id":id
            }
        }
   
        fetch(`${ENDPOINT}/risk-monkey`,{
            method: "POST",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
          .then(res => res.json())
          .then(responseJson => {
              this.setState({showStats:responseJson})
            }
            )
        .catch(err => console.log('get stats error message: ', err))
    }

    getTotalVal = (arr)=>{
        let total = 0
        arr.forEach(ele=>{
            total += ele.value
        })
        return total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
    }

    statsModal = ()=>{
        if(this.state.showStats){
            if(this.state.showStats === 'loading'){
                return(
                    <div style={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        position: 'fixed',
                        height: '100%',
                        width: '100%',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'auto',
                        zIndex: 99999,
                        }}>
                        <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height:'90%'}}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                                <p style={{textAlign:'center', fontSize:26, fontWeight:'bold',margin:0}}>Loading...</p>
                            </div>
                        </div>
                    </div>
                )
            }
            let holdingsTitle = <div>
                <div style={{display:"flex", flexDirection:'row', width:'100%', width:'100%'}} >
                    <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-start',padding:12,borderRight:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                        <p style={{fontWeight:'bold', margin:0,}}>
                            Symbol
                        </p>
                    </div>
                    <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12}}>
                        <p style={{fontWeight:'bold', margin:0,}}>
                            # Shares
                        </p>
                    </div>
                    
                    <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12, borderLeft:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                        <p style={{fontWeight:'bold', margin:0,}}>
                        Percentage
                        </p>
                    </div>
                     
                    <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12, borderLeft:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                        <p style={{fontWeight:'bold', margin:0,}}>
                            Position Value
                        </p>
                    </div>
        
                </div>
                
            </div>
            let holdingsData = []
            // FILL HOLDINGS
            let total = 0
            this.state.showStats.portfolio.forEach(ele=>{
                total += ele.value
            })
            this.state.showStats.portfolio.forEach(ele=>{
                console.log('ele', ele)
                holdingsData.push(
                        <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', borderBottom: 'solid', borderWidth: .25, borderColor: '#ddd'}}>
                            <div style={{width:'25%', borderRight: 'none', borderWidth: .25, borderColor: '#ddd',display:'flex', justifyContent:'flex-start', alignItems:'center', padding:12}}>
                                <a style={{textDecoration:'none', color:brandColor, cursor:'pointer'}} href={ele.symbol === '$$'?null :`/CompanyPage/${ele.symbol}`} target='_blank'>{ele.symbol}</a>
                            </div>
                            <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                                <p style={{margin:0}}>{ele.symbol==='$$' || ele.symbol === 'CASH' ? '' : this.addCommas(ele.quantity)}</p>     
                            </div>
                                <div style={{width:'25%',display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                                    <p style={{margin:0}}>{parseFloat(ele.value/total*100).toFixed(2)}%</p>
                                </div>
                                <div style={{width:'25%',display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                                    <p style={{margin:0}}>${this.addCommas((parseFloat(ele.value)).toFixed(2))}</p>
                                </div>

                        </div>
                    )
                
            })
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height:'90%'}}>
                        <div style={{ width:this.props.isSmallScreen?'90%' :'40%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px', position:'fixed', zIndex:20,}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Stats</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({showStats:false})}> X </p>
                        </div>
                        <div style={{display:'flex', width:'100%', overflowY:'scroll', height:'100%', flexDirection:'column'}}>
                            <div style={{display:'flex', margin:0, justifyContent:'space-between', width:'100%', padding:20, marginTop:50}}>
                                <div style={{display:'flex'}}>
                                    <p style={{fontSize:24, margin:0}}>Portfolio Value</p> 
                                    <div style={{display:'flex', alignItems:'center'}} onClick={()=>this.setState({showVal:!this.state.showVal})}>
                                        {this.state.showVal ?<FaEye style={{width:20, marginLeft:10}}/> : <FaEyeSlash  style={{width:20, marginLeft:10}}/> }
                                    </div>
                                </div>       
                                <p style={{fontSize:24, fontWeight:'bold', margin:0}}>{this.state.showVal? '••••••••••' :`$${this.getTotalVal(this.state.showStats.portfolio)}`}</p>
                            </div>
                            <hr></hr>
                            <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', padding:20, marginTop:20}}>
                                <p style={{fontSize:24}}>AI Based Portfolio Risk Statistics</p>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <p style={{fontWeight:'bold', fontSize:20}}>Correlation with S&P:</p>
                                    <p style={{fontSize:20}}>{(this.state.showStats.data.rSquared).toFixed(2)}</p>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <p style={{fontWeight:'bold', fontSize:20}}>Maximum Historical Risk:</p>
                                        <p style={{fontSize:20}}>{Math.abs(this.state.showStats.data.maxDrawdownVal).toFixed(2)}%</p>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <p style={{fontWeight:'bold', fontSize:20}}>Total Number of Holdings:</p>
                                        <p style={{fontSize:20}}>{this.state.showStats.portfolio.length}</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', padding:20}}>
                                <p style={{fontSize:24}}>Current Risk Level</p>
                                <RiskForecast myStrats={true} isSmallScreen={this.props.isSmallScreen} cvar={Math.abs(this.state.showStats.data.CVar)}/>
                            </div>
                            <hr></hr>
                            <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', padding:20}}>
                                <p style={{fontSize:24}}>Historical Risk</p>
                                <div style={{width:'100%'}}>
                                    <DdChart drawdownChart={this.state.showStats.data.maxDrawdown}/>
                                </div>
                            </div>
                            <hr></hr>
                            <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', padding:20}}>
                                <p style={{fontSize:24}}>Holdings</p>
                                <div style={{width:'100%'}}>
                                   {holdingsTitle}
                                   <div style={{width:'100%',maxHeight:500, overflowY:'scroll'}}>
                                        <div>
                                        {holdingsData}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }


    getHoldings = (id)=>{
        let arr = []
        let total = 0
        this.state.holdings.forEach(ele=>{
            if(ele.accountId === id){
                total += ele.instValue
            }
        })
        this.state.holdings.forEach(ele=>{
            if(ele.accountId === id){
                let weight = ele.instValue/total*100
                arr.push([ele.symbol, ele.quantity, weight, ele.instValue,])
            }
        })
        this.setState({selectedStrat: id, portHoldings: arr})
    }


    ids = ()=>{
        let arr = []
        this.props.linkedPorts.forEach(ele=>{
            arr.push([ele.instName, ele.itemId])
        })
        return arr
    }


    linked = ()=>{
        let arr = []
        if(this.props.linkedPorts){
            arr.push( 
                <div style={{display:"flex", flexDirection:'row', width:'100%', width:'100%'}} onClick={()=>console.log(`clicked header`)}>
                    <div style={{width:this.props.isSmallScreen? '40%':'50%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-start',padding:12,borderRight:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                        <p style={{fontWeight:'bold', margin:0,}}>
                            Institution
                        </p>
                    </div>
                    <div style={{width:this.props.isSmallScreen? '60%':'50%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-start',padding:12}}>
                        <p style={{fontWeight:'bold', margin:0,}}>
                            Account Number
                        </p>
                    </div>
                </div>
            )
            this.props.linkedPorts.forEach(ele=>{
                ele.accounts.forEach(innerEle=>{
                    // console.log('innerEle', innerEle)
                    arr.push(
                        <div style={{display:'flex', flexDirection:'column',width:'100%'}}>
                            <div 
                                onMouseEnter = {()=>this.setState({hover:ele.instName})}
                                onMouseLeave = {()=>this.setState({hover:''})}
                                style={{display:"flex", flexDirection:'row', width:'100%', borderBottom:'solid', borderWidth:.25, borderColor:'#ddd', cursor:'pointer', width:'100%', backgroundColor:this.state.hover === ele.instName?'#f7f7f7':null, cursor:'pointer'}} 
                                
                            >
                                <div 
                                    onClick={()=>{
                                        if(this.state.selectedStrat !== innerEle.accountId && this.state.holdings){
                                            this.getHoldings(innerEle.accountId)
                                        }else{
                                            this.setState({selectedStrat:''})
                                        }
                                    }}
                                    style={{width:this.props.isSmallScreen? '40%':'50%',padding: 12, display:'flex',alignItems:'center',justifyContent:'flex-start',/*borderRight:'solid', borderWidth:.25, borderColor:'#ddd',*/textAlign:'center', }}
                                >
                                    <p style={{margin:0}}>
                                        {ele.instName}
                                    </p>
                                </div>
                                <div style={{width:this.props.isSmallScreen? '40%':'40%',padding: 12, display:'flex',alignItems:'center',justifyContent:'flex-start',textAlign:'center'}}
                                    onClick={()=>{
                                        if(this.state.selectedStrat !== innerEle.accountId && this.state.holdings){
                                            this.getHoldings(innerEle.accountId)
                                        }else{
                                            this.setState({selectedStrat:''})
                                        }
                                    }}
                                >
                                    <p style={{margin:0}}>
                                        {innerEle.name} - {innerEle.mask}
                                    </p>
                                </div>
                                <div style={{width:this.props.isSmallScreen? '20%':'10%',padding: 12, display:'flex',alignItems:'center',justifyContent:'flex-start',textAlign:'center'}}>
                                    <div
                                        style={{ height:'100%', borderLeft:'solid', borderWidth:.25, borderColor:'#ddd', display:'flex',alignItems:'center', justifyContent:'center', cursor:'pointer', backgroundColor:'#3b8e8c', paddingTop:5, paddingBottom:5,paddingLeft:15,paddingRight:15, borderRadius:15}}
                                        // onClick={()=>this.setState({showStats:innerEle.accountId})}
                                        onClick={()=>this.getStats(innerEle.accountId,"portfolio" )}
                                    >
                                        <p style={{margin:0, color:'rgb(255,255,255)'}}>
                                            See Stats
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {this.showPortData(innerEle.accountId,this.state.portHoldings,'linked')}
                            </div>
                        </div>
                    )
                })
            })
            return(
                <div>
                    <div style={{minheight: 0,marginTop:25, width:'100%', display:'flex', justifyContent:'flex-start', flexDirection:'column', marginBottom: 25}}>
                        <div style={{width:'95%', margin:'auto'}}>
                            <h4 style={{color:'rgb(17,17,17)'}}>Your Linked Portfolios</h4>
                        </div>
                        {this.props.linkedPorts.length > 1 ? 
                                <div style={{display:'flex', justifyContent:'flex-start', width:'95%', margin:'auto',marginBottom: 8, alignItems:'center', flexWrap:'wrap'}}>
                                    <p onClick={()=>this.getStats(1234,"overall_portfolio" )} style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold', cursor:'pointer'}}>See Overall Stats</p>
                                </div>
                            :
                                null
                        }
                        <div style={{display:'flex', justifyContent:'flex-start', width:'95%', margin:'auto',marginBottom: 8, alignItems:'center', flexWrap:'wrap'}}>
                            <p style={{margin: 0, marginRight: 4, color:'#afb2bb'}}>To track all your portfolios in one place </p>
                            {this.props.linkedPorts.length > 0 ? 
                                <p onClick={()=>this.setState({showLinkModal:true})} style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold', cursor:'pointer'}}>link an account </p>
                            :
                                <LinkToPlaid button={false} user={this.props.user} numAccounts={this.props.linkedPorts.length}/>
                            }
                            
                        </div>
                        
                        <div style={{width:'95%', margin:'auto'}}>
                            <div>
                                {arr}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    customized =()=>{
        if(this.state.customPorts){
            console.log('this.state.customPorts',this.state.customPorts)
            let arr = []
            Object.keys(this.state.customPorts).forEach(ele=>arr.push(this.showOneCustom(ele ,this.state.customPorts[ele])))
            return(
                <div style={{minheight: 0,marginTop:25, width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-start', flexDirection:'column', marginBottom: 25}}>
                    <div style={{width:'95%', display:'flex',justifyContent:'flex-start', alignItems:'center'}}>
                        <h4 style={{color:'rgb(17,17,17)'}}>Your Customized Portfolio Strategies</h4>
                    </div>
                    <div style={{width:'95%', display:'flex',justifyContent:'flex-start', alignItems:'center'}}>
                        <p style={{color:'#afb2bb'}}>Use our <strong style={{fontWeight:'bold', color:brandColor, cursor:'pointer'}} onClick={()=>this.props.toPc()}> portfolio constructor</strong> to create a new portfolio strategy, or optimize an existing portfolio</p>
                    </div>
                    {Object.keys(this.state.customPorts).length === 0?null:<div style={{width:'95%', display:'flex', alignItems:'center', justifyContent:'space-around',  border: 'none', borderWidth: .25, borderColor: '#ddd', backgroundColor:'#3b8e8c'}}>
                            <p style={{margin:0, padding:10, color:'rgb(255,255,255)'}}>Portfolio Name - Select for more infomation</p>
                    </div>}
                    <div style={{width:'95%'}}>
                        {this.state.customPorts?
                            <div style={{}}>
                                {arr}
                            </div>
                            :null
                        }
                    </div>
                </div>
            )
        }
    }

    render(){
        // console.log('holdings', this.state.holdings)
        // console.log('portholdings', this.state.portHoldings)
        // console.log('this.props.linkedPorts', this.props.linkedPorts)
        return(
            <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                <div style={{width:'100%', backgroundColor:'rgb(255,255,255)', borderRadius: 8, padding:8, marginBottom:20}}>
                    {this.linked()}
                </div>
                <div style={{width:'100%', backgroundColor:'rgb(255,255,255)', borderRadius: 8, padding:8}}>
                    {this.customized()}
                </div>
                {this.linkModal()}
                {this.statsModal()}
            </div>
        )
    }
}