import React from 'react'
import {StickyTable, Row, Cell} from 'react-sticky-table';
import upArrow from "../../assets/single_up.svg";
import downArrow from "../../assets/single_down.svg";
import strongUpArrow from "../../assets/double_up.svg";
import strongDownArrow from "../../assets/double_down.svg";
import flatArrow from "../../assets/flat.svg";
import { NavLink } from 'react-router-dom';

export default class TickersTable extends React.Component{

    getArrow = (direction) => {
        switch (direction) {
          case -1:
            return downArrow;
          case 1:
            return upArrow;
          case 2:
            return strongUpArrow;
          case -2:
            return strongDownArrow;
          default:
            return flatArrow;
        }
      };


    addCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    createCells = ()=>{
        return this.props.data.map(ele=>{
            return(
                <Row >
                    <Cell style={{width:'25%'}}>
                    <NavLink to={`/CompanyPage/${ele.symbol}`} style={{ fontWeight: "bold", fontSize: 16, color:'#00A99D', backgroundColor: 'rgb(247,247,247)', borderRadius: 12, padding: '10px 20px'}}>
                        {ele.symbol}
                    </NavLink>
                    </Cell>
                    <Cell style={{width:'25%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <img
                                src={this.getArrow(ele.direction)}
                                style={{
                            //    marginTop: 5,
                                width: 20,
                                height: 20,
                                }}
                            />
                        </div>
                    </Cell>
                    <Cell style={{width:'25%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <img
                                src={this.getArrow(ele.directionPrev)}
                                style={{
                            //    marginTop: 5,
                                width: 20,
                                height: 20,
                                }}
                            />
                        </div>
                    </Cell>
                    <Cell style={{width:'25%'}}>${this.addCommas(ele.priceUSD.toFixed(2))}</Cell>
                    {/* <Cell style={{width:'16%'}}>${ele.priceUSD}</Cell> */}
                </Row>
            )
        })
    }

    editCells = ()=>{
        return this.props.data.map(ele=>{
            return(
                <Row>
                    <Cell style={{width:'25%'}}>
                        <NavLink to={`/CompanyPage/${ele.symbol}`} style={{ fontWeight: "bold", fontSize: 20, color:'#00A99D', backgroundColor: 'rgb(247,247,247)', borderRadius: 12, padding: '10px 20px'}}>
                            {ele.symbol}
                        </NavLink>
                    </Cell>
                    <Cell style={{width:'75%',padding:3}}>
                        <div style={{backgroundColor:'rgb(240,240,240)', borderRadius:10, display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>this.props.remove(ele.symbol)}>
                            <p style={{color:'rgb(230,88,82)', margin:'auto'}}>
                                {`Remove ${ele.symbol}`}
                            </p>
                        </div>
                    </Cell>
                </Row>
            )
        })
    }

    render(){
        return(
            <div style={{width:370, display:'flex',justifyContent:'center',alignItems:'center', }}>
                <div style={{width: '100%', display:'flex',justifyContent:'center',alignItems:'center', marginBottom:20}}>
                <StickyTable style={{width:'100%', margin:0,padding:0}}>
                    <Row>
                        <Cell style={{width:'25%',height:18}}>Symbol</Cell>
                        {this.props.editMode ? <Cell style={{width:'75%'}}>Remove</Cell> : null}
                        {this.props.editMode ? null : <Cell style={{width:'25%',height:18}}>Trend</Cell>}
                        {this.props.editMode ? null :  <Cell style={{width:'25%',height:18}}>Prior</Cell>}
                        {this.props.editMode ? null :  <Cell style={{width:'25%',height:18}}>Price</Cell>}         
                    </Row>
                    {this.props.editMode ? this.editCells() :this.createCells()}
                </StickyTable>
                </div>
            </div>
        )
    }
}