import React from 'react'
import ENDPOINT from '../Endpoint'
import { FaEye, FaEyeSlash, FaChevronCircleDown, FaChevronCircleUp, FaUniversity } from 'react-icons/fa';

const brandColor = "rgb(70,182,179)"


export default class GroupAcc extends React.Component{
    state={
        netenData:'loading',
        ivanData:false,
        show:'more',
        activeDropdown:false,
    }

    componentDidMount(){
        this.getTgStats()
    }

    getTgStats = ()=>{
        let id = this.props.acc.accountId
        
        fetch(`${ENDPOINT}/dw/group/${id}`,{
            headers:{
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,

            },
        })
        .then((res) => res.json())
        .then((responseJson)=>{
            this.setState({ivanData:responseJson.payload})
        })
        fetch(`${ENDPOINT}/mobile/trade-groups/buy-stats?account_id=${id}`,{
            headers:{
                'Content-Type': 'application/json'
            },
        })
        .then((res) => res.json())
        .then((responseJson)=>{
            if(responseJson.Stocks === 'No Stocks'){
                this.setState({netenData:'no stocks'})
            }
            else if(responseJson.Stocks.length >0){
                this.setState({netenData:responseJson.Stocks})
            }
            else{
                this.setState({netenData:'no stocks'})
            }
        })
        .catch(err=>{
            console.log(this.props.acc.name, 'ERROR' ,err)
            this.setState({netenData:'no stocks'})
        })
    }



    addCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    etcToLocal(dateInput) {
        // EST - UTC offset: 5 hours
        var offset = 5.0,
        
            /*
              - calculate the difference between the server date and UTC
              - the value returned by the getTime method is the number of milliseconds since 1 January 1970 00:00:00 UTC.
              - the time-zone offset is the difference, in minutes, between UTC and local time
              - 60000 milliseconds = 60 seconds = 1 minute
            */
            serverDate = new Date(dateInput),
            utc = serverDate.getTime() - (serverDate.getTimezoneOffset() * 60000),
        
            /*
              - apply the offset between UTC and EST (5 hours)
              - 3600000 milliseconds = 3600 seconds = 60 minutes = 1 hour
            */
            clientDate = new Date(utc + (3600000 * offset));
        
        // return ;
        let date = clientDate.toLocaleString().split(',')
        return(

            <div style={{width:'100%'}}>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {date[0].replace(/^0(?:0:0?)?/, '')} 
                </p>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {date[1]}
                </p>
            </div>
        )
      }

      formatPnL(val){
        let x = '0.00'
        if (!val){
            x = '0.00' 
        }
        else{
             x = this.addCommas(val.toFixed(2))
             if (val < 0){
                x = '-$'  + x.substring(1)
             }
             else{
                x = '$'  + x 
             }
        }
        return <strong style={{color:val<0?'rgb(238,94,104)':brandColor}}>({x})</strong>
    }

    parseDate=(dt)=>{
    if(!dt){
        return false
    }else{
        let full = new Date(dt * 1000)
        console.log('full',full)
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let month = full.getMonth()
        let day = full.getDate()
        let year = full.getFullYear()
        let hours = full.getHours()
        let mins = full.getMinutes()
        let secs = full.getSeconds()
        let ampm = 'AM' 
        if (hours > 12){
            ampm = 'PM'
            hours = hours - 12
        }
        console.log('dt: ', dt, `${hours}:${mins < 10 ? `0${mins}`:mins} ${ampm} ${months[month]} ${day}, ${year}`)
        // this.convertTimestamp(dt)
        // return `${months[month]} ${day}, ${year}`
        return (
            <div style={{width:'100%'}}>
                {/* <p style={{textAlign:"right",margin:0, fontSize:14}}> */}
                    {/* {time.replace(/^0(?:0:0?)?/, '')}  */}
                    {/* {hours}:{mins < 10 ? `0${mins}`:mins} {ampm} */}
                {/* </p> */}
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {months[month]} {day}, {year}
                </p>
            </div>
        )
    }
    }

    timeAgo(someDateInThePast) {
        let result = '';
        let difference = Date.now() - new Date(someDateInThePast * 1000);
    
        if (difference < 5 * 1000) {
            return 'Just now';
        } else if (difference < 90 * 1000) {
            return 'Moments ago';
        }
    
        // console.log('difference', difference)
        //it has days
        if ((difference / 1000 * 3600 * 60 * 24) > 0) {
            if (Math.floor(difference / 1000 / 60 / 60 / 24) > 0) {
                let s = Math.floor(difference / 1000 / 60 / 60 / 24) == 1 ? '' : 's';
                result = `${Math.floor(difference / 1000 / 60 / 60 / 24)} day${s}${result == '' ? '' : ','} ` + result;
            }
            // console.log('in days', result)
    
        }
         //it has hours
        if (result === '' && (difference / 1000 * 3600 * 60) > 0) {
            if (Math.floor(difference / 1000 / 60 / 60 % 24) > 0) {
                let s = Math.floor(difference / 1000 / 60 / 60 % 24) == 1 ? '' : 's';
                result = `${Math.floor(difference / 1000 / 60 / 60 % 24)} hour${s}${result == '' ? '' : ','} ` + result;
            }
            // console.log('in hours', result)
        }
        //it has minutes
        if (result === '' && (difference / 1000 * 3600) > 0) {
            if (Math.floor(difference / 1000 / 60 % 60) > 0) {
                let s = Math.floor(difference / 1000 / 60 % 60) == 1 ? '' : 's';
                result = `${Math.floor(difference / 1000 / 60 % 60)} minute${s} `;
            }
            // console.log('in min',result)
        }
        if(result === ''){
            return 'One hour ago'
        }
        else{
            return result + ' ago';
        }
    }
    

    convertTimestamp(timestamp) {
        var d = new Date(timestamp * 1000),	// Convert the passed timestamp to milliseconds
              yyyy = d.getFullYear(),
              mm = ('0' + (d.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
              dd = ('0' + d.getDate()).slice(-2),			// Add leading 0.
              hh = d.getHours(),
              h = hh,
              min = ('0' + d.getMinutes()).slice(-2),		// Add leading 0.
              ampm = 'AM',
              time;
                  
          if (hh > 12) {
              h = hh - 12;
              ampm = 'PM';
          } else if (hh === 12) {
              h = 12;
              ampm = 'PM';
          } else if (hh == 0) {
              h = 12;
          }
          
          // ie: 2013-02-18, 8:35 AM	
          time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
              
          console.log('coverted time: ',time)
      }

    utcToLocal(timeAndDate){
        
        let date = new Date(timeAndDate).toString().split(" ")
        // console.log('date',date)
        let hour = date[4].substring(0,2)
        let min = date[4].substring(3,5) 
        // console.log('hour',hour)
        // console.log('min',min)

        let day = date[1] + ' ' + date[2] + ', ' + date[3]
        let amPm = 'AM'
        if (hour > 12){
            hour = hour - 12
            amPm = 'PM'
        }
        else if(hour === '00'){
            hour = '12'
        }
        let time = `${hour}:${min} ${amPm}`
        return (
            <div style={{width:'100%'}}>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {time.replace(/^0(?:0:0?)?/, '')} 
                </p>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {day}
                </p>
            </div>
        )
    }

    getPriceChange = ()=>{
        if(this.state.ivanData){
            // console.log('first', this.state.ivanData.balanceHistory[0])
            let firstday
            if(this.state.ivanData.balanceHistory){
                firstday = this.state.ivanData.balanceHistory[0]
            }
            else{
                return '$0.00'
            }
            // console.log('last', this.state.ivanData.balanceHistory[this.state.ivanData.balanceHistory.length-1])
            let lastday =  this.state.ivanData.balanceHistory[this.state.ivanData.balanceHistory.length-1]
            if(firstday && lastday){
                let x = lastday.balance - firstday.balance
                if (x>=0){
                    return `$${this.addCommas(x.toFixed(2))}`
                }else{
                    return`-$${this.addCommas(x.toFixed(2).slice(1))}`
                }
            }
        }
    }

    checkLogo = (symbol)=>{
        let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        if (http.status != 404){
            return (
              <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:70, height:70,justifySelf:'flex-end',}}>
                    <img 
                        // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
                        src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
                        onerror="this.onerror=null; this.remove();" 
                        style={{objectFit:'contain', width:70,height:70, }}
                    />
                </div>
            )
        }
    }

    buttonDropdown = (acc)=>{
        if(this.state.activeDropdown){
            return(
                <div>
                    <div 
                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)',fontSize:18}}>Menu</p>
                    </div>
                    <div style={{position: 'absolute', right:'5%',backgroundColor: '#f9f9f9',minWidth: '10%',overflow: 'auto',boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',zIndex: 100, marginRight:25}}>
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'orders'?'rgb(200,200,200)':'',}}onClick={()=>this.props.orders()}onMouseEnter={()=>this.setState({hover:'orders'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Orders</p>
                        </div>
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'transactions'?'rgb(200,200,200)':'',}}onClick={()=>this.props.transactions()} onMouseEnter={()=>this.setState({hover:'transactions'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transactions</p>
                        </div>
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'statements'?'rgb(200,200,200)':'',}}onClick={()=>this.setState({showStatements:acc})} onMouseEnter={()=>this.setState({hover:'statements'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Statements</p>
                        </div>
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'statements'?'rgb(200,200,200)':'',}}onClick={()=>this.setState({showStatements:acc})} onMouseEnter={()=>this.setState({hover:'withdraw'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Withdraw</p>
                        </div>
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'unlink'?'rgb(200,200,200)':'',}}onClick={()=>this.setState({showUnlink:acc})} onMouseEnter={()=>this.setState({hover:'unlink'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Unlink</p>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div>
                    <div 
                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)',fontSize:18}}>Menu</p>
                    </div>
                </div>
            )
        }
    }

    row(stock){
        // console.log(`----------------${stock.ticker}---------------`)
        return(
            <div style={{width:'100%'}}>
                <div style={{display:'flex', justifyContent:'space-between', width:'100%', marginBottom:5, marginTop:10, alignItems:'center'}}>
                    <div style={{width:'8%'}}>
                        {stock.ticker === 'CASH'?null: this.checkLogo(stock.ticker)}
                    </div>
                    <a style={{width:'10%'}} /*onClick={()=>this.props.tradeStock()} href='#'*/href={`/CompanyPage/${stock.ticker}`}><p style={{width:'100%', margin:0 ,textAlign:'left', color:brandColor,marginLeft:7}}>{stock.ticker}</p></a>
                    <p style={{width:'20%', textAlign:'right', margin:0,fontSize:'large'}}>{stock.bought_percentage===100.00? 'YES':'NO'}</p>
                    <p style={{width:'20%', textAlign:'right', margin:0,fontSize:'large'}}>{stock.allocated_percentage.toFixed(1)}%</p>
                    <p style={{width:'20%', textAlign:'right', margin:0,fontSize:'large'}}>${stock.bought_cost.toFixed(2)}</p>
                    <p style={{width:'20%', textAlign:'right', margin:0,fontSize:'large'}}>{this.timeAgo(stock.unix_trend_date)}</p>
                    {/* <p style={{width:'20%', textAlign:'right', margin:0,fontSize:'large'}}>{this.parseDate(stock.unix_trend_date)}</p> */}
                    {/* <p style={{width:'20%', textAlign:'right', margin:0,fontSize:'large'}}>{this.utcToLocal(stock.trend_date)}</p> */}
                    {/* <p style={{width:'20%', textAlign:'right', margin:0,fontSize:'large'}}>{this.etcToLocal(stock.trend_date)}</p> */}
                    <div style={{width:'2%'}}></div>
                </div>
                <hr></hr>
            </div>
        )
    }

    pending = (acc)=>{
        return(
            <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column', }}>
                <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:40, borderRadius: '50px',  backgroundImage:'linear-gradient(97deg, #ffffff 1%, #f2f2f2)', width:'100%',  marginTop:25}}>
                    <div style={{width:'100%', margin:'auto',marginBottom:10}}>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{acc.product === 'robo'? 'Robo': acc.product==='group'?'Trade Group': acc.ria? 'Directed Account':'Brokerage Account'}: </strong>{acc.inst === 'DriveWealth'? 'DriveWealth '+ acc.mask_full  :`${acc.inst} ${acc.mask}`}</p>
                        </div>
                        
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%', marginTop:10}}>
                            <p style={{fontWeight:'bold', fontSize:22, margin:0}}>This account is pending approval.</p>
                        </div>
                    </div>
                </div>                
            </div>
        )
    }


    accButtons = ()=>{
        let acc = this.props.acc
        return (
            <div style={{display:'flex', width:'40%', justifyContent:'flex-end', flexWrap:'wrap'}}>
                            {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({orders:true,showOrders:acc})}>
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Orders</p>
                                    </div>
                                :null
                                }
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({orders:false,showOrders:acc})}>
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transactions</p>
                                    </div>
                                :null
                                }
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({showStatements:acc})}
                                    // onClick={()=>this.getStatements(acc.accountId)}
                                    >
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Statements</p>
                                    </div>
                                :null
                                }
                                <div 
                                style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#ee5e68', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                onClick={()=>this.setState({showUnlink:acc})}
                                >
                                    <p style={{margin:0,fontWeight:'', color:'#ee5e68'}}>Unlink</p>
                                </div>
                            </div>
        )
    }

    render(){
        let acc = this.props.acc
        if(acc.status === 'PENDING'){
            this.pending(acc)
        }else{
            if(this.props.isSmallScreen){
                return(
                    <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column', }}>
                        <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:10, borderRadius: '50px',  backgroundImage:'linear-gradient(97deg, #ffffff 1%, #f2f2f2)', width:'100%',  marginTop:25}}>
                            <div style={{width:'100%', margin:'auto',marginBottom:10}}>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{ Array.isArray(this.state.netenData)?this.state.netenData[0].group_name :`Trade Group`}</strong></p>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        <div onClick={()=>this.setState({activeDropdown:!this.state.activeDropdown})}>
                                            {this.buttonDropdown(acc)}
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                                    <div style={{marginLeft:10}}>
                                        <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.inst}</p>
                                        <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.mask_full}</p>
                                        {/* <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.name}</p> */}
                                        <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}><strong style={{color:brandColor,}}>${acc.balances&& acc.balances[0]&& acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):0}</strong></p>
                                    </div>
                                    <div style={{marginRight:10,}}>
                                        <div style={{height:48, display:'flex',justifyContent:'flex-end',alignItems:'center',marginTop:9, marginBottom:15}}>
                                            <FaUniversity style={{height:'100%', width:'auto'}}/>
                                        </div>
                                        <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.ria ? 'RIA-Directed':'Self-Directed'}</p>
                                    </div>
                                </div>
                                <div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else{

                return(
                    <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column', marginTop:25 }}>
                        <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:40, borderRadius: '50px', backgroundImage: 'linear-gradient(97deg, #ffffff 1%, #f2f2f2)',/* backgroundColor:'#FFFFFF', */ width:'100%',  marginBottom:25}}>
                            <div style={{display:'flex', justifyContent:'space-between', width:'100%', margin:'auto',marginBottom:10}}>
                                <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{Array.isArray(this.state.netenData)?this.state.netenData[0].group_name :`Trade Group`}: </strong>{acc.inst === 'DriveWealth'? 'DriveWealth '+ acc.mask_full  :`${acc.inst} ${acc.mask}`}</p>
                                <div style={{display:'flex', width:'60%', justifyContent:'flex-end', flexWrap:'wrap'}}>
                                {/* {
                                    acc.inst  === 'DriveWealth'? 
                                        <div 
                                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                        onClick={()=>this.props.orders()}>
                                            <p style={{margin:0,fontWeight:'', fontSize:18,color:'rgb(17,17,17)'}}>Orders</p>
                                        </div>
                                    :null
                                    } */}
                                    {
                                    acc.inst  === 'DriveWealth'? 
                                        <div 
                                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                        onClick={()=>this.props.deposit()}
                                        // onClick={()=>this.getStatements(acc.accountId)}
                                        >
                                            <p style={{margin:0,fontWeight:'', fontSize:18,color:'rgb(17,17,17)'}}>Transfer</p>
                                        </div>
                                    :null
                                    }
                                    {/* {
                                    acc.inst  === 'DriveWealth'? 
                                        <div 
                                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                        onClick={()=>this.props.redemption()}
                                        // onClick={()=>this.getStatements(acc.accountId)}
                                        >
                                            <p style={{margin:0,fontWeight:'', fontSize:18,color:'rgb(17,17,17)'}}>Redeem</p>
                                        </div>
                                    :null
                                    } */}
                                    {
                                    acc.inst  === 'DriveWealth'? 
                                        <div 
                                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                        onClick={()=>this.props.transactions()}>
                                            <p style={{margin:0,fontWeight:'', fontSize:18,color:'rgb(17,17,17)'}}>Transactions</p>
                                        </div>
                                    :null
                                    }
                                    
                                    
                                    {
                                    acc.inst  === 'DriveWealth'? 
                                        <div 
                                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                        onClick={()=>this.props.statements()}
                                        // onClick={()=>this.getStatements(acc.accountId)}
                                        >
                                            <p style={{margin:0,fontWeight:'', fontSize:18,color:'rgb(17,17,17)'}}>Statements</p>
                                        </div>
                                    :null
                                    }
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#ee5e68', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                    onClick={()=>this.props.unlink()}
                                    >
                                        <p style={{margin:0,fontWeight:'', fontSize:18,color:'#ee5e68'}}>Unlink</p>
                                    </div>
                                </div>
                                {/* <div onMouseEnter={()=>this.setState({activeDropdown:true})} onMouseLeave={()=>this.setState({activeDropdown:false})}>{this.buttonDropdown(acc)}</div> */}
                            </div>
                            <div style={{width:'100%', margin:0}}>
                                <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                                    <p style={{textAlign:'left'}}>Current Holdings</p>
                                    {/* <p style={{textAlign:'right'}}>+/- {this.formatPnL(acc.balances[0]['profit-loss'])}</p> */}
                                    {/* <p style={{}}>+/-<strong style={{color:brandColor,marginLeft:5}}>{this.getPriceChange()}</strong></p> */}
                                    <p>Total Value: <strong style={{color:brandColor, marginLeft:5}}>${acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):null}</strong> {this.formatPnL(acc.balances[0]['profit-loss'])}</p>
                                </div>
                                {/* <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                                    <p onClick={()=>this.getStats(acc.accountId,"portfolio" )} style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold', cursor:'pointer'}}>See Portfolio Stats</p>
                                </div> */}
                                <div style={{display:'flex', justifyContent:'space-between', width:'100%', marginBottom:5, marginTop:10}}>
                                    <p style={{width:'18%', textAlign:'center', fontSize:'large'}}>Symbol</p>
                                    <div style={{width:'20%',}}>
                                        <p style={{width:'100%', textAlign:'right', fontSize:'large', margin:0}}>Currently </p>
                                        <p style={{width:'100%', textAlign:'right', fontSize:'large', margin:0}}>Invested</p>
                                    </div>
                                    <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Max %</p>
                                    <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Mkt Val</p>
                                    <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Last Trend</p>
                                    <div style={{width:'2%'}}></div>
                                </div>
                                <div style={{width:'100%', maxHeight:this.state.show === 'more'? '':250, overflowY:'scroll'}}>
                                    {/* {acc.holdings.map(ele=>this.brokerageRow(ele,total))} */}
                                    {this.state.netenData.length >0 && Array.isArray(this.state.netenData)? this.state.netenData.map(ele=>this.row(ele)):null}
                                    {this.state.netenData === 'loading'? <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%'}}><p style={{fontSize:24, fontWeight:'bold'}}>Loading...</p></div>:null}
                                    {this.state.netenData === 'no stocks'? <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%'}}><p style={{fontSize:24, fontWeight:'bold'}}>Your Trade Group is Empty</p></div>:null}
                                </div>
                            </div>
                        </div>                
                    </div>
                )
            }
        }
    }
}