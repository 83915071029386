import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from "../PublicHeader"
import { NavLink } from 'react-router-dom';

const GREEN = '#669890'

export class Unconstructed extends Component {
    render() {
        return (
            <div>
                <Header user={this.props.user} signOut={this.props.signOut}/>
            <div style={{marginTop: 100}}>
                <Container>
                    <Row className="page-blank-row"></Row>
                    <p className="oops">Oops...</p>
                    <p className="under-construction">This page is currently under construction</p>
                    <NavLink to='/'>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <div style={{backgroundColor:GREEN, padding:12, borderRadius:12}}>
                                <p style={{color:'rgb(255,255,255)', fontSize: 36, margin:0, }}>
                                    Return to ForeSCITE.com
                                </p>
                            </div>
                        </div>
                    </NavLink>
                    <Row className="page-blank-row"></Row>
                </Container>
            </div>
            </div>
        )
    }
}

export default Unconstructed

