import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ChineseCalendarCommentary from "../layout/ChineseCalendarCommentary";
import PressAndArticles from "../layout/PressAndArticles";
import ChineseFooter from "../layout/ChineseFooter";
import Header from "../layout/Header"

export class ChineseInsights extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <div>
        <Container>
          <Row className="page-blank-row-md" />
          <Row>
            <Col sm={3} className="insights-calendar-commentary">
              <p className="insight-page-title">每月评述</p>
              <ChineseCalendarCommentary />
            </Col>
            <Col sm={9}>
              <PressAndArticles isChinese={true} />
            </Col>
          </Row>
          <Row className="page-blank-row" />
        </Container>
        <ChineseFooter />
      </div>
      </div>
    );
  }
}

export default ChineseInsights;
