import React from 'react'
import Warning from '../../assets/newUI/warning.svg'


export default class RiskNum extends React.Component{
    state={}

    num(cvar,ticker){
        if(ticker === 'BIL' || ticker === "TLT"){
            return '0'
        }
        else{
           if( Math.abs(cvar*200/10) > 10){
                return '10+'
           }else if (Math.abs(cvar*200/10) < 1){
               return '1'
           }
           else{
               return Math.abs(cvar*200/10).toFixed(0)
           }
        }
    }
    numText = (cvar,ticker)=>{
        let num = this.num(cvar,ticker)
        if (num < 3){
            return 'Low'
        }
        else if (num < 6){
            return 'Medium'
        }
        else if (num < 8){
            return 'High'
        }
        else{
            return 'Extreme'
        }
    }
    numColor = (cvar,ticker)=>{
        let num = this.num(cvar,ticker)
        if (num < 3){
            return 'rgb(112,184,187)'
        }
        else if (num < 6){
            return 'rgb(240,161,33)'
        }
        else if (num < 8){
            return 'rgb(240,121,33)'
        }
        else{
            return 'rgb(240,33,33)'
        }
    }

    render(){
        return(
            <div style={{width:'100%', height: '100%',borderRadius: 7,backgroundColor: this.numColor(this.props.cvar, this.props.symbol), paddingLeft: '10%',paddingRight: '10%',paddingTop: '5%', paddingBottom:'5%', display:'flex', flexDirection:'row' }}>
                <div style={{width:'25%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img
                        src={Warning}
                        style={{width:'100%'}}
                    />
                </div>
                <div style={{width:'75%', display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
                            <p 
                            style={{display:'flex', justifyContent:'center', alignItems:'center',margin:0, fontSize: '37px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.22, letterSpacing: 'normal', textAlign: 'left', color: 'rgb(36, 42, 56)'}}
                            >
                               {this.num(this.props.cvar, this.props.symbol)}
                            </p>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center',margin:0}}>
                            <p style={{margin: 0, marginLeft:5}}>{this.numText(this.props.cvar, this.props.symbol)}</p>
                        </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <p style={{color:'rgb(255,255,255)'}}>
                            AR Risk Number
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}