import React from 'react'

export default class BudgetCategory extends React.Component{

    state = {
        
    }

    addCommas = (x)=>{
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    shiftDate(dt){
        let arr = dt.split('-')
        return arr[1].replace(/^0+/, '') + '/' + arr[2].replace(/^0+/, '')+ '/' + arr[0]
    }

    utcToLocal(timeAndDate){
        let date = new Date(timeAndDate).toString().split(" ")
        let hour = date[4].substring(0,2) 
        let min = date[4].substring(3,5) 
        let day = date[1]+ ' ' + date[2]+ ', ' + date[3]
        let amPm = 'AM'
        if (hour > 12){
            hour = hour - 12
            amPm = 'PM'
        }
        else if(hour === '00'){
            hour = '12'
        }
        let time = `${hour}:${min} ${amPm}`
        return (
            <div style={{width:'100%'}}>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {time.replace(/^0(?:0:0?)?/, '')} 
                </p>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {day}
                </p>
            </div>
        )
    }

    createRow(ele){
        return(
            <div style={{display:'flex',alignItems:'center', justifyContent:'space-between', width:'100%',borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.25px', marginBottom:5}}>
            {/* <div> */}
                <div style={{width:'10%',marginRight:'2%'}}>
                    <p style={{margin:0}}>{this.shiftDate(ele.transDetails.date)}</p>
                </div>
                <div style={{width:'50%',marginRight:'2%'}}>
                    <p style={{margin:0}}>{ele.transDetails.name}</p>
                </div>
                <div style={{width:'20%',marginRight:'2%'}}>
                    <p style={{margin:0}}>{ele.transDetails.type}</p>
                </div>
                <div style={{width:'10%',}}>
                    <p style={{margin:0}}>{this.addCommas(Math.abs(ele.amount))}</p>
                </div>
            </div>
        )
    }
    createHeading(){
        return(
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between', width:'100%', borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.25px'}}>
                <div style={{width:'10%',marginRight:'2%'}}>
                    <p style={{margin:0}}>Date</p>
                </div>
                <div style={{width:'50%',marginRight:'2%'}}>
                    <p style={{margin:0}}>Description</p>
                </div>
                <div style={{width:'20%',marginRight:'2%'}}>
                    <p style={{margin:0}}>Type</p>
                </div>
                <div style={{width:'10%'}}>
                    <p style={{margin:0}}>Amount</p>
                </div>
            </div>
        )
    }

    render(){
        // console.log( 'orders resp',this.state.ordersResp)
        let category = ''
        let data = []
        switch(this.props.category) {
            case 'debt':
              category = 'Debt Payments'
              data = this.props.data.debtPay.debtPayDetails
              break;
            case 'nonEssEx':
              category = 'Non Essential Expenses'
              data = this.props.data.nonEssExpense.nonEssExpenseDetails
              break;
            case 'essEx':
              category = 'Essential Expenses'
              data = this.props.data.essExpense.essExpenseDetails
              break;
            case 'income':
              category = 'Income'
              data = this.props.data.income.incomeDetails
              break;
            case 'cashFlow':
              category = 'Cash Flow'
              data = ['Cash flow is  not important']
              break;
            default:
              // code block
          }
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', minWidth:725, height: '90%', paddingBottom:25}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>{category}</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeModal()}> X </p>
                    </div>
                    <div style={{width:'100%', overflowY:'scroll'}}>
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                            {data.length > 0? this.createHeading():
                                <p>You do not have any {category} that you have shared with us. Please link the rest of your accounts to see your complete financial picture.</p>
                            }
                        </div>
                        <div style={{backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%',maxHeight:500, overflowY:'scroll' }}>
                            {data.length > 0? data.map(ele=>this.createRow(ele)):null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}