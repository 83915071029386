import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import TeamMembers from "../layout/TeamMembers";
import Footer from "../layout/Footer";
import Header from "../layout/Header"


export class Team extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <div>
        <Row className="page-blank-row-md" />
        <TeamMembers />
        <Footer />
      </div>
      </div>
    );
  }
}

export default Team;
