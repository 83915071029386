import React from 'react'
import LogoWithText from '../assets/newUI/foreScite_yestext.png'
import SearchGlass from '../assets/newUI/searchGlass.png'
import { NavLink, /*Redirect*/ } from "react-router-dom";
import { FaBars, FaSignOutAlt,FaUserCircle, FaBell } from "react-icons/fa"
import upArrow from "../assets/single_up.svg";
import downArrow from "../assets/single_down.svg";
import strongUpArrow from "../assets/double_up.svg";
import strongDownArrow from "../assets/double_down.svg";
import flatArrow from "../assets/flat.svg";
import Notifications from './Notifications'
import ENDPOINT from './Endpoint'

// const GREEN = '#669890'
// const GREEN = 'rgb(78,167,158)'
const GREEN = '#3b8e8c'
const LIGHTGREEN = "#7bc0c3"
const WHITE = 'rgb(255,255,255)'

export default class PrivateHeader extends React.Component{
    state={
        searchActive: false,
        searchText: '',
        searchResults: [],
        dataLoading: false,
        scrollY: 0,
        dropdown: false,
        userDetails:{},
        showNotif: false,
        notifs:[],
        resolved:true,
    }

    componentDidMount(){
        if(this.props.user){
            // this.getUserDetails()
            this.getNotifcations()
        }
    }

    getNotifcations = ()=>{
        // console.log('----------getting notifs----------')
        let url = `${ENDPOINT}/users/notification`
            fetch(url,{
                headers:{
                    Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                    'Content-Type': 'application/json'
                },
        })
        .then(resp=>resp.json())
        .then(responseJson=>{
            // console.log('notif resp',responseJson)
            let notifs = responseJson.payload.notifications
            let resolved = true
            notifs.forEach(n=>{
                if(!n.resolved){
                    // console.log('unresolved', n)
                    resolved = false
                }
            })
            this.setState({notifs, resolved})
            setTimeout(()=>this.getNotifcations(),150000)
            // this.startTimer()
        })
        .catch(err=>console.log('notif catch', err))
    }

    resolveAllNotif = ()=>{
        if(this.state.resolved){
            this.setState({showNotif:true,resolved:true})
        }else{
            this.setState({showNotif:true,resolved:true})
        
            fetch(`${ENDPOINT}/users/notification/all/resolve`,{
                headers:{
                    Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                    'Content-Type': 'application/json'
                },
            })
            .then(resp=>resp.json())
            .then(responseJson=>{
                console.log('resolve resp',responseJson)
            })
        }
    }

    circle(){
        return(
            <div style={{ width:16,height:16,borderRadius:8,backgroundColor:'rgb(224, 92, 103)',zIndex:100}}>
            </div>
        )
      
    }

    getUserDetails = ()=>{
        fetch(`${ENDPOINT}/users?details=true`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => this.setState({userDetails:responseJson}))
    
    }
    
    getArrow = (direction) => {
        switch (direction) {
          case -1:
            return downArrow;
          case 1:
            return upArrow;
          case 2:
            return strongUpArrow;
          case -2:
            return strongDownArrow;
          default:
            return flatArrow;
        }
      };

    search = (event) => {
        let searchText = event.target.value.toUpperCase();
        this.setState({ searchText }, () => {
            const { isSearching } = this.state;
            if (isSearching) {
                return;
            } else {
                this.setState({ isSearching: true });
                setTimeout(() => {
                    this.setState(
                        { isSearching: false },
                        this.onSubmitSearchMomentum
                    );
                }, 2000);
            }
        });
    }

    startTimer = ()=>{
        console.log('starting timer')
        setTimeout(this.getNotifcations(),60000)
    }

    onSubmitSearchMomentum = () => {
        const { searchText } = this.state;
        this.setState({ dataLoading: true });
        // console.log(searchMomentumText);
        let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchText}`;
    
        fetch(url, {
            "Access-Control-Allow-Headers": {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Access-Control-Request-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
            },
        })
            .then((res) => res.json())
            .then((responseJson) => {
                var data = responseJson.content;
                // console.log("SEARCHING RESULT: " , data);
    
                for (var i = 0; i < data.length; i++) {
                    var item = data[i];
                    var color = "";
                    if (item.rar) {
                        switch (item.rar) {
                            case "H":
                                color = "#dc143c";
                                break;
                            case "M":
                                color = "#fcac0c";
                                break;
                            default:
                                color = "#00A99D";
                        }
                    } else {
                        color = "rgb(0,0,0)";
                    }
                    item.symbolColor = color;
                    data[i] = item;
                }
                this.setState({
                    dataLoading: false,
                    searchResults: data,
                });
            })
            .catch((err) => {
                this.setState({ searchResults: false });
                console.log(err);
            });
    };

    showResults = ()=>{
        let arr = []
        // this.state.searchResults.forEach(ele=>{
        for (let i = 0; i < 5; i++) {
            console.log(this.state.searchResults[i])
            arr.push(
                <NavLink onClick={()=>this.setState({searchActive:false})} onMouseLeave={()=>this.setState({hover:false})} onMouseEnter={()=>this.setState({hover:this.state.searchResults[i]})} to={`/CompanyPage/${this.state.searchResults[i].symbol}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:this.state.hover===this.state.searchResults[i]? '#f1f1f1' :'#fafafa',cursor:'pointer', textDecoration:'none', color:'rgb(17,17,17)', borderBottom:'solid', borderWidth:.25, borderColor:'#cccccc', }}>
                   <div style={{width: '80%', margin:'auto', display:'flex',justifyContent:'space-between'}}>
                        <div style={{width: '30%',}}>
                            <p style={{margin:0}}>{this.state.searchResults[i].symbol}</p>
                        </div> 
                        <div style={{width: '30%',}}>
                            <p style={{margin:0}}>${this.state.searchResults[i].priceIntraday.toFixed(2)}</p>
                        </div> 
                        <div style={{width: '30%',textAlign:'right'}}>
                            <img
                            src={this.getArrow(this.state.searchResults[i].direction)}
                            style={{
                            // marginTop: 5,
                            width: 20,
                            height: 20,
                            }}
                        />
                        </div> 
                   </div>
                </NavLink>
            )
            if(i+1 >= this.state.searchResults.length){
                i += 5
            }
        }
        return (
            <div style={{position:'absolute',width:'100%' }}>
                {arr}
            </div>
        )
    }

    searchbar = ()=>{
        if(this.state.searchActive){
            // console.log('this.state.searchText',this.state.searchText)
            // console.log('this.state.searchResults',this.state.searchResults)
            return(
                <div style={{marginRight: '1.5rem', position: 'relative'}}>
                    <div style={{display:'flex', alignItems:'center', height: 25,width: 250, borderRadius:this.state.searchResults.length > 0?'20px 20px 0 0 ' :20, backgroundColor:"#ffffff", paddingLeft:20,}}>
                        <img 
                            src={SearchGlass}
                            style={{height:15, marginRight:5}}
                            onClick={()=>this.setState({searchActive:!this.state.searchActive})}
                        />
                        <input autoFocus value={this.state.searchText} id="headerSearch" onChange={(e)=>this.search(e)} type="text" placeholder="Enter any stock symbol" style={{ border:'none', outline:'none',backgroundColor:"#fafafa"}}/>
                    </div>
                    {this.state.searchResults.length > 0 ?
                    <div style={{position:'relative'}}>
                        {this.showResults()}
                    </div>
                    :null
                    }
                </div>
            )
        }else{
            return(
                <div style={{marginRight: '1.5rem', position: 'relative'}}>
                    <img
                        src={SearchGlass}
                        style={{height: 20, cursor:'pointer'}}
                        onClick={()=>{
                            this.setState({searchActive:!this.state.searchActive})
                        }
                        }
                    />
                </div>
            )
        }
    }

    titleText = ()=>{
       if(this.props.activeTab === 'MyStrategies'){
           return 'PORTFOLIOS & STRATEGIES'
       }
       if(this.props.activeTab === 'Budgeting'){
           if(this.props.isSmallScreen){
               return 'Financial Picture'
           }else{
               return 'My Full Financial Picture'
           }
       }
       else if (this.props.activeTab === 'MyWatchlist'){
           return 'WATCHLIST'
       }
       else if (this.props.activeTab === 'Invest'){
        // return 'TRADE CENTER'
        return 'MY ACCOUNTS'
       }
       else if (this.props.activeTab === 'PortfolioConstructor' || window.location.pathname === `/Dashboard/Constructor`){
        return 'Portfolio Constructor'
       }
       else if (this.props.activeTab === 'Portfolio'){
        return 'Portfolio'
       }
       else if (this.props.activeTab === 'My Accounts'){
        return 'MY ACCOUNTS'
       }
       else if (this.props.activeTab === 'Account'){
        return 'ACCOUNT'
       }
       else if (this.props.activeTab === 'PriceTrendPredictor'){
        return 'PRICE TREND PREDICTOR'
       }
       else{
            return 'WATCHLIST'
        //    return this.props.activeTab.toUpperCase()
       }
    }

    render(){
        return(
            <div style={{ display:'flex', alignItems: 'center', margin: '0 auto', padding: '1rem 0', clear: 'both', zIndex:199, marginTop:'3%'}} id='top'>
                <div style={{width:'100%',display:'flex', justifyContent:"space-between"}}>{/**header right */}
                    {this.props.isSmallScreen?
                        <div style={{ padding:15, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                            <FaBars onClick={()=>this.props.toggleMenu()}/>
                            <div onClick={()=>this.props.toAcc()} style={{display:'flex', justifyContent:'center', alignItems:'center', paddingLeft:10, paddingRight:10, cursor:'pointer', marginRight:this.props.isSmallScreen?'':'5%'}}>
                                <FaUserCircle size={30} color={GREEN}/>
                            </div>
                            {/* <div style={{display:'flex', justifyContent:'center', alignItems:'center', paddingLeft:10, paddingRight:10, cursor:'pointer', marginRight:this.props.isSmallScreen?'':'15%'}}>
                                {this.state.showNotif? <FaBell size={30} color={GREEN} onClick={()=>this.setState({showNotif:false})}/>:<FaBell size={30} color={GREEN} onClick={()=>this.setState({showNotif:true})}/>}
                            </div>
                            {this.state.showNotif ?
                            <div style={{position: 'absolute', top:'15%',right:'5%',backgroundColor: '',minWidth: '10%',overflow: 'auto',zIndex: 100, marginRight:25,backgroundColor: '#f9f9f9',boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)', paddingLeft:5, paddingRight:5}}>
                                <Notifications notifs={this.state.notifs}/>
                            </div>:null
                            } */}
                        </div>
                        :
                        null
                    }
                    {this.state.searchActive && this.props.isSmallScreen?
                        null : 
                        <div style={{flex:1, display:'flex',justifyContent: this.props.isSmallScreen? 'center':'flex-start', alignItems:'center',}}>
                            <p style={{margin:0, fontSize:24, fontWeight:'bold',textAlign:'center'}}>{this.titleText()}</p>
                        </div>
                    }
                    <div style={{flex:this.props.isSmallScreen?'':1, display:'flex',justifyContent:'flex-end', alignItems:'center',}}>
                        {/* {this.props.activeTab === 'My Watchlist'?
                        <div>
                            {this.searchbar()}
                        </div>:null} */}
                        {this.props.isSmallScreen?null:
                        <div onClick={()=>this.props.toAcc()} style={{display:'flex', justifyContent:'center', alignItems:'center', paddingLeft:10, paddingRight:10, cursor:'pointer',}}>
                            <FaUserCircle style={{height:40, width:'auto'}} color={GREEN}/>
                        </div>
                        }

                        
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', paddingLeft:10, paddingRight:10, cursor:'pointer', }}>
                                {this.state.showNotif? <FaBell style={{height:this.props.isSmallScreen? 30: 40,width:'auto'}} color={GREEN} onClick={()=>this.setState({showNotif:false})}/>
                                :
                                <div>
                                {this.state.resolved? null : <div style={{display:'flex',justifyContent:'flex-end', alignItems:'baseline',marginBottom:'-15px',marginRight:'-5px'}}>{this.circle()}</div>}
                                <FaBell style={{height:this.props.isSmallScreen? 30: 40,width:'auto'}} color={GREEN} onClick={()=>this.resolveAllNotif()}
                                /></div>}
                        </div>
                        {this.state.showNotif ?
                            <div style={{position: 'absolute', top:'15%',right:'5%',backgroundColor: '',minWidth: '10%',overflow: 'auto',zIndex: 100, marginRight:25,backgroundColor: '#f9f9f9',boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)', paddingLeft:5, paddingRight:5}}>
                                <Notifications notifs={this.state.notifs}/>
                            </div>:null
                        }
                        
                        { this.props.isSmallScreen?
                            <FaSignOutAlt color={GREEN} style={{height:30,width:'auto'}} onClick={()=>this.props.signOut()} />
                            :
                            <div onClick={()=>this.props.signOut()} style={{display:'flex', justifyContent:'center', alignItems:'center', borderRadius: 20, paddingLeft:10, paddingRight:10, paddingTop:5, paddingBottom:5, backgroundColor: GREEN, cursor:'pointer', marginLeft:10,marginRight:this.props.isSmallScreen?'':'10%'}}>
                                    <p  style={{margin:0, color:WHITE,textDecoration:'none'}}>
                                        Sign Out
                                    </p>
                            </div>
                        }
                        {/* <div onClick={()=>this.props.signOut()} style={{height: 40, width:40, borderRadius:'50%', display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer' }}>
                                <FaSignOutAlt/>
                        </div> */}
                        {/* <ul style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '18px', listStyleType: 'none',margin:0 }}>
                            <li style={{marginRight: '1.5rem', position: 'relative', cursor:"pointer",listStyleType: 'none'}}>
                                
                            </li>
                        </ul> */}
                    </div>
                </div>
            </div>
        )
    }
}