import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import ElementsOfPlatfrom from "../layout/ElementsOfPlatfrom";
import RouletteChart from "../layout/RouletteChart";
import AdvantageOfPlatformDetails from "../AdvantageOfPlatformDetails";
import SecureYourFuture from "../layout/SecureYourFuture";
import Footer from '../Footer'
import Header from "../layout/Header"
import PublicHeader from "../PublicHeader"

export class Platform extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = `ForeSCITE`;
  }

  render() {
    return (
      <div>
        {/* <Header user={this.props.user} signOut={this.props.signOut}/> */}
        {/* <PublicHeaderDark/> */}
        <PublicHeader user={this.props.user} signOut={this.props.signOut} isSmallScreen={this.props.isSmallScreen}/>
      <div>
        <Container>
          <Row className="page-blank-row-md" />
          <Row style={{display:'flex', justifyContent:'center', alignItems:'center', width: '70%'}}>
            <h1>Our Platform</h1>
          </Row>
          <Row>
            <ElementsOfPlatfrom />
          </Row>
          {/* <Row>
            <RouletteChart />
          </Row> */}
          <Row className="platform-advertise">
            <p className="platform-advertise-title">
              We believe that successful investment management goes far beyond a
              smart strategy.
            </p>
            <p className="platform-advertise-text">
              That’s why we built a complete platform to provide investors and
              advisers with flexibility, scalability, and intelligent
              automation. We have also developed and integrated blockchain
              technology to ensure immutability and data integrity of our
              monthly allocation signals.
            </p>
          </Row>
          {/* 
          <Row className="platform-advantage">
            <AdvantageOfPlatformDetails />
          </Row> 
          */}
          <Row>
            <SecureYourFuture isChinese={false} />
          </Row>
          <Row className="page-blank-row" />
        </Container>
        <Footer />
      </div>
      </div>
    );
  }
}

export default Platform;
