import React from 'react'
import { Container, Card, Image } from "react-bootstrap"
import StripeCheckout from 'react-stripe-checkout'

import ENDPOINT from './Endpoint'

import Bank from '../assets/submodal/bank.svg'
import Hands from '../assets/submodal/hands.svg'
import Peace from '../assets/submodal/peace.svg'
import Improve from '../assets/submodal/improve.svg'
import Protection from '../assets/submodal/protection.svg'

const brandColor = "#00A99D"

export default class SubModal extends React.Component{
    state={
        shouldShowPaymentForm: true,
        subSuccessful: false,
        isSmallScreen: false,
        subStatus: this.props.subStatus
    }

    subscribe = () => {
        const{shouldShowPaymentForm} = this.state
        this.setState({shouldShowPaymentForm: !shouldShowPaymentForm})
    }

    _upgradeSubscription =(upgradeTrueDowngradeFalse)=>{
        const body ={'productType': upgradeTrueDowngradeFalse ? 'ar_wealth_management':'ar_generic'}    
        let URL = `${ENDPOINT}/stripe/subscription/activate`

        fetch(URL, {
            method: 'POST',
            'Access-Control-Allow-Headers': { 
                'Access-Control-Request-Headers': "*",
                'Access-Control-Allow-Origin': "*",
                "Access-Control-Allow-Methods": "*",
                'Access-Control-Allow-Credentials' : true,
            },
            headers: {
                Authorization: "Bearer " + this.props.user.idToken.jwtToken
            //     'Content-Type': 'application/json',
            //     'Cache-Control': 'no-cache',
            //     'Access-Control-Allow-Origin': "*",
            },
            body: JSON.stringify(body)
        })
        .then(response => {
                return response.json();
            })
            .then(responseJson => {
                var succeed = responseJson.status
                if(succeed){
                    this.setState({subSuccessful: true})
                }else{
                    console.log('error', responseJson)
                    alert("We either don't have your card on file or your card on file has expired, please add a new credit card")
                    this.setState({subStatus:false})
                }
            })
            .catch(err =>{
                alert('Something went wrong, please double check your card info or try a different card.')

            });
    }

    _sendUpdateSubscribeToBackend = async (token, upgrade) => {
        const{email} = this.props.userEmail
        // console.log(token.id)
        const body = { 
            sourceId: token.id,
            email,
            origin:'web'
            // phone: phoneNumber
        };
        // console.log(this.state.userToken)
        fetch(`${ENDPOINT}/stripe/customer-sources/web`, {
            method: 'POST',
            'Access-Control-Allow-Headers': { 
                'Access-Control-Request-Headers': "*",
                'Access-Control-Allow-Origin': "*",
                "Access-Control-Allow-Methods": "*",
                'Access-Control-Allow-Credentials' : true,
            },
            headers: {
                Authorization: "Bearer " + this.props.user.idToken.jwtToken
            //     'Content-Type': 'application/json',
            //     'Cache-Control': 'no-cache',
            //     'Access-Control-Allow-Origin': "*",
            },
            body: JSON.stringify(body)
        })
        .then(response => {
                return response.json();
            })
            .then(responseJson => {
                var succeed = responseJson.status
                if(succeed){
                    this.setState({subSuccessful: true})
                    this.props.updatePaymentStatus()
                }else{
                    alert('Something went wrong, please double check your card info or try a different card.')
                }
            })
            .catch(err =>{
                alert(
                    "Please check your card information and try again."
                )
            });
    }; // end _sendSubscribeToBackend

    checkoutButtonUpgrade = (basic)=>{
        return(
            <div style={{width:'30%', backgroundColor:'rgb(110,188,186)', borderRadius:10, margin:'auto', display:'flex', justifyContent:'center', alignItems:'center', padding: 5, marginBottom: basic? 50:0, cursor:'pointer'}}>
                <div onClick={()=>this._upgradeSubscription(true)}>
                    <p style={{color:'rgb(255,255,255)', margin:0}}>
                        Upgrade
                    </p>
                </div>
            </div>
        )
    }

    checkoutButtonStripe(basic){
        return(
            <div style={{width:'30%', backgroundColor:'rgb(110,188,186)', borderRadius:10, margin:'auto', display:'flex', justifyContent:'center', alignItems:'center', padding: 5, marginBottom: basic? 50:0, cursor:'pointer'}}>
                <StripeCheckout
                    name="AllocateRite" // the pop-in header title
                    description={basic?"Basic Subscription":'Wealth Management Subscription'} // the pop-in header subtitle
                    image={require("../assets/ar_logo_small.png")} // the pop-in header image (default none)
                    ComponentClass="div"
                    label="Confirm Purchase" // text inside the Stripe button
                    panelLabel="Subscribe" // prepended to the amount in the bottom pay button
                    amount={basic ? 9.99 * 100 : 29.99 * 100 } // cents
                    currency="USD"
                    stripeKey="pk_live_Z5TBzWXmsRTQXelPUooM7GhI"
                    locale="us"
                    // Note: Enabling either address option will give the user the ability to
                    // fill out both. Addresses are sent as a second parameter in the token callback.
                    shippingAddress
                    billingAddress={false}
                    // Note: enabling both zipCode checks and billing or shipping address will
                    // cause zipCheck to be pulled from billing address (set to shipping if none provided).
                    zipCode={false}
                    alipay={false} // accept Alipay (default false)
                    bitcoin={false} // accept Bitcoins (default false)
                    allowRememberMe={false} // "Remember Me" option (default true)
                    token={(token)=>{
                        this._sendUpdateSubscribeToBackend(token)
                    }} // submit callback
                    opened={this.onOpened} // called when the checkout popin is opened (no IE6/7)
                    closed={this.onClosed} // called when the checkout popin is closed (no IE6/7)
                    // Note: `reconfigureOnUpdate` should be set to true IFF, for some reason
                    // you are using multiple stripe keys
                    reconfigureOnUpdate={false}
                    // Note: you can change the event to `onTouchTap`, `onClick`, `onTouchStart`
                    // useful if you're using React-Tap-Event-Plugin
                    triggerEvent="onClick"
                    >
                    <div>
                        <p style={{color:'rgb(255,255,255)', margin:0}}>
                            Subscribe
                        </p>
                    </div>
                    </StripeCheckout>
            </div>
        )
    }

    buildSubscriptionCard = () => {
        const{shouldShowPaymentForm, subSuccessful, isSmallScreen}=this.state
        // if(stage > 4){
        return(
            <Card className="registration-card" style={{marginTop: 50, padding: 20}}>
                <div style={{
                        marginLeft: 20, 
                        marginRight: 20, 
                        // display: "flex",
                        // justifyContent: "center", 
                        alignItems: 'center',
                        padding: 20,
                        width: isSmallScreen ? '100%' : '75%'
                    }}
                >
                    <p style={{marginTop: 8, fontWeight: '600', textAlign: 'left', fontSize: isSmallScreen?16:20}}>Subscribe to AllocateRite:</p>
                    <h5 style={{color: '#00A99D'}}>Enjoy UNLIMITED AllocateRite access FOR LIFE and get COMMISSION-FREE Planner Securities trades.</h5>
                    <h2 style={{color: '#00A99D', fontWeight: 'bold', textAlign: 'center'}}>For only $9.99 per month.</h2>

                    <div style={{display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'row'}}>
                        { subSuccessful ? 
                            <h5 style={{color: '#00A99D', marginTop: 50}}>(You have successfully subscribed.)</h5>
                            : shouldShowPaymentForm ? 
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <button style={{ color: '#00A99D', paddingLeft: 30, paddingRight: 30, paddingTop: 5, paddingBottom: 5, marginRight: 20}}
                                    onClick={this.subscribe} 
                                >
                                    Cancel
                                </button>
                                <StripeCheckout 
                                    stripeKey={'pk_live_Z5TBzWXmsRTQXelPUooM7GhI'}
                                    // stripeKey={'pk_test_7gJdr9OXIGK3QCgELwzeE3dd'}
                                    token={(token)=>{
                                        this._sendUpdateSubscribeToBackend(token)
                                    }}
                                    billingAddress
                                    amount={9.99 * 100}
                                    name={'Monthly Subscription'}
                                />
                            </div> : 
                            <button 
                                style={{backgroundColor: 'rgb(110,188,186)', color: 'rgb(255,255,255)', paddingLeft: 30, paddingRight: 30, paddingTop: 5, paddingBottom: 5,}}
                                onClick={this.subscribe} 
                            >
                                Subscribe Now
                            </button>
                        }
                    </div>
                </div>
            </Card>
        )
    }

    showUpgradeVsshowSubscribe = ()=>{
        // console.log('substatus: ',this.props.subStatus)
        if(this.state.subStatus === 'subscribed'){ 
            return true
        }else{
            return false
        }
    }

    basicSub = ()=>{
        return(
            <div style={{display:'flex', flexDirection:'column', border:'solid',borderWeight:.25, borderColor:'rgb(200,200,200)', paddingLeft: '5%',paddingRight:'5%',paddingTop:'2%',paddingBottom:'2%',borderRadius:10, width:this.checkBasicSub()?'80%':'100%', height:'100%'}}>
                        <div style={{ border:'solid',borderWeight:.25, borderColor:'rgb(200,200,200)', borderRadius: 10, padding: '1%', backgroundColor: 'rgb(110,188,186)', marginBottom: 20}}>
                            <div style={{marginBottom: 10, padding:10}}>
                                <p style={{color:'rgb(255,255,255)', fontSize: 20, fontWeight:'bold'}}>AllocateRite Basic</p>
                                <p style={{color:'rgb(255,255,255)', fontSize: 18, fontWeight:'bold'}}>1 month free trial*</p>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between', width:'95%', margin:'auto'}}>
                                <p style={{ margin:0}}>then <strong>$9.99 per month</strong></p>
                                <p style={{fontSize:'smaller', margin:0, color:'rgb(255,255,255)'}}>Cancel anytime</p>
                            </div>
                        </div>
                        <div>
                            <div style={{display:'flex', justifyContent: "flex-start", width: '50%', alignItems:'center'}}>
                                <img
                                    src={Bank}
                                    style={{width: 40, marginRight: 15}}
                                />
                                <p style={{margin:0}}>Multiple Accounts</p>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <p style={{color:'rgb(120,120,120)', margin: 0}}>
                                    Link Mutiple Accounts and Get Access to Professional Risk Analysis
                                </p>
                            </div>
                            <div style={{display:'flex', justifyContent: "flex-start", width: '55%', alignItems:'center'}}>
                                <img
                                    src={Protection}
                                    style={{width: 40, marginRight: 15}}
                                />
                                <p style={{margin:0}}>Price Trend Prediction</p>
                            </div>
                            <div>
                                <p style={{color:'rgb(120,120,120)', margin: 0}}>
                                    Get access to price trend predictions of thousands of stocks worldwide
                                </p>
                            </div>
                          
                            <div style={{marginTop: 10}}>
                                <p style={{color:'rgb(120,120,120)', margin: 0}}>
                                    *Trial begins immediately after you create an account with AllocateRite. You will be only changed if you subscribe.
                                </p>
                            </div>
                            <div style={{marginTop: 20, marginBottom: 125}}>
                                {this.showUpgradeVsshowSubscribe() ? this.checkoutButtonUpgrade(true):this.checkoutButtonStripe(true) }
                            </div>
                        </div>
                    </div>
        )
    }

    checkBasicSub = ()=>{
        return this.props.subLevel === 'arapp_monthly' || this.props.subLevel === 'prod_FaN0loj0oRP7de'
    }
    
    wmSub = ()=>{
        return(
            <div style={{display:'flex', flexDirection:'column', border:'solid',borderWeight:.25, borderColor:'rgb(200,200,200)', paddingLeft: '5%',paddingRight:'5%',paddingTop:'2%',paddingBottom:'2%',borderRadius:10, width:this.checkBasicSub()?'80%':'100%', height:'100%', margin:'auto'}}>
                    <div style={{ border:'solid',borderWeight:.25, borderColor:'rgb(200,200,200)', borderRadius: 10, padding: '1%', backgroundColor: 'rgb(110,188,186)', marginBottom: 20}}>
                            <div style={{marginBottom: 10, padding:10}}>
                                <p style={{color:'rgb(255,255,255)', fontSize: 20, fontWeight:'bold'}}>Wealth Management</p>
                                <p style={{color:'rgb(255,255,255)', fontSize: 18, fontWeight:'bold', margin:0}}>Includes all features of AllocateRite Basic and More!</p>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between', width:'95%', margin:'auto'}}>
                                <p style={{ margin:0}}><strong>$29.99 per month</strong></p>
                                <p style={{fontSize:'smaller', margin:0, color:'rgb(255,255,255)'}}>Cancel anytime</p>
                            </div>
                        </div>
                        <div>
                            <div style={{display:'flex', justifyContent: "flex-start", width: '60%', alignItems:'center'}}>
                                <img
                                    src={Bank}
                                    style={{width: 40, marginRight: 15}}
                                />
                                <p style={{margin:0}}>Investing Made Right</p>
                            </div>
                            <div style={{marginBottom: 10}}>
                                <p style={{color:'rgb(120,120,120)', margin: 0}}>
                                    Invest in our Signature Robo-Strategies
                                </p>
                            </div>
                            <div style={{display:'flex', justifyContent: "flex-start", width: '55%', alignItems:'center'}}>
                                <img
                                    src={Protection}
                                    style={{width: 40, marginRight: 15}}
                                />
                                <p style={{margin:0}}>Financial Security</p>
                            </div>
                            <div style={{marginBottom:7}}>
                                <p style={{color:'rgb(120,120,120)', margin: 0}}>
                                    AI-Optimized Allocations Help Protect Against Risk
                                </p>
                            </div>
                            <div style={{display:'flex', justifyContent: "flex-start", width: '55%', alignItems:'center'}}>
                                <img
                                    src={Improve}
                                    style={{width: 40, marginRight:15}}
                                />
                                <p style={{margin:0}}>Improve Your Portfolio</p>
                            </div>
                            <div style={{marginBottom:7}}>
                                <p style={{color:'rgb(120,120,120)', margin: 0}}>
                                    Minimize Your Risk while Increasing Your Returns
                                </p>
                            </div>
                            <div style={{display:'flex', justifyContent: "flex-start", width: '55%', alignItems:'center'}}>
                                <img
                                    src={Peace}
                                    style={{width: 40, marginRight:15}}
                                />
                                <p style={{margin:0}}>Peace of Mind</p>
                            </div>
                            <div style={{marginBottom:7}}>
                                <p style={{color:'rgb(120,120,120)', margin: 0}}>
                                    Save Your Portfolio and our Automated Rebalancing Will Keep Your Portfolio In Tune with the Market
                                </p>
                            </div>
                            <div style={{display:'flex', justifyContent: "flex-start", width: '55%', alignItems:'center'}}>
                                <img
                                    src={Hands}
                                    style={{width: 40, marginRight:15}}
                                />
                                <p style={{margin:0}}>Convience</p>
                            </div>
                            <div>
                                <p style={{color:'rgb(120,120,120)', margin: 0}}>
                                Submit the trades Provided and You're Done Until Next Rebalance
                                </p>
                            </div>
                            <div style={{marginTop:25}}>
                                {this.showUpgradeVsshowSubscribe() ?this.checkoutButtonUpgrade(false):this.checkoutButtonStripe(false)}
                            </div>
                        </div>
                    </div>
        )
    }

    setTopText = ()=>{
        if(this.checkBasicSub()){
            return(
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                    <p style={{fontSize:22, }}>{`To access ${this.props.activeTab}`}</p>
                    <p style={{fontSize:22,margin:'auto' }}>Upgrade to Wealth Management Subscription</p>
                </div>
            )
        }
        else{
            return(
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                    <p style={{fontSize:22,margin:'auto' }}>Subscribe to AllocateRite</p>
                    <p style={{fontSize:22, }}>Pick the right plan for you, upgrade or downgrade at any time</p>
                </div>
            )
        }
    }

    multipleSubOptions = ()=>{
        return(
            <div style={{width: '90%', display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column', margin:'auto'}}>
                {this.setTopText()}
                <div style={{display:'flex', justifyContent:'space-around', alignItems:'flex-start',width:'100%'}}>
                    <div style={{width: this.checkBasicSub()? '0%':'100%'}}>
                        {this.checkBasicSub() ? null :this.basicSub()}
                    </div>
                    <div style={{width: '100%', marginLeft:5}}>
                        {this.wmSub()}
                    </div>
                </div> 
            </div>
        )
    }

    render(){
        // console.log('props', this.props)
        return(
            <div>
                {/* {this.buildSubscriptionCard()} */}
                {this.multipleSubOptions()}
            </div>
        )
    }
}