import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, } from "react-router-dom";
import { createBrowserHistory } from "history";

import ReactGA from "react-ga";

/* Layout */
import Header from "./components/layout/Header";

/* Page */
import Home from "./components/pages/Home";
import AiHome from "./components/pages/AiHome"
import Home2 from "./components/pages/Home2"
import Platform from "./components/pages/Platform";
import Strategies from "./components/pages/Strategies";
import Insights from "./components/pages/Insights";
// import Login from "./components/pages/Login";
import Login from "./components/pages/Login2";
// import SignUp from "./components/pages/SignUp";
import SignUp from "./components/pages/SignUp2";
import WatchList from "./components/pages/UserWatchList";
// import UserDashboard from "./components/pages/UserDashboard";
import Dashboard from "./components/pages/Dashboardv2";
import VideoBay from "./components/pages/VideoBay";
import Team from "./components/pages/Team";
import FreeTrial from "./components/pages/FreeTrial";
import Contact from "./components/pages/Contact"
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import EULA from "./components/pages/EULA";
import TermsOfUse from "./components/pages/TermsOfUse";
import GeneralDisclaimer from "./components/pages/GeneralDisclaimer";
import Disclosures from "./components/pages/Disclosures";
import InvestingTerms from "./components/pages/InvestingTerms";
import AllocateRiteForecastDisclaimer from './components/pages/AllocateRiteForecastDisclaimer'
import USDomestic from "./components/pages/USDomestic";
import Careers from "./components/pages/Careers";
import DiversifiedInternational from "./components/pages/DiversifiedInternational";
import DynamicGlobalBlend from "./components/pages/DynamicGlobalBlend";
import DisclaimersDisclosures from "./components/pages/DisclaimersDisclosures";
import ProvideList from "./components/pages/ProvideList";
import UnderConstruction from "./components/pages/UnderConstruction";
import ChineseHome from "./components/pages/ChineseHome";
import ChinesePrivacyPolicy from "./components/pages/ChinesePrivacyPolicy";
import ChineseTermsOfUse from "./components/pages/ChineseTermsOfUse";
import ChineseGeneralDisclaimer from "./components/pages/ChineseGeneralDisclaimer";
import ChinesePlatform from "./components/pages/ChinesePlatform";
import ChineseStrategies from "./components/pages/ChineseStrategies";
import ChineseTeam from "./components/pages/ChineseTeam";
import ChineseInsights from "./components/pages/ChineseInsights";
import AccountRegistrationForm from './components/pages/AccountRegistrationForm'
// import CompanySearch from './components/pages/CompanySearchPage'
import CompanyPage from './components/pages/Cp3'
// import CompanyPage from './components/pages/CompanyPage2'
// import CompanyPage from './components/pages/CompanyPage'
import GoogleHome from './components/pages/GoogleHome'
import Search2 from './components/pages/Search2'
import NoMatch from './components/pages/NoMatch'
import CreateDwAcc from "./components/pages/DwCreate"
import Transfer from "./components/pages/Transfers"
import Questionnaire from "./components/pages/Questionnaire"
import UpdateDw from "./components/pages/UpdateDw"

/* Style sheet */
import "./App.scss";
import "./components/layout/Style/style.scss";

/** AWS AUTH  */
import Amplify, { Auth } from 'aws-amplify'
import awsconfig from './aws-config';
import { withAuthenticator } from 'aws-amplify-react';


// import Test from "./components/pages/Test";



// var USDomesticFactSheet = require( "./assets/factsheets/US-Domestic-Fact-Sheet.pdf")
// var USDLFactSheet = require( "./assets/factsheets/US-Dynamic-Leverage-Fact-Sheet.pdf")

const history = createBrowserHistory();

ReactGA.initialize("UA-101523183-1");
ReactGA.pageview("/");
require("typeface-ubuntu");


//--------WHICH USER POOL? -------------------------
Amplify.configure(awsconfig.prod)    //PRODUCTION  |
// Amplify.configure(awsconfig.dev) //DEV          |
//--------------------------------------------------


export class App extends Component { 

  state={
    user:null,
    errors: false,
    isSmallScreen: false,
  }

  componentDidMount(){
    this.updateWindowDimensions()
    if (this.state.user === null){
      Auth.currentSession()
      .then(resp => {
        this.setState({ user: resp })
      })
    }
  }

  signIn = (username, password) => {
        let user = false
        let error = ''
        Auth.signIn(username, password)
        .catch(error => {
          switch(error.message) {
            case 'User does not exist.':
              error = 'Username not found'
              break;
            case 'Incorrect username or password.':
              error = 'Incorrect Password'
              break;
            default:
              // code block
          }
          this.setState({errors: error})
        }
        )
        .then(Auth.currentSession())
        .then(resp => {
          console.log('login resp', resp)
          user = resp
          this.setState({ user })
        })
  }

  signOut = ()=> {
    try {
      const response =  Auth.signOut();;
      this.setState({ user: null });
      window.location.replace("/")
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth });
    }
  };

  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <div className="App">
          {/* <Header hiddenSearch={}/> */}
          <div className="">
            <Switch>
              {/* FORMER HOME PAGE  */}
              {/* <Route exact path="/" component={() => <GoogleHome signOut={this.signOut} user={this.state.user}/>} /> */}
              
              {/*.ai HOME PAGE*/} 
              {/* <Route exact path="/" component={() => <AiHome errors={this.state.errors} signIn={this.signIn} signOut={this.signOut} user={this.state.user}/>} /> */}
              
              {/* .com HOME PAGE  */}
              <Route exact path="/" component={()=><Home2 signOut={this.signOut} user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/About" component={()=><Home signOut={this.signOut} user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Careers" component={()=><Careers  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Login" component={(props) => <Login prps={props} errors={this.state.errors} signIn={this.signIn} signOut={this.signOut} user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Login/:tick" component={() => <Login errors={this.state.errors} signIn={this.signIn}  signOut={this.signOut} user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/SignUp" component={() => <SignUp signIn={this.signIn} user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Watchlist" component={() => <WatchList signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route path="/Dashboard" component={() => <Dashboard errors={this.state.errors} signIn={this.signIn} signOut={this.signOut} user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route path="/Dashboard/:tab" component={() => <Dashboard errors={this.state.errors} signIn={this.signIn} signOut={this.signOut} user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/platform" component={()=><Platform  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Strategies" component={()=><Strategies  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              {/* <Route exact path="/Contact" component={()=><Contact  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/> */}
              <Route exact path="/Insights" component={()=><Insights  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Videos" component={()=><VideoBay  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Contact" component={()=><Contact  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              {/* <Route exact path="/Test" component={()=><Test  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/> */}
              <Route exact path="/Team" component={()=><Team isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/FreeTrial" component={()=><FreeTrial  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/PrivacyPolicy" component={()=><PrivacyPolicy  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/EULA" component={()=><EULA  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/TermsOfUse" component={()=><TermsOfUse  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/GeneralDisclaimer" component={()=><GeneralDisclaimer  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Disclosures" component={()=><Disclosures  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/InvestingTerms" component={()=><InvestingTerms  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/AllocateRiteForecastDisclaimer" component={()=><AllocateRiteForecastDisclaimer  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Register" component={()=><AccountRegistrationForm  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/DynamicGlobalBlend" component={()=><DynamicGlobalBlend  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/USDomestic" component={()=><USDomestic  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/DiversifiedInternational" component={()=><DiversifiedInternational  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/DisclaimersDisclosures" component={()=><DisclaimersDisclosures  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/ProvideList" component={()=><ProvideList  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/CompanySearch" component={() => <GoogleHome signOut={this.signOut} user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/CompanyPage/:ticker" component={()=><CompanyPage location={this.props} signOut={this.signOut} user={this.state.user}  isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Transfer" component={()=><Transfer location={this.props} signOut={this.signOut} user={this.state.user}  isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/Questionnaire" component={()=><Questionnaire location={this.props} signOut={this.signOut} user={this.state.user}  isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/UpdateDw" component={()=><UpdateDw location={this.props} signOut={this.signOut} user={this.state.user}  isSmallScreen={this.state.isSmallScreen}/>}/>

              <Route exact path="/CreateDw" component={()=><CreateDwAcc location={this.props} signOut={this.signOut} user={this.state.user}  isSmallScreen={this.state.isSmallScreen}/>}/>

              {/* CHINESE */}
              <Route exact path="/cn/About" component={()=><ChineseHome  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/cn/PrivacyPolicy" component={()=><ChinesePrivacyPolicy  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/cn/TermsOfUse" component={()=><ChineseTermsOfUse  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/cn/GeneralDisclaimer" component={()=><ChineseGeneralDisclaimer  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/cn/Platform" component={()=><ChinesePlatform  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/cn/Strategies" component={()=><ChineseStrategies  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/cn/Team" component={()=><ChineseTeam  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route exact path="/cn/Insights" component={()=><ChineseInsights  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>

              {/* UNDER CONSTRUCTION OR NON EXISTANT */}
              <Route exact path="/UnderConstruction" component={()=><UnderConstruction  signOut={this.signOut}user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
              <Route component={()=><UnderConstruction signOut={this.signOut} user={this.state.user} isSmallScreen={this.state.isSmallScreen}/>}/>
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}
export default App;
