import React from 'react'
import {  FaSearch, FaShareSquare } from "react-icons/fa";
import NoImage from "../../assets/newUI/logo_notfound.png"
import { NavLink } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'

import ENDPOINT from '../Endpoint'


export default class TradeModal extends React.Component{
    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.state={
            canTrade:false,
            //step 0
            noSelection:false,
            acc:this.props.acc,
            allAccounts:[],
            holdings:[],
            itemsResp:[],
            // step 1
            searchMomentumText:'',
            momDataSource: [],
            theme:'most_active',
            isSearching: false,
            selected: this.props.selected,
            hover:'',
            //step 2
            price: '',
            quantity:'',
            pcnt:'',
            buySell: false,
            priceType: 'MARKET',
            limitPrice: '0',
            purchaseMethod: 'numShares',
            errors:'',
            lastChanged:'price',
            //step 3
            summary: false,
            orderPlaced: false,
            sharesOwned:0,
            orderStatus:'new',
            orderId:'',
            orderResp:false,
            // all
            titleText:'',
        }
    }

    componentDidMount(){
        if(this.props.user && this.props.acc === 'NO'){
            // this.getPlaidItems()
            this.getAllAccountInfo()
            // this.getPlaidHoldings()
            this.getLivePrice()
            this.setState({theme:'most_active'})
        }
    }

    startPriceTimer = ()=>{
        // console.log('this.state.orderStatus',this.state.orderStatus)
        // console.log('this.state.selected.symbol',this.state.selected.symbol)
        // console.log('this.state.summary',this.state.summary)
        if(this.state.orderStatus === 'new' && this.state.selected.symbol && !this.state.summary){
            // console.log('starting price timer')
            setTimeout(()=>this.getLivePrice(),60000)
        }else{
            console.log('stopping price timer')
        }
    }

    startTradeStatusTimer = ()=>{
        if(this.state.orderStatus === 'new'){
            // console.log('starting trade status timer')
            setTimeout(()=>this.checkOrderStatus(),1000)
        }else{
            console.log('stopping trade status timer')
        }
    }

    getLivePrice = (sym)=>{
        let url = `${ENDPOINT}/risk-monkey/ticker/`;
        if(this.state.selected){
            url = url + `${this.state.selected.symbol.toUpperCase()}/live`
        }else{
            url = url + `${sym.toUpperCase()}/live`
        }
        fetch(url, {
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
        })
        .then((res) => res.json())
        .then((responseJson) => {
            // console.log('getLivePrice:', responseJson)
            this.setState({selected:{...this.state.selected,live:responseJson}})
            this.startPriceTimer()
        })
    }

    getNBBOPrice = (sym)=>{
        console.log('getting NBBO')
        let url = `${ENDPOINT}/risk-monkey/ticker/${sym}/live?nbbo=true`;
        fetch(url, {
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
        })
        .then((res) => res.json())
        .then((responseJson) => {
            // console.log('getNBBO:', responseJson)
            this.setState({selected:{...this.state.selected,live:responseJson}})
        })
    }

    onSubmitSearchMomentum = () => {
        const { searchMomentumText } = this.state;
        this.setState({ isMomDataLoading: true });
        // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
        let url = `${ENDPOINT}/search/ticker?search=${searchMomentumText}`;
        fetch(url)
            .then((res) => res.json())
            .then((responseJson) => {
                var data = responseJson.content;
                this.setState({
                    isMomDataLoading: false,
                    momDataSource: data,
                });
            })
            .catch((err) => {
                this.setState({ isMomDataLoading: false });
                console.log(err);
            });
    };


    getPlaidItems = ()=>{ // an Item is all the accounts at one place (multiple accounts at robinhood)
        fetch(`${ENDPOINT}/plaid/items`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => {
            //   console.log('plaid items resp: ',responseJson)
              this.setState({itemsResp: responseJson})
            })
          .catch(err=>console.log(`catching errors`, err))
    }

    getPlaidHoldings = ()=>{ // all holdings of all linked accounts combined
        fetch(`${ENDPOINT}/plaid/holdings`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => this.setState({holdings: responseJson}))
          .catch(err=>console.log(`catching errors`, err))
    }

    checkOrderStatus = ()=>{
        let orderId = this.state.orderId
        fetch(`${ENDPOINT}/mobile/order-status?orderID=${orderId}`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson =>{
            //   console.log('check order status success resp: ',responseJson)
              this.setState({orderStatus:responseJson.status, orderResp:responseJson})
            })
          .catch(err=>console.log(`check order status error: `, err))
    }

    combineAccountsAndHoldings = ()=>{
        let holdings = {}
        this.state.holdings.forEach(ele=>{
            if(holdings[ele.accountId]){
                holdings[ele.accountId].push(ele)
            }
            else{
                holdings[ele.accountId] = [ele]
            }
        })
        let accounts = []
        this.state.itemsResp.forEach(inst=>{
            inst.accounts.forEach(account=>{
                accounts.push({...account,inst:inst.instName, holdings:holdings[account.accountId]? holdings[account.accountId]: []})
            })
        })
        // console.log('all accounts with holdings', accounts)
        return accounts
    }


    displayErrors = ()=>{
        if(this.state.errors){
            return(
                <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', marginBottom:25}}>
                    <div style={{padding:20, backgroundColor:'rgb(238,139,132)', borderRadius:15}}>
                        <p style={{margin:0}}>
                            {this.state.errors}
                        </p>
                    </div>
                </div>
            )
        }
    }


    checkLogo = (symbol)=>{
        let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        if (http.status != 404){
            return true
        }
        else{
            return false
        }
    }

    createBox = (ele)=>{
        // console.log('inside create box', ele)
            return (
                <div onClick={()=>{
                    console.log('in cretae box on click')
                    if(ele.isDwSupported){
                        console.log('is dw supported?', ele.isDwSupported)
                        this.owned(this.state.acc,ele.symbol)
                        this.getLivePrice(ele.symbol)
                        this.setState({searchMomentumText:'', selected: ele, limitPrice:ele.priceUSD.toFixed(2)})
                    }else{
                        this.setState({errors:`DriveWealth does not currently support ${ele.symbol}`})
                    }
                }}
                onMouseEnter={()=>this.setState({hover:ele})}
                onMouseLeave={()=>this.setState({hover:''})}
                style={{width:100,height:175, marginBottom:10, marginRight:15}}
                // style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
            >
                <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:this.state.hover === ele? 'rgb(225,225,225)':'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:100, height:100,justifySelf:'flex-end',border:'solid',borderWidth:'0.25px' }}>
                {ele.imageURL === "not-found"?
                    <div style={{objectFit:'contain', width:50,height:50,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(17,17,17)',borderRadius:10 }}
                    >
                        <p style={{margin:0, fontSize:24,fontWeight:'bold', color:'rgb(255,255,255)'}}>{ele.symbol[0]}</p>
                    </div>
                    :
                    <img 
                        // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
                        src={ele.imageURL}
                        style={{objectFit:'contain', width:80,height:80,padding:10, }}
                    />}
                </div>
                <div style={{display:'flex', flexDirection: 'column', width:'100%', padding:10 }}>
                    <p style={{ margin: 0, textAlign: 'center', fontWeight:'bold' }}>{ele.symbol}</p>
                    {/* <p style={{ margin: 0, textAlign: 'center',overflow: 'hidden',  textOverflow: 'ellipsis' }}>{ele.company}</p> */}
                </div>
            </div>
                )
        
    }
    
    getHeight = ()=>{
        let totalHeight = window.innerHeight
        return totalHeight*.7
    }

    selectStock = ()=>{
        if(this.state.titleText !== 'Make a Trade'){
            this.setState({titleText: 'Make a Trade'})
        }
        let data = []
        if(this.props.presetTickers.most_active){
            data = this.props.presetTickers[this.state.theme]
        }
        let mapped = data.map(ele=>this.createBox(ele))
        return(
            <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12, marginBottom:25}}>
                        {/* <div style={{width:'90%', margin:'auto', marginTop: 25, marginBottom: 25}}>
                            <p style={{fontSize:22}}>
                                Search for any stock by company name or symbol
                            </p>
                            <p style={{fontSize:22}}>
                                Click on any result to invest in that company
                            </p>
                        </div> */}
                        
                    <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',}}>
                        <div
                            style={{
                                backgroundColor: 'rgb(255,255,255)', width: '75%', marginBottom: 20, borderRadius: 20, paddingLeft: 10, display: 'flex', justifyContent: 'flex-start', boxShadow: this.state.hover ? '0 0 10px rgb(223,225,229)' : 'none', borderColor: 'rgb(237,238,240)', borderStyle: 'solid', borderWidth: 'thin', minWidth: 250, 
                            }}
                            onMouseEnter={() => this.setState({ hover: 'input' })}
                            onMouseLeave={() => this.setState({ hover: false })}
                        >
                            <div style={{ minWidth: 20, width: '5%', /*backgroundColor: '#00A99D',*/ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                                <FaSearch size={'50%'} style={{color: '#dddddd'}} />                        
                            </div>
                            <input 
                                ref={this.input}
                                style={{ width: '90%', border: 'none', outline: 'none', paddingTop: 5, paddingBottom: 5}}
                                placeholder="Enter stock symbol"
                                type="text" 
                                value={this.state.searchMomentumText} 
                                onChange={(event) => {
                                let searchMomentumText = event.target.value.toUpperCase();
                                this.setState({ searchMomentumText, selectedPreset: null,errors:false }, () => {
                                    const { isSearching } = this.state;
                                    if (isSearching) {
                                        return;
                                    } else {
                                        this.setState({ isSearching: true });
                                        setTimeout(() => {
                                            this.setState(
                                                { isSearching: false },
                                                this.onSubmitSearchMomentum
                                            );
                                        }, 2000);
                                    }
                                });
                            }} />
                        </div>
                    </div>
                    <div style={{width:'100%',marginBottom:10, marginTop:10}}>
                        <div style={{width:'95%', margin:'auto', display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
                            <div style={{paddingLeft:15, paddingRight:15, paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==="most_active"?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'most_active'})}>
                                <p style={{margin:0}}>Top 30</p>
                            </div>
                            <div style={{paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==='Energy'?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'Energy'})}>
                                <p style={{margin:0}}>Energy</p>
                            </div>
                            <div style={{paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==='Communications'?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'Communications'})}>
                                <p style={{margin:0}}>Comms</p>
                            </div>
                            <div style={{paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==='ETFs'?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'ETFs'})}>
                                <p style={{margin:0}}>ETFs</p>
                            </div>
                            <div style={{paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==='Finance'?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'Finance'})}>
                                <p style={{margin:0}}>Finance</p>
                            </div>
                            <div style={{paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==='SaaS'?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'SaaS'})}>
                                <p style={{margin:0}}>Saas</p>
                            </div>
                            <div style={{paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==='Cannabis'?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'Cannabis'})}>
                                <p style={{margin:0}}>Cannabis</p>
                            </div>
                            <div style={{paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==='EV'?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'EV'})}>
                                <p style={{margin:0}}>EV</p>
                            </div>
                            <div style={{paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==='Recovery'?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'Recovery'})}>
                                <p style={{margin:0}}>Recovery</p>
                            </div>
                            <div style={{paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==='Semis'?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'Semis'})}>
                                <p style={{margin:0}}>Semis</p>
                            </div>
                            <div style={{paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10, cursor:'pointer', borderRadius:10,marginRight:5,marginBottom:5, backgroundColor:this.state.theme==='Healthcare'?'rgb(218,240,240)':'rgb(238, 238, 238)'}} onClick={()=>this.setState({searchMomentumText:'',theme:'Healthcare'})}>
                                <p style={{margin:0}}>Healthcare</p>
                            </div>
                        </div>
                    </div>
                        <div style={{maxHeight: 350, overflowY:'scroll', paddingLeft:20, paddingRight:20, marginTop:20, minHeight:this.getHeight()}}>
                        {this.state.searchMomentumText.length > 0? 
                            this.state.isSearching? 
                            <div style={{width:'100%', marginTop:75}}>
                                <p style={{textAlign:'center', fontSize:22, fontWeight:'bold'}}> Loading...</p>
                            </div>:
                            <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center',justifyContent:'center', flexWrap:'wrap', marginLeft:15, }}>
                                {this.state.momDataSource.map(ele =>(this.createBox(ele)))}
                            </div>
                        :
                        <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center',justifyContent:'center', flexWrap:'wrap', marginLeft:15, }}>
                            {mapped.length === 0 ? 
                                <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                                :
                                 mapped
                            }   
                        </div>
                        }
                        {this.displayErrors()}
                        
                        </div>
                    </div>
        )
    }

    owned = (account, symbol)=>{
        let total = 0
        account.holdings.forEach(stock=>{
            if(stock.symbol === symbol){
                total= stock.quantity
            }
        })
        this.setState({sharesOwned:total})
        
    }

    cost = ()=>{
        if(this.state.purchaseMethod === 'numShares'){
            if(this.state.quantity > 0){
                return parseFloat(this.state.quantity)*this.state.selected.live.price
            }
            else{
                return 0
            }
        }
        else if(this.state.purchaseMethod === 'dollarAmt'){
            if(this.state.price > 0){
                return parseFloat(this.state.price)
            }
            else{
                return 0
            }
        }
        else{
            return this.availableFunds() * parseFloat(this.state.pcnt)
        }
    }

    setBuyPercent = (e)=>{
        let shareprice 
        if(e.target.value === '.'){
            this.setState({price:0, quantity:0, pcnt:'0.', lastChanged:'cost',errors:''})
        }
        else if(this.numberOnlyInput(e.target.value)){
            if(this.state.priceType === 'MARKET'){
                shareprice = this.state.selected.live.price
            }
            else{
                shareprice = this.state.limitPrice
            }
            let price = ((this.availableFunds()*e.target.value)/100).toFixed(2)
            let quantity = (price/shareprice).toFixed(8)
            // if(quantity <= 10000){
                this.setState({pcnt:e.target.value, quantity, price, lastChanged:'percent', errors:''})
            // }
            // else{
            //     this.setMaxShares()
            // }
        }
    }

    setMaxShares =()=>{
        let shareprice
        if(this.state.priceType === 'MARKET'){
            shareprice = this.state.selected.live.price
        }
        else{
            shareprice = this.state.limitPrice
        }
        let price = (10000 * shareprice).toFixed(2)
        let pcnt = (price/this.availableFunds()*100).toFixed(2)
        this.setState({price, quantity:10000, pcnt, lastChanged:'quantity',errors:'maximum order quanity is 10,000 shares'})
    }

    setBuyCost = (e)=>{ 
        let shareprice 
        if(e.target.value === '.'){
            this.setState({price:'0.', quantity:0, pcnt:0, lastChanged:'cost',errors:''})
        }
        else if(this.numberOnlyInput(e.target.value) ){
            if(this.state.priceType === 'MARKET'){
                shareprice = this.state.selected.live.price
            }
            else{
                shareprice = this.state.limitPrice
            }
            let pcnt = (e.target.value/this.availableFunds()).toFixed(2)
            let quantity = (e.target.value / shareprice).toFixed(8)
            // if(quantity <= 10000){
                this.setState({price:e.target.value, quantity, pcnt, lastChanged:'cost',errors:''})
            // }
            // else{
            //     this.setMaxShares()
            // }
        }
    }

    setLimit = (e)=>{ 
        let shareprice 
        if(e.target.value === '.'){
            this.setState({limitPrice:'0.'})
        }
        else if(this.numberOnlyInput(e.target.value) ){            
            this.setState({limitPrice:e.target.value})
        }
    }

    setBuyNumShares = (e)=>{ 
        let shareprice 
        if(e.target.value === '.'){

            this.setState({price:0, quantity:'0.', pcnt:0, lastChanged:'cost',errors:''})
        }
        else if(this.numberOnlyInput(e.target.value)){
            if(this.state.priceType === 'MARKET'){
                shareprice = this.state.selected.live.price
            }
            else{
                shareprice = this.state.limitPrice
            }
            let price = (e.target.value * shareprice).toFixed(2)
            let pcnt = (price/this.availableFunds()*100).toFixed(2)
            // if(e.target.value <= 10000){
            this.setState({price, quantity:e.target.value, pcnt, lastChanged:'quantity',errors:''})
            // }
            // else{
            //     this.setMaxShares()
            // }
        }
    }

    showShares = ()=>{
        if(this.state.quantity === '.' || this.state.quantity === '0.'){
            return '0.'
        }
        else if(this.state.quantity === ''){
            return ''
        }
        else{
            return parseFloat(this.state.quantity)
        }
    }

    setSellPercent =(e)=>{
        let shareprice 
        let mv
        if(e.target.value === '.'){
            this.setState({price:0, quantity:0, pcnt:'0.', lastChanged:'cost',errors:''})
        }
        else if(this.numberOnlyInput(e.target.value)){
            if(this.state.priceType === 'MARKET'){
                shareprice = this.state.selected.live.price
            }
            else{
                shareprice = this.state.limitPrice
            }
            mv = shareprice * this.state.sharesOwned
            let price = ((mv*e.target.value)/100).toFixed(2)
            let quantity = (parseFloat(e.target.value) * this.state.sharesOwned / 100).toFixed(8)
            // if(quantity <= 10000){
                this.setState({pcnt:e.target.value, quantity, price, lastChanged:'percent', errors:''})
            // }
            // else{
            //     this.setMaxShares()
            // }
        }
    }

    setSellCost =(e)=>{
        let shareprice
        let mv
        if(e.target.value === '.'){
            this.setState({price:'0.', quantity:0, pcnt:0, lastChanged:'cost',errors:''})
        }
        else{
            if(this.numberOnlyInput(e.target.value)){
                if(this.state.priceType === 'MARKET'){
                    shareprice = this.state.selected.live.price
                }
                else{
                    shareprice = this.state.limitPrice
                }
                mv = shareprice * this.state.sharesOwned
                let pcnt = (e.target.value/mv).toFixed(2)
                let quantity = (e.target.value / shareprice).toFixed(8)
                this.setState({price:e.target.value, quantity, pcnt, lastChanged:'cost',errors:''})
            }
        }
    }

    setSellNumShares =(e)=>{
        let shareprice
        if(e.target.value === '.'){
            console.log('in decimal')
            this.setState({price:0, quantity:'0.', pcnt:0, lastChanged:'cost',errors:''})
        }else{
            if(this.numberOnlyInput(e.target.value)){
                if(this.state.priceType === 'MARKET'){
                    shareprice = this.state.selected.live.price
                }
                else{
                    shareprice = this.state.limitPrice
                }
                let price = (e.target.value * shareprice).toFixed(2)
                let pcnt = ((e.target.value/this.state.sharesOwned)*100).toFixed(2)
                this.setState({price, quantity:e.target.value, pcnt, lastChanged:'quantity',errors:''})
            }
        }
    }


    numberOnlyInput(val){
        let goodNum = true
        let arr = val.split("")
        let acceptable = ["0","1","2","3","4","5","6","7","8","9"]
        let i =0
        let dots = 0
        while (i < arr.length) {
            if(arr[i] === '.' && dots === 0){
                dots = 1
                i++
            }
            else if(arr[i] === '.' && dots === 1){
                goodNum = false
                i+=arr.length
            }
            else if(acceptable.includes(arr[i])){
                i++
            }
            else{
                goodNum = false
                i+=arr.length
            }
          }
        return goodNum
    }

    purchaseInput = ()=>{
        if(this.state.purchaseMethod === 'numShares'){
            return (
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <p style={{margin: 0, marginRight:20, verticalAlign:'middle'}}>
                         Number of Shares
                    </p>
                    <input 
                        type="text" 
                        value={this.state.quantity} 
                        onChange={(e)=>{
                            if(this.numberOnlyInput(e.target.value)){
                                this.setState({quantity:e.target.value})
                            }
                        }
                    }/>
                </div>
            )
        }
        else if(this.state.purchaseMethod === 'dollarAmt'){
            return (
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <p style={{margin: 0, marginRight:20}}>
                        Price
                    </p>
                    <input 
                        type="text" 
                        value={this.state.price} 
                        onChange={(e)=>{
                            if(this.numberOnlyInput(e.target.value)){
                                this.setState({price:e.target.value})
                            }
                        }
                    }/>
                </div>
            )
        }
        else{
            return (
                <div style={{}}>
                    <p style={{margin:0, textAlign:'center'}}>
                        {(this.state.pcnt * 100).toFixed(0)}%
                    </p>
                    <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                        <input style={{}}type="range" 
                            min="0.01"
                            max="1" 
                            step={0.01}
                            value={this.state.pcnt}
                            onChange={e => this.setState({ pcnt:e.target.value })}
                        />
                    </div>
                </div>
            )
        }
    }

    addCommas(x){
        if(x === ''){
            return ''
        }else{
            let arr = x.toString().split(".")
            if(arr[1]){
                return (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))+ '.' + arr[1]
            }
            else{
                return  (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            }
        } 
    }

    setTrade = ()=>{
        // console.log('this.availableFunds()', this.availableFunds())
        // this.startTradeStatusTimer()
        if(this.state.titleText !== 'Make a Trade'){
            this.setState({titleText: 'Make a Trade'})
        }

        let price = this.state.selected.live && this.state.selected.live.price? this.addCommas(this.state.selected.live.price.toFixed(2)) :this.state.selected.priceUSD? this.addCommas(this.state.selected.priceUSD.toFixed(2)) :0
        let so = this.state.sharesOwned.toFixed(8).split('.')
        let sharesOwned = this.addCommas(so[0]) + '.' +so[1]
        let limit = this.state.limitPrice
        console.log('limit', limit)
        if(limit.split('.')[1]&&limit.split('.')[1].length > 2){
            parseFloat(limit).toFixed(2)
        }
        return(
        <div style={{width:'100%', }}>
           <div style={{display:'flex', flexDirection:'column', paddingLeft:'5%',paddingRight:'5%', paddingTop:'2%', paddingBottom:'2%', width:'100%',}}>
                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:100, height:100,justifySelf:'flex-end', }}>
                        <img 
                            src={`https://drivewealth.imgix.net/symbols/${this.state.selected.symbol.toLowerCase()}.png`}
                            src={this.state.selected.imageURL === "not-found"? NoImage:this.state.selected.imageURL}
                            style={{objectFit:'contain', width:100,height:100}}
                        />
                    </div>
                    
                </div>
                <div style={{display:'flex', justifyContent:'space-between',alignItems:'center' }}>
                    <p style={{margin:0, fontSize:28}}>
                        {this.state.selected.symbol} 
                    </p>
                    <p style={{margin:0, fontSize:24}}>
                        {this.state.acc? `${this.state.acc.inst} ${this.state.acc.mask}`:null} 
                    </p>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', }}>
                    <p style={{margin:0,overflow: 'hidden', textOverflow: 'ellipsis',}}>
                        {this.state.selected.company} 
                    </p>
                    <p style={{margin:0, color:'rgb(88,183,180)', fontWeight:'bold', textAlign:'right'}}>
                        <strong style={{fontWeight:'normal', color:'rgb(17,17,17)'}}>Shares Owned:{' '}</strong>
                        {parseFloat(sharesOwned) === 0 ? 0 :sharesOwned}
                    </p>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', }}>
                    <p style={{margin:0, overflow: 'hidden', textOverflow: 'ellipsis',}}>
                        {this.state.selected.industry} 
                    </p>
                    <p style={{margin:0, color:'rgb(88,183,180)', fontWeight:'bold', textAlign:'right'}}>
                        <strong style={{fontWeight:'normal', color:'rgb(17,17,17)'}}>Position Value:{' '}</strong>
                        ${this.state.sharesOwned === 0 ? 0 : this.addCommas((this.state.sharesOwned*price).toFixed(2))}
                    </p>
                    
                </div>
                <div style={{display:'flex', justifyContent:'space-between', }}>
                    <p style={{margin:0, color:'rgb(88,183,180)', fontWeight:'bold', textAlign:'left'}}>
                        <strong style={{fontWeight:'normal', color:'rgb(17,17,17)'}}>Price:{' '} </strong>
                        ${price} 
                    </p>
                    <p style={{margin:0, color:'rgb(88,183,180)', fontWeight:'bold', textAlign:'right'}}>
                        <strong style={{fontWeight:'normal', color:'rgb(17,17,17)'}}>Buying Power:{' '}</strong>${this.addCommas(this.availableFunds())}
                    </p>
                    
                </div>
                <div style={{display:'flex', justifyContent:'space-between', }}>
                    <p style={{margin:0, color:'rgb(88,183,180)', fontWeight:'bold', textAlign:'left'}}>
                        <strong style={{fontWeight:'normal', color:'rgb(17,17,17)'}}>30 Day Forecast*:{' '} </strong>
                        {this.state.selected.needOptimization? 
                        `$${this.addCommas((this.state.selected.returnForecast*this.state.selected.priceUSD+this.state.selected.priceUSD).toFixed(2))}`:'HIGH RISK'}
                    </p>
                    <p style={{margin:0, color:'rgb(88,183,180)', fontWeight:'bold', textAlign:'right'}}>
                        <strong style={{fontWeight:'normal', color:'rgb(17,17,17)'}}>Account Value:{' '}</strong>${this.addCommas(this.state.acc.balances[0].current.toFixed(2))}
                    </p>
                </div>
                <hr></hr>
                <div style={{paddingTop:0, display:'flex', alignItems:'center', width:'100%', cursor:'pointer',justifyContent:'space-around'}}>
                    <div  style={{borderRight:'solid', borderColor:'rgb(255,255,255)', borderWidth:'0.5px',width:'48%', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.buySell === 'buy'? 'rgb(52,131,129)':'rgb(200,200,200)', padding: 5, borderRadius:8 }}
                        onClick={()=>{
                            if(this.availableFunds() > .01){
                                this.setState({buySell:'buy',errors:''})
                            }
                            else{
                                this.setState({errors:'You do not have any cash to invest with'})
                            }
                        }}
                    >
                        <p style={{margin:0, color: this.state.buySell === 'buy'? 'rgb(255,255,255)':'',}}>Buy</p>
                    </div>
                    <div  style={{width:'48%', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.buySell === 'sell'? 'rgb(52,131,129)':'rgb(200,200,200)', padding: 5, borderRadius:8}}
                    onClick={()=>{
                        if(this.state.sharesOwned !== 0){
                            this.setState({buySell:'sell', errors:''})
                        }
                        else{
                            this.setState({errors:'You cannot sell shares you do not own'})
                        }
                    }}
                    >
                        <p style={{margin:0, color: this.state.buySell === 'sell'? 'rgb(255,255,255)':'', }}>Sell</p>
                    </div>
                </div>
                <div style={{paddingTop:10, display:'flex', alignItems:'center',justifyContent:'space-between', width:'100%', }}>
                    <p style={{margin:0, marginRight:25}}>
                        Price Type: 
                    </p>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center', paddingLeft:5, paddingRight:5,paddingTop:7,paddingBottom:7, background:'rgb(225,238,238)', borderRadius:5, /*width:'40%'*/}}>
                            <select placeholder="Select Below" onChange={(e)=>this.setState({priceType:e.target.value})} value={this.state.priceType} style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent',}}>
                                <option value='MARKET' selected={this.state.priceType == 'MARKET'}>Market Order</option>
                                <option value={'LIMIT'} selected={this.state.priceType == 'LIMIT'}>Limit Order</option>                
                                {/*                                 
                                <option value={'Stop on Quote'} selected={this.state.priceType == 'Stop on Quote'}>Stop on Quote</option>
                                <option value={'Stop Limit on Quote'} selected={this.state.priceType == 'Stop Limit on Quote'}>Stop Limit on Quote</option>
                                <option value={'Trailing Stop $'} selected={this.state.priceType == 'Trailing Stop $'}>Trailing Stop $</option>
                                <option value={'Trailing Stop %'} selected={this.state.priceType == 'Trailing Stop %'}>Trailing Stop %</option> 
                                */}
                            </select>
                        </div>
                </div>
                
                {this.state.priceType === 'LIMIT' ?
                    <div style={{paddingTop:10, display:'flex', alignItems:'center',justifyContent:'space-between', width:'100%', }}>
                        <p style={{margin:0, marginRight:25}}>
                            Limit Price: 
                        </p>
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'40%'}}>
                                <input 
                                    style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent'}}
                                    type="text" 
                                    value={limit} 
                                    onChange={(e)=>{
                                        this.setLimit(e)
                                    }
                                }/>
                        </div>
                    </div>
                    :null
                }
                
                {/* <div style={{paddingTop:10, width:'100%', }}>
                    
                    <div style={{display:'flex', width:'100', alignItems:'center', cursor:'pointer'}}>
                        <div onClick={()=>this.setState({purchaseMethod:'numShares'})} style={{width:'33%', borderRight:'solid', borderColor:'rgb(255,255,255)', borderWidth:'0.25px', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.purchaseMethod === 'numShares'? 'rgb(52,131,129)':'rgb(243,243,243)', padding: 13, }}>
                            <p style={{margin:0, textAlign:'center', color: this.state.purchaseMethod === 'numShares'? 'rgb(255,255,255)':'',}}>Number of Shares</p>
                        </div>
                        <div onClick={()=>this.setState({purchaseMethod:'dollarAmt'})} style={{width:'33%', borderRight:'solid', borderColor:'rgb(255,255,255)', borderWidth:'0.25px', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.purchaseMethod === 'dollarAmt'? 'rgb(52,131,129)':'rgb(243,243,243)', padding: 13, }}>
                            <p style={{margin:0, textAlign:'center',color: this.state.purchaseMethod === 'dollarAmt'? 'rgb(255,255,255)':'',}}>US Dollar Amount</p>
                        </div>
                        <div onClick={()=>this.setState({purchaseMethod:'pcnt'})} style={{width:'33%', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.purchaseMethod === 'pcnt'? 'rgb(52,131,129)':'rgb(243,243,243)', padding: 13, }}>
                            <p style={{margin:0, textAlign:'center',color: this.state.purchaseMethod === 'pcnt'? 'rgb(255,255,255)':'',}}>Funds Percentage</p>
                        </div>
                    </div>
                </div> */}
                {/* <div style={{paddingTop:10, width:'100%', }}>
                    {this.purchaseInput()}          
                </div> */}
                <div style={{paddingTop:10, display:'flex', alignItems:'center',justifyContent:'space-between', width:'100%', }}>
                    <p style={{margin:0, marginRight:25}}>
                        {this.state.lastChanged === 'quantity' ? '':'Approx '} Number of Shares: 
                    </p>
                    {this.state.buySell === 'sell' ? 
                        <div  style={{borderRight:'solid', cursor:'pointer',borderColor:'rgb(255,255,255)', borderWidth:'0.5px', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:'rgb(52,131,129)', padding: 5, borderRadius:8 }}
                            onClick={()=>{
                                let p = (this.state.sharesOwned*price).toFixed(2)
                                this.setState({quantity:this.state.sharesOwned, pcnt:100, price:p,lastChanged:'quantity',errors:'', purchaseMethod:'numShares'})}
                            }
                        >
                            <p style={{margin:0,color:'rgb(255,255,255)'}}>Sell All</p>
                        </div>
                    :null}
                    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'40%'}}>
                            <input 
                                style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent'}}
                                type="text" 
                                value={this.showShares()} 
                                onChange={(e)=>{
                                    if(this.state.buySell === 'sell'){
                                        this.setSellNumShares(e)
                                    }else{
                                        this.setBuyNumShares(e)
                                    }
                                }
                            }/>
                    </div>
                </div>
                <div style={{paddingTop:10, display:'flex', alignItems:'center',justifyContent:'space-between', width:'100%', }}>
                    <p style={{margin:0, marginRight:25}}>
                    {this.state.lastChanged !== 'quantity' ? '':'Approx '} {this.state.buySell === 'sell'?'% Position':'% Funds'}: 
                    </p>
                        <input style={{}}type="range" 
                            min="1"
                            max="100" 
                            step={1}
                            value={this.state.pcnt}
                            onChange={(e)=>{
                                if(this.state.buySell === 'sell'){
                                    this.setSellPercent(e)
                                }else{
                                    this.setBuyPercent(e)
                                }
                            }
                        }/>
                    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'20%'}}>
                            <input 
                                style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent'}}
                                type="text" 
                                value={this.state.pcnt} 
                                onChange={(e)=>{
                                    if(this.state.buySell === 'sell'){
                                        this.setSellPercent(e)
                                    }else{
                                        this.setBuyPercent(e)
                                    }
                                }
                            }/>
                            <p style={{margin:0,color:'rgb(52,131,129)'}}>%</p>
                    </div>
                </div>
                <div style={{paddingTop:10, display:'flex', alignItems:'center',justifyContent:'space-between', width:'100%', }}>
                    <p style={{margin:0, marginRight:25}}>
                    {this.state.lastChanged !== 'quantity' ? '':''}Total Value: 
                    </p>
                    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'40%'}}>
                        <input 
                            style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent',}}
                            type="text" 
                            value={this.state.price} 
                            onChange={(e)=>{
                                if(this.state.buySell === 'sell'){
                                    this.setSellCost(e)
                                }else{
                                    this.setBuyCost(e)
                                }
                            }
                        }/>
                        <p style={{margin:0,color:'rgb(52,131,129)'}}>(USD)</p>
                    </div>
                </div>
                
                
                <div style={{paddingTop:10, width:'100%', }}>
                    {this.state.errors.length > 0 ? this.displayErrors():null}
                </div>
                <div style={{width:'100%', paddingTop:25, paddingBottom:10, display:'flex', justifyContent:'space-evenly'}}>
                    <div onClick={this.props.stockPage? ()=>this.setState({acc:'NO'}) :()=>this.setState({selected:false, quantity:'',price:'',limitPrice:'',pcnt:'',})} style={{width:'40%', margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(30,30,30)', paddingTop: 7, paddingBottom:7, borderRadius: 8, cursor:'pointer'}}>
                        <p style={{margin:0, color:'rgb(30,30,30)', }}>
                            Go Back
                        </p>
                    </div>
                    <div onClick={()=>this.canTrade()} style={{width:'40%', margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(52,131,129)', paddingTop: 7, paddingBottom:7, borderRadius: 8, cursor:'pointer'}}>
                        <p style={{margin:0, color:'rgb(52,131,129)', }}>
                            Preview Trade
                        </p>
                    </div>
                </div>
                <div>
                    <p style={{fontSize:12}}>*Price forecast is based on ForeSCITE's AI powered forecasting model. It is neither advice nor recommendation to Buy or Sell stocks.</p>
                </div>
           </div>
        </div>
        )
    }

    time = ()=>{
        let both = new Date().toLocaleString();
        let spl = both.split(",")
        return spl[1] + ' ' + spl[0]

    }

    availableFunds = ()=>{
        let total = 0
        this.state.acc.holdings.forEach(stock=>{
            if((stock.symbol === 'CASH' || stock.symbol === 'Us dollar') && stock.instPrice === 1){
                total+= stock.quantity
            }
        })
        return this.state.acc.balances[0].buyingPower
    }

    canTrade =()=>{
        let availableFunds = this.availableFunds()
        let cost = this.cost()
        // console.log('cost',cost)
        // console.log('price',this.state.price)
        let errors = ''
        if(!this.state.buySell){
            errors = 'Please select buy or sell'
        }
           
        else if(cost <= 0 || this.state.price === '0.00'){

        }
        else if(this.state.priceType === 'LIMIT' && this.state.quantity === Math.floor(this.state.quantity)) {
            errors = 'You cannot do LIMIT orders for fractional shares'
        }
        else if (this.state.buySell === 'buy' && cost > availableFunds ){
            errors = 'You do not have enough funds for this trade'
        }
        else if (this.state.buySell === 'sell' && this.state.quantity > this.state.sharesOwned){
            errors = 'You cannot sell more shares than you own'
        }
        else if (this.state.quantity > 10000){
            errors='Maximum number of shares per order is 10,000. Please send multiple orders if necessary.'
        }
        
        if(errors.length > 0){
            this.setState({errors})
        }
        else{
            // GO TO SUMMARY
            if(this.state.acc.inst === "DriveWealth"){
               this.setState({summary:true})
            }
        }
    }

    successErrorLoadingBackground = ()=>{
       if(this.state.orderPlaced === 'Order successfully submitted'){
           return 'rgb(234,255,219)'
       } else if(this.state.orderPlaced === 'Placing Order...'){
           return 'rgb(225,225,235)'
       }else{
           return 'rgb(253,180,181)'
       }
    }

    shareWithFriends = ()=>{
        return(
            <div onClick={()=>this.setState({showShare:true})} style={{width:'40%', margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(30,30,30)', paddingTop: 7, paddingBottom:7, borderRadius: 8, cursor:'pointer'}}>
                <p style={{margin:0, color:'rgb(30,30,30)',marginRight:5 }}>
                    Share
                </p>
                <FaShareSquare/>
            </div>
        )
    }

    sendMessage = ()=>{
        console.log('sending message')
    }
    shareMessage = ()=>{
        if(this.state.showShare){
            return(
                <div style={{marginTop:10}}>
                    <div style={{display:'flex', width:'10%', alignItems:'center', }}>
                            <p style={{margin:0, marginRight:10}}>To:</p>
                        <input type='text' placeholder='name@email.com' value={this.state.recipient} onChange={(e)=>this.setState({recipient:e.target.value})}/>
                    </div>
                    <div style={{display:'flex', width:'10%', alignItems:'center'}}>
                            <p style={{margin:0, marginRight:10,}}>From:</p>
                        <input type='text' placeholder='name@email.com' value={this.state.sender} onChange={(e)=>this.setState({sender:e.target.value})}/>
                    </div>
                    <div style={{marginTop:10}}>
                        <p style={{margin:0}}>Message:</p>
                        <p style={{margin:0}}>
                            {
                                `I just ${this.state.buySell === 'buy'?'invested in':'sold'} ${parseFloat(this.state.quantity).toFixed(2)} shares of ${this.state.selected.symbol}. ForeSCITE projects that ${this.state.selected.symbol} will be worth $${this.state.selected.returnForecastValue && (this.state.selected.returnForecastValue+1 > this.state.selected.priceUSD )?this.state.selected.returnForecastValue.toFixed(0):null} in the next 30 days. You can trade with ForeSCITE at `
                            }
                            <a href='https://www.allocaterite.com' target='_blank'>allocaterite.com</a>.
                        </p>
                    </div>
                    <div>
                    <div onClick={()=>this.sendMessage()} style={{width:'40%', margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(30,30,30)', paddingTop: 7, paddingBottom:7, borderRadius: 8, cursor:'pointer'}}>
                        <p style={{margin:0, color:'rgb(30,30,30)',marginRight:5 }}>
                            Send
                        </p>
                    </div>
                    </div>
                </div>
            )
        }
    }

    summary = ()=>{
        // this.checkOrderStatus()
        if(this.state.titleText !== 'Review Trade'){
            this.setState({titleText: 'Review Trade'})
        }

        let body = {
            dwAccountID:this.state.acc.accountId,
            orderType:this.state.priceType,
            symbol: this.state.selected.symbol,
            side: this.state.buySell.toUpperCase(),
            orderPrice: this.state.priceType === 'LIMIT'? this.state.limitPrice:null,
            quantity: this.state.quantity,
            amount: this.state.price
        }
        if(this.state.showShare){
            return(
                this.shareMessage()
            )
        }
        else if(this.state.orderPlaced === 'Order successfully submitted'){
            this.startTradeStatusTimer()
            if(this.state.orderStatus !== 'new'){

                let message = ''
                if(this.state.priceType === 'MARKET'){
                   message = this.state.lastChanged !== "quantity" ? 
                    `You have successfully ${this.state.buySell === 'buy'?'bought':'sold'} $${parseFloat(this.state.orderResp.averagePrice*this.state.orderResp.quantity).toFixed(2)} of ${this.state.selected.symbol} stock equal to ${parseFloat(this.state.orderResp.quantity) > 100 || this.state.orderResp.quantity % 1 === 0? parseFloat(this.state.orderResp.quantity): parseFloat(this.state.orderResp.quantity).toFixed(8)*1} ${this.state.quantity === 1 ? 'share':'shares'} at a price of $${parseFloat(this.state.orderResp.averagePrice).toFixed(2)} each.`
                        :
                    `You have successfully ${this.state.buySell === 'buy'?'bought':'sold'} ${parseFloat(this.state.quantity) > 100 ||  this.state.quantity % 1 === 0? parseFloat(this.state.quantity): parseFloat(this.state.quantity).toFixed(8)} ${this.state.quantity === 1 ? 'share':'shares'} of ${this.state.selected.symbol} at a price of $${this.state.priceType === 'MARKET'? parseFloat(this.state.orderResp.averagePrice).toFixed(2):parseFloat(this.state.limitPrice).toFixed(2)} and a total ${this.state.buySell === 'buy'?'investment':'sale'} amount of $${parseFloat(this.state.orderResp.averagePrice*this.state.orderResp.quantity).toFixed(2)}.`
                    
                }
                else{
                    message = this.state.lastChanged !== "quantity" ? 
                    `You have successfully placed an order to ${this.state.buySell === 'buy'?'buy':'sell'} $${parseFloat(this.state.price).toFixed(2)} of ${this.state.selected.symbol} stock, which is approximately ${parseFloat(this.state.quantity) > 100 || this.state.quantity % 1 === 0? parseFloat(this.state.quantity): parseFloat(this.state.quantity).toFixed(8)*1} ${this.state.quantity === 1 ? 'share':'shares'}.`
                        :
                    `You have successfully placed an order to ${this.state.buySell === 'buy'?'buy':'sell'} ${parseFloat(this.state.quantity) > 100 ||  this.state.quantity % 1 === 0? parseFloat(this.state.quantity): parseFloat(this.state.quantity).toFixed(8)} ${this.state.quantity === 1 ? 'share':'shares'} of ${this.state.selected.symbol} at a price of $${parseFloat(this.state.limitPrice).toFixed(2)} and a total ${this.state.buySell === 'buy'?'investment':'sale'} amount of approximately $${parseFloat(this.state.limitPrice*this.state.quantity).toFixed(2)}.`
                    
                }
                return(
                    <div style={{display:'flex', flexDirection:'column', paddingLeft:'5%',paddingRight:'5%', paddingTop:'2%', paddingBottom:'2%', width:'100%',}}>
                        <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:100, height:100,justifySelf:'flex-end', }}>
                                <img 
                                    // src={`https://drivewealth.imgix.net/symbols/${this.state.selected.symbol.toLowerCase()}.png`}
                                    src={this.state.selected.imageURL === "not-found"? NoImage:this.state.selected.imageURL}
                                    style={{objectFit:'contain', width:100,height:100}}
                                />
                            </div>
                        </div>
                        {/* <div style={{display:'flex', justifyContent:'flex-start',marginBottom:25, marginTop:25 }}>
                            <p style={{margin:0, fontSize:28}}>
                               {this.state.buySell === 'buy' ? 'Investment ':'Sale '}Successful
                            </p>
                        </div> */}
                        {this.state.orderResp.status === "REJECTED"?
                        <div style={{ marginBottom:25, marginTop:25}}>
                            <p style={{margin:0, fontSize:24}}>
                                Trade Incomplete
                            </p>
                            <p style={{margin:0, fontSize:24}}>
                                {this.state.orderResp.statusMessage.message.split('[')[0]}
                            </p>
                        </div>
                        :
                        <div style={{display:'flex', justifyContent:'flex-start', marginBottom:25, marginTop:25}}>
                            <p style={{margin:0, fontSize:24}}>
                                {/* { 
                                this.state.lastChanged !== "quantity" ? 
                                `You have successfully ${this.state.buySell === 'buy'?'bought':'sold'} $${parseFloat(this.state.orderResp.averagePrice*this.state.orderResp.quantity).toFixed(2)} of ${this.state.selected.symbol} stock equal to ${parseFloat(this.state.orderResp.quantity) > 100 || this.state.orderResp.quantity % 1 === 0? parseFloat(this.state.orderResp.quantity): parseFloat(this.state.orderResp.quantity).toFixed(8)*1} ${this.state.quantity === 1 ? 'share':'shares'} at a price of $${parseFloat(this.state.orderResp.averagePrice).toFixed(2)} each.`
                                    :
                                `You have successfully ${this.state.buySell === 'buy'?'bought':'sold'} ${parseFloat(this.state.quantity) > 100 ||  this.state.quantity % 1 === 0? parseFloat(this.state.quantity): parseFloat(this.state.quantity).toFixed(8)} ${this.state.quantity === 1 ? 'share':'shares'} of ${this.state.selected.symbol} at a price of $${this.state.priceType === 'MARKET'? parseFloat(this.state.orderResp.averagePrice).toFixed(2):parseFloat(this.state.limitPrice).toFixed(2)} and a total ${this.state.buySell === 'buy'?'investment':'sale'} amount of $${parseFloat(this.state.orderResp.averagePrice*this.state.orderResp.quantity).toFixed(2)}.`
                                } */}
                                {message}
                            </p>
                        </div>
                        }
                        <div style={{display:'flex', justifyContent:'flex-start', marginBottom:25}}>
                            <p style={{margin:0, fontSize:20}}>
                                Time of Trade {this.time()}
                            </p>
                        </div>
                        
                        <div style={{width:'100%', paddingBottom:10, display:'flex', justifyContent:'space-around'}}>
                            {/* {this.shareWithFriends()} */}
                            <div onClick={()=>this.props.closeTrade()} style={{width:'40%', margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(30,30,30)', paddingTop: 7, paddingBottom:7, borderRadius: 8, cursor:'pointer'}}>
                                <p style={{margin:0, color:'rgb(30,30,30)', }}>
                                    Close
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
            else{
                return(
                    <div style={{width:'100%',height:'100%',display:'flex', justifyContent:'center',alignItems:'center'}}>
                        <h3>
                            {this.state.selected.symbol} {this.state.buySell} order in Progress...
                        </h3>
                    </div>
                )
            }
        }  
        return(
            <div style={{width:'100%', }}>
                <div style={{width:'100%', display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{width:'20%'}}/>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:100, height:100,justifySelf:'flex-end', }}>
                        <img 
                            // src={`https://drivewealth.imgix.net/symbols/${this.state.selected.symbol.toLowerCase()}.png`}
                            src={this.state.selected.imageURL === "not-found"? NoImage:this.state.selected.imageURL}
                            style={{objectFit:'contain', width:100,height:100}}
                        />
                    </div>
                    <div style={{width:'20%', display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <div 
                            style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(52,131,129)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                            onClick={()=>this.getNBBOPrice(this.state.selected.symbol)}>
                                <p style={{margin:0,fontWeight:'', color:'rgb(52,131,129)', textAlign:'center'}}>See NBBO Price</p>
                        </div>
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'column', paddingLeft:'5%',paddingRight:'5%', paddingTop:'2%', paddingBottom:'2%', width:'100%',}}>
                    <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',}}>
                        <div>
                            <p style={{margin:0, fontSize:36, fontWeight:'bold'}}>
                                {body.side === 'BUY' ? 'BUY':'SELL'}
                            </p>
                        </div>
                        <div>
                            <p style={{margin:0, fontSize:20, fontWeight:'bold',textAlign:'right'}}>
                                {this.state.selected.symbol}
                            </p>
                            <p style={{margin:0, fontSize:18,textAlign:'right'}}>
                                {this.state.selected.company} 
                            </p>
                        </div>
                    </div>
                    <hr></hr>
                    <div style={{display:'flex', flexDirection:'column',}}>
                        <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',}}>
                            <p>Market Price</p>
                            <p>${this.state.selected.live.price.toFixed(2)}</p>
                        </div>
                        <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',}}>
                            <p>Estimated Quantity</p>
                            <p>{parseFloat(body.quantity).toFixed(8)}</p>
                        </div>
                        <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',}}>
                            <p>Order Type</p>
                            <p>{this.state.priceType}</p>
                        </div>
                        {this.state.selected.live?
                        <div style={{width:'100%'}}>
                            <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',}}>
                                <p>Bid / Ask Price</p>
                                <p>{this.state.selected.live.bid}{' - '}{this.state.selected.live.ask}</p>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',}}>
                                <p>Day's Range</p>
                                <p>{this.state.selected.live.low}{' - '}{this.state.selected.live.high}</p>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',}}>
                                <p style={{margin:0}}>Volume</p>
                                <p style={{margin:0}}>{this.addCommas(this.state.selected.live.volume)}</p>
                            </div>
                        </div>
                        :null}
                    </div>
                    <hr></hr>
                    <div style={{display:'flex', flexDirection:'column',}}>
                        <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',}}>
                            <p>Estimated Commision</p>
                            <p>0.00</p>
                        </div>
                        <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',}}>
                            <p style={{margin:0}}>Estimated Total</p>
                            <p style={{margin:0}}>${(this.state.selected.live.price*body.quantity).toFixed(2)}</p>
                        </div>
                        <hr></hr>
                    </div>
                    {/* <div style={{display:'flex', flexDirection:'column',}}>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <p>Order Messages</p>
                        </div>
                    </div> */}
                    { this.state.orderPlaced?
                        <div style={{display:'flex', margin:'auto', width:'60%', backgroundColor:this.successErrorLoadingBackground(), padding:12, marginBottom:10, borderRadius:8,}}>
                            <p style={{margin:'auto'}}>
                               {this.state.orderPlaced}
                            </p> 
                        </div>
                    :null}
                    <div style={{width:'100%', paddingBottom:10, display:'flex', justifyContent:'space-around'}}>
                        <div onClick={()=>this.setState({summary:false, errors:''})} style={{width:'40%', margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(30,30,30)', paddingTop: 7, paddingBottom:7, borderRadius: 8, cursor:'pointer'}}>
                            <p style={{margin:0, color:'rgb(30,30,30)', }}>
                                Go back
                            </p>
                        </div>
                        <div onClick={()=>this.tradeStockDW()} style={{width:'40%', margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(52,131,129)', paddingTop: 7, paddingBottom:7, borderRadius: 8, cursor:'pointer'}}>
                            <p style={{margin:0, color:'rgb(52,131,129)', }}>
                                Submit Trade
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    getAllAccountInfo = ()=>{
        fetch(`${ENDPOINT}/mobile/user/holdings`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
        //   .then(responseJson => this.setState({holdings: responseJson}))
          .then(responseJson =>{
              let allAccounts = []
            //   console.log('neten all resp: ',responseJson)
            responseJson.items.forEach(inst=>inst.accounts.forEach(acc=>{
                //   console.log(acc.name,acc.type )
                  if(acc.type === 'depository' || acc.type === 'investment' || acc.type === 'credit'){
                      allAccounts.push({...acc, inst:inst.instName})
                  }
              }))
            //   console.log('neten all parsed: ',allAccounts)
              this.setState({allAccounts, itemsResp:responseJson.items})
            })
          .catch(err=>console.log(`catching errors`, err))
    }

    chooseAcc = ()=>{
        if(this.state.titleText !== 'Make a Trade'){
         this.setState({titleText: 'Make a Trade'})
        }
        let mapped =[]
        let accounts
        if(this.state.itemsResp.length>0){
            // accounts = this.combineAccountsAndHoldings()
            accounts = this.state.allAccounts
            accounts.forEach(account=>{
                // console.log('account: ', account)
                if(account.inst === "DriveWealth" && !account.product){
                    mapped.push(
                        <div
                            style={{
                                padding: 25,
                                borderRadius: 8,
                                margin: 10,
                                backgroundColor: this.state.hover === account.itemId ? '#F8F9FA': "rgb(248,249,250)",
                                textDecoration: "none",
                                cursor: 'pointer',
                            }}
                            onClick={()=>{
                                this.owned(account,this.state.selected.symbol)
                                this.setState({acc:account})
                            }}
                        >
                            <div style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 8,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                textDecoration: "none",
                            }}>
                                <div style={{width:'30%', display:'flex', justifyContent:"flex-start", alignItems:'center'}}>
                                    <p style={{margin:0}}>{account.inst}</p>
                                </div>
                                <div style={{width:'30%', display:'flex', justifyContent:"flex-start", alignItems:'center'}}>
                                    <p style={{margin:0}}>{account.mask_full}</p>
                                </div>
                                {/* {this.props.isSmallScreen?null: 
                                <div style={{width:'10%', display:'flex', justifyContent:"flex-end", alignItems:'center'}}>
                                    <p style={{margin:0}}>{inner.mask}</p>
                                </div>} */}
                                <div style={{width:'30%', display:'flex', justifyContent:"flex-end", alignItems:'center'}}>
                                    <p style={{margin:0}}><strong style={{fontSize:20}}>${account.balances[0].current? this.addCommas(account.balances[0].current.toFixed()):'0.00'}</strong></p>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }
        let windowheight = window.innerHeight*0.7
        return(
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{width:'90%', margin:'auto',marginBottom:10, marginTop:25}}>
                    {mapped.length > 0? <h4 style={{color:'rgb(17,17,17)'}}>Choose account</h4> : <a href='/CreateDw' target="_blank"><h5 style={{color:'rgb(52,131,129)'}}>You do not have any Self Directed DriveWealth Accounts linked. Click here to open a new DriveWealth Account.</h5></a>}
                </div>
                {/* <div style={{width:'90%', margin:'auto',marginBottom:25, }}>
                    <p>Investing is currently only avaiable through DriveWealth Accounts. If you do not have one you can open one <NavLink to="/Dashboard">here</NavLink>.</p>
                </div> */}
                {mapped.length>0?
                    <div style={{width:'95%', margin:'auto',}}>
                        <div style={{borderRadius:12,overflowY:'scroll', height:'80%', maxHeight:windowheight }}>
                            {mapped}
                        </div>
                    </div>
                    :null
                }
                {mapped.length>0?
                    <div style={{width:'95%', margin:'auto', backgroundColor:'rgb(255,255,255)',borderRadius:12,marginTop:10}}>
                        <a href='/CreateDw' target="_blank">
                            <p style={{textAlign:'center', margin:0, color:'rgb(52,131,129)'}}>
                                Open another DriveWealth Brokerage Account
                            </p>
                        </a>
                    </div>
                :null
                }
            </div>
        )
    }


    tradeStockDW = ()=>{
        /// HERE
        this.setState({orderPlaced:'Placing Order...'})
        let body = {
            dwAccountID:this.state.acc.accountId,
            orderType:this.state.priceType,
            symbol: this.state.selected.symbol,
            side: this.state.buySell.toUpperCase(),
            orderPrice: this.state.priceType === 'LIMIT'? this.state.limitPrice:null,
        }
        if(this.state.lastChanged === 'quantity'){
            body['quantity'] = this.state.quantity
        }
        else{
            body['amount'] = this.state.price
        }
        fetch(`${ENDPOINT}/dw/trades/submit`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken
            },
            body: JSON.stringify(body)
        })
        .then(resp=>resp.json())
        .catch(err=> {
            console.log('trade submission errors: ', err)
            this.setState({errors:'Something went wrong, please try again later'})
        })
        .then(responseJson=> {
            console.log('resp:', responseJson)
            if ('success' in responseJson && responseJson.success){
                this.setState({orderPlaced:responseJson.payload.message,orderId:responseJson.payload.orderID})
                this.startTradeStatusTimer()
            }else{
                this.setState({orderPlaced:responseJson.payload})
            }
        })
    }

    utcToLocal(timeAndDate){
        if(timeAndDate === 'Market is closed today'){
            return ''
        }
        // console.log('mkt open',timeAndDate )
        let date = new Date(timeAndDate).toString().split(" ")
        if (date && date.length > 3){
            let hour = date[4].substring(0,2) 
            let min = date[4].substring(3,5) 
            let day = date[1]+ ' ' + date[2].replace(/^0(?:0:0?)?/, '')+ ', ' + date[3]
            let amPm = 'AM'
            if (hour > 12){
                hour = hour - 12
                amPm = 'PM'
            }
            else if(hour === '00'){
                hour = '12'
            }
            let time = `${hour}:${min} ${amPm}`
            return `The market will open at ${time.replace(/^0(?:0:0?)?/, '')} on ${day}`
        }
    }

    etcToLocal(dateInput) {
        // EST - UTC offset: 5 hours
        var offset = 5.0,
        
            /*
              - calculate the difference between the server date and UTC
              - the value returned by the getTime method is the number of milliseconds since 1 January 1970 00:00:00 UTC.
              - the time-zone offset is the difference, in minutes, between UTC and local time
              - 60000 milliseconds = 60 seconds = 1 minute
            */
            serverDate = new Date(dateInput),
            utc = serverDate.getTime() - (serverDate.getTimezoneOffset() * 60000),
        
            /*
              - apply the offset between UTC and EST (5 hours)
              - 3600000 milliseconds = 3600 seconds = 60 minutes = 1 hour
            */
            clientDate = new Date(utc + (3600000 * offset));
        
        return clientDate.toLocaleString();
      }
      

    render(){
        console.log('all accounts',this.state.allAccounts)
        console.log('all accounts filtered',this.state.allAccounts.filter(acc => acc.inst === "DriveWealth" && !acc.product))
        if(this.props.canTrade.status){
            // if(true){
                // let titleText = this.state.summary? 'Review Trade':'Make a Trade'
                let titleText = ''
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height: '95%', paddingBottom:25}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>{this.state.titleText}</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeTrade()}> X </p>
                        </div>
                    {this.state.acc === 'NO'?
                        <div>
                            {this.chooseAcc()}
                        </div>
                    :
                    this.state.selected === false ?
                        <div>
                            {this.selectStock()}
                        </div>
                    :
                    this.state.summary?
                        <div style={{width:'95%', height:'100%', margin:'auto', overflowY:'scroll'}}>
                            {this.summary()}
                        </div>
                        :
                        <div style={{width:'95%', height:'100%', margin:'auto', overflowY:'scroll'}}>
                            {this.setTrade()}
                        </div>
                    }
                </div>
            </div>
            )
        }
        else{
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height: '40%', paddingBottom:25}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Market Closed</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeTrade()}> X </p>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                            {
                                this.state.allAccounts.filter(acc => acc.inst === "DriveWealth" && !acc.product).length < 1 && !this.state.acc ?
                                    <div style={{width:'90%', margin:'auto',marginBottom:25, marginTop:25}}>
                                        <a href='/CreateDw' target="_blank"><h5 style={{color:'rgb(52,131,129)'}}>You do not have any Self Directed DriveWealth Accounts linked. Click here to open a new DriveWealth Account.</h5></a>
                                    </div>
                                :null
                            }                         
                            <div style={{width:'90%', margin:'auto',marginBottom:25, marginTop:25}}>
                                <h4 style={{color:'rgb(17,17,17)'}}>The market is currently closed, please try again during trading hours.</h4>
                            </div>
                            <div style={{width:'90%', margin:'auto',marginBottom:25, marginTop:25}}>
                                <h4 style={{color:'rgb(17,17,17)'}}>{this.utcToLocal(this.props.canTrade.market_open)}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}