import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class FreeTrialText extends Component {
    render() {
        return (
            <Container className="FreeTrial-left-container">
                <Row>
                    <p className="FreeTrial-left-title">AllocateRite is proud of its commitment to transparency.</p>
                </Row>

                <Row className="FreeTrial-left-text-row">
                    <p className="FreeTrial-left-subtitle">We offer our proprietary market signal analysis, the current breakdown of our portfolio’s asset allocation as well as market insight to Asset Managers, Institutions, and Registered Investment Advisors, who can then share this information with their clients.</p>
                </Row>

                <Row>
                    <p className="FreeTrial-left-text">Call or Email us today:</p>
                </Row>

                <Row className="FreeTrial-left-email-row">
                    <Col xs={1}>
                        <img src="/images/FreeTrial-phone.png" alt="phone" width="40px" />
                    </Col>
                    <Col>
                        <p className="FreeTrial-left-contact-info">(212) 995-9191</p>
                    </Col>
                </Row>

                <Row className="FreeTrial-left-email-row">
                    <Col xs={1}>
                        <img src="/images/FreeTrial-mail.png" alt="mail" width="40px" />
                    </Col>
                    <Col>
                        <p className="FreeTrial-left-contact-info"><a className="FreeTrial-Left-mail-adress" href={`mailto:info@allocaterite.com`}>info@allocaterite.com</a></p>
                    </Col>
                </Row>

            </Container>
        )
    }
}

export default FreeTrialText
