import React from 'react'
import Header from "../PublicHeader";
import ARlogo from "../../assets/ar_logo.png"
import Footer from "../Footer";
const brandColor = "#00A99D"


export default class Careers extends React.Component{
    render(){
        return(
            <div>
                <Header user={this.props.user} signOut={this.props.signOut}/>
                <div style={{ marginTop: 100, marginLeft: '7%', marginRight: '7%' }}>
                    <div style={{ height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={ARlogo}
                            style={{ height: 100 }}
                        />
                    </div>
                    <div >
                        <h3> Careers </h3>
                    </div>
                    <div >
                        <p>
                            We are growing and always hiring smart, self-motivated people. Feel free to reach out at <a style={{color: brandColor, fontWeight: 'bold'}} href="mailto:careers@allocaterite.com">careers@allocaterite.com</a> with your resume and tell us why we should hire you. 
                        </p>
                    </div>
                    <div>
                        {/* <h5> Open Roles at AllocateRite: </h5>
                        <ul>
                            <li>
                                <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 500}}>
                                    <a style={{ color: 'rgb(17,17,17)', }} href="https://www.indeed.com/jobs?q=allocateRite&l=New%20York%2C%20NY&vjk=bd397998641c0d67" target="_blank"> UX/UI Designer </a>
                                    <p> New York, NY</p>
                                </div>
                            </li>
                        </ul> */}
                    </div>
                    <div>
                        <h5> We are always looking for great people in the following fields:  </h5>
                        <ul>
                            <li>Software Engineer</li>
                            <li>Data Scientist</li>
                            <li>Sales Representive</li>
                            <li>Project Manager</li>
                            <li>QA Engineer</li>
                        </ul>
                    </div>
                </div>
                <div style={{height: 100}}/>
                <Footer/>
            </div>
        )
    }
}