import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import ElementOfPlatfrom from '../ElementOfPlatfrom';
import Brains from '../../assets/newUI/Brains.svg'
import Drive from '../../assets/newUI/Drive.svg'
import Muscle from '../../assets/newUI/Muscle.svg'

class ElementsOfPlatfrom extends Component {
    

    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            isSmallScreen: false,
            elements: [{
                id: 1,
                isLeft: true,
                title: `The Brains`,
                class:'Brains',
                iconUrl: Brains,
                subtitle: `AI-Driven Investment Strategies`,
                text: `The driving force behind AllocateRite’s platform is its dynamic asset allocation model powered by proprietary AI algorithms.`,
                details: [
                    {
                    id: 1,
                    title: `A more complete understanding of market information`,
                    text: `Our technology absorbs and assesses an enormous range of market data using a set of technical, fundamental, and relative value metrics weighted to the market’s current reality. From this foundation our algorithms build a worldview – and a set of predictions around risk and returns.`,
                }, 
                {
                    id: 2,
                    title: `AI-driven global macro algorithms for agility and speed`,
                    text: `Our allocation decisions prioritize capital preservation by targeting 70% of the upside and just 30% of the downside to produce smoother (and stronger) long-term performance. To accomplish that, we leverage the flexibility, liquidity, and low prices of ETFs to provide maximum maneuverability at an extremely low cost.`
                }, 
                {
                    id: 3,
                    title: `Optimized portfolios that look forward, not backwards`,
                    text: `We don’t rely on assumptions about returns distributions or how things “used” to work. Instead, we look ahead to tomorrow’s risks and opportunities – and help investors avoid the pitfalls of static, “backwards-looking” investment management models.`
                }, 
                {
                    id: 4,
                    title: `A focus on risk analysis`,
                    text: `Risk is ongoing – and risk analysis should be, too. Our algorithm-based adaptive intelligence provides proactive risk monitoring and disciplined execution of investment decisions, helping guide portfolios (and individuals) through the constant swirl of the markets.`
                }
            ]
            }, 
            {
                id: 2,
                isLeft: false,
                title: `The Muscle`,
                class:'Muscle',
                iconUrl: Muscle,
                subtitle: `Automated, Feature-Packed Platform`,
                text: `Our strategies are wrapped in an intuitive platform that seamlessly integrates with your business, simplifies operations, and automates key functions and features.`,
                details: [
                    {
                    id: 1,
                    title: `Seamless On-Boarding`,
                    text: `Start investing smarter today. Our on-boarding process helps you seamlessly create accounts and begin using the AllocateRite investment platform.`
                }, 
                {
                    id: 2,
                    title: `Complete Broker Integration`,
                    text: `We believe in providing the full flexibility to control your business and your funds. Choose your preferred provider across 2,500 custodians and brokers through the FIS Broker/RIA Connect platform.`
                }, 
                {
                    id: 3,
                    title: `Tailored Portfolio Construction`,
                    text: `Use our models or choose the fund families or specific ETFs that best align with your business model. Our platform will automatically backtest your selections to ensure compatibility with the AllocateRite strategy.`
                }, 
                {
                    id: 4,
                    title: `Automated Trade Rebalancing`,
                    text: `You designate total investment funds within each account, and our allocation engine optimizes trade orders to best facilitate your business requirements.`
                }, 
                {
                    id: 5,
                    title: `Comprehensive Portfolio Reporting`,
                    text: `Get the information you need when you need it. Our portfolio reporting platform gives you insight into trade performance, best execution, returns versus benchmarks, risk metrics, and risk adjusted returns.`
                }, 
                {
                    id: 6,
                    title: `Informative Resource Center`,
                    text: `Build understanding and engagement with our monthly market commentaries, composite tear-sheets, white papers, and presentations.`
                }, 
                {
                    id: 7,
                    title: `Flexible Tax Optimization`,
                    text: `ETFs are tax-efficient in themselves, and we choose ours with tax efficiency in mind. But our system goes one step further by allowing you to select your tax treatment from a menu of optimization options, including a fully-automated, AI-based solution.`
                }, 
                {
                    id: 8,
                    title: `Secure with Blockchain`,
                    text: `Furthering the transparency and security provided to client advisors, all allocation signals will be added to the AllocateRite blockchain, which uses consensus and cryptographic algorithms.`
                }
            ]
            }, 
            {
                id: 3,
                isLeft: true,
                title: `The Drive`,
                class:'Drive',
                iconUrl: Drive,
                subtitle: `Wealth Management for the Long Run`,
                text: `Together, our platform and strategy provide critical benefits to portfolios, individual investors, and advisors looking to streamline and strengthen their businesses.`,
                details: [
                    {
                    id: 1,
                    title: `High-tech risk management for individual investors.`,
                    text: `Our proprietary algorithms prioritize capital preservation and bypass the shortcomings of traditional investment strategies (and human nature). It’s a strategy that just makes sense. We believe that fewer assumptions and less emotion means smarter decisions and quicker reaction times, while less downside means investors can maintain access to savings when they need it – whether it’s right now or 30 years from now.`
                }, 
                {
                    id: 2,
                    title: `Reacting to risk is obsolete: From now on, you can anticipate it.`,
                    text: `Our risk management process constantly checks allocations and investment decisions against the current risk environment. That means ongoing, real-time risk monitoring and dynamic allocation recommendations. By actively monitoring and managing risk, we believe portfolios can and adapt before a potential problem becomes a full-blown crisis.`
                }, 
                {
                    id: 3,
                    title: `Our technology is proprietary, but our process is an open book.`,
                    text: `We stand by our results: Our performance/returns are verified and transparent. We also keep control in your hands: we never take custody or control of client funds, and with our brokerage and custodian network, you can keep your assets wherever it’s most convenient and receive trading signals in the way that makes sense for your business.`
                }, 
                {
                    id: 4,
                    title: `We help you leverage the benefits of ETFs for your business.`,
                    text: `ETFs are famously low-cost, but certain fund families may be more advantageous for your business. Our technology helps you tailor each portfolio using the fund families and even the specific funds that you prefer. We’ll automatically run back-tests to make sure your selections optimize your risk management options.`
                }, 
                {
                    id: 5,
                    title: `Maintain liquidity: the ultimate weapon in risk management.`,
                    text: `Liquidity doesn’t just help manage costs by reducing spreads, it acts as a powerful buffer against risk. When markets fall, the ability to maneuver is paramount, and the liquidity of ETFs provides the risk-managed long-term returns investors seek without locking up assets unnecessarily. For an individual investor, that means better total risk management – not just against the markets, but against the ups and downs of life itself.`
                }
            ]
            }]
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        if (window.innerWidth < 600) {
            this.setState({ isSmallScreen: true })
        }
    }

    render() {
        return (
            <Container>
                {this.state.elements.map(element => 
                    <ElementOfPlatfrom key={element.id} value={element} isSmallScreen={this.state.isSmallScreen}/>
                )}
            </Container>
        )
    }
}

export default ElementsOfPlatfrom
