import React from 'react'

import ENDPOINT from '../Endpoint'

import Spinner from 'react-bootstrap/Spinner'


export default class UnlinkModal extends React.Component{

    state={
        yn:'No',
        unlinkClicked:false,

    }

    unlinkRobo(){
        fetch(`${ENDPOINT}/trackers`,{
            headers: {
                "Content-Type":"application/json",
                "Authorization": "Bearer " + this.props.user.idToken.jwtToken,
            },
        })
       .then(response => response.json())
       .then(respJSON => {
           let trackerId =  respJSON[0].trackerId
           console.log('unlinkRobo resp1',respJSON)
           this.unlinkRobopart2(trackerId)
        })
    }

    unlinkPlaidAcc(){
        let body = {
            accounts : [
                {
                    "id": this.props.acc.accountId,
                    "itemId": this.props.acc.itemId
                }
            ]
           } 
        fetch(`${ENDPOINT}/plaid/accounts/remove`,{
            method: "POST",
            headers: {
                "Content-Type":"application/json",
                "Authorization": "Bearer " + this.props.user.idToken.jwtToken,
            },
            body:JSON.stringify(body)
        })
        .then(response => response.json())
        .then(respJSON => console.log('unlinkRobo resp2',respJSON))
        .then(()=>this.props.closeModal())
    }

    unlinkRobopart2(trackerId){
        fetch(`${ENDPOINT}/trackers/${trackerId}`,{
        method: "DELETE",
        headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            Authorization: "Bearer " + this.props.user.idToken.jwtToken,
        },
       })
       .then(response => response.json())
       .then(respJSON => console.log('unlinkRobo resp2',respJSON))
       .then(()=>this.props.closeModal())
    }

    liquidateDW = ()=>{
        fetch(`${ENDPOINT}/mobile/liquidate`,{
        method: "POST",
        headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            Authorization: "Bearer " + this.props.user.idToken.jwtToken,
        },
        body:JSON.stringify({
            "dw_account_id": this.props.acc.accountId, 
            "product": this.props.acc.product
        })
       })
       .then(response => response.json())
       .then(respJSON => console.log('liq resp',respJSON))
       .then(()=>this.props.closeModal())
       .catch(err=>console.log('liq err: ', err))
    }

    utcToLocal(timeAndDate){
        if(timeAndDate === 'Market is closed today'){
            return ''
        }
        // console.log('mkt open',timeAndDate )
        let date = new Date(timeAndDate).toString().split(" ")
        if (date && date.length > 3){
            let hour = date[4].substring(0,2) 
            let min = date[4].substring(3,5) 
            let day = date[1]+ ' ' + date[2].replace(/^0(?:0:0?)?/, '')+ ', ' + date[3]
            let amPm = 'AM'
            if (hour > 12){
                hour = hour - 12
                amPm = 'PM'
            }
            else if(hour === '00'){
                hour = '12'
            }
            let time = `${hour}:${min} ${amPm}`
            return `The market will open at ${time.replace(/^0(?:0:0?)?/, '')} on ${day}`
        }
    }

    confirm = ()=>{
        this.setState({unlinkClicked:true})
        if (this.props.acc.product === 'robo'){
            this.unlinkRobo(this.props.acc.accountId)
        }
        else if(this.props.acc.inst === 'DriveWealth'){
            console.log('inside else if')
            this.liquidateDW()
        }
        else if (this.props.acc.inst !== 'DriveWealth'){
            this.unlinkPlaidAcc()
        }
    }

    render(){

        const {acc} = this.props 
        let message = []
        const unlinkMessages = {
            robo:[`Are you sure you want to unlink from the robo strategy from your account, ${acc.inst} ${acc.mask}?`, `Doing so will liquidate your entire account to cash.`],
            group:[`Are you sure you want to unlink from the trade group from your account, ${acc.inst} ${acc.mask}?`, `Doing so will liquidate your entire account to cash.`],
            null:[`Are you sure you want to unlink your brokerage account, ${acc.inst} ${acc.mask}?`, `Doing so will prevent you from tracking your account here.`],
            goal:[`Are you sure you want to unlink  ${acc.inst} ${acc.mask} from the your goal?`, `Doing so will liquidate your entire account to cash and halt all recurring deposits you have set up.`],
        }
        unlinkMessages[acc.product].forEach(ele=>{
            message.push(
                <p style={{fontSize:24}}>
                    {ele}
                </p>
            )
        })
        
        
        if(this.props.canTrade.status || !(acc.product === 'robo' ||acc.product === 'goal' ||acc.product === 'group' )){
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height: '90%', paddingBottom:25}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Unlink Account</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeModal()}> X </p>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12,margin:'auto', width:'95%', }}>
                            {message}
                        </div>
                        <div style={{width: '70%', margin:'auto', }}>
                            <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start', }}>
                                <input type="radio" checked={this.state.yn==='Yes'} onChange={()=>{this.setState({yn:'Yes'})}}/> 
                                <p style={{margin:0, marginLeft:10, fontSize:20}}>Yes, I am sure.</p>
                            </div>
                            <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                <input type="radio" checked={this.state.yn==='No'} onChange={()=>{this.setState({yn:'No'})}}/> 
                                <p style={{margin:0, marginLeft:10, fontSize:20}}>No, do not proceed</p>
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center', width:'100%'}}>
                            <div onClick={this.state.yn === 'Yes'? ()=>this.confirm():null} style={{display:'flex', cursor:'pointer',justifyContent:'center',alignItems:'center', width:'50%', paddingLeft:12, paddingRight:12, paddingTop:10, paddingBottom:10, backgroundColor:'#ee5e68', borderRadius:10}}>
                                {this.state.unlinkClicked? 
                                    <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                    :
                                    <p style={{color:'rgb(255,255,255)', margin:0}}>Unlink</p>
                                    }
                                
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height: '40%', paddingBottom:25}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Market Closed</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeModal()}> X </p>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                            <div style={{width:'90%', margin:'auto',marginBottom:25, marginTop:25}}>
                                <h4 style={{color:'rgb(17,17,17)'}}>The market is currently closed, so we cannot unlink your account right now. Please try again during trading hours.</h4>
                            </div>
                            <div style={{width:'90%', margin:'auto',marginBottom:25, marginTop:25}}>
                                <h4 style={{color:'rgb(17,17,17)'}}>{this.utcToLocal(this.props.canTrade.market_open)}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}