import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterPageTitle from "../layout/FooterPageTitle";
import Footer from "../Footer";
import Header from "../PublicHeader"
const brandColor = "rgb(70,182,179)"

export class InvestingTerms extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  

  render() {
      console.log('small screen?', this.props.isSmallScreen)
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
        <div>
            <FooterPageTitle title="Terms of Investing" />
            <div style={{width:'80%', margin:'auto',}}>
                <p>Investing terms here</p>
                
            </div>
            <Footer />
        </div>
      </div>
    );
  }
}

export default InvestingTerms;
