import React, { Component } from "react";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

class RouletteChart extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      isSmallScreen: false,
      tooltips: [
        {
          id: 1,
          placement: `top`,
          smallScreenPlacement: `bottom`,
          title: `SEAMLESS ON-BOARDING`,
          text: `Start investing smarter today. Our on-boarding process helps you create accounts and begin using the AllocateRite Investment platform.`
        },
        {
          id: 2,
          placement: `right`,
          smallScreenPlacement: `bottom`,
          title: `TAILORED PORTFOLIO CONSTRUCTION`,
          text: `Use our models or choose the fund families or specific ETFs that best align with your business model. Our platform will automatically backtest your selections to ensure compatibility with the AllocateRite strategy.`
        },
        {
          id: 3,
          placement: `right`,
          smallScreenPlacement: `bottom`,
          title: `AUTOMATED TRADE REBALANCING`,
          text: `Use our models or choose the fund families or specific ETFs that best align with your business model. Our platform will automatically backtest your selections to ensure compatibility with the AllocateRite strategy.`
        },
        {
          id: 4,
          placement: `bottom`,
          smallScreenPlacement: `bottom`,
          title: `COMPREHENSIVE PORTFOLIO REPORTING`,
          text: `Get the information you need when you need it. Our portfolio reporting platform gives you insight into trade performance, best execution, returns versus benchmarks, risk metrics, and risk adjusted returns.`
        },
        {
          id: 5,
          placement: `left`,
          smallScreenPlacement: `bottom`,
          title: `INFORMATIVE RESOURCE CENTER`,
          text: `Build understanding and engagement with our monthly market commentaries, composite tear-sheets, white-papers, and presentations.`
        },
        {
          id: 6,
          placement: `left`,
          smallScreenPlacement: `bottom`,
          title: `FLEXIBLE TAX OPTIMIZATION`,
          text: `ETFs are tax-efficient in themselves, and we choose ours with tax efficiency in mind. But our system goes one step further by allowing you to select you tax treatment from a menu of optimization options, including a fully-automated, AI-based solution.`
        }
      ]
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window.innerWidth < 600) {
      this.setState({ isSmallScreen: true });
    }
  }

  render() {
    return (
      <Container className="roulette-chart-container">
        <Row>
          <Col>
            <img
              id="roulette-chart"
              src="/images/Roulette-Chart.png"
              alt="roulette-chart"
            />
            {this.state.tooltips.map(tip => (
              <OverlayTrigger
                key={tip.id}
                placement={
                  this.state.isSmallScreen
                    ? tip.smallScreenPlacement
                    : tip.placement
                }
                overlay={
                  <Tooltip
                    key={tip.id}
                    className="roulette-chart-tooltip"
                    id={`tooltip-${tip.id}`}
                  >
                    <p className="roulette-chart-tooltip-title">{tip.title}</p>
                    <p className="roulette-chart-tooltip-text">{tip.text}</p>
                  </Tooltip>
                }
              >
                <button
                  key={tip.id}
                  className="roulette-chart-tooltip-button"
                  id={`tooltip-button-${tip.id}`}
                >
                  i
                </button>
              </OverlayTrigger>
            ))}
          </Col>
        </Row>
        <Row>
          <Col className="platform-title">
            <p className="roulette-chart-title">
              INTEGRATED DIGITAL ADVISOR PLATFORM
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default RouletteChart;
