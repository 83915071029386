import React from 'react'
import { withRouter } from "react-router-dom";
import Header from "../PublicHeader"

const SomeComponent = withRouter((props) => <NoMatch {...props} />);

export default class NoMatch extends React.Component{
    render(){
        return (
          <div>
            <Header user={this.props.user} signOut={this.props.signOut}/>
            <div style={{ flex: 1, textAlign: "center", marginTop: "20%" }}>
              <h4>{`The page: https://www.allocaterite.com${this.props.location.pathname} does not exist`}</h4>
            </div>
          </div>
        );
    }
}