import React from 'react'
import OneIndex from './oneIndex'
import ENDPOINT from '../Endpoint'
import Spinner from 'react-bootstrap/Spinner'

export default class TickerTape extends React.Component{
    
    state={
        indiciesData:'loading',
        firstShown:0,
        lastShown:5,
    }

    componentDidMount(){
        this.getIndecies()
    }

    componentDidUpdate(prevProps, prevState) {
        Object.entries(this.props).forEach(([key, val]) =>
          prevProps[key] !== val && console.log(`Prop '${key}' changed from ${prevProps[key]} to ${val}`)
        );
        if (this.state) {
          Object.entries(this.state).forEach(([key, val]) =>
            prevState[key] !== val && console.log(`State '${key}' changed from ${prevState[key]} to ${val}`)
          );
        }
      }

    getIndecies = ()=>{
        console.log('getting indexes')
        let path = window.location.pathname.split("/")[2]
        // console.log('path',path)
        if(path === '' || path === 'Watchlist' || !path){
            fetch(`${ENDPOINT}/search/indexPrice`)
            .then((res) => res.json())
            .then((responseJson) => {
                // console.log('idicies resp: ', responseJson)
                
                // let indiciesData = {...this.state.indiciesData,responseJson}
                this.setState({indiciesData:{...responseJson}})
                // if(this.state.indiciesData === 'loading'){
                    setTimeout(()=>this.getIndecies(),300000)
                // }
                // else{
                //     // console.log('replacing data')
                //     let indiciesData = {...this.state.indiciesData,...responseJson}
                //     this.setState({indiciesData})
                //     setTimeout(()=>this.getIndecies(),15000)
                // }
            })
            .catch(err=> console.log('indexes errors', err))
        }
    }

    oneIndex(info){
        return(
            // <div style={{paddingLeft:20,paddingRight:20, paddingTop:10, paddingBottom:10, backgroundColor:info.price_delta<0?'rgb(253,180,181)':'rgb(200,238,210)', width:'20%', borderRadius:12,}}>
            <div style={{paddingLeft:20,paddingRight:20, paddingTop:10, paddingBottom:10, border:'solid',borderWidth:'2px',borderColor:info.price_delta<0?'rgb(234,50,35)':'rgb(82,140,139)', width:'20%', borderRadius:12,marginRight:10}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <p style={{margin:0,fontSize:24}}>{info.symbol}</p>
                <div style={{}}>
                    <p style={{margin:0, textAlign:'right', color:info.price_delta<0?'rgb(234,50,35)':'rgb(82,140,139)'}}>${info.real_time_price.toFixed(2)}</p>
                    <p style={{margin:0, textAlign:'right', color:info.price_delta<0?'rgb(234,50,35)':'rgb(82,140,139)'}}>{info.price_delta>0?'+':''}{(info.price_delta*100).toFixed(2)}%</p>
                </div>
                </div>
            </div>
        )
    }

    shuffleTiles = ()=>{
        let fs = this.state.firstShown
        let ls = this.state.lastShown
        let keys = Object.keys(this.state.indiciesData).length
        if(fs > keys - 1){
            fs = fs % keys 
            ls = ls % keys
        }
        else if (ls > keys - 1){
        }
        // setInterval(()=>this.setState({firstShown:fs,lastShown:ls}),3000)
    }

    showSome = ()=>{
        let iData = []
        let sData = []
        let data = []
        let counter = 0
        Object.keys(this.state.indiciesData).forEach(i=> {
            counter += 1 
            if(this.state.indiciesData[i].is_index){
                iData.push({...this.state.indiciesData[i],symbol:i})
            }else{
                sData.push({...this.state.indiciesData[i],symbol:i})
            }
            })
        data = [...iData,...sData]
        if(counter > 5){
            

            let fs = this.state.firstShown
            let ls = this.state.lastShown

            if(fs > counter - 1){
                // console.log('----------------------',)
                fs = fs % counter
                ls = ls % counter
                // console.log('after split FS', fs)
                // console.log('after split ls', ls)
            }
            // this.shuffleTiles()
            setTimeout(()=>this.setState({firstShown:fs+1,lastShown:ls+1}),3000)
            let arr = [...data,...data] // double length to make sure wrap-around doesnt crash
            // console.log('first nonSPLIT',fs)
            // console.log('last nonSPLIT',ls)
            // console.log('shown', arr.slice(fs,ls).map(d=>d.symbol))
            return arr.slice(fs,ls)
        }
        else{
            return data
        }
    }

    render(){
        if(this.props.isSmallScreen){
            return(
                null
            )
        }
        else if(this.state.indiciesData === 'loading'){
            return(
                <div style={{display:'flex', width:'60%',alignItems:'center', justifyContent:'space-evenly', margin:'auto'}}>
                        <Spinner animation="border" id="spinner" variant="dark" role="status" >
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                </div>
            )
        }
        else{
            // let indecies = this.state.indiciesData.map(i=>this.oneIndex(i))
            let indecies = this.showSome().map(i=><OneIndex key={i.symbol} is_index={i.is_index} price_delta={i.price_delta} symbol={i.symbol} name={i.name} real_time_price={i.real_time_price}/>)
            return(
                <div>
                    {/* <h5 style={{textAlign:'center'}}>Top Stocks Today</h5> */}
                    <div style={{display:'flex', width:'80%',alignItems:'center', justifyContent:'space-evenly', margin:'auto',}}>
                        {indecies}
                        {/* <div style={{backgroundColor:'rgb(100,100,100)', borderRadius:12,padding:20,cursor:'pointer'}} onClick={()=>{
                             let fs = this.state.firstShown
                             let ls = this.state.lastShown
                             console.log('----------------------',)
                             console.log('FIRST > LENGTH',)
                             console.log('fs',fs)
                             console.log('this.state.indiciesData.length',Object.keys(this.state.indiciesData).length)
                             console.log('----------------------',)
                             if(fs > Object.keys(this.state.indiciesData).length -1 ){

                                 console.log('AFTER CUT',)
                                 fs = fs % Object.keys(this.state.indiciesData).length
                                 ls = ls % Object.keys(this.state.indiciesData).length
                                 console.log('fs',fs)
                                 console.log('ls',ls)
                             }
                            this.setState({firstShown:fs+1,lastShown:ls+1})
                        }}>
                            <p style={{color:'rgb(255,255,255)',margin:0}}>NEXT</p>
                        </div> */}
                    </div>
                </div>
            )
        }
    }
}