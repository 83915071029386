import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import ChineseElementsOfPlatfrom from "../layout/ChineseElementsOfPlatform";
import ChineseRouletteChart from "../layout/ChineseRouletteChart";
import ChineseAdvantageOfPlatformDetails from "../ChineseAdvantageOfPlatformDetails";
import SecureYourFuture from "../layout/SecureYourFuture";
import ChineseFooter from "../layout/ChineseFooter";
import Header from "../layout/Header"

export class ChinesePlatform extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <div>
        <Container>
          <Row className="page-blank-row-md" />
          <Row>
            <ChineseRouletteChart />
          </Row>
          <Row>
            <ChineseElementsOfPlatfrom />
          </Row>
          <Row className="platform-advertise">
            <p className="platform-advertise-title">
              我们相信成功的投资管理要远胜于一个好的策略。
            </p>
            <p className="platform-advertise-text">
              这就是为什么我们建立了一个完整的平台去为投资者和顾问提供灵活性，可扩展性以及智能自动化。
            </p>
          </Row>
          <Row className="platform-advantage">
            <ChineseAdvantageOfPlatformDetails />
          </Row>
          <Row>
            <SecureYourFuture isChinese={true} />
          </Row>
          <Row className="page-blank-row" />
        </Container>
        <ChineseFooter />
      </div>
      </div>
    );
  }
}

export default ChinesePlatform;
