import React from 'react'
import ENDPOINT from '../Endpoint'

export default class OrdersModal extends React.Component{

    state = {
        
    }

    componentDidMount(){
       this.getStatements()
    }

    getStatements = ()=>{
        let url = `${ENDPOINT}/dw/documents/${this.props.acc.accountId}`
        fetch(url,{
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
            },
        })
            .then((res) => res.json())
            .then((responseJson)=>{
                console.log('get statements resp', responseJson)
                this.setState({...responseJson.payload})
                return responseJson
            })
        
    }

    getLink = (fileId)=>{
        let url = `${ENDPOINT}/dw/documents/link/${this.props.acc.accountId}/${fileId}`
        fetch(url,{
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
            },
        })
            .then((res) => res.json())
            .then((responseJson)=>{
                console.log('tax docs link: ', responseJson)
                // this.setState({presetTickers: responseJson.lists})
                window.open(responseJson.payload.url, "_blank")
            })
    }


    showAll = ()=>{
        let mappedConf = []
        console.log('cfs:', this.state.confirmations)
        this.state.confirmations.forEach(ele=>{
            mappedConf.push(
                <div>
                   <a href='#' onClick={()=>this.getLink(ele.fileKey)}>
                        {ele.displayName}
                   </a>
                </div>
            )
        })
        console.log('sts:', this.state.statements)
        let mappedStatments = []
        this.state.statements.forEach(ele=>{
            mappedStatments.push(
                <div>
                   <a href='#' onClick={()=>this.getLink(ele.fileKey)}>
                        {ele.displayName}
                   </a>
                </div>
            )
        })
        console.log('tfs:', this.state.tax_forms)
        let mappedTf = []
        this.state.tax_forms.forEach(ele=>{
            mappedTf.push(
                <div>
                   <a href='#' onClick={()=>this.getLink(ele.fileKey)}>
                        {ele.displayName}
                   </a>
                </div>
            )
        })
        return(
            <div>
                <div>
                    <p style={{fontSize:'larger', fontWeight:'bold'}}>Tax Forms</p>
                </div>
                <div>
                    {mappedTf.length=== 0 ?<p>You have no Tax Forms Available</p> :mappedTf}
                </div>
                <div>
                    <p style={{fontSize:'larger', fontWeight:'bold'}}>Statements</p>
                </div>
                <div>
                    {mappedStatments.length=== 0 ?<p>You have no Statements Available</p> :mappedStatments}
                </div>
                <div>
                    <p style={{fontSize:'larger', fontWeight:'bold'}}>Confirmations</p>
                </div>
                <div>
                    {mappedConf.length=== 0 ?<p>You have no Confirmations Available</p> :mappedConf}
                </div>
            </div>
        )
    }

    addCommas = (x)=>{
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    parseDate(dt){
        let all = dt.split('T')
        let day = all[0].split("-")
        let time = all[1].slice(0,5)
        return time + " " + day[1] +"/"+ day[2] +"/"+ day[0]
    }
    utcToLocal(timeAndDate){
        let date = new Date(timeAndDate).toString().split(" ")
        let hour = date[4].substring(0,2) 
        let min = date[4].substring(3,5) 
        let day = date[1]+ ' ' + date[2]+ ', ' + date[3]
        let amPm = 'AM'
        if (hour > 12){
            hour = hour - 12
            amPm = 'PM'
        }
        else if(hour === '00'){
            hour = '12'
        }
        let time = `${hour}:${min} ${amPm}`
        return (
            <div style={{width:'100%'}}>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {time.replace(/^0(?:0:0?)?/, '')} 
                </p>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {day}
                </p>
            </div>
        )
    }

    render(){
        const {acc} = this.props
        console.log('SM.state', this.state)
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', minWidth:725, height: '90%', paddingBottom:25}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Statement History</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeModal()}> X </p>
                    </div>
                    <div style={{width:'100%', overflowY:'scroll'}}>
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                            <p style={{fontSize:28}}>
                                {acc.mask_full} 
                            </p>
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                            {this.state.statements? this.showAll():
                            <div>
                                <p>Loading...</p>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}