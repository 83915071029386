import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterPageTitle from "../layout/FooterPageTitle";
import Footer from "../Footer";
import Header from "../PublicHeader"

export class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <div>
        <FooterPageTitle title="PRIVACY POLICY" />
        <Container>
          <Row />
          <Row className="terms-top-paragraph">
            <p className="terms-text">
            This privacy policy (“Policy”) discloses the privacy practices for ForeSCITE.com. This privacy policy applies solely to information collected by this web site. It will notify you of the following:
            </p>
            <p className="terms-text">
            What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.
            </p>
            <p className="terms-text">
              What choices are available to you regarding the use of your data.
            </p>
            <p className="terms-text">
              The security procedures in place to protect the misuse of your information.
            </p>
            <p className="terms-text">
              How you can correct any inaccuracies in the information.
            </p>
            <p className="terms-text">
              Personal data received or transferred to our website is subject to the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain situations, we may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>INFORMATION COLLECTION, USE, AND SHARING</h3>
            <p className="terms-text">
              The user recognizes that non-public information concerning the user, disclosed by the user to ForeSCITE (“the Company”), or other agents of either party (the “information”) (such as the user’s name, company name, address, assets under management, type of business, etc.) (i) may be disclosed to the general partner, attorneys, accountants and auditors in furtherance of the Company’s business and to other service providers such as brokers who may have a need for the information in connection with providing services to the company, (ii) to third party service providers or financial institutions who may be providing marketing services to the funds provided that such persons must agree to protect the confidentiality of the information and use the information only for the purposes of providing services to the Company and (iii) as otherwise required or permitted by law. The Company restricts access to the information to their employees who need to know the information to provide services to the Company, and maintain physical, electronic and procedural safeguards that comply with U.S. federal standards to guard the information.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>YOUR ACCESS TO AND CONTROL OVER INFORMATION</h3>
            <p className="terms-text">
              You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number provided on our website:
            </p>
            <p className="terms-text">
              See what data we have about you, if any.
            </p>
            <p className="terms-text">
              Change/correct any data we have about you.
            </p>
            <p className="terms-text">
              Have us delete any data we have about you.
            </p>
            <p className="terms-text">
              Express any concern you have about our use of your data.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>REGISTRATION</h3>
          </Row>
          <Row>
            <p className="terms-text">
              In order to use this website, a user must first complete the registration form. During registration a user is required to give certain information (such as name and email address). This information is used to contact you about the products/services on our site in which you have expressed interest.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>DURATION</h3>
          </Row>
          <Row>
            <p className="terms-text">
              We will retain your personal information for as long as is necessary and as permitted by applicable laws.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>YOUR RIGHTS</h3>
          </Row>
          <Row>
            <p className="terms-text">
              We always will enable you to exercise your rights as provided by data protection laws.
            </p>
            <p className="terms-text">
              Depending on the laws of the country where you live, you may have other rights in respect of your personal information. If you wish to exercise any legal right in respect of your personal information, please contact us.
            </p>
            <p className="terms-text">
              In some cases, we may be entitled to decline a request you have made regarding your personal information, in accordance with application laws. We will explain if that is the case. Otherwise, we will meet your request as promptly as we reasonably can. If you have requested that we stop sending you marketing messages, please note that you may still receive them for a short period while the request is processed.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>COOKIES</h3>
            <p className="terms-text">
              This site uses cookies and other tracking technologies to assist with navigation, use, authority, accessing content, and website use practices.
            </p>
          </Row>
          <Row className="terms-subparagraph-row">
            <h5>WHAT ARE COOKIES?</h5>
            <p className="terms-text">
              Cookies are small tracking files that are stored by the on your computer or mobile phone. They allow websites to store user habits and preferences.
            </p>
            <p className="terms-text">
              Some cookies are required for users to be able to log in to our sites.
            </p>
            <p className="terms-text">
              We use “cookies” on this site. A cookie is a piece of data stored on a site visitor’s hard drive to help us improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site. Some of our business partners may use cookies on our site (for example, advertisers). However, we have no access to or control over these cookies.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>LINKS</h3>
            <p className="terms-text">
             This web site may contain links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>SECURITY</h3>
            <p className="terms-text">
              We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.
            </p>
            <p className="terms-text">
              Wherever we collect sensitive information, that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a closed lock icon at the bottom of your web browser or looking for “https” at the beginning of the address of the web page.
            </p>
            <p className="terms-text">
              While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <h3>UPDATES</h3>
          </Row>
          <Row>
            <p className="terms-text">
              Our Privacy Policy may change from time to time and all updates
              will be posted on this page.
            </p>
            <p className="terms-text">
              If you feel that we are not abiding by this privacy policy, you
              should contact our customer support department immediately via
              telephone at (212) 995-9191 or via email at
              <a
                className="terms-contact-us"
                href={`mailto:help@allocaterite.com`}
              >
                {" "}
                help@allocaterite.com
              </a>
              .
            </p>
          </Row>
          <Row className="terms-paragraph-row">
            <Col className="terms-bottom-row">
              <p className="terms-bottom-date">Last updated: May 5, 2021</p>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
