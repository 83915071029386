import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import FooterPageTitle from "../layout/FooterPageTitle";
import Footer from "../layout/Footer";
import Header from "../layout/Header"

export class AllocateRiteForecastDisclaimer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
        <div>
          <FooterPageTitle title="AllocateRite Forecast Disclaimer" />
          <Container>
            <Row />
            <Row className="terms-top-paragraph">
              <h3 className="terms-title">
                  AllocateRite Forecast Disclaimer
              </h3>
              <p className="terms-text">
                  AllocateRite Digital (“AllocateRite”) believes the information provided within this app or website is reliable;  however, AllocateRite does not warrant that any information is accurate and persons relying on the information do so at their own risk. To the extent permitted by law, AllocateRite disclaims all liability to any person relying on the information with respect to any loss or damage (including consequential loss or damage) however caused, which may be suffered or arise directly or indirectly from such information.
              </p>
              <p className="terms-text">
                  Every forecast contained in the information is merely an estimate. Such projections are subject to market influences and contingent upon matters outside the control of AllocateRite and therefore may not be realized in the future. Historical returns are no guarantee of future performance.
              </p>
              <p className="terms-text">
                  Every forecast, idea, or suggestion contained in this app is for general information only. It has been prepared without taking into account any person’s objectives, financial situation or needs, and because of that, any person should before acting on any forecast, idea, or suggestion, consider its appropriateness with regard to his or her objectives, financial situation and needs.
              </p>
              <p className="terms-text">
                  All securities entail risk. AllocateRite’s assessments are estimates of relative risk, taking into consideration estimated returns and what is typical in the market.  Estimates are uncertain and their accuracy cannot be guaranteed under all scenarios.
              </p>
              
            </Row>
            
          </Container>
          <Footer />
        </div>
      </div>

    );
  }
}

export default AllocateRiteForecastDisclaimer;
