import React from 'react'
import Header from "../PublicHeader"
import Footer from "../Footer"
import TradeModal from "../dashboard/TradeModal"
import ChangeSinceYest from "../companyPage3/ChangeSinceYest2"

import { NavLink, /*Redirect*/ } from "react-router-dom";
import { FaArrowLeft, FaRegShareSquare, FaArrowRight, FaPlus, FaLongArrowAltLeft } from "react-icons/fa";
import { Container, Row, Col,Button } from 'react-bootstrap';
import Ptp from '../companyPage3/Ptp'
import RsCi from '../companyPage3/RsCi'
import SearchGlass from '../../assets/newUI/searchGlass.png'
import upArrow from "../../assets/single_up.svg";
import downArrow from "../../assets/single_down.svg";
import strongUpArrow from "../../assets/double_up.svg";
import strongDownArrow from "../../assets/double_down.svg";
import flatArrow from "../../assets/flat.svg";
import NoImage from "../../assets/newUI/logo_notfound.png"

import ENDPOINT from '../Endpoint'


export default class Cp3 extends React.Component{
    state={
        activeTab:'Ptp',
        sourceData: false,
        symbol: this.cutURL().toUpperCase(),
        searchActive: true,
        searchResults: [],
        showTrade: false,
        imgURL: NoImage,
        canTrade:true,
        noTicker:'loading'
    }

    componentDidMount() {
        if(this.props.user){
          this.fetchDataLoggedIn()
          this.getMarketStatus()
        }
        else{
          this.fetchDataLoggedOut()
        }
        this.updateWindowDimensions()
        document.title = `ForeSCITE - ${this.cutURL().toUpperCase()}`;
        if (window.innerWidth > 850 && window.innerWidth < 1295) {
          document.body.style.zoom = 0.9
        }
        this.setState({symbol:this.cutURL().toUpperCase()})
        this.setImage()
    }

    componentWillReceiveProps(){
        if(this.state.symbol !== this.cutURL().toUpperCase()){
          if(this.props.user){
            this.fetchDataLoggedIn()
          }
          else{
            this.fetchDataLoggedOut()
          }
          this.setImage()
          document.title = `AllocateRite - ${this.cutURL().toUpperCase()}`;
          this.setState({symbol:this.cutURL().toUpperCase()})
        }
      }

      addCommas(x){
        if(x === ''){
            return ''
        }else{
            let arr = x.toString().split(".")
            if(arr[1]){
                return (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))+ '.' + arr[1]
            }
            else{
                return  (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            }
        } 
    }

      getMarketStatus = ()=>{
        // console.log('inside can trade')
        fetch(`${ENDPOINT}/search/istradinghour`,{
            method:"GET"
        })
        .then((res) => res.json())
        .then((responseJson) => {
            // console.log('can trade resp: ', responseJson)
            this.setState({canTrade:responseJson})
        })
    }

      fetchDataLoggedOut = () => {
          // console.log('fetching Logged Out')
        const symbol = this.cutURL().toUpperCase()
        let url = `${ENDPOINT}/risk-monkey/ticker/${symbol}`
    
        fetch(url,
          {
            'Access-Control-Allow-Headers': {
              'Content-Type': 'application/json',
              'Cache-Control': 'no-cache',
              'Access-Control-Request-Headers': "*",
              'Access-Control-Allow-Origin': "*",
              "Access-Control-Allow-Methods": "*",
            }
          }
        )
          .then(res => res.json())
          .then(responseJson => {
            var sourceData = responseJson
            let noTicker = false
            if(Object.keys(sourceData).length < 3){
              noTicker = true
            }
            this.setState({ sourceData,noTicker })
    
          })
          .catch(error => console.log(error))
      }
      fetchDataLoggedIn = () => {
        // console.log('fethcing Logged Innnn')

        const symbol = this.cutURL().toUpperCase()
        let url = `${ENDPOINT}/risk-monkey/ticker/mobile/${symbol}`
    
        fetch(url,
          {
            method: "GET",
            headers:{
              Authorization: "Bearer " + this.props.user.idToken.jwtToken
            },
            'Access-Control-Allow-Headers': {
              'Content-Type': 'application/json',
              'Cache-Control': 'no-cache',
              'Access-Control-Request-Headers': "*",
              'Access-Control-Allow-Origin': "*",
              "Access-Control-Allow-Methods": "*",
            }
          }
        )
          .then(res => res.json())
          .then(responseJson => {
            var sourceData = responseJson
            let noTicker = false
            // console.log('LENGTH:',Object.keys(sourceData))
            if(Object.keys(sourceData).length < 3){
              noTicker = true
            }
            this.setState({ sourceData,noTicker })
    
          })
          .catch(error => console.log(error))
      }
    
      cutURL(){
        return window.location.pathname.split("/CompanyPage/")[1]
      }

      updateWindowDimensions = () => {
        if (window.innerWidth < 1000) {
          this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
        } else {
          this.setState({ isSmallScreen: false, screenWidth: window.innerWidth });
        }
      };

      componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
      }
      updateWindowDimensions = () => {
        if (window.innerWidth < 850) {
            this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
        } else {
            this.setState({ isSmallScreen: false, screenWidth: window.innerWidth });
        }
    };

    setImage = ()=>{
      let symbol = this.cutURL().toLowerCase()
      let url = `https://drivewealth.imgix.net/symbols/${symbol}.png`
      var http = new XMLHttpRequest();
      http.open('HEAD', url, false);
      http.send();
      if (http.status != 404){
          this.setState({imgURL:`https://drivewealth.imgix.net/symbols/${symbol}.png`})
        }else{
          this.setState({imgURL:`not-found`}) 
      }
      
    }

    search = (event) => {
      let searchText = event.target.value.toUpperCase();
      this.setState({ searchText }, () => {
          const { isSearching } = this.state;
          if (isSearching) {
              return;
          } else {
              this.setState({ isSearching: true });
              setTimeout(() => {
                  this.setState(
                      { isSearching: false },
                      this.onSubmitSearchMomentum
                  );
              }, 2000);
          }
      });
  }

  onSubmitSearchMomentum = () => {
    const { searchText } = this.state;
    this.setState({ dataLoading: true });
    // console.log(searchMomentumText);
    // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchText}`;
    let url = `${ENDPOINT}/search/ticker?search=${searchText.toUpperCase()}`;

    fetch(url, {
        "Access-Control-Allow-Headers": {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
        },
    })
        .then((res) => res.json())
        .then((responseJson) => {
            var data = responseJson.content;
            console.log("SEARCHING RESULT: " , data);
            for (var i = 0; i < data.length; i++) {
                var item = data[i];

            }
            this.setState({
                dataLoading: false,
                searchResults: data,
            });
        })
        .catch((err) => {
            this.setState({ searchResults: false });
            console.log(err);
        });
};

showResults = ()=>{
  let arr = []
  // this.state.searchResults.forEach(ele=>{
  for (let i = 0; i < 4; i++) {
      arr.push(
          <NavLink /* onClick={()=>this.setState({searchActive:false})} */ 
            onMouseLeave={()=>this.setState({hover:false})} 
            onMouseEnter={()=>this.setState({hover:this.state.searchResults[i]})} 
            onClick={()=>this.setState({searchText:'',searchResults: [],sourceData:false, noTicker:'loading'})}  
            to={`/CompanyPage/${this.state.searchResults[i].symbol}`} 
            style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:this.state.hover===this.state.searchResults[i]? '#f1f1f1' :'#fafafa',cursor:'pointer', textDecoration:'none', color:'rgb(17,17,17)', borderBottom:'solid', borderWidth:.25, borderColor:'#cccccc',padding:8 }}
          >
             <div style={{width: '80%', margin:'auto', display:'flex',justifyContent:'space-between'}}>
                  <div style={{width: '30%',}}>
                      <p style={{margin:0}}>{this.state.searchResults[i].symbol}</p>
                  </div> 
                  <div style={{width: '30%',}}>
                      <p style={{margin:0}}>{this.state.searchResults[i].priceUSD? `$${this.state.searchResults[i].priceUSD.toFixed(2)}`:'$00.00'}</p>
                  </div> 
                  <div style={{width: '30%',textAlign:'right'}}>
                      <img
                      src={this.getArrow(this.state.searchResults[i].direction)}
                      style={{
                        // marginTop: 5,
                        width: 20,
                        height: 20,
                      }}
                  />
                  </div> 
             </div>
          </NavLink>
      )
      if(i+1 >= this.state.searchResults.length){
          i += 5
      }
  }
  return (
      <div style={{position:'absolute',width:'100%',zIndex:9 }}>
          {this.state.searchText.length > 0 ? arr : null}
      </div>
  )
}

getArrow = (direction) => {
  switch (direction) {
    case -1:
      return downArrow;
    case 1:
      return upArrow;
    case 2:
      return strongUpArrow;
    case -2:
      return strongDownArrow;
    default:
      return flatArrow;
  }
};

    alterWatchlist(symbol, addRemove){
      fetch(`${ENDPOINT}/risk-monkey/watchlist`,{
        method: "POST",
        headers:{
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken
        },
        body: JSON.stringify({[addRemove]:[symbol]})
      })
    }

    cutURL(){
        return window.location.pathname.split("/CompanyPage/")[1]
      }

      searchbar = ()=>{
        if(this.state.searchActive){
            // console.log('this.state.searchText',this.state.searchText)
            // console.log('this.state.searchResults',this.state.searchResults)
            if(this.props.isSmallScreen){
                return(
                    <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <div style={{display:'flex', alignItems:'center',width: '', borderRadius:this.state.searchResults.length > 0?'20px 20px 0 0 ' :20, backgroundColor:"rgb(231,232,233)", paddingLeft:this.state.isSmallScreen? 0: 20, padding:this.state.isSmallScreen?8:''}}>
                        <img 
                            src={SearchGlass}
                            style={{height:15, marginRight:5}}
                            onClick={()=>this.setState({searchActive:!this.state.searchActive})}
                        />
                        <input autoFocus value={this.state.searchText} id="headerSearch" onChange={(e)=>this.search(e)} type="text" placeholder="Enter stock symbol" style={{ border:'none', outline:'none',backgroundColor:"rgb(231,232,233)",  }}/>
                    </div>
                    {this.state.searchResults.length > 0 ?
                    <div style={{position:'absolute', zIndex:999, width:'60%', marginTop:20}}>
                        {this.showResults()}
                    </div>
                    :null
                    }
                </div>
                )
            }
            return(
                <div style={{marginRight: '1.5rem', position: 'relative'}}>
                    <div style={{display:'flex', alignItems:'center', height: 40,width: '', borderRadius:this.state.searchResults.length > 0?'20px 20px 0 0 ' :20, backgroundColor:"rgb(231,232,233)", paddingLeft:20,}}>
                        <img 
                            src={SearchGlass}
                            style={{height:15, marginRight:5}}
                            onClick={()=>this.setState({searchActive:!this.state.searchActive})}
                        />
                        <input autoFocus value={this.state.searchText} id="headerSearch" onChange={(e)=>this.search(e)} type="text" placeholder="Enter any stock symbol" style={{ border:'none', outline:'none',backgroundColor:"rgb(231,232,233)", width:'90%'}}/>
                    </div>
                    {this.state.searchResults.length > 0 ?
                    <div style={{position:'relative'}}>
                        {this.showResults()}
                    </div>
                    :null
                    }
                </div>
            )
        }else{
            if(this.props.isSmallScreen){
                return(
                    <div style={{ position: 'relative'}}>
                        <img
                            src={SearchGlass}
                            style={{height: 20}}
                            onClick={()=>{
                                this.setState({searchActive:!this.state.searchActive})
                            }
                            }
                        />
                    </div>
                )
            }
            return(
                <div style={{marginRight: '1.5rem', position: 'relative',}}>
                    <img
                        src={SearchGlass}
                        style={{height: 20, cursor:'pointer'}}
                        onClick={()=>{
                            this.setState({searchActive:!this.state.searchActive})
                        }
                        }
                    />
                </div>
            )
        }
    }

    tabs(){
        // let selectedBk = {backgroundImage: this.state.activeTab === 'Ptp' ? 'linear-gradient(94deg, rgb(30, 51, 66) 0%, rgb(73, 185, 168))' : 'linear-gradient(94deg,rgb(73, 185, 168) 0%, rgb(30,51,66)', width: '50%', height: '100%', cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: this.state.activeTab === 'Ptp' ?'5px 0px 0px 5px':'0px 5px 5px 0px', height:'100%',}
        // let unselectedBk = {width: '50%', height: '100%', cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: this.state.activeTab === 'Ptp'? '0px 5px 5px 0px' :'5px 0px 0px 5px', height:'100%', backgroundColor:'rgba(146, 149, 161, 0.1)'}
        let selectedBk = {backgroundColor: '#3b8e8c', width: '50%', height: '100%', cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: this.state.activeTab === 'Ptp' ?'5px 0px 0px 5px':'0px 5px 5px 0px', height:'100%',}
        let unselectedBk = {width: '50%', height: '100%', cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: this.state.activeTab === 'Ptp'? '0px 5px 5px 0px' :'5px 0px 0px 5px', height:'100%', backgroundColor:'rgba(146, 149, 161, 0.1)'}
        let selectedTxt = {margin:0, margin:'auto',marginLeft: this.state.isSmallScreen? 10:'auto', fontSize: this.state.isSmallScreen?14:16, fontSize: '16px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.3, letterSpacing: 'normal', textAlign: 'left', color: 'rgb(255, 255, 255)',}
        let unselectedTxt = {margin:0, margin:'auto',marginLeft: this.state.isSmallScreen? 10:'auto', fontSize: this.state.isSmallScreen?14:16, fontSize: 16, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.3, letterSpacing: 'normal', textAlign: 'left', color: 'rgb(146, 149, 161)',}
        return(
            <div style={{width:this.state.isSmallScreen?'100%':'40%', display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                <div 
                    style={this.state.activeTab === 'Ptp' ? selectedBk : unselectedBk}
                    onClick={() => this.setState({ activeTab:'Ptp' })}
                >
                    <p 
                        style={this.state.activeTab === 'Ptp' ? selectedTxt : unselectedTxt}
                    >
                      Price Trend
                    </p>
                </div>
                <div 
                    style={this.state.activeTab === 'RsCi' ? selectedBk : unselectedBk}
                    onClick={() => this.setState({ activeTab:'RsCi' })}
                >
                    <p 
                        style={this.state.activeTab === 'RsCi' ? selectedTxt : unselectedTxt}
                    >
                      Risk & Company Info
                    </p>
                </div>
            </div>
        )
    }

    addButton = ()=>{
      if(this.props.user){
        if(this.state.sourceData.watchlisted){
          return(
            <div 
              onClick={()=>{
                let sourceData = this.state.sourceData
                sourceData.watchlisted = !this.state.sourceData.watchlisted
                this.alterWatchlist(this.state.symbol, 'delete')
                this.setState({sourceData})
              }}
              style={{height:40,cursor: 'pointer' ,borderRadius: '29px',paddingTop:'1%', paddingBottom:'1%', paddingLeft:'3%', paddingRight:'3%' ,border: 'solid 1px rgb(227,154,146)',minWidth:120,display:'flex',justifyContent:'center', alignItems:'center' }}
            >
              <p style={{margin:0, fontSize:'smaller', opacity: 1, fontSize: '12px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', letterSpacing: '0.34px', textAlign: 'center', color: 'rgb(227,154,146)',}}>
                Remove from Watchlist
              </p> 
            </div>
          )
        }
        else{
          return(
            <div 
              onClick={()=>{
                let sourceData = this.state.sourceData
                sourceData.watchlisted = !this.state.sourceData.watchlisted
                this.alterWatchlist(this.state.symbol, 'add')
                this.setState({sourceData})
              }}
              style={{cursor: 'pointer' ,height:40,borderRadius: '29px',paddingTop:'1%', paddingBottom:'1%', paddingLeft:'3%', paddingRight:'3%' ,border: 'solid 1px #669890',minWidth:120, display:'flex',justifyContent:'center', alignItems:'center' }}
            >
              <p style={{margin:0, fontSize:'smaller', opacity: 1, fontSize: '12px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', letterSpacing: '0.34px', textAlign: 'center', color: '#669890',}}>
                Add to Watchlist
              </p> 
            </div>
          )
        }

      }else{
        if(this.state.isSmallScreen){
          return(
          <NavLink to='/Login'/*onClick={()=>this.alterWatchlist(this.state.symbol, 'add')}*/ style={{cursor: 'pointer' ,borderRadius: '29px',paddingTop:'1%', paddingBottom:'1%', paddingLeft:'3%', paddingRight:'3%' ,border: 'solid 1px #669890',minWidth:120 }}>
            <p style={{margin:0, fontSize:22, opacity: 1, fontSize: '12px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', letterSpacing: '0.34px', textAlign: 'center', color: '#669890',}}>
                Add to Watchlist
              </p> 
          </NavLink>
          )
        }
        else{
          return(
          <NavLink to='/Login'/*onClick={()=>this.alterWatchlist(this.state.symbol, 'add')}*/ style={{cursor: 'pointer' ,borderRadius: '29px',paddingTop:'1%', paddingBottom:'1%', paddingLeft:'3%', paddingRight:'3%' ,border: 'solid 1px #669890',minWidth:120, display:'flex',justifyContent:'center', alignItems:'center' }}>
              <p style={{margin:0, fontSize:22, opacity: 1, fontSize: '12px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', letterSpacing: '0.34px', textAlign: 'center', color: '#669890',}}>
                  Add to Watchlist
                </p> 
            </NavLink>
          )
        }
      }
    }

    wlButtons = ()=>{
      if(this.state.isSmallScreen){
        return(
          <div style={{display:'flex', justifyContent:'center', width: this.state.isSmallScreen?'100%':'25%', marginTop:20, marginBottom:20}}>
            {this.props.user && this.state.sourceData.isDWSupported?
            <div 
              onClick={()=>{
                this.getMarketStatus()
                this.setState({showTrade:true})
              }}
              style={{cursor: 'pointer' ,borderRadius: '29px',paddingTop:'1%', paddingBottom:'1%', paddingLeft:'3%', paddingRight:'3%' ,border: 'solid 1px #669890',minWidth:120, display:'flex',justifyContent:'center',alignItems:'center',  }}
            >
              <p style={{margin:0, fontSize:22, opacity: 1, fontSize: '12px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', letterSpacing: '0.34px', textAlign: 'center', color: '#669890',}}>
                Trade
              </p> 
            </div>:null}
            <NavLink to='/Login' style={{cursor: 'pointer' ,borderRadius: '29px',paddingTop:'1%', paddingBottom:'1%', paddingLeft:'3%', paddingRight:'3%' ,border: 'solid 1px rgb(36, 42, 56)', marginRight:'1.5%', minWidth:120,display:'flex',justifyContent:'center',alignItems:'center' }}>
              <p style={{ margin:0, fontSize:22, opacity: 1, fontSize: '12px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', letterSpacing: '0.34px', textAlign: 'center', color: 'rgb(36, 42, 56)',}}>
                  My Watchlist
                </p> 
            </NavLink>
            {this.addButton()}
          </div>
        )
      }else{
        return(
          <div style={{display:'flex', justifyContent:'flex-end', width: '70%',marginTop:20,marginBottom:20}}>
            {this.props.user && this.state.sourceData.isDWSupported?
            <div 
              onClick={()=>{
                this.getMarketStatus()
                this.setState({showTrade:true})
              }}
              style={{cursor: 'pointer' ,borderRadius: '29px',paddingTop:'1%', paddingBottom:'1%', paddingLeft:'3%', paddingRight:'3%' ,border: 'solid 1px #669890',minWidth:120, marginRight:5, display:'flex',justifyContent:'center', alignItems:'center',height:'100%', display:'flex',justifyContent:'center',alignItems:'center',height:40 }}
            >
              <p style={{margin:0, fontSize:'smaller', opacity: 1, fontSize: '12px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', letterSpacing: '0.34px', textAlign: 'center', color: '#669890',}}>
                Trade
              </p> 
            </div>:null}
            <NavLink to='/Login' style={{cursor: 'pointer' ,borderRadius: '29px',paddingTop:'1%', paddingBottom:'1%', paddingLeft:'3%', paddingRight:'3%' ,border: 'solid 1px rgb(36, 42, 56)', marginRight:5, minWidth:120, display:'flex',justifyContent:'center', alignItems:'center',height:'100%', display:'flex',justifyContent:'center',alignItems:'center',height:40 }}>
              <p style={{ margin:0, fontSize:'smaller', opacity: 1, fontSize: '12px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', letterSpacing: '0.34px', textAlign: 'center', color: 'rgb(36, 42, 56)',}}>
                  My Watchlist
                </p> 
            </NavLink>
            {this.addButton()}
          </div>
        )
      }
    }

    closeTrade = ()=>{
      this.setState({showTrade:false})
  }

  checkLogo = (symbol)=>{
    let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    if (http.status != 404){
        return (
          <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:100, height:100,justifySelf:'flex-end',}}>
                <img 
                    // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
                    src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
                    onerror="this.onerror=null; this.remove();" 
                    style={{objectFit:'contain', width:100,height:100, }}
                />
            </div>
        )
    }
    else{
        return (
          <div style={{borderRadius:8, padding: 10, backgroundColor:'rgb(255,255,255)', }}>
            <p style={{fontSize:32, fontWeight:'bold', color: '#242A38',margin:0, textAlign:'center'}}>
              {symbol}
            </p>
          </div>
        )
    }
}


    render(){
      console.log('no ticker',this.state.noTicker)
      if(this.state.noTicker === 'loading'){
        return(
            <div>
              <Header cp={true} user={this.props.user} signOut={this.props.signOut}/>
              <div style={{marginTop:60, display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', backgroundColor:'rgb(247, 247, 247)', height:700}}>
                  <h1>Loading....</h1>
              </div>
              <Footer isSmallScreen={this.state.isSmallScreen}/>
           </div>
        )
      }
      else if(this.state.noTicker){
        return(
            <div>
              <Header cp={true} user={this.props.user} signOut={this.props.signOut}/>
              <div style={{marginTop:60, display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', backgroundColor:'rgb(247, 247, 247)', height:700,}}>
                <div style={{width:'80%',margin:'auto'}}>
                  <h1>We do not currently support '{this.state.sourceData.symbol}'.</h1> 
                  <h3>Please make sure ‘{this.state.sourceData.symbol}’ is the right symbol. Our team will look to find all information about ‘{this.state.sourceData.symbol}’ and add it to our system.</h3>
                </div>
              </div>
              <Footer isSmallScreen={this.state.isSmallScreen}/>
          </div>
        )
      }
      else{

        const {sourceData} = this.state
        const {currentPrice} = sourceData
        let currentPriceInt 
        let hiPriceInt 
        let loPriceInt 
        let openPriceInt
        let prevCloseChange
        let prevClosePrice 
        let dollarChange 
        let textColor 
        if(sourceData){
          if(currentPrice){
              currentPriceInt = parseFloat(currentPrice.currentPrice)
              hiPriceInt = parseFloat(currentPrice.hiPrice)
              loPriceInt = parseFloat(currentPrice.loPrice)
              openPriceInt = parseFloat(currentPrice.openPrice)
              prevCloseChange = parseFloat(currentPrice.prevClose)
              prevClosePrice = parseFloat(currentPrice.closePrice)
              dollarChange = currentPriceInt - prevClosePrice
              textColor = dollarChange < 0 ? 'rgb(181,23,28)' : 'rgb(32,179,125)'
          }
          let selected = {
            symbol:sourceData.symbol,
            priceUSD: sourceData.currentPrice.currentPrice,
            company:sourceData.companyName,
            industry: sourceData.industry,
            imageURL:  this.state.imgURL,
            returnForecast: sourceData.returnForecast,
            needOptimization: sourceData.needOptimization

          }

            return(
                <div style={{width:'100%', paddingTop:this.state.isSmallScreen? 20:null}}>
                    <Header cp={true} user={this.props.user} signOut={this.props.signOut} isSmallScreen={this.state.isSmallScreen}/>
                    {this.state.showTrade? <TradeModal stockPage={true} canTrade={this.state.canTrade} user={this.props.user} acc={'NO'}  closeTrade={this.closeTrade} selected={selected}/> : null}
                    <div style={{marginTop:20, display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', backgroundColor:'rgb(247, 247, 247)', }}>
                        <div style={{display:'flex', alignItems:'center', width:'100%', justifyContent:'center'}}>
                            <div style={{display:'flex', alignItems:'center', width:this.state.isSmallScreen?'90%':'100%', flexDirection:'column', justifyContent:'center'}}>
                                <div style={{width:'70%', margin:'auto', marginTop: 75, marginBottom: this.state.isSmallScreen?null:null}}> 
                                  {/* {this.tabs()} */}
                                  <div style={{display:'flex', justifyContent:this.state.isSmallScreen?'center':'space-between', alignItems:'center', flexDirection:this.state.isSmallScreen?'column':"row"}}>
                                  {!this.state.isSmallScreen?
                                    <div onClick={()=>window.history.back()}>
                                      <FaLongArrowAltLeft size={50} color='#888888'/>
                                    </div>
                                    :null}
                                    <div style={{width:'40%',}}>
                                      {this.searchbar()}
                                    </div>
                                    
                                    <div style={{ width: '40%', display:'flex', justifyContent:'flex-end'}}>
                                        {this.wlButtons()}
                                    </div>
                                  </div>
                                </div>
                                <div style={{width:'70%', display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:10}}>
                                  <div style={{display:'flex'}}>
                                    <div style={{display:'flex', zIndex:0}}>
                                      {/* {this.checkLogo(sourceData.symbol)} */}
                                      <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:100, height:100,justifySelf:'flex-end',}}>
                                        {/* <img 
                                            // src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
                                            src={this.state.imgURL}
                                            onerror="this.onerror=null; this.remove();" 
                                            style={{objectFit:'contain', width:100,height:100, }}
                                        /> */}
                                        {this.state.imgURL === "not-found"?
                                          <div style={{objectFit:'contain', width:80,height:80,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(17,17,17)',borderRadius:10 }}
                                          >
                                              <p style={{margin:0, fontSize:32,fontWeight:'bold', color:'rgb(255,255,255)'}}>{this.state.sourceData.symbol[0]}</p>
                                          </div>
                                        :
                                          <img 
                                              // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
                                              src={this.state.imgURL}
                                              style={{objectFit:'contain', width:80,height:80,padding:10, }}
                                          />
                                        }
                                    </div>
                                      <div style={{margin:'auto', marginLeft:20}}>
                                        <p style={{fontSize: '28px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1, letterSpacing: '1.2px', textAlign: 'left', color: '#242A38', margin:0, marginBottom:10 }}>
                                          {sourceData.companyName}
                                        </p>
                                        <p style={{opacity: 0.53, fontSize: '24px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.08, letterSpacing: '0.48px', textAlign: 'left', color: '#242A38',margin:0 }}>
                                          {sourceData.symbol}
                                        </p>
                                      </div>
                                    </div>
                                    
                                  </div>
                                  {this.props.isSmallScreen?null:
                                  <div style={{display:'flex'}}>
                                    <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center', marginRight:20}}>
                                      <p style={{ fontSize: '48px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: '1.09', letterSpacing: 'normal', textAlign: 'left', color: ''/*'rgb(123, 192, 195)'*/,}}>
                                          ${sourceData.tickerCurrency === 'USD' ? this.addCommas(sourceData.currentPrice.currentPrice.toFixed(2)): this.addCommas(sourceData.currentPrice.priceUSD.toFixed(2))}
                                      </p>
                                      {/* <p style={{ fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: '1.18', letterSpacing: '0.66px', textAlign: 'left', color: 'rgb(123, 192, 195)', verticalAlign:'bottom', margin:0, marginBottom:7, marginLeft: '1%'}}>
                                          {sourceData.tickerCurrency}
                                          
                                      </p> */}
                                    </div>
                                    {/* <div >
                                      {(sourceData.isCrypto || !prevCloseChange || !dollarChange ) ? null :
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center',}}>
                                            <ChangeSinceYest new={true} changePercent={prevCloseChange} dollarChange={dollarChange} textColor={textColor} />
                                        </div>
                                      }
                                    </div> */}
                                  </div>
                                  }
                                </div>
                                {this.state.isSmallScreen?null :
                                 <div style={{width:'70%', height:40, margin:'auto', marginBottom:25}}> 
                                 {this.tabs()}
                                 </div>}
                                {this.state.isSmallScreen? 
                                  <div style={{display:'flex',flexDirection:'column', width:'95%', margin:'auto', overflowY:'scroll'}}>
                                      <Ptp signalPrice={sourceData.signalPrice} signalPricePrev={sourceData.signalPricePrev} isSmallScreen={this.state.isSmallScreen} volume={sourceData.volume} isCrypto={sourceData.isCrypto} cvar={sourceData.riskData ? sourceData.riskData.cvar: 0} companyName={sourceData.companyName} symbol={sourceData.symbol} industry={sourceData.industry} tickerCurrency={sourceData.tickerCurrency} currentPrice={sourceData.currentPrice} direction={sourceData.direction} directionPrev={sourceData.directionPrev} directionLastUpdate={sourceData.directionLastUpdate} directionPrevLastUpdate={sourceData.directionPrevLastUpdate} />
                                      <RsCi isSmallScreen={this.state.isSmallScreen} sourceData={sourceData}/>
                                  </div>
                                :
                                  <div style={{display:'flex', justifyContent:'center', alignItems:'top', width: '100%', height:900}}>
                                      {this.state.activeTab === 'Ptp' ? 
                                          <Ptp signalPrice={sourceData.signalPrice} signalPricePrev={sourceData.signalPricePrev} isSmallScreen={this.state.isSmallScreen} volume={sourceData.volume} isCrypto={sourceData.isCrypto} cvar={sourceData.riskData ? sourceData.riskData.cvar: 0} companyName={sourceData.companyName} symbol={sourceData.symbol} industry={sourceData.industry} tickerCurrency={sourceData.tickerCurrency} currentPrice={sourceData.currentPrice} direction={sourceData.direction} directionPrev={sourceData.directionPrev} directionLastUpdate={sourceData.directionLastUpdate} directionPrevLastUpdate={sourceData.directionPrevLastUpdate} />
                                          : 
                                          <RsCi isSmallScreen={this.state.isSmallScreen} sourceData={sourceData}/>
                                      }
                                  </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{width:'100%', marginTop: this.state.isSmallScreen?null:-50}}>
                      <Footer isSmallScreen={this.state.isSmallScreen}/>
                    </div>
                </div>
            )
          }
        }
        
    }
}