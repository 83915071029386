import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import StrategiesTitle from "../StrategiesTitle";
import StrategiesCharts from "../layout/StrategiesCharts";
import LineCharts from "../layout/LineCharts";
import FundDashboard from "../layout/FundDashboard";
import SecureYourFuture from "../layout/SecureYourFuture";
import StrategiesPolicy from "../layout/StrategiesPolicy";
import Footer from "../Footer";
import Header from "../PublicHeader"

export class Strategies extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    document.title = `ForeSCITE`;
  
  }

  constructor(props) {
    super(props);
    this.state = {
      titles: [
        {
          id: 1,
          title: `Smoother journeys navigated by 24/7 risk monitoring, lower costs and smarter tax management.`,
          subtitle: `Event tested and established under varying conditions.  Explore how AllocateRite adapts to deliver sound risk-adjusted performance over the long-term.`
        },
        {
          id: 2,
          title: `See how AllocateRite handles extraordinary events in practice.`,
          subtitle: `As a result, AllocateRite delivers superior risk-adjusted performance.`
        }
      ]
    };
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <Container fluid>
        <Row className="page-blank-row" style={{height: 100}}/>
        <Row>
          <StrategiesTitle
            key={this.state.titles[0].id}
            value={this.state.titles[0]}
          />
        </Row>
        <Row>
          <StrategiesCharts />
        </Row>
        <Row>
          <StrategiesTitle
            key={this.state.titles[1].id}
            value={this.state.titles[1]}
          />
        </Row>
        <Row>
          <LineCharts isChinese={false} />
        </Row>
        <Row>
          <FundDashboard />
        </Row>
        <Row>
          <StrategiesPolicy />
        </Row>
        <Row>
          <SecureYourFuture isChinese={false} />
        </Row>
        <Row className="page-blank-row" />
        <Footer />
      </Container>
      </div>
    );
  }
}

export default Strategies;
