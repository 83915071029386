import React from 'react'
import CompanyInfo from '../companyPage3/CompanyInfo'
import CurrPrevTrend from '../companyPage3/CurrPrevTrend'

export default class Ptp extends React.Component{
    state={

    }

    render(){      
        if (this.props.isSmallScreen){
            return(
                <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                    <div style={{marginBottom:20,marginTop:20}}>
                        <CompanyInfo volume={this.props.volume} isSmallScreen={this.props.isSmallScreen} cvar={this.props.cvar} isCrypto={this.props.isCrypto} companyName={this.props.companyName} symbol={this.props.symbol} industry={this.props.industry} tickerCurrency={this.props.tickerCurrency} currentPrice={this.props.currentPrice} isSmallScreen={true}/>
                    </div>
                    <div style={{marginBottom:20}}>
                        <CurrPrevTrend generated={this.props.directionLastUpdate} signalPrice={this.props.signalPrice} direction={this.props.direction} isSmallScreen={true}/>
                    </div>
                    <div style={{marginBottom:20}}>
                        <CurrPrevTrend generated={this.props.directionPrevLastUpdate} direction={this.props.directionPrev} prev={true} isSmallScreen={true}/>
                    </div>
                </div>
            )
        } else{
            return(
                <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center'}}>
                    <div style={{height:'61%', width:'29%', marginRight: '1.5%'}}>
                        {
                            this.props.cvar? 
                            <CompanyInfo volume={this.props.volume} isSmallScreen={this.props.isSmallScreen} cvar={this.props.cvar} isCrypto={this.props.isCrypto} companyName={this.props.companyName} symbol={this.props.symbol} industry={this.props.industry} tickerCurrency={this.props.tickerCurrency} currentPrice={this.props.currentPrice}/>
                            :
                            null
                        }
                    </div>
                    <div style={{height:'61%', width:'41%'}}>
                        <div style={{height:'45%', width:'100%', marginBottom:20}}>
                            <CurrPrevTrend generated={this.props.directionLastUpdate} signalPrice={this.props.signalPrice} direction={this.props.direction}/>
                        </div>
                        <div style={{height:'45%', width:'100%',marginTop:20}}>
                            <CurrPrevTrend generated={this.props.directionPrevLastUpdate} direction={this.props.directionPrev} prev={true}/>
                        </div>
                    </div>
                </div>
            )
        }
    }
}