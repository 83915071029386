import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../layout/Footer";
import Header from "../layout/Header"

class ProvideList extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    icons: [
      {
        src: "images/ameriprise-financial-icon.png"
      },
      {
        src: "images/betterment-icon.png"
      },
      {
        src: "images/charles-schwab-icon.png"
      },
      {
        src: "images/chase-icon.png"
      },
      {
        src: "images/edward-jones-icon.png"
      },
      {
        src: "images/etrade-icon.png"
      },
      {
        src: "images/fidelity-icon.png"
      },
      {
        src: "images/merrill-edge-icon.png"
      },
      {
        src: "images/merrill-lynch-icon.png"
      },
      {
        src: "images/morgan-stanley-icon.png"
      },
      {
        src: "images/stash-icon.png"
      },
      {
        src: "images/td-ameritrade-icon.png"
      },
      {
        src: "images/vanguard-icon.png"
      },
      {
        src: "images/wealthfront-icon.png"
      },
      {
        src: "images/wells-fargo-icon.png"
      }
    ]
  };

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <Container>
        <Row className="page-blank-row" />
        <p className="provide-list-title">Including:</p>
        <Row className="icons-row">
          {this.state.icons.map(icon => (
            <Col xs={6} sm={4} className="icons-col">
              <img className="icons" src={icon.src} />
            </Col>
          ))}
        </Row>
        <Row className="page-blank-row" />
        <Footer />
      </Container>
      </div>
    );
  }
}

export default ProvideList;
