import React from 'react'
import { NavLink, /*Redirect*/ } from "react-router-dom";

import { Button, FormGroup, FormControl, } from "react-bootstrap";
import Header from '../PublicHeader';
import ARlogo from "../../assets/ar_logo.png"
import ForeSciteLogo from "../../assets/newUI/foreScite_noText.png"
import Entry from "../../assets/newUI/entry.svg"
import Locked from "../../assets/newUI/locked.svg"
import Mail from "../../assets/newUI/mail.svg"
import Verified from "../../assets/newUI/verified.svg"
import 'react-phone-input-2/lib/bootstrap.css'
// import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2'
import '../../app.css'


// import Amplify from "aws-amplify";
import Amplify, { Auth, container } from 'aws-amplify'
import awsExports from "../../aws-config";

Amplify.configure(awsExports);

const brandColor = "#00A99D"
const phoneInputStyle = {background:'transparent !important' , border:'none !important'}
export default class SignUp extends React.Component {

    state = {
        username: '',
        password: '',
        passwordConfirm: '',
        phoneNumber: '',
        agree: false,
        showCode: false,
        authCode: '',
        errors:false,
        signUpClicked: false
    }

    componentDidMount(){
        this.updateWindowDimensions()
    }

    signInRedirect = () =>{
        const {history} = this.props 
        this.props.signIn(this.state.username, this.state.password)
        if(this.props.errors){
            console.log('errors present, not going to watchlist')
            return null
        }
        else{
           console.log('going to watchlist?')
        }
    }

    confirmSignUp() {
        const { username, authCode, } = this.state;
        // const username = email;
        console.log("username", username);
        Auth.confirmSignUp(username, authCode)
            .then(() => {
                window.alert("Sign up successful");
                this.props.signIn(this.state.username, this.state.password)
            })
            .catch(err => {
                if (!err.message) {
                    console.log("Error when entering confirmation code: ", err);
                    window.alert("Error when entering confirmation code: ", err);
                    this.setState({ authCodeText: "Resend Code" });
                } else {
                    console.log("Error when entering confirmation code: ", err.message);
                    window.alert("Error when entering confirmation code: ", err.message);
                    this.setState({ authCodeText: "Resend Code" });
                }
            });
    }


    validateUsername = ()=>{
        const{ username }= this.state
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(username).toLowerCase());
    }

    validatePassword = ()=>{
        const{ password, passwordConfirm }= this.state
        if(password.length < 5 || password !== passwordConfirm ){
            return false
        }else{
            return true
        }
    }
    validatePhoneNumber = ()=>{
        console.log('type of ', typeof this.state.phoneNumber)
        if(typeof this.state.phoneNumber === 'undefined' ){
            return false
        }else{  
            return this.state.phoneNumber.length > 6
        }
    }

    validateForm() {
        const { agree } = this.state
        return (this.validatePhoneNumber() && this.validatePassword() && this.validatePassword() && agree)
        
    }

    showCodeAndSuccess = ()=>{
        console.log('got to step 2')
        if (!this.state.errors) {
            this.setState({ showCode: true, signUpClicked: true, sendCodeClicked:true })
        }   
        else{
            return null
        }
    }

    signUp =()=>{
        console.log('got here')
        const { username, password, phoneNumber} = this.state
        let email = username
        const phone_number = phoneNumber
        if (this.validateForm()){
            Auth.signUp({
                username,
                password,
                attributes:{
                    email,
                    // phone_number: `+${phone_number}`
                }
            })
            .then(resp => console.log(resp))//CATCH ERRORS
            .catch(error => this.setState({ errors: error.message }))
            .then(this.showCodeAndSuccess())
    
        }
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    showErrors = () => {
        if (this.state.errors) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '75%' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(253,180,181)', borderRadius: 10, padding: 10, marginBottom: 5, width: '100%' }}>
                        <p style={{ margin: 0 }}>{this.state.errors}</p>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    showSuccess = () => {
        if (!this.state.errors && this.state.signUpClicked) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '75%' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(234,255,219)', borderRadius: 10, padding: 10, marginBottom: 5, width: '100%' }}>
                        <p style={{ margin: 0, textAlign:'center' }}>{`A confirmation code has been sent to you at ${this.state.username}`}</p>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
    

    signUpForm = () => {
        const { username, password, passwordConfirm, phoneNumber, agree, authCode } = this.state
        return (
            <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="username" bsSize="large">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 10 }}>
                        <p style={{ color: '#53b9b6', fontSize: 28, paddingBottom: 10, fontWeight: 'bold', }}>SIGN UP</p>
                    </div>
                    <div style={{backgroundColor:'#f0f0f0', display:'flex', paddingTop:'10px', paddingBottom:'10px'}}>
                        <div style={{width:75, display:'flex', alignItems:'center', justifyContent:'center' }}>
                            <img
                                src={Mail}
                            />
                        </div>
                        <div style={{paddingTop:5, paddingBottom:5, paddingLeft:7, paddingRight:7, width:'80%'}}>
                            <p>Email Address</p>
                            <input
                                style={{minWidth:200, border:'none', width:'100%', backgroundColor:'transparent', outline:'none'}}
                                autoFocus
                                type="username"
                                placeholder="yourname@example.com"
                                value={username}
                                onChange={e => this.setState({ ['username']: e.target.value })}
                            />
                        </div>
                    </div>
                    </FormGroup>
                    <FormGroup>
                    <div style={{border:'solid',borderColor:'#f0f0f0', borderWidth:'.25px', display:'flex',display:'flex', paddingTop:'10px', paddingBottom:'10px'}}>
                        <div style={{width:75, display:'flex', alignItems:'center', justifyContent:'center' }}>
                            <img
                                src={Locked}
                            />
                        </div>
                        <div style={{paddingTop:5, paddingBottom:5, paddingLeft:7, paddingRight:7, width:this.state.isSmallScreen?'50%':'80%'}}>
                            <p>Phone Number</p>
                            <PhoneInput
                                class="phoneInput"
                                onChange={(e) => this.setState({ ['phoneNumber']: e}) }
                                style={{}}
                                country='us'
                                enableSearch={true}
                            />
                        </div>
                    </div>
                </FormGroup>
                {/* <FormControl
                    style={{minWidth:200}}
                    autoFocus
                    placeholder='Country Code and Phone Number'
                    type="text"
                    value={phoneNumber}
                    onChange={e => this.setState({ ['phoneNumber']: e.target.value })}
                /> */}
               
                <FormGroup controlId="password" bsSize="large">
                <div style={{backgroundColor:'#f0f0f0', display:'flex', paddingTop:'10px', paddingBottom:'10px'}}>
                        <div style={{width:75, display:'flex', alignItems:'center', justifyContent:'center' }}>
                            <img
                                src={Locked}
                            />
                        </div>
                        <div style={{paddingTop:5, paddingBottom:5, paddingLeft:7, paddingRight:7, width:'80%'}}>
                            <p>Password</p>
                            <input
                                style={{minWidth:200, border:'none', width:'100%', backgroundColor:'transparent', outline:'none'}}
                                placeholder='Password'
                                value={password}
                                onChange={e => this.setState({ ['password']: e.target.value })}
                                type="password"
                            />
                        </div>

                    </div>
                </FormGroup>
                <FormGroup controlId="passwordconfirm" bsSize="large">
                    
                    <div style={{border:'solid',borderColor:'#f0f0f0', borderWidth:'.25px', display:'flex',display:'flex', paddingTop:'10px', paddingBottom:'10px'}}>
                        <div style={{width:75, display:'flex', alignItems:'center', justifyContent:'center' }}>
                            <img
                                src={Locked}
                            />
                        </div>
                        <div style={{paddingTop:5, paddingBottom:5, paddingLeft:7, paddingRight:7, width:'80%'}}>
                            <p>Confirm Password</p>
                            <input
                                style={{minWidth:200, border:'none', width:'100%', backgroundColor:'transparent', outline:'none'}}
                                placeholder='Confirm Password'
                                value={passwordConfirm}
                                onChange={e => this.setState({ passwordConfirm: e.target.value })}
                                type="password"
                            />
                        </div>

                    </div>
                </FormGroup>
                {/* <FormGroup>
                    <PhoneInput
                        onChange={(e) => this.setState({ ['phoneNumber']: e}) }
                        style={{width:'100%'}}
                        country='us'
                    />
                </FormGroup> */}
                <FormGroup controlId="agree" bsSize="large">
                    <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <input
                        style={{width: 20, color: brandColor}}
                        value={agree}
                        onClick={() => this.setState({ agree: !this.state.agree })}
                        type="radio"
                    />
                        <p style={{ margin: 0, marginLeft: 5, }}>I Agree to the <a style={{ color: brandColor }} href='https://www.allocaterite.com/PrivacyPolicy' target="_blank">Privacy Policy</a> & <a style={{ color: brandColor }} href='https://www.allocaterite.com/EULA' target="_blank">EULA</a></p>
                    </div>
                </FormGroup>
                <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    {this.showSuccess()}
                </div>
                {this.state.showCode ? 
                    <FormGroup controlId="passwordconfirm" bsSize="large">
                       <div style={{backgroundColor:'#f0f0f0', display:'flex', paddingTop:'10px', paddingBottom:'10px'}}>
                        <div style={{width:75, display:'flex', alignItems:'center', justifyContent:'center' }}>
                            <img
                                src={Locked}
                            />
                        </div>
                        <div style={{paddingTop:5, paddingBottom:5, paddingLeft:7, paddingRight:7, width:'80%'}}>
                            <p>Confirmation Code</p>
                            <input
                                style={{minWidth:200, border:'none', width:'100%', backgroundColor:'transparent', outline:'none'}}
                                placeholder='Confirmation Code'
                                value={authCode}
                                onChange={e => this.setState({ authCode: e.target.value })}
                                type="text"
                            />
                        </div>

                    </div>
                    </FormGroup>:null}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {this.showErrors()}
                </div>
                
                <Button block bsSize="large" onClick={this.state.showCode && this.state.errors !== 'Invalid phone number format.'? ()=>this.confirmSignUp():()=> this.signUp()} disabled={this.state.showCode?this.state.authCode.length < 2 :!this.validateForm()} style={{ border:'solid', borderWidth:'.5px' ,borderColor: '#3b8e8c', width:this.state.isSmallScreen?'80%':'30%', backgroundColor:'transparent', margin:this.state.isSmallScreen?'auto':'', marginBottom:this.state.isSmallScreen?20:''}} type="submit">
                    <p style={{color:'#3b8e8c', margin:0}}>Sign Up</p>
                </Button>
            </form>
        )
    }

    updateWindowDimensions = () => {
        if (window.innerWidth < 1000) {
          this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
        } else {
          this.setState({ isSmallScreen: false, screenWidth: window.innerWidth });
        }
      };

    render() {
        if (this.props.user) {
            window.location.replace("/Dashboard")
        } 
        return (
            <div>
                <Header hiddenSearch={true} isSmallScreen={this.props.isSmallScreen}/>
                <div style={{ width:'100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 25 }}>
                    {/* <div style={{ height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        <img
                            src={ForeSciteLogo}
                            style={{ height: 50 }}
                        />
                    </div> */}
                    <div style={{width:'100%', display:'flex',alignItems:'center', height:'100%', justifyContent:'space-between' }}>
                        <div className="Login" style={{  borderRadius: 20, width:this.state.isSmallScreen?'100%':'50%',alignSelf:'flex-start', marginTop:75 }}>
                            <div style={{ height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, marginTop:40 }}>
                                <img
                                    src={ForeSciteLogo}
                                    style={{ height: 75 }}
                                />
                            </div>
                            <div style={{ paddingLeft: this.state.isSmallScreen? '2%' :100, paddingRight: this.state.isSmallScreen? '2%' :100, paddingTop: 30, }}>
                                {this.signUpForm()}
                                <div style={{display:'flex', marginTop:20}}>
                                        <p style={{color:'#959595'}}>Already have an account? <NavLink style={{color:'#3b8e8c'}} to='/Login'>login here</NavLink></p>
                                </div>
                            </div>
                            
                        </div>
                        {this.state.isSmallScreen? null :
                            <div style={{width:'50%', height:'100%' }}>
                                <img
                                    src={Entry}
                                    style={{width:'100%', height:'auto',}}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}