import React from 'react'
import LinkToPlaid from '../LinkToPlaid'
import RoiChart from '../roiChart'
import NoImage from "../../assets/newUI/usersicon.png"
import { FaUserCircle, FaCog,FaLongArrowAltLeft } from "react-icons/fa"
import AccountModal from "./AccountModal"
import ENDPOINT from '../Endpoint'
import { NavLink } from 'react-router-dom'

const brandColor = "#00A99D"
const GREEN = '#3b8e8c'


export default class Account extends React.Component{
    state={
        activeTab:'inv',
        acc:false,
        hover:false,
        dwFix:[],
        dwNeedsFix:[],
        settingsDropdown:false,
        youSure:false,
        //kyc
        kyc:false,
        docType:'PASSPORT',
        docFront:false,
        docBack:false,
        kycNecessary:false,

    }

    componentDidMount(){
        this.getKycStatus()
        this.fetchPresetCategories()
    }

    fetchPresetCategories = ()=>{
        fetch(`${ENDPOINT}/drivewealth/preset`,{
            method: "GET",
            headers:{
              'Content-Type': 'application/json',
            },
          })
          .then(res => res.json())
          .then(responseJson => this.setState({presets:responseJson}))

    }

    createRow = (title,value)=>{
        return(
            <div style={{width: '95%', display:'flex', justifyContent:'space-between', alignItems:'center', margin:'auto', borderRadius: 7,backgroundColor: 'rgba(247, 247, 247, 0.73)', padding:10, marginTop:'3%', marginBottom:'3%', paddingLeft:5, paddingRight:5}}>
                <div style={{width:'50%', marginLeft: 10}}>
                    <p style={{color:'#242a38' ,margin:0}}>
                        {title}
                    </p>
                </div>
                <div style={{borderRadius: 15, backgroundColor: GREEN, padding: '2%',width:'50%',marginRight:10}}>
                    <p style={{color:'rgb(255,255,255)', margin:0, fontSize:'13px', textAlign:'center'}}>
                        {value}
                    </p>
                </div>
            </div>
        )
    }
    
    tabs(){
        let selectedBk = {backgroundImage: this.state.activeTab === 'inv' ? 'linear-gradient(94deg, rgb(30, 51, 66) 0%, rgb(73, 185, 168))' : 'linear-gradient(94deg,rgb(73, 185, 168) 0%, rgb(30,51,66)', width: '50%', height: '100%', cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: this.state.activeTab === 'inv' ?'5px 0px 0px 5px':'0px 5px 5px 0px', height:'100%',}
        let unselectedBk = {width: '50%', height: '100%', cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: this.state.activeTab === 'inv'? '0px 5px 5px 0px' :'5px 0px 0px 5px', height:'100%', backgroundColor:'rgba(146, 149, 161, 0.1)'}
        let selectedTxt = {margin:0, margin:'auto',marginLeft: this.state.isSmallScreen? 10:'auto', fontSize: this.state.isSmallScreen?14:16, fontSize: '16px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.3, letterSpacing: 'normal', textAlign: 'left', color: 'rgb(255, 255, 255)',}
        let unselectedTxt = {margin:0, margin:'auto',marginLeft: this.state.isSmallScreen? 10:'auto', fontSize: this.state.isSmallScreen?14:16, fontSize: 16, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.3, letterSpacing: 'normal', textAlign: 'left', color: 'rgb(146, 149, 161)',}
        return(
            <div style={{width: '100%', display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                <div 
                    style={this.state.activeTab === 'inv' ? selectedBk : unselectedBk}
                    onClick={() => this.setState({ activeTab:'inv' })}
                >
                    <p 
                        style={this.state.activeTab === 'inv' ? selectedTxt : unselectedTxt}
                    >
                    Investment Accounts
                    </p>
                </div>
                <div 
                    style={this.state.activeTab === 'bank' ? selectedBk : unselectedBk}
                    onClick={() => this.setState({ activeTab:'bank' })}
                >
                    <p 
                        style={this.state.activeTab === 'bank' ? selectedTxt : unselectedTxt}
                    >
                      Bank Accounts
                    </p>
                </div>
            </div>
        )
    }

    filterAccs = ()=>{
        let data = this.props.accounts
        let filtered
       
        if(this.state.activeTab === 'bank'){
            filtered = data.filter(acc=> acc.type === 'depository')
        }
        else{
            filtered = data.filter(acc=> acc.type !== 'depository')
           
        }
        return filtered.map(acc=>this.displayOneAcc(acc))
    }

    displayOneAcc = (acc)=>{
        return(
            <div style={{display:'flex', padding:10, marginBottom:5, backgroundColor:acc.name === this.state.hover? '#dddddd': ''}} onMouseLeave={()=>this.setState({hover:false})} onMouseEnter={()=>this.setState({hover:acc.name})}>
                <div style={{flex:3}}>
                    <p style={{margin:0}}>{acc.inst}</p>
                </div>
                <div style={{flex:3}}>
                    <p style={{margin:0}}>{acc.mask}</p>
                </div>
                <div style={{flex:3}}>
                    <p style={{margin:0}}>{acc.product?acc.product:this.state.activeTab === 'inv'?'Brokerage Account':'Bank Account'}</p>
                </div>
                <div style={{flex:3}}>
                    <p style={{margin:0}}>{acc.mask_full}</p>
                </div>
                {/* <div style={{flex:1, display:'flex',justifyContent:'flex-end',alignItems:'center',cursor:'pointer'}} onClick={()=>this.setState({acc:acc})}>
                    <FaCog/>
                </div> */}
            </div>
        )
    }
    accHeadings = ()=>{
        return(
            <div style={{display:'flex', padding:10, marginBottom:5,marginTop:10}}>
                <div style={{flex:3}}>
                    <p style={{margin:0, fontWeight:'bold'}}>Institution</p>
                </div>
                <div style={{flex:3}}>
                    <p style={{margin:0, fontWeight:'bold'}}>Account #</p>
                </div>
                <div style={{flex:3}}>
                    <p style={{margin:0, fontWeight:'bold'}}>Product</p>
                </div>
                <div style={{flex:3}}>
                    <p style={{margin:0, fontWeight:'bold'}}>Name</p>
                </div>
                {/* <div style={{flex:1, display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                    <p style={{margin:0, fontWeight:'bold'}}>Settings</p>
                </div> */}
            </div>
        )
    }

    deleteOneCustom = (id)=>{
        this.setState({selectedStrat:''})
        fetch(`${ENDPOINT}/risk-monkey/portfolios/delete/${id}`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => console.log('responseJson:', responseJson))
          .then(()=>{
              let strats = {...this.props.customPorts}
              delete strats[id]
              this.props.updateCustoms(strats)
          })
    }

    showOneCustom =(title,data)=>{
        return(
            <div style={{display:'flex', flexDirection:'column'}} >
                <div 
                    
                    style={{width:'100%', display:'flex' , cursor:'pointer', justifyContent:'space-between', alignItems:'center', borderBottom: 'solid', borderWidth: .25, borderColor: '#ddd', height:'100%', padding:12, backgroundColor: this.state.hover === title ? '#f7f7f7':null}}
                >
                    <div style={{display:'flex',justifyContent:'flex-start',width:'60%' }}onMouseEnter = {()=>this.setState({hover:title})}
                    onMouseLeave = {()=>this.setState({hover:''})}
                      onClick={()=>this.setState({selectedStrat:this.state.selectedStrat === title? '':title})}
                    >
                        <p style={{margin:0, fontWeight:'bold'}}>
                        {title}
                        </p>
                    </div>
                    <div
                        style={{ height:'100%', borderLeft:'solid', borderWidth:.25, borderColor:'#ddd', display:'flex',alignItems:'center', justifyContent:'center', cursor:'pointer', /*backgroundColor:'rgb(253,180,181)',LIGHTER */ backgroundColor:'#ee5e68', paddingTop:5, paddingBottom:5,paddingLeft:15,paddingRight:15}}
                        // onClick={()=>this.deleteOneCustom(title)}
                        onClick={()=>this.setState({youSure:title})}
                    >
                        <p style={{margin:0, color:'rgb(255,255,255)'}}>
                            Delete
                        </p>
                    </div>
                </div>
                <div>
                    {this.showPortData(title,data, false)}
                </div>
            </div>
        )
    }

    showPortData = (title, data, location)=>{
        let arr = []
        if(this.state.selectedStrat===title){
            data.forEach(ele=>{
                // USE THIS TO MIRROR HOLDINGS
                arr.push(
                    <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', borderBottom: 'solid', borderWidth: .25, borderColor: '#ddd'}}>
                        <div style={{width:Array.isArray(ele)?'25%':'50%', borderRight: 'none', borderWidth: .25, borderColor: '#ddd',display:'flex', justifyContent:'flex-start', alignItems:'center', padding:12}}>
                            {Array.isArray(ele)?
                                <a style={{textDecoration:'none', color:brandColor, cursor:'pointer'}} href={ele.symbol === '$$'?null :`/CompanyPage/${ele[0]}`} target='_blank'>{ele[0]}</a>
                                :
                                <a style={{textDecoration:'none', color:brandColor, cursor:'pointer'}} href={ele.symbol === '$$'?null :`/CompanyPage/${ele.symbol}`} target='_blank'>{ele.symbol}</a>
                            }
                        </div>
                        <div style={{width:Array.isArray(ele)?'25%':'50%',display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                            {Array.isArray(ele)?
                                <p style={{margin:0}}>{ele.symbol==='$$' ? `$${this.addCommas(parseFloat(ele[1]).toFixed(2))}` : this.addCommas(ele[1].toFixed(2))}</p>
                                :
                                <p style={{margin:0}}>{ele.symbol==='$$' ? this.addCommas(parseFloat(ele.quantity).toFixed(2)) : this.addCommas(ele.quantity.toFixed(2))}</p>
                            }
                        </div>
                        {Array.isArray(ele)?
                            <div style={{width:'25%',display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                                <p style={{margin:0}}>{this.addCommas(parseFloat(ele[2]).toFixed(2))}%</p>
                            </div>
                            :null
                        }
                        {Array.isArray(ele)?
                            <div style={{width:'25%',display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                                <p style={{margin:0}}>${(parseFloat(ele[3])).toFixed(2)}</p>
                            </div>
                            :null
                        }
                    </div>
                )
            })
            return(
                <div>
                    <div style={{display:"flex", flexDirection:'row', width:'100%', width:'100%'}} onClick={()=>console.log(`clicked header`)}>
                        <div style={{width:location==='linked'?'25%':'50%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-start',padding:12,borderRight:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                            <p style={{fontWeight:'bold', margin:0,}}>
                                Symbol
                            </p>
                        </div>
                        <div style={{width:location==='linked'?'25%':'50%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12}}>
                            <p style={{fontWeight:'bold', margin:0,}}>
                                Number of Shares
                            </p>
                        </div>
                        {location === 'linked' ? 
                        <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12, borderLeft:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                            <p style={{fontWeight:'bold', margin:0,}}>
                            Percentage
                            </p>
                        </div>:null
                        }
                        {location === 'linked' ? 
                        <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12, borderLeft:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                            <p style={{fontWeight:'bold', margin:0,}}>
                                Position Value
                            </p>
                        </div>:null
                        }
                    </div>
                    <div style={{width:'100%',maxHeight:600, overflowY:'scroll'}}>
                        <div>
                            {arr}
                        </div>
                    </div>
                </div>
            )
        }
    }

    addCommas(flt){
        return flt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    fixes=()=>{
        let fixed = []
        this.dwNeedsFix.forEach(fix=>{
            return (
                <div>
                    <p>SSN</p>
                    <input type='text' value={{dwFix:''}} onChange={(e)=>this.setState({dwFix:e.target.value})}/>
                </div>
            )
        }
        )
    }

    customized =()=>{
        let general = {backgroundImage: 'linear-gradient(94deg, rgb(30, 51, 66) 0%, rgb(73, 185, 168))', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5, height:'100%',}
        let arr = []
        Object.keys(this.props.customPorts).forEach(ele=>arr.push(this.showOneCustom(ele ,this.props.customPorts[ele])))
        return(
            <div style={{minheight: 0,marginTop:25, width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-start', flexDirection:'column', marginBottom: 25}}>
                <div style={{width:'95%', display:'flex',justifyContent:'flex-start', alignItems:'center'}}>
                    <h4 style={{color:'rgb(17,17,17)'}}>Your Customized Portfolio Strategies</h4>
                </div>
                <div style={{width:'95%', display:'flex',justifyContent:'flex-start', alignItems:'center'}}>
                    <p style={{color:'#afb2bb'}}>Use our <strong style={{fontWeight:'bold', color:brandColor, cursor:'pointer'}} onClick={()=>this.props.toPc()}> portfolio constructor</strong> to create a new portfolio strategy, or optimize an existing portfolio</p>
                </div>
                {Object.keys(this.props.customPorts).length === 0?null:<div style={general}>
                        <p style={{margin:0, padding:10, color:'rgb(255,255,255)'}}>Portfolio Name - Select for more infomation</p>
                </div>}
                <div style={{width:'95%'}}>
                    {this.props.customPorts?
                        <div style={{}}>
                            {arr}
                        </div>
                        :null
                    }
                </div>
            </div>
        )
    }

    settings = ()=>{
        if(this.state.settingsDropdown){
            return(
                <div onMouseLeave={()=>this.setState({settingsDropdown:false})}
                >
                     <div style={{width:'100%', display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                            <FaCog size={'25px'}/>
                    </div>
                    <div style={{position: 'absolute', right:'2%',backgroundColor: '#f9f9f9',minWidth: '10%',overflow: 'auto',boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',zIndex: 100, marginRight:25}}>
                       <NavLink to='/Questionnaire' exact={true}>
                            <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'orders'?'rgb(200,200,200)':'',}} onMouseEnter={()=>this.setState({hover:'orders'})}>
                                <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Update Investment Questionnaire</p>
                            </div>
                       </NavLink>
                       <NavLink to='/UpdateDw' exact={true}>
                            <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'statements'?'rgb(200,200,200)':'',}} onMouseEnter={()=>this.setState({hover:'statements'})}>
                                <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Update DriveWealth Application</p>
                            </div>
                        </NavLink>
                        {
                            this.state.kycNecessary?
                                <div>
                                    <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'kyc'?'rgb(200,200,200)':'',}} onClick={()=>this.setState({kyc:true})} onMouseEnter={()=>this.setState({hover:'kyc'})}>
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Upload Proof of ID</p>
                                    </div>
                                </div>
                                :null
                        }
                    </div>
                </div>
            )
        }
        else{
            return(
                <div>
                    <div style={{width:'100%', display:'flex',justifyContent:'flex-end',alignItems:'center'}}
                    onMouseEnter={()=>this.setState({settingsDropdown:true})}
                    >
                        <FaCog size={'25px'}color={'rgb(34,37,41)'}/>
                    </div>
                </div>
            )
        }
    }

    sureCheck=()=>{
        let title =this.state.youSure
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', paddingBottom:25}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Delete {title}</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({youSure:false})}> X </p>
                    </div>
                    <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',marginTop:30,paddingLeft:10,paddingRight:10}}>
                        <p>
                            Are you sure you want to delete the custom strategy: {title}?
                        </p>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center',width:'100%'}}>
                            <div style={{backgroundColor:'rgb(221,103,108', borderRadius:12, padding:20, marginTop:10, marginBottom:20, cursor:'pointer'}} onClick={()=>{
                                this.deleteOneCustom(title)
                                this.setState({youSure:false})
                            }
                            }>
                                <p style={{margin:0, color:'rgb(255,255,255)'}}>
                                    Delete this strategy
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }

    createDropdownDocType =()=>{
        let list = {}
        if (this.state.presets){
            list = this.state.presets.document_types_v2
        }
        let mapped = []
        list.forEach(option=>{
            let title = Object.keys(option)[0]
            mapped.push(
                <option value={option.name} selected={this.state.docType == option.name}>{option.description}</option>
            ) 
        })
        return(
            <select placeholder="Select Below" onChange={(e)=>this.setState({docType:e.target.value})} value={this.state.docType}>
                {mapped}
            </select>
        )
    }

    getKycStatus = ()=>{
        let hasDw = false
        for (let i = 0; i < this.props.accounts.length; i++) {
            if(this.props.accounts[i].inst === "DriveWealth"){
                hasDw = true
                i+=this.props.accounts.length
            }
        }
        if(hasDw){
            fetch(`${ENDPOINT}/dw/kyc`,{
                headers:{
                    Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                    "Content-Type": "application/json",
                },
            })
            .then(res => res.json())
            .then(responseJson => {
                // console.log('kyc status resp', responseJson)
                if(responseJson.payload.status &&  responseJson.payload.status.name === "APPROVED"){
                    this.setState({kycNecessary:false})
                }else{
                    this.setState({kycNecessary:true})
                }
            })
            
        }
    }

    uploadImage = (front)=>{
        // let body = {
        //     "userID":dwUserID,
        //     "type": this.state.docType,
        //     "documentImage":image,
        //     "side":front?'FRONT':'BACK'
        // }
        let dwUserId = false
        for (let i = 0; i < this.props.accounts.length; i++) {
            if(this.props.accounts[i].inst === "DriveWealth"){
                dwUserId = this.props.accounts[i].itemId
                i+=this.props.accounts.length
            }
        }
        console.log("[INFO] Do KYC document upload");
        let prodid = dwUserId;
        // let testId = "193e233f-22ec-401e-a938-35927974f84f";
        let url = ENDPOINT + "/user/kyc-doc";
        // let testurl = "http://arjavaapi-env-test.us-east-1.elasticbeanstalk.com/drivewealth/user/kyc-doc";
        let produrl = "https://api.allocaterite.com/drivewealth/user/kyc-doc"
        // let proddriecturl = "http://arjavaapi-env.eba-pppapp25.us-east-1.elasticbeanstalk.com/drivewealth/user/kyc-doc";
        // let devurl = "https://dev-api.allocaterite.com/drivewealth/user/kyc-doc";
        let doc = front?this.state.docFront:this.state.docBack
        let formData = new FormData();
        formData.append("userID", dwUserId);
        formData.append("documentType", this.state.docType);
        formData.append("documentImage", doc);
        formData.append("side",front? "FRONT":'BACK');
        fetch(produrl, {
            method: 'POST',
            headers:{
                "Accept": "application/json"
            },
            body: formData
        })
        .then((response)=> {
            return response.json();
        })
        .then((data)=> {
            console.log('file upload resp: ', data);
        }).catch(err => console.log('Upload Failed', err));
    }

    kycModal=()=>{
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', paddingBottom:25}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Document Upload</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({kyc:false})}> X </p>
                    </div>
                    <div style={{display: 'flex', justifyContent:'flex-start', alignItems:'center', flexDirection:'column', margin:'auto',marginTop:30, width:'95%'}}>
                        <p style={{fontSize:20, color:''}}>
                            To get your DriveWealth account approved please select a document type document below. 
                        </p>
                        {this.createDropdownDocType()}
                        <div style={{width:'95%', margin:'auto'}}>

                            <p style={{fontSize:20, color:'', marginTop:10}}>Then upload the necessary image{this.state.docType === 'PASSPORT' || this.state.docType === 'VISA'?'':'s'}.</p>
                            <p style={{fontSize:20, color:''}}>All files must be either .pdf .jpg or .png</p>
                            {this.state.docType === 'PASSPORT' || this.state.docType === 'VISA'?
                                <div>
                                    <p style={{margin:0, marginLeft:10,marginTop:10, fontSize:20}}>Front of Document</p>
                                    <input type="file" id="img" name="img" accept="image/*" onChange={event => {
                                            this.setState({
                                                docFront: event.target.files[0]
                                            }, () => {
                                            console.log(this.state.docFront);
                                            });
                                        }}
                                    />
                                </div>
                            :
                                <div style={{}}>  
                                    <p style={{margin:0, marginLeft:10,marginTop:10, fontSize:20}}>Front of Document</p>
                                    <input type="file" id="img" name="img" accept="image/*" onChange={event => {
                                            this.setState({
                                                docFront: event.target.files[0]
                                            }, () => {
                                            console.log(this.state.docFront);
                                            });
                                        }
                                    }
                                    />
                                    <p style={{margin:0, marginLeft:10,marginTop:10, fontSize:20}}>Back of Document</p>
                                    <input type="file" id="img" name="img" accept="image/*" onChange={event => {
                                            this.setState({
                                                docBack: event.target.files[0]
                                            }, () => {
                                            console.log(this.state.docFront);
                                            });
                                        }
                                    }/>
                                </div>
                            }
                        </div>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center',width:'100%'}}>
                            <div style={{backgroundColor:GREEN, borderRadius:12, padding:20, marginTop:10, marginBottom:20, cursor:'pointer'}} 
                                onClick={()=>{
                                    if(this.state.docType === 'PASSPORT' || this.state.docType === 'VISA'){
                                        this.uploadImage(true)
                                    }
                                    else{
                                        this.uploadImage(true)
                                        this.uploadImage(false)
                                    }
                                }}
                            >
                                <p style={{margin:0, color:'rgb(255,255,255)'}}>
                                    Upload Files
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }

    render(){
        // let user = this.state.userInfo
        return(
            <div>
                <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12, marginBottom:10   }}>
                    <div style={{width:'95%', margin:'auto',marginBottom:10, display:'flex', justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{width:'10%', display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                <FaLongArrowAltLeft size={40} color={'rgb(34,37,41)'} onClick={()=>window.history.back()}/>
                        </div>
                        <h3 style={{color:'rgb(17,17,17)', textAlign:'center'}}>Account Information</h3>
                        <div style={{width:'10%', display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                            {this.settings()}
                        </div>
                        {/* <h5 style={{color:'rgb(17,17,17)', textAlign:'center'}}>All your finances in one place</h5> */}
                    </div>
                    <hr></hr>
                    <div style={{width:'100%', display:'flex', justifyContent:this.props.isSmallScreen? '':'space-between',flexDirection:this.props.isSmallScreen?'column':'row'}}>
                        {this.props.isSmallScreen?null:
                        <div style={{width:this.props.isSmallScreen? '100%':'40%', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        {/* <img 
                            src={NoImage}
                            style={{margin:50,width:'60%',borderRadius: '60%',borderWidth:60,borderColor:"black"}}
                        /> */}
                        <FaUserCircle size={'50%'} color={GREEN} /> 
                            {/* <p style={{fontSize:18}}>User Email: {userEmail} </p> */}
                            {/* <p style={{fontSize:18}}>Link your bank account to better understand your spending</p>
                            <p style={{fontSize:18}}>ForeSCITE's AI will help you save by studying your habits and finding money for you to save and invest</p>
                            <p style={{fontSize:18}}>We will also warn you of any suspicious activity or large upcoming payments to make you sure you stay on top of your expenses </p> */}
                        </div>
                        }
                        {/* <div style={{padding:this.props.isSmallScreen?5: 10, height:'100%',width:'60%', }}> */}
                        <div style={this.props.isSmallScreen?{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', padding:5} :{padding: 10, height:'100%',width:'60%', }}>
                            {/* <div>
                                <p style={{margin:0, fontSize: 22, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 0.75, letterSpacing: '0.56px', textAlign: 'left', color: 'rgb(36, 42, 56)',marginBottom:5}}>
                                    Account
                                </p>
                            </div> */}
                            <div style={{width:'100%'}}>
                                {this.props.userEmail?this.createRow('User Email',this.props.userEmail):this.createRow('User Email','•••••@gmail.com')}
                                {this.props.userEmail?this.createRow('Password',"••••••"):null}
                                {this.props.subStatus?this.createRow('Subscription Status','Subscribed'):this.createRow('Subscription Status','Not Subscribed')}
                            </div>
                        
                        </div>
                    </div>
                </div>
                {/* <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12, marginBottom:10   }}>
                    <div style={{width:'95%', display:'flex',justifyContent:'flex-start', alignItems:'center'}}>
                        <h4 style={{color:'rgb(17,17,17)'}}>Your Linked Accounts</h4>
                    </div>
                    <div style={{height:48}}>
                        {this.tabs()}
                    </div>
                    <div style={{width:'90%',margin:'auto'}}>
                        {this.accHeadings()}
                        {this.filterAccs()}
                    </div>
                </div> */}
                {/* { this.dwNeedsFix.length === 0? null :
                    <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12, marginBottom:10   }}>
                        <div>
                            <p>Error With DriveWealth Account Approval</p>
                            <p>Please fix the fields below</p>
                        </div> 
                        <div>
                            {this.fixes()}
                        </div>                   
                    </div>
                } */}
                <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12, marginBottom:10   }}>
                    {this.props.customPorts? this.customized():null}
                </div>
                {this.state.acc? <AccountModal activeTab={this.state.activeTab} acc={this.state.acc} closeModal={()=>this.setState({acc:false})}/>:null}
                {this.state.youSure? this.sureCheck() :null}
                {this.state.kyc? this.kycModal() :null}
            </div>
        )
    }    
}