import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../PublicHeader"
import emailjs from 'emailjs-com';

const backgroundImage = require('../../assets/background.jpeg')

export class Contact extends Component {

  state={
    email:'',
    name:'',
    text:'',
    placeholder:'We appreciate any and all feedback!'
  }


  componentDidMount(){
    window.scrollTo(0, 0);
  }

  createEmail = () => {
    return `${this.state.text}`
  }

  checkName = ()=>{
    if (this.state.name.length > 0){
      return true
    }
    else{
      return false
    }
  }
  checkEmail = ()=>{
    if (this.state.email.length > 0){
      return true
    }
    else{
      return false
    }
  }
  checkBody = ()=>{
    if (this.state.text.length > 0){
      return true
    }
    else{
      return false
    }
  }

  reachOut = ()=>{
    return `${this.state.name} who can be reached at ${this.state.email} says:`
  }

  sendEmail = () => {
    let templateParams = {
      from_name: this.state.name,
      to_name: '<YOUR_EMAIL_ID>',
      subject: 'subject',
      contact: this.reachOut(),
      message: this.createEmail(),
 }
    console.log('email should say: ', this.createEmail())
    // emailjs.sendForm('service_m8qm1fe', 'template_jw39141', this.createEmail(), 'user_fsr7vl0Mp1zwzsbgJAwzD')
    emailjs.send(
      'service_m8qm1fe',
      'template_jw39141',
       templateParams,
      'user_fsr7vl0Mp1zwzsbgJAwzD'
 )
      .then((result) => {
          console.log('success result',result.text);
          this.setState({ 
            email:'',
            name:'',
            text:'',
            placeholder:'Your message has been sent!'
          })
      }, (error) => {
          console.log('error result',error.text);
          this.setState({ 
            email:'',
            name:'',
            text:'',
            placeholder:'Something went wrong'
          })
      });
  }

  render(){
      return(
        <div style={{width:'100%',}}>
            <Header user={this.props.user} signOut={this.props.signOut}/>
            <div style={{marginTop:50, width:'100%',marginTop: 15,}}>
              {/* <div style={{zIndex:-1, marginTop: -70}}>
                <img
                  src={backgroundImage}
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    transform: 'rotate(-5deg)',
                  }}
                /> 
                </div>*/}
              <div style={{zIndex:20, backgroundImage:`url(${backgroundImage})`, padding:50, width:'100%', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
                <div style={{ display:'flex', justifyContent:'center', alignItems:'center',marginTop: 70}}>
                    <p style={{fontSize:22, color:'rgb(255,255,255)'}}>
                        We would love to hear from you!
                    </p>
                </div>
                {/* <div>
                  <img
                    src={require('../../assets/background.jpeg')}
                  />
                </div> */}
                <div style={{width:'60%', height:'100%', display:'flex', justifyContent:'space-around', alignItems:'center', padding:10, margin:'auto', marginBottom: 20}}>
                  <input type="text" placeholder="name" value={this.state.name} onChange={(e)=>this.setState({name:e.target.value})} style={{paddingLeft:'0.5%', paddingRight:'0.5%'}}/>
                  <input type="text" placeholder="email" value={this.state.email} onChange={(e)=>this.setState({email:e.target.value})} style={{paddingLeft:'0.5%', paddingRight:'0.5%'}}/>
                </div>
                <div style={{width:'100%', height:'60%', display:'flex', justifyContent:'center', alignItems:'center', marginBottom: 20}}>
                    <textarea placeholder={this.state.placeholder} style={{height:200, width: '60%', padding: '1%'}} value={this.state.text} onChange={(e)=>this.setState({text:e.target.value})}/>
                </div>
                <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
                  <div 
                    onClick={this.checkBody && this.checkEmail()&&this.checkName() ? ()=>this.sendEmail() : null} 
                    style={{cursor:'pointer' ,display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'#ddd', borderRadius: 8, padding:10, marginBottom: 200}}
                  >
                    <p style={{fontSize:18, margin:0}}>Submit</p>
                  </div>
                </div>
            </div>
            <div style={{width:'100%', marginTop:0}}>
              <Footer/>
            </div>
        </div>
        </div>
      )
  }
  
}

export default Contact;
