import React from 'react'
import ENDPOINT from '../Endpoint'
import { FaUniversity } from 'react-icons/fa'; 
import BrokerageRow from '../BrokerageRow'
import AreYouSure from '../areYouSureModal'

const brandColor = "rgb(70,182,179)"


export default class BrokerageAcc extends React.Component{
    state={
       deposit:false,
       showAllStock:false,
       addRobo:[],
    }

    addCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    utcToLocal(timeAndDate){

        let date = new Date(timeAndDate).toString().split(" ")
        let hour = date[4].substring(0,2) 
        let min = date[4].substring(3,5) 
        let day = date[1]+ ' ' + date[2]+ ', ' + date[3]
        let amPm = 'AM'
        if (hour > 12){
            hour = hour - 12
            amPm = 'PM'
        }
        else if(hour === '00'){
            hour = '12'
        }
        let time = `${hour}:${min} ${amPm}`
        return (
            <div style={{width:'100%'}}>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {time.replace(/^0(?:0:0?)?/, '')} 
                </p>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {day}
                </p>
            </div>
        )
    }

    checkLogo = (symbol)=>{
        let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        if (http.status != 404){
            return (
              <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:70, height:70,justifySelf:'flex-end',}}>
                    <img 
                        // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
                        src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
                        onerror="this.onerror=null; this.remove();" 
                        style={{objectFit:'contain', width:70,height:70, }}
                    />
                </div>
            )
        }
    }

    row(stock,total){
        return(
            <div style={{width:'100%'}}>
                <div style={{display:'flex', justifyContent:'space-between', width:'100%', padding:5, alignItems:'center'}}>
                    <div style={{width:'8%'}}>
                        {stock.symbol === 'CASH'?null: this.checkLogo(stock.symbol)}
                    </div>
                    <a style={{width:'10%'}} target='_blank' href={stock.symbol === 'CASH' || stock.symbol === 'Us dollar' ?null:`/CompanyPage/${stock.symbol}`}><p style={{width:'100%', margin:0 ,textAlign:'left', color:brandColor}}>{stock.symbol}</p></a>
                    <p style={{width:'20%', margin:0 ,textAlign:'right'}}>${this.addCommas(stock.instPrice.toFixed(2))}</p>
                    <p style={{width:'20%', margin:0 ,textAlign:'right'}}>{this.addCommas(stock.quantity.toFixed(2))}</p>
                    <p style={{width:'20%', margin:0 ,textAlign:'right'}}>${this.addCommas(stock.instValue.toFixed(2))}</p>
                    <p style={{width:'20%', margin:0 ,textAlign:'right'}}>{isNaN(((stock.instValue/total)*100).toFixed(2))?0:((stock.instValue/total)*100).toFixed(2)}%</p>  
                    <div style={{width:'2%'}}></div>
                </div>
                <hr></hr>
            </div>
        )
    }


    buttonDropdown = (acc)=>{
        if(this.state.activeDropdown){
            return(
                <div>
                     <div 
                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)',fontSize:18}}>Menu</p>
                    </div>
                    <div style={{position: 'absolute', right:'5%',backgroundColor: '#f9f9f9',minWidth: '10%',overflow: 'auto',boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',zIndex: 100, marginRight:25}}>
                        {/* <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'deposit'?'rgb(200,200,200)':'',}}onClick={()=>this.props.deposit()} onMouseEnter={()=>this.setState({hover:'deposit'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Deposit</p>  
                        </div>
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'withdraw'?'rgb(200,200,200)':'',}}onClick={()=>this.props.withdraw()} onMouseEnter={()=>this.setState({hover:'withdraw'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Withdraw</p>  
                        </div> */}
                        {acc.inst  === 'DriveWealth' && !acc.ria && this.props.isSmallScreen? 
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'trade'?'rgb(200,200,200)':'',}}
                            onClick={()=>{
                                this.props.trade()
                                this.setState({hover:false})
                                } 
                            }
                            onMouseEnter={()=>this.setState({hover:'trade'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Trade</p>
                        </div>:null}
                        {acc.inst  === 'DriveWealth' && !acc.ria && this.props.isSmallScreen?  
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'transfer'?'rgb(200,200,200)':'',}}
                            onClick={()=>{
                                this.props.deposit()
                                this.setState({hover:false})
                            }} 
                            onMouseEnter={()=>this.setState({hover:'transfer'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transfer</p>
                        </div>:null}
                        {acc.inst  === 'DriveWealth' && !acc.ria? 
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'orders'?'rgb(200,200,200)':'',}}
                            onClick={()=>{
                                this.props.orders()
                                this.setState({hover:false})
                                }
                            }
                            onMouseEnter={()=>this.setState({hover:'orders'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Orders</p>
                        </div>:null}
                        {acc.inst  === 'DriveWealth'? 
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'transactions'?'rgb(200,200,200)':'',}}
                            onClick={()=>{ 
                                this.props.transactions()
                                this.setState({hover:false})
                                }
                            } 
                            onMouseEnter={()=>this.setState({hover:'transactions'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transactions</p>
                        </div>:null}
                        {acc.inst  === 'DriveWealth'? 
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'statements'?'rgb(200,200,200)':'',}}
                            onClick={()=>{
                                this.props.statements()
                                this.setState({hover:false})
                                } 
                            }onMouseEnter={()=>this.setState({hover:'statements'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Statements</p>
                        </div>:null}
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'analyze'?'rgb(200,200,200)':'',}}
                            onClick={()=>{
                                this.props.analyze()
                                this.setState({hover:false})
                                } 
                            }onMouseEnter={()=>this.setState({hover:'analyze'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Analyze</p>
                        </div>
                        {acc.inst  !== 'DriveWealth' || acc.product ? 
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'unlink'?'rgb(200,200,200)':'',}}
                            onClick={()=>{
                                this.props.unlink()
                                this.setState({hover:false})
                                } 
                            }onMouseEnter={()=>this.setState({hover:'unlink'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Unlink</p>
                        </div>:null}
                        
                    </div>
                </div>
            )
        }
        else{
            return(
                <div>
                     <div 
                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)', fontSize:18}}>Menu</p>
                    </div>
                </div>
            )
        }
    }

    accButtons = ()=>{
        let acc = this.props.acc
        return (
            <div style={{display:'flex', width:'60%', justifyContent:'flex-end', flexWrap:'wrap'}}>
                            {/* {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({orders:true,showOrders:acc})}>
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Orders</p>
                                    </div>
                                :null
                                }
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({orders:false,showOrders:acc})}>
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transactions</p>
                                    </div>
                                :null
                                }
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({showStatements:acc})}
                                    // onClick={()=>this.getStatements(acc.accountId)}
                                    >
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Statements</p>
                                    </div>
                                :null
                                } */}
                                <div 
                                style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#ee5e68', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                onClick={()=>this.setState({showUnlink:acc})}
                                >
                                    <p style={{margin:0,fontWeight:'', color:'#ee5e68'}}>Unlink</p>
                                </div>
                            </div>
        )
    }

    tradeStock = (acc,ele)=>{
        let stock
        fetch(`${ENDPOINT}/search/ticker-bulk-live?search=${ele.symbol.toUpperCase()}`)
        .then(resp=>resp.json())
        .then(responseJson=>{
           console.log('trade stock search resp: ', responseJson)
           stock = responseJson.content_list[0]
           this.props.tradeStock(acc,stock)
        })
    }

    formatPnL(val){
        let x = '0.00'
        if (!val){
            x = '0.00'
        }
        else{
             x = this.addCommas(val.toFixed(2))
             if (val < 0){
                x = '-$'  + x.substring(1)
             }
             else{
                x = '$'  + x 
             }
        }
        return <strong style={{color:val<0?'rgb(238,94,104)':brandColor}}>({x})</strong>
    }

    showAccBody = ()=>{
        let total = 0
        let acc = this.props.acc
        // console.log('acc', acc)
        
        acc.holdings.forEach(stock=>{
            if(!stock.cashEq){
                total += stock.instValue
            }
        })
        
        if(acc.balances[0].available === 0 && acc.balances[0].buyingPower === 0 && acc.balances[0].cashAvailableForWithdrawal === 0 && acc.balances[0].current === 0 && acc.holdings.length === 0){
            return(
                <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', marginTop:15}}>
                    <a onClick={()=>this.props.deposit()} href='#'><p style={{width:'100%', margin:0 ,textAlign:'left', color:brandColor, fontSize:22}}>To Get Started Click Here and Deposit Funds </p></a>
                    {acc.ria?<p>You must deposit at least $100 to get started with the robo</p>:null}
                </div>
            )
        }
        else if(acc.ria && acc.balances[0].available > 99.9 && !this.props.hasRobo){
            return(
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:15}}>
                    <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>Account Value: <strong style={{color:brandColor, marginLeft:5}}>${acc.balances&& acc.balances[0]&& acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):0}</strong> {acc.inst ==='DriveWealth' ? this.formatPnL(acc.balances[0]['profit-loss']):null} </p>
                    <a href='#'>
                        <p 
                        onClick={()=>this.setState({
                            addRobo:{
                                id:acc.accountId,
                                total: acc.balances[0].available ,
                                acc:acc,
                                instName: acc.inst
                            }
                            
                        })} 
                        style={{width:'100%', margin:0 ,textAlign:'left', color:brandColor, fontSize:22}}>Your Funds have Settled! Click Here to Link to Robo</p>
                    </a>
                </div>
            )
        }
        else if(acc.ria && acc.balances[0].available > 99.9){
            return(
                <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', marginTop:15}}>
                    <p style={{width:'100%', margin:0 ,textAlign:'center', color:brandColor, fontSize:22}}>You can only have one robo at account at a time.</p>
                </div>
            )
        }
        else{
            return(
                <div style={{width:'100%'}}>
                    <div style={{display:'flex', width:'95%', justifyContent:'space-between', flexWrap:'wrap', margin:'auto', marginBottom:25, marginTop:15}}>
                        <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>Account Value: <strong style={{color:brandColor, marginLeft:5}}>${acc.balances&& acc.balances[0]&& acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):0}</strong> {acc.inst ==='DriveWealth' ? this.formatPnL(acc.balances[0]['profit-loss']):null} </p>
                        {acc.inst  === 'DriveWealth' && !acc.ria? <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}> Buying Power: <strong style={{color:brandColor, marginLeft:5}}>${acc.balances&& acc.balances[0]&& acc.balances[0].buyingPower ?this.addCommas(acc.balances[0].buyingPower.toFixed(2)):null}</strong></p> : null}
                        <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}> Cash: <strong style={{color:brandColor, marginLeft:5}}>${acc.balances&& acc.balances[0]&& acc.balances[0].current ? this.addCommas((acc.balances[0].current-total).toFixed(2)):0}</strong></p>
                    </div>
                    {/* {this.renderButtons(acc)} */}
                    <div>
                        <div style={{display:'flex', justifyContent:'space-between', width:'100%', marginBottom:5, marginTop:10}}>
                            <p style={{width:'18%', textAlign:'center', fontSize:'large'}}>Symbol</p>
                            <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Cost Basis</p>
                            <p style={{width:'20%', textAlign:'right', fontSize:'large'}}># Shares</p>
                            <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Mkt Val</p>
                            <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Allocation</p>
                            <div style={{width:'2%'}}></div>
                        </div>
                    </div>
                    <div style={{maxHeight:this.state.showAllStock ?'':300, overflowY:'scroll'}}>
                        {acc.holdings.map(ele=><BrokerageRow tradeStock={()=>this.tradeStock(acc,ele)} key={ele.symbol} stock={ele} total={acc.balances[0].current}/>)}
                    </div>
                    {acc.holdings.length > 3 ?
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <p style={{color:brandColor, fontWeight:"bold", cursor:'pointer'}} onClick={()=>this.setState({showAllStock:!this.state.showAllStock})}>{this.state.showAllStock?'Show Less' :'Show More'}</p>
                    </div>
                    :null}
                </div>
            )
        }
    }

    linkToRobo = ()=>{
        // console.log('accountId:',accountId)
        let accountId = this.props.acc.accountId
        fetch(`${ENDPOINT}/trackers/live`,{
        method: "POST",
        headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            "Authorization": "Bearer " + this.props.user.idToken.jwtToken,
        },
        body:JSON.stringify({
            accountId:accountId,
        })
       })
       .then(response => response.json())
       .then(respJSON => console.log('link2robo resp',respJSON))
       .then(() => this.props.updateAccs())
       .catch((err)=>console.log('err', err))
    }

    closeSure = ()=>{
        this.setState({addRobo:[]})
    }

    render(){
        let total = 0
        let acc = this.props.acc
        // console.log('acc', acc)
        
        acc.holdings.forEach(stock=>{
            if(!stock.cashEq){
                total += stock.instValue
            }
        })
        if(this.props.isSmallScreen){
            return(
                <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column', }}>
                    <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:10, borderRadius: '50px',  backgroundImage:'linear-gradient(97deg, #ffffff 1%, #f2f2f2)', width:'100%',  marginTop:25}}>
                        <div style={{width:'100%', margin:'auto',marginBottom:10}}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{ acc.ria? 'Directed Account':'Brokerage Account'}</strong></p>
                                <div style={{display:'flex',justifyContent:'flex-end'}}>
                                    <div onClick={()=>this.setState({activeDropdown:!this.state.activeDropdown})}>
                                        {this.buttonDropdown(acc)}
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                                <div style={{marginLeft:10}}>
                                    <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.inst}</p>
                                    <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.mask_full}</p>
                                    {/* <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.name}</p> */}
                                    <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}><strong style={{color:brandColor,}}>${acc.balances&& acc.balances[0]&& acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):0}</strong></p>
                                </div>
                                <div style={{marginRight:10,}}>
                                    <div style={{height:48, display:'flex',justifyContent:'flex-end',alignItems:'center',marginTop:9, marginBottom:15}}>
                                        <FaUniversity style={{height:'100%', width:'auto'}}/>
                                    </div>
                                    <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.ria ? 'RIA-Directed':'Self-Directed'}</p>
                                </div>
                            </div>
                            <div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column', }}>
                    <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:10, borderRadius: '50px',  backgroundImage:'linear-gradient(97deg, #ffffff 1%, #f2f2f2)', width:'100%',  marginTop:25}}>
                        <div style={{width:'100%', margin:'auto',marginBottom:10}}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                
                                <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{acc.product === 'robo'? 'Robo': acc.product==='group'?'Trade Group': acc.ria? 'Directed Account':'Brokerage Account'}: </strong>{acc.inst === 'DriveWealth'? 'DriveWealth '+ acc.mask_full  :`${acc.inst} ${acc.mask}`}</p>
                                <div style={{display:'flex',justifyContent:'flex-end'}}>
                                    <div style={{}}>
                                    {
                                        acc.inst  === 'DriveWealth' && !acc.ria? 
                                            <div 
                                            style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#3b8e8c', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                            // onClick={()=>this.setState({showTrade:acc})}>
                                            onClick={()=>this.props.trade()}>
                                                <p style={{margin:0,fontWeight:'', color:'#3b8e8c',fontSize:18}}>Trade</p>
                                            </div>
                                        :null
                                    }
                                    
                                    </div>
                                    <div style={{}}>
                                    {
                                    acc.inst  === 'DriveWealth'? 
                                        <div 
                                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                        onClick={()=>this.props.deposit()}
                                        // onClick={()=>this.getStatements(acc.accountId)}
                                        >
                                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)',fontSize:18}}>Transfer</p>
                                        </div>
                                    :null
                                    }
                                    </div>
                                    {/* {
                                    acc.inst  === 'DriveWealth'? 
                                        <div 
                                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                        onClick={()=>this.props.withdraw()}
                                        // onClick={()=>this.getStatements(acc.accountId)}
                                        >
                                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)',fontSize:18}}>Withdraw</p>
                                        </div>
                                    :null
                                    } */}
                                    <div onMouseEnter={()=>this.setState({activeDropdown:true})} onMouseLeave={()=>this.setState({activeDropdown:false,hover:''})}>
                                        {this.buttonDropdown(acc)}
                                    </div>
                                </div>
                            </div>
                                {this.showAccBody()}
                        </div>
                    </div>  
                    {Object.keys(this.state.addRobo).length === 4 ? <AreYouSure product={'robo'} info={this.state.addRobo} confirm={this.linkToRobo} closeModal={this.closeSure} />:null}              
                </div>
            )
        }
    }
}