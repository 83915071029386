import React from 'react'
import { FaEye, FaEyeSlash, FaChevronCircleDown, FaChevronCircleUp,FaPlusCircle } from 'react-icons/fa';
import DdChart from '../companyPage3/DdChart'
import ReturnsChart from './ReturnsChart'
import TradeModal from './TradeModal'
import OrdersModal from './OrdersModal'
import RiskForecast from '../companyPage3/RiskForecast'
import UnlinkModal from './UnlinkModal'
import DepositModal from './DepositModal'
import RedemptionModal from './RedemptionModal'
import StatementsModal from './StatementsModal'
import GroupAcc from './GroupAcc'
import RoboAcc from './RoboAcc'
import BrokerageRow from '../BrokerageRow'
import { PlaidLink } from 'react-plaid-link';
import { NavLink } from 'react-router-dom'

import ENDPOINT from '../Endpoint'
import BrokerageAcc from './BrokerageAcc';
import BankAcc from './BankAcc';


const brandColor = "rgb(70,182,179)"

export default class Accounts extends React.Component{
    
    state={
        showVal: true,
        ddData:[],
        rData:[],
        stratData:{},
        showTrade: false,
        showUnlink: false,
        showDeposit: false,
        showStatements:false,
        showRedemption:false,
        showVal:true,
        showOrders:false,
        presetTickers:this.props.presetTickers,
        canTrade:true,
        orders:true,
        tgData:[],
        // filter:'false',
        filter:'inv',
        activeDropdown:false,
        plusDropdown:false,
        selectedStock:false,
        errorsSucceses:false,
    }

    componentDidMount(){
        // this.getUSDomReturn()
        // this.getUSDom()
        this.getMarketStatus()
        // this.fetchCommonStocks()
        this.accountTimer()
    }

    accountTimer = ()=>{
        setTimeout(()=>this.props.updateAccs(),120000)
    }

    getMarketStatus = ()=>{
        // console.log('inside can trade')
        fetch(`${ENDPOINT}/search/istradinghour`,{
            method:"GET"
        })
        .then((res) => res.json())
        .then((responseJson) => {
            // console.log('can trade resp: ', responseJson)
            this.setState({canTrade:responseJson})
        })
    }

    fetchCommonStocks = ()=>{
        // let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
        let url = `${ENDPOINT}/search/ticker-list`
        fetch(url)
            .then((res) => res.json())
            .then((responseJson)=>{
                this.setState({presetTickers: responseJson.lists})
            })
        
    }

    getStatements = (id)=>{
        let url = `${ENDPOINT}/dw/documents/${id}`
        fetch(url,{
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
            },
        })
            .then((res) => res.json())
            .then((responseJson)=>{
                console.log('get statements resp', responseJson)
                // this.setState({presetTickers: responseJson.lists})
            })
        
    }


    getUSDom(){
        fetch(`${ENDPOINT}/strategies/1`,{
            method:"GET"
        })
        .catch(err=>console.log(`catching errors strat info`, err))
        .then(res => res.json())
        .then(responseJson => this.setState({stratData: responseJson}) )
    }
    
    getUSDomReturn(){
        fetch(`${ENDPOINT}/strategies/ar-invest`,{
            method:"GET"
        })
        .catch(err=>console.log(`catching errors getting returns`, err))
        .then(res => res.json())
        // .then(responseJson => console.log( 'graph resp',responseJson))
        .then(responseJson => {
            let rData = []
            responseJson[0].linegraph_date.forEach((ele,i)=>{
                rData.push([Date.parse(ele),responseJson[1].monthly_return_ar[i]])
            })
            this.setState({rData})
            
        } )
    }

    getTotalVal = (arr)=>{
        let total = 0
        arr.forEach(ele=>{
            total += ele.value
        })
        return total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
    }

    closeTrade = ()=>{
        this.setState({showTrade:false})
        this.props.updateAccs()
    }
    closeUnlink = ()=>{
        this.setState({showUnlink:false})
        this.props.updateAccs()
    }
    closeDeposit = ()=>{
        this.props.updateAccs()
        this.setState({showDeposit:false})
    }
    closeOrders = ()=>{
        this.setState({showOrders:false})
    }
    closeStatements = ()=>{
        this.setState({showStatements:false})
    }
    closeRedemption = ()=>{
        this.setState({showRedemption:false})
    }

    showUnlinkMessage = () =>{
        this.setState({errorsSucceses:'Account Unlinked'})
    }



    robo = (acc)=>{
        // console.log('accRobo', acc)
        return(
            <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column' }}>
                <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%', display:'flex', justifyContent:'space-between',alignItems:'center' ,marginBottom:25}}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>Robo Strategy: </strong>{this.state.stratData.displayName} Composite</p>
                        <div style={{display:'flex'}}>
                            <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>Total Account Value:</strong> {this.state.showVal? '••••••••••' :'$2,298,431'}</p>
                            <div style={{display:'flex', alignItems:'center'}} onClick={()=>this.setState({showVal:!this.state.showVal})}>
                                {this.state.showVal ?<FaEye style={{width:20, marginLeft:10}}/> : <FaEyeSlash  style={{width:20, marginLeft:10}}/> }
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <p style={{margin:0, fontSize:22, textAlign:'right'}}>Chase</p>
                        <p style={{margin:0, fontSize:22, textAlign:'right'}}>JP Morgan - 5198</p>
                    </div>
                </div>
                <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%', display:'flex', flexDirection:"column", alignItems:'flex-start', marginBottom:25}}>
                    <p style={{margin:0, fontSize:22, textAlign:'left', marginBottom:10}}><strong>Strategy Description: </strong>{this.state.stratData.description}</p>
                    <div style={{display:'flex', justifyContent:'space-around', alignItems:'center', width:'100%'}}>
                        <div>
                            <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>Month To Date: </strong></p>
                            <p style={{margin:0, fontSize:22, textAlign:'left'}}>{this.state.stratData.mtd?(this.state.stratData.mtd*100).toFixed(2):'00.00'}%</p>
                        </div>
                        <div>
                            <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>Year To Date: </strong></p>
                            <p style={{margin:0, fontSize:22, textAlign:'left'}}>{this.state.stratData.ytd?(this.state.stratData.ytd*100).toFixed(2):'00.00'}%</p>
                        </div>
                        <div>
                            <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>Lifetime To Date: </strong></p>
                            <p style={{margin:0, fontSize:22, textAlign:'left'}}>{this.state.stratData.ltd?(this.state.stratData.ltd*100).toFixed(2):'00.00'}%</p>
                        </div>
                    </div>
                </div>
                <div style={{paddingLeft:'2%',paddingRight:'2%',paddingTop:40,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', padding:20}}>
                        <p style={{fontSize:24}}><strong>Returns</strong></p>
                        <div style={{width:'100%'}}>
                            <ReturnsChart returnsChart={this.state.rData}/>
                        </div>
                    </div>
                </div>
                {/* <div style={{paddingLeft:'2%',paddingRight:'2%',paddingTop:40,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', padding:20}}>
                        <p style={{fontSize:24}}>Historical Risk</p>
                        <div style={{width:'100%'}}>
                            <DdChart drawdownChart={this.state.ddData}/>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }

    utcToLocal(timeAndDate){

        let date = new Date(timeAndDate).toString().split(" ")
        let hour = date[4].substring(0,2) 
        let min = date[4].substring(3,5) 
        let day = date[1]+ ' ' + date[2]+ ', ' + date[3]
        let amPm = 'AM'
        if (hour > 12){
            hour = hour - 12
            amPm = 'PM'
        }
        else if(hour === '00'){
            hour = '12'
        }
        let time = `${hour}:${min} ${amPm}`
        return (
            <div style={{width:'100%'}}>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {time.replace(/^0(?:0:0?)?/, '')} 
                </p>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {day}
                </p>
            </div>
        )
    }
    

    tgRow(stock){
        return(
            <div style={{width:'100%'}}>
                <div style={{display:'flex', justifyContent:'space-between', width:'100%', marginBottom:5, marginTop:10}}>
                    <div style={{width:'8%'}}>
                        {stock.ticker === 'CASH'?null: this.checkLogo(stock.ticker)}
                    </div>
                    <p style={{width:'10%', textAlign:'center', fontSize:'large'}}>{stock.ticker}</p>
                    <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>{stock.bought_percentage===100.00? 'YES':'NO'}</p>
                    <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>{stock.allocated_percentage.toFixed(1)}%</p>
                    <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>${stock.bought_cost.toFixed(2)}</p>
                    <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>{this.utcToLocal(stock.trend_date)}</p>
                    <div style={{width:'2%'}}></div>
                </div>
                <hr></hr>
            </div>
        )
    }

    getTgStats = (id)=>{
        fetch(`${ENDPOINT}/mobile/trade-groups/buy-stats?account_id=${id}`,{
            headers:{
                'Content-Type': 'application/json'
            },
        })
        .then((res) => res.json())
        .then((responseJson)=>{
            // console.log('tgdata resp', responseJson)
                this.setState({tgData:responseJson.Stocks})
            })
    }

    tradeGroups = (acc)=>{
        if(acc.product === 'group'){
            if(Object.keys(this.state.tgData).length === 0){
                this.getTgStats(acc.accountId)
            }
            let total = 0
            acc.holdings.forEach(stock=>total+=stock.instValue)
            return(
                <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column' }}>
                    <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%',  marginBottom:25}}>
                        <div style={{display:'flex', justifyContent:'space-between', width:'100%', margin:'auto',marginBottom:10}}>
                            <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{Object.keys(this.state.tgData).length > 0? this.state.tgData[0].group_name:'Trade Group'}: </strong>{acc.inst === 'DriveWealth'? acc.mask_full :`${acc.inst} ${acc.mask}`}</p>
                            <div style={{display:'flex', width:'60%', justifyContent:'flex-end', flexWrap:'wrap'}}>
                            {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({orders:true,showOrders:acc})}>
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Orders</p>
                                    </div>
                                :null
                                }
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({orders:false,showOrders:acc})}>
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transactions</p>
                                    </div>
                                :null
                                }
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({showStatements:acc})}
                                    // onClick={()=>this.getStatements(acc.accountId)}
                                    >
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Statements</p>
                                    </div>
                                :null
                                }
                                {
                                    acc.inst  === 'DriveWealth'? 
                                        null
                                    :
                                        <div 
                                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#ee5e68', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                        onClick={()=>this.setState({showUnlink:acc})}
                                        >
                                            <p style={{margin:0,fontWeight:'', color:'#ee5e68'}}>Unlink</p>
                                        </div>
                                }
                            </div>
                        </div>
                        <div style={{width:'100%', margin:0}}>
                            <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                                <p style={{textAlign:'left'}}>Current Holdings</p>
                                {/* <p style={{textAlign:'right'}}>Daily +/- {'xx.xx'}%</p> */}
                                <p style={{textAlign:'right'}}>Total Value: <strong style={{color:brandColor}}>${acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):null}</strong></p>
                            </div>
                            {/* <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                                <p onClick={()=>this.getStats(acc.accountId,"portfolio" )} style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold', cursor:'pointer'}}>See Portfolio Stats</p>
                            </div> */}
                            <div style={{display:'flex', justifyContent:'space-between', width:'100%', marginBottom:5, marginTop:10}}>
                                <p style={{width:'18%', textAlign:'center', fontSize:'large'}}>Symbol</p>
                                <div style={{width:'20%',}}>
                                    <p style={{width:'100%', textAlign:'right', fontSize:'large', margin:0}}>Currently </p>
                                    <p style={{width:'100%', textAlign:'right', fontSize:'large', margin:0}}>Invested</p>
                                </div>
                                <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Max %</p>
                                <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Mkt Val</p>
                                <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Last Trend</p>
                                <div style={{width:'2%'}}></div>
                            </div>
                            <div style={{width:'100%', maxHeight:250, overflowY:'scroll'}}>
                                {/* {acc.holdings.map(ele=>this.brokerageRow(ele,total))} */}
                                {this.state.tgData.length >0? this.state.tgData.map(ele=>this.tgRow(ele,total)):<div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%'}}><p style={{fontSize:24, fontWeight:'bold'}}>Loading...</p></div>}
                            </div>
                        </div>
                    </div>                
                </div>
            )
        }
    }

    brokerageRow(stock,total){
        if(!stock.cashEq){
            return(
                <div style={{width:'100%'}}>
                    <div style={{display:'flex', justifyContent:'space-between', width:'100%', padding:5, alignItems:'center'}}>
                        <div style={{width:'8%'}}>
                            {stock.symbol === 'CASH'?null: this.checkLogo(stock.symbol)}
                        </div>
                        <a style={{width:'10%'}} target='_blank' href={stock.symbol === 'CASH' || stock.symbol === 'Us dollar' ?null:`/CompanyPage/${stock.symbol}`}><p style={{width:'100%', margin:0 ,textAlign:'left', color:brandColor}}>{stock.symbol}</p></a>
                        <p style={{width:'20%', margin:0 ,textAlign:'right'}}>${this.addCommas(stock.instPrice.toFixed(2))}</p>
                        <p style={{width:'20%', margin:0 ,textAlign:'right'}}>{this.addCommas(stock.quantity.toFixed(2))}</p>
                        <p style={{width:'20%', margin:0 ,textAlign:'right'}}>${this.addCommas(stock.instValue.toFixed(2))}</p>
                        <p style={{width:'20%', margin:0 ,textAlign:'right'}}>{isNaN(((stock.instValue/total)*100).toFixed(2))?0:((stock.instValue/total)*100).toFixed(2)}%</p>  
                        <div style={{width:'2%'}}></div>
                    </div>
                    <hr></hr>
                </div>
            )
        }
    }

    getStats = (id, type)=>{
        this.setState({showStats:'loading'})
        let body = {
            "portfolio":[],
            "data":{},
            "request":{
                "type":type,
                "id":id
            }
        }
        console.log('get stats body', body)
   
        fetch(`${ENDPOINT}/risk-monkey`,{
            method: "POST",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
          .then(res => res.json())
          .then(responseJson => {
              this.setState({showStats:responseJson})
            }
            )
        .catch(err => console.log('get stats error message: ', err))
    }

    checkLogo = (symbol)=>{
        let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        if (http.status != 404){
            return (
              <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:70, height:70,justifySelf:'flex-end',}}>
                    <img 
                        // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
                        src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
                        onerror="this.onerror=null; this.remove();" 
                        style={{objectFit:'contain', width:70,height:70, }}
                    />
                </div>
            )
        }
    }

    brokerage = (acc)=>{
        let total = 0
        acc.holdings.forEach(stock=>total+=stock.instValue)
        // console.log('acc', acc)
        return(
            <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column' }}>
                <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%',  marginBottom:25}}>
                    <div style={{display:'flex', justifyContent:'space-between', width:'100%', margin:'auto',marginBottom:10}}>
                        <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{acc.product === 'robo'? 'Robo': acc.ria? 'Directed Account':'Brokerage Account'}: </strong>{acc.inst === 'DriveWealth'? acc.mask_full :`${acc.inst} ${acc.mask}`}</p>
                        <div style={{display:'flex', width:'40%', justifyContent:'flex-end', flexWrap:'wrap'}}>
                           
                            {
                            acc.inst  === 'DriveWealth' && !acc.ria? 
                                <div 
                                style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#3b8e8c', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                onClick={()=>this.setState({showTrade:acc})}>
                                    <p style={{margin:0,fontWeight:'', color:'#3b8e8c'}}>Trade</p>
                                </div>
                            :null
                            }
                            {
                            acc.inst === 'DriveWealth'? 
                                <div 
                                style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                onClick={()=>this.setState({showDeposit:acc})}>
                                    <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Deposit</p>
                                </div>
                            :null
                            }
                            {
                            acc.inst  === 'DriveWealth'? 
                                <div 
                                style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                onClick={()=>this.setState({orders:true,showOrders:acc})}>
                                    <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Orders</p>
                                </div>
                            :null
                            }
                            {
                            acc.inst  === 'DriveWealth'? 
                                <div 
                                style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                onClick={()=>this.setState({orders:false,showOrders:acc})}>
                                    <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transactions</p>
                                </div>
                            :null
                            }
                            {
                            acc.inst  === 'DriveWealth'? 
                                <div 
                                style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                onClick={()=>this.setState({showStatements:acc})}
                                // onClick={()=>this.getStatements(acc.accountId)}
                                >
                                    <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Statements</p>
                                </div>
                            :null
                            }

                            <div 
                                style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                onClick={()=>this.getStats(acc.accountId,"portfolio" )}>
                                    <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Analyze</p>
                            </div>
                            <div 
                            style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#ee5e68', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                            onClick={()=>this.setState({showUnlink:acc})}
                            >
                                <p style={{margin:0,fontWeight:'', color:'#ee5e68'}}>Unlink</p>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'100%', margin:0}}>
                        <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                            <p style={{textAlign:'left'}}>Current Holdings</p>
                            {/* <p style={{textAlign:'right'}}>Daily +/- {'xx.xx'}%</p> */}
                            <p style={{textAlign:'right'}}>Total Value: <strong style={{color:brandColor}}>${acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):null}</strong></p>
                        </div>
                        {/* <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                            <p onClick={()=>this.getStats(acc.accountId,"portfolio" )} style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold', cursor:'pointer'}}>See Portfolio Stats</p>
                        </div> */}
                        <div style={{display:'flex', justifyContent:'space-between', width:'100%', marginBottom:5, marginTop:10}}>
                            <p style={{width:'18%', textAlign:'center', fontSize:'large'}}>Symbol</p>
                            <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Price</p>
                            <p style={{width:'20%', textAlign:'right', fontSize:'large'}}># Shares</p>
                            <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Mkt Val</p>
                            <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Allocation</p>
                            <div style={{width:'2%'}}></div>
                        </div>
                        <div style={{width:'100%', maxHeight:250, overflowY:'scroll'}}>
                            {acc.holdings.map(ele=>this.brokerageRow(ele,total))}
                        </div>
                    </div>
                </div>                
            </div>
        )
    }

    addCommas(x){
        if(x === ''){
            return ''
        }else{
            let arr = x.toString().split(".")
            if(arr[1]){
                return (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))+ '.' + arr[1]
            }
            else{
                return  (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            }
        } 
    }

    statsModal = ()=>{
        if(this.state.showStats){
            if(this.state.showStats === 'loading'){
                return(
                    <div style={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        position: 'fixed',
                        height: '100%',
                        width: '100%',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        overflow: 'auto',
                        zIndex: 99999,
                        }}>
                        <div style={{ width:this.props.isSmallScreen?'90%' :'40%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px', position:'fixed', zIndex:20,}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Stats</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({showStats:false})}> X </p>
                        </div>
                        <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height:'90%'}}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                                <p style={{textAlign:'center', fontSize:26, fontWeight:'bold',margin:0}}>Loading...</p>
                            </div>
                        </div>
                    </div>
                )
            }
            let accTotal = this.getTotalVal(this.state.showStats.portfolio)

            let holdingsTitle = <div>
                <div style={{display:"flex", flexDirection:'row', width:'100%', width:'100%'}} >
                    <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-start',padding:12,borderRight:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                        <p style={{fontWeight:'bold', margin:0,}}>
                        {this.props.isSmallScreen? 'Sym':'Symbol'}
                        </p>
                    </div>
                    <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12}}>
                        <p style={{fontWeight:'bold', margin:0,}}>
                           {this.props.isSmallScreen? 'Qty':'# Shares'} 
                        </p>
                    </div>
                    
                    <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12, borderLeft:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                        <p style={{fontWeight:'bold', margin:0,}}>
                        {this.props.isSmallScreen? '%':'Percentage'}
                        </p>
                    </div>
                     
                    <div style={{width:'25%', backgroundColor:'rgb(245,246,247)', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:12, borderLeft:'solid', borderWidth:1, borderColor:'rgb(255,255,255)'}}>
                        <p style={{fontWeight:'bold', margin:0,}}>
                        {this.props.isSmallScreen?  'Val':'Position Value'}
                        </p>
                    </div>
        
                </div>
                
            </div>
            let holdingsData = []
            // FILL HOLDINGS
            let total = 0
            this.state.showStats.portfolio.forEach(ele=>{
                total += ele.value
            })
            this.state.showStats.portfolio.forEach(ele=>{
                holdingsData.push(
                        <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', borderBottom: 'solid', borderWidth: .25, borderColor: '#ddd'}}>
                            <div style={{width:'25%', borderRight: 'none', borderWidth: .25, borderColor: '#ddd',display:'flex', justifyContent:'flex-start', alignItems:'center', padding:12}}>
                                <a style={{textDecoration:'none', color:brandColor, cursor:'pointer'}} href={ele.symbol === '$$'?null :`/CompanyPage/${ele.symbol}`} target='_blank'>{ele.symbol}</a>
                            </div>
                            <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                                <p style={{margin:0}}>{ele.symbol==='$$' || ele.symbol === 'CASH' ? '' : this.addCommas(ele.quantity)}</p>     
                            </div>
                                <div style={{width:'25%',display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                                    <p style={{margin:0}}>{parseFloat(ele.value/total*100).toFixed(2)}%</p>
                                </div>
                                <div style={{width:'25%',display:'flex', justifyContent:'flex-end', alignItems:'center', padding:12}}>
                                    <p style={{margin:0}}>${this.addCommas((parseFloat(ele.value)).toFixed(2))}</p>
                                </div>

                        </div>
                    )
                
            })
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'60%', height:'90%'}}>
                        <div style={{ width:this.props.isSmallScreen?'90%' :'60%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px', position:'fixed', zIndex:20,}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Stats</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({showStats:false})}> X </p>
                        </div>
                        <div style={{display:'flex', width:'100%', overflowY:'scroll', height:'100%', flexDirection:'column'}}>
                            { accTotal <= 0 ? null :
                                <div style={{display:'flex', margin:0, justifyContent:'space-between', width:'100%', padding:20, marginTop:50}}>
                                    <div style={{display:'flex'}}>
                                        <p style={{fontSize:24, margin:0}}>Portfolio Value</p> 
                                        <div style={{display:'flex', alignItems:'center'}} onClick={()=>this.setState({showVal:!this.state.showVal})}>
                                            {this.state.showVal ?<FaEye style={{width:20, marginLeft:10}}/> : <FaEyeSlash  style={{width:20, marginLeft:10}}/> }
                                        </div>
                                    </div>       
                                    <p style={{fontSize:24, fontWeight:'bold', margin:0}}>{this.state.showVal? '••••••••••' :`$${accTotal}`}</p>
                                </div>
                            }
                            { accTotal <= 0 ? null :<hr></hr>}
                            <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', padding:20, marginTop:20}}>
                                <p style={{fontSize:24}}>AI Based Portfolio Risk Statistics</p>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <p style={{fontWeight:'bold', fontSize:20}}>Correlation with S&P:</p>
                                    <p style={{fontSize:20}}>{(this.state.showStats.data.rSquared).toFixed(2)}</p>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <p style={{fontWeight:'bold', fontSize:20}}>Maximum Historical Risk:</p>
                                        <p style={{fontSize:20}}>{Math.abs(this.state.showStats.data.maxDrawdownVal).toFixed(2)}%</p>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <p style={{fontWeight:'bold', fontSize:20}}>Total Number of Holdings:</p>
                                        <p style={{fontSize:20}}>{this.state.showStats.portfolio.length}</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', padding:20}}>
                                <p style={{fontSize:24}}>Current Risk Level</p>
                                <RiskForecast myStrats={true} isSmallScreen={this.props.isSmallScreen} cvar={Math.abs(this.state.showStats.data.CVar)}/>
                            </div>
                            <hr></hr>
                            <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', padding:20}}>
                                <p style={{fontSize:24}}>Historical Risk</p>
                                <div style={{width:'100%'}}>
                                    <DdChart drawdownChart={this.state.showStats.data.maxDrawdown}/>
                                </div>
                            </div>
                            <hr></hr>
                            <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', padding:20}}>
                                <p style={{fontSize:24}}>Holdings</p>
                                <div style={{width:'100%'}}>
                                   {holdingsTitle}
                                   <div style={{width:'100%',maxHeight:500, overflowY:'scroll'}}>
                                        <div>
                                        {holdingsData}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    relinkAccount(acc){
        let total = 0
        return(
            <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column' }}>
                <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:40, borderRadius: '50px', backgroundColor:'#FFFFFF', width:'100%',  marginBottom:25}}>
                    <div style={{display:'flex', justifyContent:'space-between', width:'100%', margin:'auto',marginBottom:10}}>
                        <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{acc.product === 'robo'? 'Robo':'Brokerage Account'}: </strong>{`${acc.inst} ${acc.mask}`}</p>                        
                    </div>
                    <div style={{width:'100%', margin:0}}>
                        <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                           <p>The link to this account has been lost. Please relink it by clicking here</p> 
                        </div>
                    </div>
                </div>               
            </div>
        )
    }

    handleExit=()=>{
        console.log('exit')
        // this.props.updateAccs()
    }

    showPlaid(inv){

        return(
            <div onClick={()=>this.setState({filter:!inv?'bank':''})}>
                <PlaidLink
                    clientName="ForeSCITE"
                    // env="sandbox"
                    env="production"
                    product={inv?["investments"]:['transactions']}
                    publicKey="3e65a03171afbab180fa7198f87cef"
                    onSuccess={this.plaidHandleOnSuccess}
                    linkCustomizationName={inv? 'bank':'brokerage'}
                    // className="test"
                    style={{marginRight:'5%', cursor:'pointer', backgroundColor:'transparent',border:'none',}}
                    // style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold'}}
                    // style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer',}}
                >
                    <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor: inv? (this.state.hover === 'linkInv'?'rgb(200,200,200)':''): this.state.hover === 'linkBank'?'rgb(200,200,200)':'',}}onClick={null} onMouseEnter={()=>this.setState({hover:inv?'linkInv':'linkBank'})}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)', textAlign:'left'}}>{inv?'Link an Investment Account':'Link an Investment Account'}</p>  
                    </div>
                </PlaidLink>
            </div>
        )
    }

    plusDropdown = ()=>{
        if(this.state.plusDropdown){
            return(
                <div style={{position: 'absolute', right:'5%',backgroundColor: '#f9f9f9',width: 235,overflow: 'auto',boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',zIndex: 100, marginRight:25}} >
                    <div style={{ color: 'black', padding: '12px 16px', width:'fit-content',textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'manage'?'rgb(200,200,200)':'',}}onClick={()=>this.props.addAcc()} onMouseEnter={()=>this.setState({hover:'manage'})}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Manage My Accounts</p>  
                    </div>
                    {/* <div style={{ color: 'black', padding: '12px 16px', width:'fit-content',textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'createDw'?'rgb(200,200,200)':'',}}onClick={()=>this.props.addAcc()} onMouseEnter={()=>this.setState({hover:'createDw'})}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Create DriveWealth Account</p>  
                    </div> */}
                    <div onClick={()=>this.setState({filter:'inv'})}>
                        <PlaidLink
                            clientName="ForeSCITE"
                            // env="sandbox"
                            env="production"
                            product={["investments"]}
                            publicKey="3e65a03171afbab180fa7198f87cef"
                            onSuccess={this.plaidHandleOnSuccess}
                            linkCustomizationName={'brokerage'}
                            // className="test"
                            style={{marginRight:'5%', cursor:'pointer', backgroundColor:'transparent',border:'none',}}
                            // style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold'}}
                            // style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer',}}
                        >
                            <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'linkInv'?'rgb(200,200,200)':'',}}onClick={null} onMouseEnter={()=>this.setState({hover:'linkInv'})}>
                                <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)', textAlign:'left'}}>Link an Investment Account</p>  
                            </div>
                        </PlaidLink>
                    </div>
                    <div onClick={()=>this.setState({filter:'bank'})}>
                        <PlaidLink
                            clientName="ForeSCITE"
                            // env="sandbox"
                            env="production"
                            product={["transactions"]}
                            publicKey="3e65a03171afbab180fa7198f87cef"
                            onSuccess={this.plaidHandleOnSuccess}
                            linkCustomizationName={'bank'}
                            // className="test"
                            style={{marginRight:'5%', cursor:'pointer', backgroundColor:'transparent',border:'none',}}
                            // style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold'}}
                            // style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer',}}
                        >
                            <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'linkBank'?'rgb(200,200,200)':'',}}onClick={null} onMouseEnter={()=>this.setState({hover:'linkBank'})}>
                                <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)',textAlign:'left'}}>Link a Bank Account</p>  
                            </div>
                        </PlaidLink>
                    </div> 
                </div>
            )
        }
    }

   


    renderAccounts = ()=>{
       let accounts = []
       this.props.accounts.forEach(acc=>{
           if(acc.type === 'investment'){
            if (acc.product === 'group'){
                accounts.push(
                    <div style={{width:'100%', marginTop:50}}>
                        {/* {this.tradeGroups(acc)} */}
                        <GroupAcc user={this.props.user} acc={acc} orders={()=>this.setState({orders:true,showOrders:acc})} transactions={()=>this.setState({orders:false,showOrders:acc})} statements={()=>this.setState({showStatements:acc})} unlink={()=>this.setState({showUnlink:acc})}/>
                    </div>
                    )
            }
               // else if (acc.product === 'robo'){
               //     accounts.push(
               //         <div style={{width:'100%', marginTop:50}}>
               //             {this.robo(acc)}
               //         </div>
               //         )
               // }
               else if(!acc.product || this.robo){
                accounts.push(
                    <div style={{width:'100%', marginTop:50}}>
                        {this.brokerage(acc)}
                    </div>
                    )
            }
               else if (acc.product === 'goal'){
                   console.log('goal')
               }
           }
        })
        
       return accounts
    }

    renderButtons = (acc)=>{
        return (

            <div style={{display:'flex', width:'95%', justifyContent:'flex-start', flexWrap:'wrap', margin:'auto'}}>        
                {
                acc.inst  === 'DriveWealth' && !acc.ria? 
                    <div 
                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#3b8e8c', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                    onClick={()=>this.setState({showTrade:acc})}>
                        <p style={{margin:0,fontWeight:'', color:'#3b8e8c'}}>Trade</p>
                    </div>
                :null
                }
                {
                acc.inst === 'DriveWealth'? 
                    <div 
                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                    onClick={()=>this.setState({showDeposit:acc})}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Deposit</p>
                    </div>
                :null
                }
                {
                acc.inst  === 'DriveWealth'? 
                    <div 
                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                    onClick={()=>this.setState({orders:true,showOrders:acc})}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Orders</p>
                    </div>
                :null
                }
                {
                acc.inst  === 'DriveWealth'? 
                    <div 
                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                    onClick={()=>this.setState({orders:false,showOrders:acc})}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transactions</p>
                    </div>
                :null
                }
                {
                acc.inst  === 'DriveWealth'? 
                    <div 
                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                    onClick={()=>this.setState({showStatements:acc})}
                    // onClick={()=>this.getStatements(acc.accountId)}
                    >
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Statements</p>
                    </div>
                :null
                }

                <div 
                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                    onClick={()=>this.getStats(acc.accountId,"portfolio" )}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Analyze</p>
                </div>
                <div 
                style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#ee5e68', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                onClick={()=>this.setState({showUnlink:acc})}
                >
                    <p style={{margin:0,fontWeight:'', color:'#ee5e68'}}>Unlink</p>
                </div>
            </div>
        )
    }

    buttonDropdown = (acc)=>{
        if(this.state.activeDropdown.accountId === acc.accountId){
            return(
                <div>
                     <div 
                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Menu</p>
                    </div>
                    <div style={{position: 'absolute', right:'5%',backgroundColor: '#f9f9f9',minWidth: '10%',overflow: 'auto',boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',zIndex: 100, marginRight:25}}>
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'deposit'?'rgb(200,200,200)':'',}}onClick={()=>this.setState({showDeposit:acc})} onMouseEnter={()=>this.setState({hover:'deposit'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Deposit</p>  
                        </div>
                        {acc.inst  === 'DriveWealth' && !acc.ria? 
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'orders'?'rgb(200,200,200)':'',}}onClick={()=>this.setState({orders:true,showOrders:acc})} onMouseEnter={()=>this.setState({hover:'orders'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Orders</p>
                        </div>:null}
                        {acc.inst  === 'DriveWealth'? 
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'transactions'?'rgb(200,200,200)':'',}}onClick={()=>this.setState({orders:false,showOrders:acc})} onMouseEnter={()=>this.setState({hover:'transactions'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transactions</p>
                        </div>:null}
                        {acc.inst  === 'DriveWealth'? 
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'statements'?'rgb(200,200,200)':'',}}onClick={()=>this.setState({showStatements:acc})} onMouseEnter={()=>this.setState({hover:'statements'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Statements</p>
                        </div>:null}
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'analyze'?'rgb(200,200,200)':'',}}onClick={()=>this.getStats(acc.accountId,"portfolio" )} onMouseEnter={()=>this.setState({hover:'analyze'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Analyze</p>
                        </div>
                        {acc.inst  !== 'DriveWealth' || acc.product ? 
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'unlink'?'rgb(200,200,200)':'',}}onClick={()=>this.setState({showUnlink:acc})} onMouseEnter={()=>this.setState({hover:'unlink'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Unlink</p>
                        </div>:null}
                        
                    </div>
                </div>
            )
        }
        else{
            return(
                <div>
                     <div 
                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Menu</p>
                    </div>
                </div>
            )
        }
    }

    tradeStock = (acc,ele)=>{
        
        this.setState({showTrade:acc, selectedStock:ele})
    }

    hasRobo = ()=>{
        return this.props.accounts.some(acc=>acc.product === 'robo')
    }

    showOneAcc = (acc)=>{
        // console.log('acc', acc)
        if(acc.type === 'depository' || acc.type=== 'credit'){
            return <BankAcc deposit={()=>this.setState({showDeposit:acc})} acc={acc} user={this.props.user} unlink={()=>this.setState({showUnlink:acc})} />
        }else{
            if(acc.product === 'group'){
                return <GroupAcc isSmallScreen={this.props.isSmallScreen} user={this.props.user} acc={acc} orders={()=>this.setState({orders:true,showOrders:acc})} transactions={()=>this.setState({orders:false,showOrders:acc})} statements={()=>this.setState({showStatements:acc})} unlink={()=>this.setState({showUnlink:acc})} redemption={()=>this.setState({showRedemption:acc})} deposit={()=>this.setState({showDeposit:acc})} withdraw={()=> this.setState({showDeposit:{...acc,withdraw:true}})}/>
            }
            else if(acc.product === 'robo' || acc.product === 'goal'){
                return <RoboAcc isSmallScreen={this.props.isSmallScreen} acc={acc} user={this.props.user} unlink={()=>this.setState({showUnlink:acc})} orders={()=>this.setState({orders:true,showOrders:acc})} redemption={()=>this.setState({showRedemption:acc})} transactions={()=>this.setState({orders:false,showOrders:acc})}deposit={()=>this.setState({showDeposit:acc})} withdraw={()=> this.setState({showDeposit:{...acc,withdraw:true}})}/>
            }
            
            else if(acc.status !== 'PENDING'){
                return <BrokerageAcc hasRobo={this.hasRobo()} isSmallScreen={this.props.isSmallScreen} updateAccs={this.props.updateAccs} tradeStock={this.tradeStock} acc={acc} user={this.props.user} trade={()=>this.setState({showTrade:acc})} unlink={()=>this.setState({showUnlink:acc})} orders={()=>this.setState({orders:true,showOrders:acc})} transactions={()=>this.setState({orders:false,showOrders:acc})} deposit={()=>this.setState({showDeposit:acc})} withdraw={()=>  this.setState({showDeposit:{...acc,withdraw:true}})}  statements={()=>this.setState({showStatements:acc})} analyze={()=>this.getStats(acc.accountId,"portfolio" )}/>
            }
            else{ 
                return(
                    <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column', }}>
                        <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:40, borderRadius: '50px',  backgroundImage:'linear-gradient(97deg, #ffffff 1%, #f2f2f2)', width:'100%',  marginTop:25}}>
                            <div style={{width:'100%', margin:'auto',marginBottom:10}}>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{acc.product === 'robo'? 'Robo': acc.product==='group'?'Trade Group': acc.ria? 'Directed Account':'Brokerage Account'}: </strong>{acc.inst === 'DriveWealth'? 'DriveWealth '+ acc.mask_full :`${acc.inst} ${acc.mask}`}</p>
                                </div>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%', marginTop:10}}>
                                    <p style={{fontWeight:'bold', fontSize:22, margin:0}}>This account is pending approval.</p>
                                </div>
                            </div>
                        </div>                
                    </div>
                )
            }
        }
    }

    showHeader = ()=>{
        if(this.props.isSmallScreen){
            return(
                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <select placeholder="Select Below" onChange={(e)=>this.setState({filter:e.target.value})} value={this.state.filter}>
                        {/* <option value={'false'} selected={this.state.filter == 'false'}>All Accounts</option> */}
                        <option value={'null'} selected={this.state.filter == 'null'}>ForeSCITE Self Directed Investing</option>
                        <option value={'robo'} selected={this.state.filter == 'robo'}>ForeSCITE Robo</option>
                        <option value={'group'} selected={this.state.filter == 'group'}>Group Trader</option>
                        {/* <option value={'bank'} selected={this.state.filter == 'bank'}>Bank Accounts</option> */}
                    </select>
                </div>
            )
        }else{
            return(
                <div style={{display:'flex', alignItems:'center', height: 60, width:'95%', margin:'auto', marginTop:50,}}>
                    {/* <div style={{width:'20%', borderBottom:'solid', borderWidth:'0.5px', borderColor:this.state.filter === 'false' ? 'rgb(100, 163, 162)':'#dddddd', cursor:'pointer', paddingBottom:10,paddingLeft:10}} onClick={()=>this.setState({filter:'false'})}>
                        <p style={{margin:0, color:this.state.filter === 'false' ? 'rgb(100, 163, 162)':'', textAlign:'left',fontSize:22}}>All</p>
                        <p style={{margin:0, color:this.state.filter === 'false' ? 'rgb(100, 163, 162)':'', textAlign:'left',fontSize:22}}>Accounts</p>
                    </div> */}
                    <div style={{/*width:'20%',*/flex:1, border:'solid',paddingLeft:10, paddingTop:10, borderWidth:'1 px', borderColor:this.state.filter === 'null' ? 'rgb(100, 163, 162)':'#dddddd', cursor:'pointer', paddingBottom:10, marginRight:10}} onClick={()=>this.setState({filter:'null'})}>
                        <p style={{margin:0, color:this.state.filter === 'null' ? 'rgb(100, 163, 162)':'', textAlign:'center',fontSize:22}}>ForeSCITE Self </p>
                        <p style={{margin:0, color:this.state.filter === 'null' ? 'rgb(100, 163, 162)':'', textAlign:'center',fontSize:22}}>Directed Investing</p>
                    </div>
                    <div style={{/*width:'20%',*/flex:1, border:'solid',paddingLeft:10, paddingTop:10, borderWidth:'1 px', borderColor:this.state.filter === 'robo' ? 'rgb(100, 163, 162)':'#dddddd', cursor:'pointer', paddingBottom:10,marginRight:10}} onClick={()=>this.setState({filter:'robo'})}>
                        <p style={{margin:0, color:this.state.filter === 'robo' ? 'rgb(100, 163, 162)':'', textAlign:'center',fontSize:22}}>ForeSCITE</p>
                        <p style={{margin:0, color:this.state.filter === 'robo' ? 'rgb(100, 163, 162)':'', textAlign:'center',fontSize:22}}>Robo</p>
                    </div>
                    <div style={{/*width:'20%',*/flex:1, border:'solid',paddingLeft:10, paddingTop:10, borderWidth:'1 px', borderColor:this.state.filter === 'group' ? 'rgb(100, 163, 162)':'#dddddd', cursor:'pointer', paddingBottom:10,}} onClick={()=>this.setState({filter:'group'})}>
                        <p style={{margin:0, color:this.state.filter === 'group' ? 'rgb(100, 163, 162)':'', textAlign:'center',fontSize:22}}>Group</p>
                        <p style={{margin:0, color:this.state.filter === 'group' ? 'rgb(100, 163, 162)':'', textAlign:'center',fontSize:22}}>Trader</p>
                    </div>
                    {/* <div style={{width:'20%',borderBottom:'solid', borderWidth:'0.5px', borderColor:this.state.filter === 'bank' ? 'rgb(100, 163, 162)':'#dddddd', cursor:'pointer', paddingBottom:10}} onClick={()=>this.setState({filter:'bank'})}>
                        <p style={{margin:0, color:this.state.filter === 'bank' ? 'rgb(100, 163, 162)':'', textAlign:'left',fontSize:22}}>Bank</p>
                        <p style={{margin:0, color:this.state.filter === 'bank' ? 'rgb(100, 163, 162)':'', textAlign:'left',fontSize:22}}>Accounts</p>
                    </div> */}
                </div>
            )
        }
    }

    tabs=()=>{
        // let selectedBk = {backgroundImage: this.state.filter === 'inv' ? 'linear-gradient(94deg, rgb(30, 51, 66) 0%, rgb(73, 185, 168))' : 'linear-gradient(94deg,rgb(73, 185, 168) 0%, rgb(30,51,66)', width: '50%', height: '100%', cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: this.state.filter === 'inv' ?'5px 0px 0px 5px':'0px 5px 5px 0px', height:'100%',}
        // let unselectedBk = {width: '50%', height: '100%', cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: this.state.filter === 'inv'? '0px 5px 5px 0px' :'5px 0px 0px 5px', height:'100%', backgroundColor:'rgba(146, 149, 161, 0.1)'}
        let selectedBk = {backgroundColor: '#3b8e8c', width: '50%', height: '100%', cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: this.state.filter === 'inv' ?'5px 0px 0px 5px':'0px 5px 5px 0px', height:'100%',}
        let unselectedBk = {width: '50%', height: '100%', cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: this.state.filter === 'inv'? '0px 5px 5px 0px' :'5px 0px 0px 5px', height:'100%', backgroundColor:'rgba(146, 149, 161, 0.1)'}
        let selectedTxt = {margin:0, margin:'auto',marginLeft: this.state.isSmallScreen? 10:'auto', fontSize: this.state.isSmallScreen?14:16, fontSize: '16px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.3, letterSpacing: 'normal', textAlign: 'left', color: 'rgb(255, 255, 255)',}
        let unselectedTxt = {margin:0, margin:'auto',marginLeft: this.state.isSmallScreen? 10:'auto', fontSize: this.state.isSmallScreen?14:16, fontSize: 16, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.3, letterSpacing: 'normal', textAlign: 'left', color: 'rgb(146, 149, 161)',}
        return(
            <div style={{width: this.props.isSmallScreen?'100%':'40%', display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                <div 
                    style={this.state.filter === 'inv' ? selectedBk : unselectedBk}
                    onClick={() => this.setState({ filter:'inv', errorsSucceses:false })}
                >
                    <p 
                        style={this.state.filter === 'inv' ? selectedTxt : unselectedTxt}
                    >
                    Investment Accounts
                    </p>
                </div>
                <div 
                    style={this.state.filter === 'bank' ? selectedBk : unselectedBk}
                    onClick={() => this.setState({ filter:'bank',errorsSucceses:false })}
                >
                    <p 
                        style={this.state.filter === 'bank' ? selectedTxt : unselectedTxt}
                    >
                      Bank Accounts
                    </p>
                </div>
            </div>
        )
    }

    filterAccs = ()=>{
        let data = this.props.accounts
        let filtered
        // if(this.state.filter === 'false'){
        //     filtered = data
        // }
        if(this.state.filter === 'bank'){
            filtered = data.filter(acc=> acc.type === 'depository' || acc.type === 'credit')
        }
        else{
            filtered = data.filter(acc=> acc.type !== 'depository' && acc.type !== 'credit')
            // if (this.state.filter === 'group'){
            //     filtered = data.filter(acc=> acc.product === 'group')
            // }
            // else if (this.state.filter === 'robo'){
            //     filtered = data.filter(acc=> acc.product === 'robo')
            // }
            // else if (this.state.filter === 'group'){
            //     filtered = data.filter(acc=> acc.product === 'group')
            // }
            // else if (this.state.filter === 'null'){
            //     filtered = data.filter(acc=> acc.product === null && !acc.ria && acc.type !== 'depository')
            // }
        }
        return filtered
    }

    showFilteredAccs = ()=>{
        let accs = this.filterAccs()
        // let accs =this.props.accounts
        if (accs.length > 0){
            return accs.map(ele=>this.showOneAcc(ele))
        }
        else{
            return(
                <PlaidLink
                        clientName="AllocateRite"
                        // env="sandbox"
                        env="production"
                        product={this.state.filter === 'bank'? ["transactions"]:["investments"]}
                        publicKey="3e65a03171afbab180fa7198f87cef"
                        onSuccess={this.plaidHandleOnSuccess}
                        linkCustomizationName={this.state.filter === 'bank'? 'bank':'brokerage'}
                        // className="test"
                        style={{marginRight:'5%', cursor:'pointer', backgroundColor:'transparent',border:'none'}}
                        // style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold'}}
                        // style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer',}}
                    >
                            <a href='#' style={{fontSize:20, color:'#64A3A2'}}>You do not currently have an active {this.state.filter === 'bank'? 'bank':'investment'} account. Click here to {this.state.filter === 'bank'? '':'create or '}link a new {this.state.filter === 'bank'? 'bank':'investment'} account.</a>
                    </PlaidLink>
            )
        }
        // else{
        //     let obj = {
        //         'robo':{
        //             text1: 'Robo Account',
        //             text2: 'Robo Account',
        //             step: 2,
        //             prod:'robo'
        //         },
        //         'group':{
        //             text1: 'Trade Group',
        //             text2: 'Trade Group',
        //             step: 0.2,
        //             prod:'group'
        //         },
        //         'null':{
        //             text1: 'Brokerage Account',
        //             text2: 'Brokerage Account',
        //             step: 2,
        //             prod:'normal'
        //         }
        //     }
        //     let text = ''
        //     switch(this.state.filter) {
        //         case 'robo':
        //             text = 'Robo Account'
        //           break;
        //         case 'group':
        //             text = 'Trade Group'
        //           break;
        //         default:
        //             text = 'Brokerage Account'
        //       }
        //     return(
        //         <div style={{width:'100%',display:'flex', justifyContent:'center', alignItems:'center', marginTop:50}}>
        //             <a href='#' onClick={()=>this.props.addAcc(obj[this.state.filter].step, obj[this.state.filter].prod)} style={{fontSize:20, color:'#64A3A2'}}>You do not currently have an active {obj[this.state.filter].text1}. Click here to create a new {obj[this.state.filter].text2} </a>
        //         </div>
        //     )
        // }
    }

    plaidHandleOnSuccess = (public_token, metadata)=> {
        // send token to client server
        // fetch("/auth/public_token", {
        //   public_token: public_token
        // });
        let accountId = metadata.account_id
        let itemId = metadata.institution.institution_id
        this.linkPlaidAccount(public_token, metadata)
        // let resp = this.findOtherPortfoliosSameInstitution(itemId)
        // if (resp.length > 0){
        //     this.addOtherPortSameInstituion(accountId, itemId) 
        // }
        // this.setState({respMeta:metadata, respToken:public_token})
    }

    linkPlaidAccount = (token, meta)=>{
        // console.log('plaid token' ,token)
        // console.log('plaid meta' ,meta)
        let id = meta.account_id
        let mask = meta.account.mask
        let name = meta.account.name
        let subType = meta.account.subtype

        let inst_id = meta.institution.institution_id
        let inst_name = meta.institution.name
        let public_token = token

        let body = { 
            accounts:[ {  id, mask, name, subType, type:this.state.filter === 'bank' ? "depository":"investment",} ],
            inst_id,
            inst_name,
            public_token,
            type:this.state.filter === 'bank' ? "depository":"investment",
        }
        // console.log('body: ', body)

        // fetch(`${ENDPOINT}/plaid/link`,{
        fetch(`https://api.allocaterite.com/mobile-plaid/plaid/link`,{
            method:'POST',
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(respJSON=>{
            console.log('respJSON: ', respJSON)
            if(respJSON.Error === 'Successful Linked'){
                this.props.updateAccs()
                this.setState({errorsSucceses:'Your account has been linked',plusDropdown:false,})
            }else{
                this.setState({errorsSucceses:respJSON.Error,plusDropdown:false,})
            }
        })
        .catch(err=>console.log('catching: ', err))
    }

    showErrorsSucceses = ()=>{
        if(this.state.errorsSucceses){
            return(
                <div style={{display:'flex', margin:'auto', backgroundColor:this.successErrorLoadingBackground(), padding:12, marginBottom:10, borderRadius:8,}}>
                    <p style={{margin:'auto'}}>
                        {this.state.errorsSucceses}
                    </p> 
                </div>
            )
        }
    }

    successErrorLoadingBackground = ()=>{
        if(this.state.errorsSucceses ===  'Your account has been linked'){
            return 'rgb(234,255,219)'
        }else{
            return 'rgb(253,180,181)'
        }
     }

    render(){
        return(
            <div style={{ paddingLeft: '2%', paddingRight:'2%',paddingTop:'2%', paddingBottom:'2%',}}>
                <div style={{display:'flex', justifyContent: this.props.isSmallScreen? 'center':'space-between', alignItems:'center', width:'95%', margin:'auto'}}>
                    {/* <div style={{padding:10, borderRadius:12,}}>
                        <p style={{margin:0, fontSize:'large'}}>Accounts Linked: {this.props.accounts.filter(ele=>ele.type !== 'depository').length}</p>
                        <p onClick={()=>this.getStats(1234,"overall_portfolio" )} style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold', cursor:'pointer'}}>See Overall Stats</p>
                    </div> */}
                    {/* <div onClick={()=>this.props.addAcc()} style={{padding:10, backgroundColor:'rgb(112,108,188)',borderRadius:6, border:'solid', borderWidth:'1px', borderColor:'rgb(112,108,188)', cursor:'pointer'}}>
                        <p style={{color:'rgb(255,255,255)', margin:0, fontSize:18}}>Manage Accounts</p>
                    </div> */}
                </div>
                    {this.props.isSmallScreen?
                        <div style={{marginRight:'10%',width:'90%',display:'flex',justifyContent:'flex-end',alignItems:'center'}} >
                            <FaPlusCircle size={50} color={'#3b8e8c'} style={{ cursor:'pointer'}} onClick={()=>this.setState({plusDropdown:!this.state.plusDropdown,errorsSucceses:false})} />
                            {this.plusDropdown()}
                        </div>:null
                    }
                <div style={{width:'100%', marginTop:20, marginBottom:20,height:50, marginLeft:'2%', display:this.props.isSmallScreen?'':'flex', justifyContent:'space-between', alignItems:'center'}}>
                    {this.tabs()}
                    {this.showErrorsSucceses()}
                    {this.props.isSmallScreen?null:
                        <div style={{marginRight:'10%'}} >
                            <FaPlusCircle size={75} color={'#3b8e8c'} style={{ cursor:'pointer'}} onClick={()=>this.setState({plusDropdown:!this.state.plusDropdown,errorsSucceses:false})} />
                            {this.plusDropdown()}
                        </div>
                    }
                </div>
                <div style={{width:'100%', display:this.state.filter === 'bank'? 'flex' :'',flexWrap:'wrap' }}>
                    {/* {this.renderAccounts()} */}
                    {this.showFilteredAccs()}
                </div>
                {this.statsModal()}
                {this.state.showTrade? <TradeModal isSmallScreen={this.props.isSmallScreen} canTrade={this.state.canTrade}presetTickers={this.props.presetTickers}user={this.props.user}acc={this.state.showTrade}closeTrade={this.closeTrade}selected={this.state.selectedStock}isSmallScreen={this.props.isSmallScreen}/> : null}
                {this.state.showUnlink? <UnlinkModal isSmallScreen={this.props.isSmallScreen} canTrade={this.state.canTrade} unlinkMessage={this.showUnlinkMessage} user={this.props.user} acc={this.state.showUnlink}  closeModal={this.closeUnlink} isSmallScreen={this.props.isSmallScreen}/> : null}
                {this.state.showDeposit? <DepositModal  isSmallScreen={this.props.isSmallScreen} allAccounts={this.props.accounts} acc={this.state.showDeposit} user={this.props.user} closeModal={this.closeDeposit} isSmallScreen={this.props.isSmallScreen}/> : null}
                {this.state.showOrders? <OrdersModal isSmallScreen={this.props.isSmallScreen} orders={this.state.orders} user={this.props.user} acc={this.state.showOrders}  closeModal={this.closeOrders} isSmallScreen={this.props.isSmallScreen}/> : null}
                {this.state.showStatements? <StatementsModal  isSmallScreen={this.props.isSmallScreen} user={this.props.user} acc={this.state.showStatements}  closeModal={this.closeStatements} isSmallScreen={this.props.isSmallScreen}/> : null}
                {this.state.showRedemption? <RedemptionModal  isSmallScreen={this.props.isSmallScreen} user={this.props.user} allAccounts={this.props.accounts} acc={this.state.showRedemption}  closeModal={this.closeRedemption} isSmallScreen={this.props.isSmallScreen}/> : null}
            </div>
        )
    }
}