import React from 'react'
import PhoneInput from 'react-phone-input-2'
import Spinner from 'react-bootstrap/Spinner'
import ENDPOINT from '../Endpoint'


export default class CreateDwAcc extends React.Component {

    state={
        loading:true,
        submitClicked:false,
        dwAccountID:false,
        page:0,
        errors:[],
        presets:{},
        firstName:'',
        lastName:'',
        country:'USA',
        phone:'',
        emailAddress:'',
        language:'en_us',
        idNumber:'',
        idType:null,
        citizenship:'USA',
        usTaxPayer:'',
        gender:'',
        maritalStatus:'',
        street1:'',
        street2:'',
        city:'',
        province:'NY',
        postalCode:'',
        employmentStatus:'',
        employmentPosition:'',
        employmentCompany:'',
        employmentType:'AGRICULTURE',
        employmentCountry:'USA',
        broker:false,
        directorOf:false,
        street1:'',
        investmentObjectives: "ACTIVE_DAILY",
        investmentExperience: "NONE",
        annualIncome: 0,
        networthLiquid: 0,
        networthTotal: 0,
        riskTolerance: "Low",
        termsOfUse: false,
        customerAgreement: false,
        marketDataAgreement: false,
        rule14b:  false,
        findersFee: false,
        privacyPolicy:  false,
        dataSharing:  false,
        signedBy: "",
        politicallyExposedNames:'',
        transferFrequencyPerMonth: 0,
        transferTotalExpected: 0,
        investmentHistory12M: 0,
        fundingSources: 'EMP',
        dob:'',
        idImg:false,
        docType:'PASSPORT',
        docFront:false,
        docBack:false,

    }

    componentDidMount(){
        this.fetchPresetCategories()
        if(this.props.user && !this.props.dontCheck){
            this.checkDwUserExists()
        }
        else{
            this.getPriorApp()
        }
    }

    getPriorApp = ()=>{
        console.log('need this')
    }

    checkDwUserExists = ()=>{
        console.log('checking DW user')
        fetch(`${ENDPOINT}/dw/users/dw`,{
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                "Content-Type": "application/json",
            },
        })
        .then(res => res.json())
        .then(responseJson => {
            console.log('dw user check resp',responseJson)
            if(responseJson.success && responseJson.payload.isDWUserPresent ){
                console.log('got into if')
                this.setState({dwAccountID:true, loading:false})
            }
            else{
                this.setState({ loading:false})
            }
        })
    }

    createAccExistingUser = ()=>{
        this.setState({submitClicked:true})
        let url = `${ENDPOINT}/dw/accounts/create`
        if (this.props.product === 'robo' || this.props.product === 'goal'){
            url += '?ria=true'
        }
        fetch(url,{
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                "Content-Type": "application/json",
            },
        })
        .then(res => res.json())
        .then(responseJson => {
            if(responseJson.success){
                this.props.accountPlusProduct(responseJson.payload.accountID)
            }
        })
    }



    fetchPresetCategories = ()=>{
        fetch(`${ENDPOINT}/drivewealth/preset`,{
            method: "GET",
            headers:{
              'Content-Type': 'application/json',
            },
          })
          .then(res => res.json())
          .then(responseJson => this.setState({presets:responseJson}))

    }

    createAcc = ()=>{
        this.setState({submitClicked:true})
        let dob = this.state.dob.split("-")
        let body = {
            "annualIncome": this.state.annualIncome,
            "birthdayDay":dob[2],
            "birthdayMonth":dob[1],
            "birthdayYear":dob[0],
            "broker":this.state.broker,
            "city": this.state.city,
            "country": this.state.country,
            "countryCitizenship": this.state.citizenship,
            "employmentCompany": this.state.employmentCompany,
            "employmentStatus": this.state.employmentStatus,
            "employmentType": this.state.employmentType,
            "employmentPosition": this.state.employmentPosition,
            "firstName": this.state.firstName,
            "investmentObjectives": this.state.investmentObjectives,
            "isDirectorOf":this.state.directorOf?this.state.directorOf:null,
            "lastName": this.state.lastName,
            "netWorth": this.state.networthTotal,
            "phoneNumber": this.state.phone,
            "postalCode": this.state.postalCode,
            "province": this.state.province,
            "street": this.state.street1,
            "street2": this.state.street2,
            "taxIdType": this.state.idType,
            "taxNo": this.state.idNumber,
            "usTaxPayer":this.state.usTaxPayer,
        }
        // console.log('body:',body)
        let url = `${ENDPOINT}/dw/users/create/account`
        if (this.props.product === 'robo'){
            url += '?ria=true'
        }
        fetch(url,{
            method: "POST",
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
          })
          .catch(err=>this.setState({errors:err.payload, submitClicked:false}))
          .then(res => res.json())
          .then(responseJson => {
            if(responseJson.success){
                if(!['USA','GBR','NZL','AUS'].includes(this.state.citizenship)){
                    if(this.state.docType === 'PASSPORT' || this.state.docType === 'VISA'){
                        this.uploadImage(true,responseJson.payload.dwUserID,responseJson.payload.accountID)
                    }
                    else{
                        this.uploadImage(true,responseJson.payload.dwUserID,false)
                        this.uploadImage(false,responseJson.payload.dwUserID,responseJson.payload.accountID)
                    }
                }
                else{
                    this.props.accountPlusProduct(responseJson.payload.accountID)
                }
                // this.props.accountPlusProduct(responseJson.payload.dwAccountID)
            }
            else{
                
                let errors = [...this.state.errors]
                if (responseJson.payload === 'User has been already onboarded to the DW'){
                    errors.push('You already have a DW Account with the same information')
                }
                else{
                    errors.push(responseJson.payload)
                }
                this.setState({errors, submitClicked:false})
            }
        })
    }

    // createAccOLD = ()=>{
    //     let body={
    //         "user": {
    //             "userType": "INDIVIDUAL_TRADER",
    //             // "parentIBID": "fdacbf43-253f-4941-9e85-a2bdb9aba2fc",
    //             // "wlpID": "FRCT",
    //             "documents": [
    //                 {
    //                     "type": "BASIC_INFO",
    //                     "data": {
    //                         "firstName": this.state.firstName,
    //                         "lastName": this.state.lastName,
    //                         "country": this.state.country,
    //                         "phone": this.state.phone,
    //                         "emailAddress": this.state.emailAddress,
    //                         "language": this.state.language
    //                     }
    //                 },
    //                 {
    //                     "type": "IDENTIFICATION_INFO",
    //                     "data": {
    //                         "value": this.state.idNumber,
    //                         "type": this.state.idType,
    //                         "citizenship": this.state.citizenship,
    //                         "usTaxPayer": this.state.usTaxPayer,

    //                     }
    //                 },
    //                 {
    //                     "type": "PERSONAL_INFO",
    //                     "data": {
    //                         "birthDay": this.state.birthDay,
    //                         "birthMonth": this.state.birthMonth,
    //                         "birthYear": this.state.birthYear,
    //                         "gender": this.state.gender,
    //                         "marital": this.state.maritalStatus,
    //                         "politicallyExposedNames": this.state.politicallyExposedNames
    //                     }
    //                 },
    //                 {
    //                     "type": "ADDRESS_INFO",
    //                     "data": {
    //                         "street1": this.state.street1,
    //                         "street2": this.state.street2,
    //                         "city": this.state.city,
    //                         "province": this.state.province,
    //                         "postalCode": this.state.postalCode,
    //                         "country": this.state.country,
    //                     }
    //                 },
    //                 {
    //                     "type": "EMPLOYMENT_INFO",
    //                     "data": {
    //                         "status": this.state.employmentStatus,
    //                         "position": this.state.employmentPosition,
    //                         "company": this.state.employmentCompany,
    //                         "type": this.state.employmentType,
    //                         "country": this.state.employmentCountry,
    //                         "broker": false,
    //                         "directorOf": null
    //                     }
    //                 },
    //                 {
    //                     "type": "INVESTOR_PROFILE_INFO",
    //                     "data": {
    //                         "investmentObjectives": this.state.investmentObjectives,
    //                         "investmentExperience": this.state.investmentExperience,
    //                         "annualIncome": this.state.annualIncome,
    //                         "networthLiquid": this.state.networthLiquid,
    //                         "networthTotal": this.state.networthTotal,
    //                         "riskTolerance": this.state.riskTolerance
    //                     }
    //                 },
    //                 {
    //                     "type": "COMPLIANCE_AML_INFO",
    //                     "data": {
    //                         "fundingSources": [this.state.fundingSources],
    //                         // "transferFrequencyPerMonth": this.state.transferFrequencyPerMonth,
    //                         "transferTotalExpected": this.state.transferTotalExpected,
    //                         // "investmentHistory12M": this.state.investmentHistory12M
    //                     }
    //                 },
    //                 {
    //                     "type": "DISCLOSURES",
    //                     "data": {
    //                         "termsOfUse": this.state.termsOfUse,
    //                         "customerAgreement": this.state.customerAgreement,
    //                         "marketDataAgreement": this.state.marketDataAgreement,
    //                         "rule14b": this.state.rule14b,
    //                         "findersFee": this.state.findersFee,
    //                         "privacyPolicy": this.state.privacyPolicy,
    //                         "dataSharing": this.state.dataSharing,
    //                         "signedBy": this.state.signedBy
    //                     }
    //                 }
    //             ]
    //         },
    //         "account": {
    //             "accountType": "LIVE",
    //             "accountManagementType": "RIA_MANAGED",
    //             "tradingType": "CASH"
    //         }
    //     }
    //     // console.log('fetch body: ', JSON.stringify(body))
    //     fetch(`${ENDPOINT}/drivewealth/user-account`,{
    //         method: "POST",
    //         headers:{
    //             Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
    //             "Content-Type": "application/json",
    //             "Accept": "application/json",
    //         },
    //         body: JSON.stringify(body)
    //       })
    //       .catch(err=>console.log(`catching errors creating account`, err))
    //       .then(res => res.json())
    //       .then(responseJson => console.log("create acc response", responseJson))
    // }

    uploadImage = (front,dwUserId,accountID)=>{
        // let body = {
        //     "userID":dwUserID,
        //     "type": this.state.docType,
        //     "documentImage":image,
        //     "side":front?'FRONT':'BACK'
        // }

        console.log("[INFO] Do KYC document upload");
        // let prodid = dwUserId;
        let testId = "193e233f-22ec-401e-a938-35927974f84f";
        let url = ENDPOINT + "/user/kyc-doc";
        let testurl = "http://arjavaapi-env-test.us-east-1.elasticbeanstalk.com/drivewealth/user/kyc-doc";
        let produrl = "https://api.allocaterite.com/drivewealth/user/kyc-doc"
        let proddriecturl = "http://arjavaapi-env.eba-pppapp25.us-east-1.elasticbeanstalk.com/drivewealth/user/kyc-doc";
        let devurl = "https://dev-api.allocaterite.com/drivewealth/user/kyc-doc";
        let doc = front?this.state.docFront:this.state.docBack
        let formData = new FormData();
        formData.append("userID", dwUserId);
        formData.append("documentType", this.state.docType);
        formData.append("documentImage", doc);
        formData.append("side",front? "FRONT":'BACK');
        fetch(produrl, {
            method: 'POST',
            headers:{
                "Accept": "application/json"
            },
            body: formData
        })
        .then((response)=> {
            console.log(response);
            return response.json();
        })
        .then((data)=> {
            console.log(data)
            if(accountID){
                this.props.accountPlusProduct(accountID)
            }
        }).catch(err => console.log('Request Failed', err));

    }

    createDropdownDocType =()=>{
        let list = {}
        if (this.state.presets){
            list = this.state.presets.document_types_v2
        }
        let mapped = []
        list.forEach(option=>{
            let title = Object.keys(option)[0]
            mapped.push(
                <option value={option.name} selected={this.state.docType == option.name}>{option.description}</option>
            ) 
        })
        return(
            <select placeholder="Select Below" onChange={(e)=>this.setState({docType:e.target.value})} value={this.state.docType}>
                {mapped}
            </select>
        )
    }

    createDropdownOther = (listName, stateTitle)=>{
        let list = {}
        if (this.state.presets){
            list = this.state.presets[listName]
        }
        let mapped = []
        list.forEach(option=>{
            let title = Object.keys(option)[0]
            mapped.push(
                <option value={title} selected={this.state[stateTitle] == option.id}>{option[title]}</option>
            ) 
        })
        return(
            <select placeholder="Select Below" onChange={(e)=>this.setState({[stateTitle]:e.target.value})} value={this.state[stateTitle]}>
                {mapped}
            </select>
        )
    }
    

    createDropdownCountry = (listName, stateTitle,)=>{
        let list = {}
        if (this.state.presets){
            list = this.state.presets[listName]
        }
        let mapped = []
        if(stateTitle !== 'citizenship'){
            list = list.filter(c=>c.active)
        }
        list.forEach(option=>{
            mapped.push(
                <option value={option.id} selected={this.state[stateTitle] == option.id}>{option.name}</option>
            ) 
            
        })
        return(
            <select placeholder="Select Below" onChange={(e)=>this.setState({[stateTitle]:e.target.value})} value={this.state[stateTitle]}>
                {mapped}
            </select>
        )
    }

    addCommas = (x)=>{
        if(x === ''){
            return ''
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    removeCommas = (x)=>{
        if(x === ''){
            return ''
        }else{
            return parseFloat(x.replace(/,/g, ''))
        }
    }

    validateAge(DOB) {
        var today = new Date();
        var birthDate = new Date(DOB);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--
        }
        if( age >= 18){
            return true
        }
        else{
            return false
        }
    }

    pageZero = ()=>{
        return(
            <div style={{width:'95%', margin:'auto'}}>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20,fontWeight:'bold'}}>Who is DriveWealth?</p>
                    <div style={{display:'flex',}}>
                      <p style={{}}>
                        DriveWealth is the independent custodian and broker-dealer that ForeSCITE uses to all manage members’ ForeSCITE investment accounts. Your account and any of your funds are held at DriveWealth. DriveWealth allows you to trade any of the securities in your account at the direction of ForeSCITE in order to keep your portfolio on track.
                        If you request to withdraw funds from your ForeSCITE investment account, we submit your request to DriveWealth. As custodian of those funds, DriveWealth then releases the funds into your linked bank account. Any time you add or receive funds from your investment account, you may see a reference to DriveWealth on your bank statement. 
                        To learn more about DriveWealth, visit <a href='www.Drivewealth.com' target='_blank' style={{color:'#3b8e8c'}}>www.Drivewealth.com</a>.
                      </p>
                    </div>
                    <div style={{width:'30%', margin:'auto'}}>
                        <div 
                            style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid',borderColor:'#3b8e8c',borderWidth:'.25px', fontSize: 'smaller', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center', cursor:'pointer' }}
                            onClick={() => {
                                let page = this.state.page
                                page +=1 
                                this.setState({page})
                            }}
                        >
                            {this.state.submitClicked? 
                                <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            :
                                <p style={{ color: '#3b8e8c', margin: 0, fontWeight: 'bold', fontSize: 18 }}>
                                    Next
                                </p> 
                            }
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    pageOne = ()=>{
        return(
            <div style={{width:'95%', margin:'auto'}}>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Please enter your name')?'rgb(255,0,0)':''}}>Name</p>
                    <div style={{display:'flex',}}>
                        <input type="text" placeholder="First Name" value={this.state.firstName} onChange={(e)=>{this.setState({firstName:e.target.value})}}/>
                        <input style={{marginLeft:10}} type="text" placeholder="Last Name" value={this.state.lastName} onChange={(e)=>{this.setState({lastName:e.target.value})}}/>
                    </div>
                </div>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Please enter your phone number')?'rgb(255,0,0)':''}}>Phone Number</p>
                    <div style={{display:'flex',}}>
                        <PhoneInput
                                class="phoneInput"
                                onChange={(e) => this.setState({ phone: e}) }
                                style={{}}
                                country='us'
                                enableSearch={true}
                            />                    </div>
                </div>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Please enter your email address')?'rgb(255,0,0)':''}}>Email Address</p>
                    <div style={{display:'flex',}}>
                        <input type="text" placeholder="email@example.com" value={this.state.emailAddress} onChange={(e)=>{this.setState({emailAddress:e.target.value})}}/>
                    </div>
                </div>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Country')?'rgb(255,0,0)':''}}>Country / Region</p>
                    {this.state.presets.countries ? this.createDropdownCountry('countries', 'country'): null}
                    <p style={{fontSize:14, color:this.state.errors.includes('Country')?'rgb(255,0,0)':''}}>*If you do not see your country that means we do not support your country yet</p>
                </div>
            </div>
        )
    }

    pageTwo = ()=>{
        return(
            <div style={{width:'95%', margin:'auto'}}>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Do you pay taxes in the United States?')?'rgb(255,0,0)':''}}>Do you pay taxes in the United States of America</p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.usTaxPayer===true} onChange={()=>{this.setState({usTaxPayer:true})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Yes</p>
                        </div>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <input type="radio" checked={this.state.usTaxPayer===false} onChange={()=>{this.setState({usTaxPayer:false,idType:'TIN'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>No</p>
                        </div>
                    </div>
                </div>
                {
                 this.state.usTaxPayer===true?   
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('idType')?'rgb(255,0,0)':''}}>Identification Type</p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.idType==='SSN'} onChange={()=>{this.setState({idType:'SSN'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Social Security Number</p>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.idType==='TIN'} onChange={()=>{this.setState({idType:'TIN'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Taxpayer Identification Number</p>
                        </div>
                    </div>
                </div>:null
                }
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('What is your Tax ID number??')?'rgb(255,0,0)':''}}>{this.state.idType === 'SSN'?'Social Security':'Taxpayer Identification'} Number</p>
                    <div style={{display:'flex',}}>
                        <input type="text" placeholder= {this.state.idType==='SSN'?"XXX-XX-XXXX":''} value={this.state.idNumber} onChange={(e)=>{this.setState({idNumber:e.target.value})}}/>
                    </div>
                </div>
                
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Citizen')?'rgb(255,0,0)':''}}>What country or region are you a citizen of? </p>
                    {this.state.presets.countries ? this.createDropdownCountry('countries', 'citizenship'): null}
                </div>
                {/* HERE */}
                {['USA','GBR','NZL','AUS'].includes(this.state.citizenship)?null:
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:''}}>Please select a document type document below.</p>
                    {/* <input type="file" id="img" name="img" accept="image/*" onChange={(e)=>this.setState({idImg:e.target.value})}/> */}
                    {this.state.presets.document_types ? this.createDropdownDocType(): null}
                    <p style={{fontSize:20, color:''}}>Then upload the necessary image{this.state.docType === 'PASSPORT' || this.state.docType === 'VISA'?'':'s'}.</p>
                    <p style={{fontSize:20, color:''}}>All files must be either .pdf .jpg or .png</p>
                    {this.state.docType === 'PASSPORT' || this.state.docType === 'VISA'?
                        <div>
                            <p style={{margin:0, marginLeft:10,marginTop:10, fontSize:20}}>Front of Document</p>
                            <input type="file" id="img" name="img" accept="image/*" onChange={event => {
                                    this.setState({
                                        docFront: event.target.files[0]
                                    }, () => {
                                      console.log(this.state.docFront);
                                    });
                                  }}
                            />
                        </div>
                    :
                        <div style={{}}>  
                            <p style={{margin:0, marginLeft:10,marginTop:10, fontSize:20}}>Front of Document</p>
                            <input type="file" id="img" name="img" accept="image/*" onChange={event => {
                                    this.setState({
                                        docFront: event.target.files[0]
                                    }, () => {
                                      console.log(this.state.docFront);
                                    });
                                  }
                            }
                            />
                            <p style={{margin:0, marginLeft:10,marginTop:10, fontSize:20}}>Back of Document</p>
                            <input type="file" id="img" name="img" accept="image/*" onChange={event => {
                                    this.setState({
                                        docBack: event.target.files[0]
                                    }, () => {
                                      console.log(this.state.docFront);
                                    });
                                  }
                            }/>
                        </div>
                    }
                </div>}
                {/* <div style={{backgroundColor:'rgb(0,255,0)', borderRadius:12, padding:10,}} onClick={()=>this.uploadImage()}>
                    <p>CLICK ME TO TEST FILE UPLOAD</p>
                </div> */}
                {/*IMAGE STUFF XYZ*/}
            </div>
        )
    }

    pageThree = ()=>{
        return(
            <div style={{width:'95%', margin:'auto'}}>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Please enter your birthday') ||this.state.errors.includes('Currently we do not support accounts for minors.')  ?'rgb(255,0,0)':''}}>What is your Date of Birth</p>
                    <input type="date" value={this.state.dob} 
                        onChange={(e)=>{
                            this.setState({dob:e.target.value})
                        }}/>
                </div>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Please fill in your address') || this.state.errors.includes('Address Incomplete') || this.state.errors.includes('Address not found')?'rgb(255,0,0)':''}}>Address</p>
                    <input type="text" placeholder="Street Address" value={this.state.street1} onChange={(e)=>{this.setState({street1:e.target.value})}}/>
                    <input type="text" placeholder="Street Address Line 2" value={this.state.street2} onChange={(e)=>{this.setState({street2:e.target.value})}}/>
                    <div style={{display:'flex'}}>
                        <input type="text" placeholder="City" value={this.state.city} onChange={(e)=>{this.setState({city:e.target.value})}}/>
                        
                        {this.state.presets.countries && this.state.citizenship === 'USA' ? 
                            this.createDropdownOther('us_states', 'province')
                        : 
                            <input type="text" placeholder="State" value={this.state.province} onChange={(e)=>{this.setState({province:e.target.value})}}/>
                        }
                    </div>
                    <input type="text" placeholder="Zip Code" value={this.state.postalCode} onChange={(e)=>{this.setState({postalCode:e.target.value})}}/>
                </div>
                {/* <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Please enter your marital status')?'rgb(255,0,0)':''}}>Marital Status</p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.maritalStatus === 'SINGLE'} onChange={()=>{this.setState({maritalStatus:'SINGLE'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Single</p>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.maritalStatus === 'DIVORCED'} onChange={()=>{this.setState({maritalStatus:'DIVORCED'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Divorced</p>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.maritalStatus === 'MARRIED'} onChange={()=>{this.setState({maritalStatus:'MARRIED'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Married</p>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.maritalStatus === 'WIDOWED'} onChange={()=>{this.setState({maritalStatus:'WIDOWED'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Widowed</p>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.maritalStatus === 'PARTNER'} onChange={()=>{this.setState({maritalStatus:'PARTNER'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Partner</p>
                        </div>
                    </div>
                </div> */}
                {/* <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Which gender best describes you?')?'rgb(255,0,0)':''}}>Gender</p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.gender === 'MALE'} onChange={()=>{this.setState({gender:'MALE'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Male</p>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.gender === 'FEMALE'} onChange={()=>{this.setState({gender:'FEMALE'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Female</p>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.gender === 'OTHER'} onChange={()=>{this.setState({gender:'OTHER'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Other</p>
                        </div>
                    </div>
                </div> */}
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Political Names')?'rgb(255,0,0)':''}}>Is this account maintained for a current or former Politically Exposed Person or Public Official (includes U.S. and Foreign)? A politically exposed person is someone who has been entrusted with a prominent public function, or who is closely related to such a person. If so please fill in who. Otherwise leave this blank.</p>
                    <div style={{display:'flex',}}>
                        <input type="text" placeholder="" value={this.state.politicallyExposedNames} onChange={(e)=>{this.setState({politicallyExposedNames:e.target.value})}}/>
                    </div>
                </div>
                
            </div>
        )
    }

    pageFour = ()=>{
        return(
            <div style={{width:'95%', margin:'auto'}}>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('What is your risk tolerance?')?'rgb(255,0,0)':''}}>What is your risk tolerance for this account?</p>
                    {this.state.presets.countries ? this.createDropdownOther('inv_risks', 'riskTolerance'): null}
                </div>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('What is your prior investing experience')?'rgb(255,0,0)':''}}>What is your prior investment experience?</p>
                    {this.state.presets.countries ? this.createDropdownOther('inv_exp', 'investmentExperience'): null} 
                </div>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('What is your main reason for investing')?'rgb(255,0,0)':''}}>What is your main reason for investing?</p>
                    {this.state.presets.countries ? this.createDropdownOther('inv_objectives', 'investmentObjectives'): null}
                    {/* <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                            <input type="radio" checked={this.state.investmentObjectives === 'low'} onChange={()=>{this.setState({investmentObjectives:'low'})}}/> 
                            <p style={{margin:0, marginLeft:10}}> Short Term</p>
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                            <input type="radio" checked={this.state.investmentObjectives === 'med'} onChange={()=>{this.setState({investmentObjectives:'med'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Medium Term</p>
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                            <input type="radio" checked={this.state.investmentObjectives === 'high'} onChange={()=>{this.setState({investmentObjectives:'high'})}}/> 
                            <p style={{margin:0, marginLeft:10}}> Long Term</p>
                        </div>
                    </div> */}
                </div>
                {/* <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('What is your liquid net worth?')?'rgb(255,0,0)':''}}>What is your liquid net worth?</p>
                    <div style={{display:'flex',}}>
                        <input type="text" placeholder="" value={this.addCommas(this.state.networthLiquid)}  onChange={(e)=>{
                            let networthLiquid = this.removeCommas(e.target.value)
                            this.setState({networthLiquid})
                        }}/> 
                    </div>
                </div> */}
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('What is your total net worth?')?'rgb(255,0,0)':''}}>What is your total net worth?</p>
                    <div style={{display:'flex',}}>
                        <input type="text" placeholder="" value={this.addCommas(this.state.networthTotal)} onChange={(e)=>{
                            let networthTotal = this.removeCommas(e.target.value)
                            this.setState({networthTotal})
                        }}/>
                    </div>
                </div>
            </div>
        )
    }

    pageFive = ()=>{
        return(
            <div style={{width:'95%', margin:'auto'}}>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('What is your employment status?')?'rgb(255,0,0)':''}}>What is your current employment status? </p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                            <input type="radio" checked={this.state.employmentStatus === 'STUDENT'} onChange={()=>{this.setState({employmentStatus:'STUDENT', employmentType:null,employmentPosition:null,employmentCompany:null })}}/> 
                            <p style={{margin:0, marginLeft:10}}>Student</p>
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                            <input type="radio" checked={this.state.employmentStatus === 'EMPLOYED'} onChange={()=>{this.setState({employmentStatus:'EMPLOYED'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Employed</p>
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                            <input type="radio" checked={this.state.employmentStatus === 'SELF_EMPLOYED'} onChange={()=>{this.setState({employmentStatus:'SELF_EMPLOYED'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Self Employed or Business Owner</p>
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                            <input type="radio" checked={this.state.employmentStatus === 'UNEMPLOYED'} onChange={()=>{this.setState({employmentStatus:'UNEMPLOYED',employmentType:null,employmentPosition:null,employmentCompany:null})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Not Employed</p>
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                            <input type="radio" checked={this.state.employmentStatus === 'RETIRED'} onChange={()=>{this.setState({employmentStatus:'RETIRED', employmentType:null,employmentPosition:null,employmentCompany:null})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Retired</p>
                        </div>
                    </div>
                </div>
                {this.state.employmentStatus === 'RETIRED' || this.state.employmentStatus === 'STUDENT' || this.state.employmentStatus === 'UNEMPLOYED'? null:
                    <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                        <p style={{fontSize:20, color:this.state.errors.includes('JobTitle')?'rgb(255,0,0)':''}}>What sector is your job in?</p>
                        {this.state.presets.countries ? this.createDropdownOther('emp_types', 'employmentType'): null}
                        
                    </div>
                }
                {this.state.employmentStatus === 'RETIRED' || this.state.employmentStatus === 'STUDENT' || this.state.employmentStatus === 'UNEMPLOYED'? null:
                    <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                        <p style={{fontSize:20, color:this.state.errors.includes('JobTitle')?'rgb(255,0,0)':''}}>What is your job title?</p>
                        {this.state.presets.countries ? this.createDropdownOther('emp_positions', 'employmentPosition'): null}
                        
                    </div>
                }
                {this.state.employmentStatus === 'RETIRED' || this.state.employmentStatus === 'STUDENT' || this.state.employmentStatus === 'UNEMPLOYED'? null:
                    <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                        <p style={{fontSize:20, color:this.state.errors.includes('Employer')?'rgb(255,0,0)':''}}>Who is your employer?</p>
                        <div style={{display:'flex',}}>
                            <input type="text" placeholder="" value={this.state.employmentCompany} onChange={(e)=>{this.setState({employmentCompany:e.target.value})}}/>
                        </div>
                    </div>
                }
                {/* {this.state.employmentStatus === 'RETIRED' || this.state.employmentStatus === 'STUDENT' || this.state.employmentStatus === 'UNEMPLOYED'? null:
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('EmploymentCountry')?'rgb(255,0,0)':''}}>What country is your job based?</p>
                    {this.state.presets.countries ? this.createDropdownCountry('countries', 'employmentCountry'): null}
                </div>
                } */}
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:''}}>Are you affiliated with or employed by a stock exchange, member firm of an exchange or FINRA, or a municipal securities broker-dealer?</p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.broker} onChange={()=>{this.setState({broker:true})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Yes</p>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={!this.state.broker} onChange={()=>{this.setState({broker:false})}}/> 
                            <p style={{margin:0, marginLeft:10}}>No</p>
                        </div>
                    </div>
                    {
                        this.state.broker?
                            <div>
                                <p>***You are obligated to notify your employer in writing of your intention to open this account.***</p>
                            </div>
                        :
                            null
                    }
                </div>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:''}}>Is the account holder(s) a control person of a publicly traded company? A Director, Officer or 10% stock owner?</p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={typeof this.state.directorOf !== 'boolean'} onChange={()=>{this.setState({directorOf:''})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Yes</p>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <input type="radio" checked={this.state.directorOf === false} onChange={()=>{this.setState({directorOf:false})}}/> 
                            <p style={{margin:0, marginLeft:10}}>No</p>
                        </div>
                        {typeof this.state.directorOf !== 'boolean'?
                        <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                            <p style={{fontSize:20, color:''}}>Please list the company name and its ticker symbol below.</p>
                            <div style={{display:'flex',}}>
                                <input type="text" placeholder="" value={this.state.directorOf} onChange={(e)=>{this.setState({directorOf:e.target.value})}}/>
                            </div>
                        </div>
                        :null
                        }
                    </div>
                </div>
            </div>
        )
    }

    pageSix = ()=>{
        return(
            <div style={{width:'95%', margin:'auto'}}>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('FundsFrom')?'rgb(255,0,0)':''}}>Where are the funds in this account coming from? </p>
                    {this.state.presets.countries ? this.createDropdownOther('funding_sources', 'fundingSources'): null}
                </div>
                {/* <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('')?'rgb(255,0,0)':''}}>How much money do you plan to transfer into this account?</p>
                    <div style={{display:'flex',}}>
                        <input type="text" placeholder="" value={this.addCommas(this.state.transferTotalExpected)} onChange={(e)=>{
                            let transferTotalExpected = this.removeCommas(e.target.value)
                            this.setState({transferTotalExpected})
                        }}/>
                    </div>
                </div> */}
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <p style={{fontSize:20, color:this.state.errors.includes('Annual Income')?'rgb(255,0,0)':''}}>What is your annual income?</p>
                    <div style={{display:'flex',}}>
                        <input type="text" placeholder="" value={this.addCommas(this.state.annualIncome)} onChange={(e)=>{
                            let annualIncome = this.removeCommas(e.target.value)
                            this.setState({annualIncome})
                        }}/>
                    </div>
                </div>
            </div>
        )
    }

    pageSeven = ()=>{
        return(
            <div style={{width:'95%', margin:'auto'}}>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                    <a href="https://apps.drivewealth.com/disclosures/ " target="_blank" style={{color:this.state.errors.includes('Have you read and signed the Terms of Use?')?'rgb(255,0,0)':'', fontSize:20}}>Disclaimers and Disclosures</a>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center',}}>
                        <input type="radio" checked={this.state.termsOfUse} onChange={()=>{this.setState({termsOfUse:!this.state.termsOfUse})}}/>
                        <p style={{fontSize:20,margin:0, marginLeft:10}}>I have read and agree to the DriveWealth's Disclaimers and Disclosures</p>
                    </div>
                    <a href="https://legal.drivewealth.com/privacy-policy" target="_blank" style={{color:this.state.errors.includes('Have you read and signed the Customer Agreement?')?'rgb(255,0,0)':'', fontSize:20}}>Privacy Policy</a>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center',}}>
                        <input type="radio" checked={this.state.customerAgreement} onChange={()=>{this.setState({customerAgreement:!this.state.customerAgreement})}}/>
                        <p style={{fontSize:20,margin:0, marginLeft:10}}>I have read and agree to DriveWealth's Privacy Policy</p>
                    </div>
                    <a href={`https://apps.drivewealth.com/disclosures/?showOnlyTaxDoc=true&citizenship=${this.state.citizenship}`} target="_blank" style={{color:this.state.errors.includes('Have you read and signed the Market Data Agreement?')?'rgb(255,0,0)':'', fontSize:20}}>Tax Disclosures</a>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center',}}>
                        <input type="radio" checked={this.state.marketDataAgreement} onChange={()=>{this.setState({marketDataAgreement:!this.state.marketDataAgreement})}}/>
                        <p style={{fontSize:20,margin:0, marginLeft:10}}>I have read the tax disclosures </p>
                    </div>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <p style={{fontSize:20, color:this.state.errors.includes('E-Signature is empty')?'rgb(255,0,0)':''}}>E-Signature</p>
                        <input type="text" placeholder="Your Name Here" onChange={(e)=>{this.setState({signedBy:e.target.value})}}/>
                    </div>
                </div>
            </div>
        )
    }

    whatPage = ()=>{
        switch(this.state.page) {
            case 1:
              return this.pageOne()            
            case 2:
              return this.pageTwo()
            case 3:
                return this.pageThree()
            case 4:
              return this.pageFour()
            case 5:
              return this.pageFive()
            case 6:
              return this.pageSix()
            case 7:
              return this.pageSeven()
            default:
                console.log('error step set to: ', this.state.page)
              return <div><p>ERROR</p></div>
        }
    }

    checkAddress = ()=>{
        console.log('checking address')
        fetch(`${ENDPOINT}/util/addressValidation`,{
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json"
          },
          body: JSON.stringify({
              "address1":this.state.street2.length === 0? null :this.state.street2.length,
              "address2":this.state.street1.length === 0? null : this.state.street1,
              "city":this.state.city.length === 0? null : this.state.city,
              "state":this.state.province.length === 0? null : this.state.province,
              "zip5":this.state.postalCode.length === 0? null : this.state.postalCode,
              "zip4":null,
          })
        })
        .then((resp)=>resp.json())
        .then((responseJson)=> {
            this.setState({submitClicked:false})
            console.log('check address resp:', responseJson)
            if (responseJson.pobox){
                this.setState({errors:[...this.state.errors,'We do not allow PO Boxes as your Address'],page:3})
            }
            if (responseJson.valid){
                this.setState({page:4})
            }
            else if(responseJson.incomplete){
                this.setState({errors:[...this.state.errors,'Address Incomplete'],page:3})
            }
            else{
                this.setState({errors:[...this.state.errors,'Address Not Found'],page:3})
            }
        })
        .catch(err=>console.log('CheckAddressError:', err))
    }

    checkPage = ()=>{
        let errors = []
        switch(this.state.page) {
            case 1:
              if(this.state.firstName.length < 2 || this.state.lastName.length < 1){
                errors.push('Please enter your name')
              }            
              if(this.state.phone.length < 9){
                errors.push('Please enter your phone number')
              }            
              if(this.state.emailAddress.length < 5){
                errors.push('Please enter your email address')
              }            
            //   if(this.state.country === ''){
            //     errors.push('Country')
            //   }            
              break;
            case 2:
                // if(this.state.idType === ''){
                //     errors.push('idType')
                //   }      
                if(this.state.idNumber === ''){
                    errors.push('What is your Tax ID number?')
                  }      
                if(this.state.usTaxPayer === ''){
                    errors.push('Do you pay taxes in the US')
                  }      
                // if(this.state.citizenship === ''){
                //     errors.push('Citizen')
                //   }      
                
                  break;
            case 3:
                if(this.state.street1 === '' || this.state.city === ''|| this.state.province === '' || this.state.postalCode === ''){
                    errors.push('Please fill in address')
                } 
                if(this.state.dob === ''){
                    errors.push('Please enter your birthday')
                  }      
                if(!this.validateAge(this.state.dob)){
                    errors.push('Currently we do not support accounts for minors.')
                  }      
                // if(this.state.maritalStatus === ''){
                //     errors.push('Please enter your marital status')
                //   }    
                // if(this.state.gender === ''){
                //     errors.push('Which gender best describes you')
                //   }    
                  break;
            case 4:
                if(this.state.riskTolerance === ''){
                    errors.push('What is your risk tolerance?')
                  }  
                if(this.state.investmentExperience === ''){
                    errors.push('What is your prior investing experience')
                  }  
                if(this.state.investmentObjectives === ''){
                    errors.push('What is your main reason for investing')
                  }  
                // if(this.state.networthLiquid < 1){
                //     errors.push('What is your liquid net worth?')
                //   }  
                // if(this.state.networthTotal < 1){
                //     errors.push('What is your total net worth?')
                //   }  
                  break;
            case 5:
                if(this.state.employmentStatus === ""){
                    errors.push('What is your employment status?')
                  }  
                // if(this.state.employmentPosition === ""){
                //     errors.push('JobTitle')
                //   }  
                // if(this.state.employmentCompany === ""){
                //     errors.push('Employer')
                //   }  
                  break;
            case 6:
                // if(this.state.employmentStatus === ""){
                //     errors.push('What is your employment status?')
                //   }  
                  break;
            case 7:
              if(this.state.termsOfUse === ""){
                    errors.push('Have you read and signed the Terms of Use?')
                  }  
              if(this.state.customerAgreement === ""){
                    errors.push('Have you read and signed the Customer Agreement?')
                  }  
              if(this.state.marketDataAgreement === ""){
                    errors.push('Have you read and signed the Market Data Agreement?')
                  }  
              if(this.state.rule14b === ""){
                    errors.push('Have you read and signed the Rule 14b?')
                  }  
              if(this.state.dataSharing === ""){
                    errors.push('Have you read and signed the Data Sharing Agreement')
                  }  
              if(this.state.signedBy === ""){
                    errors.push('E-Signature is empty')
                  }  
              else if(this.state.signedBy === this.state.firstName + this.state.lastName){
                    errors.push('E-Signature does not match your first and last name')
                  }  
                  break;
        }
        this.setState({errors})
        console.log('errors: ', errors)
        if(errors.length === 0){
            return true
        }
        else{
            return false
        }
    }

    showErrors = () => {
        if (this.state.errors) {
        let e = this.state.errors.map(ele=><li>• {ele}</li>)
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(253,180,181)', borderRadius: 10, padding: 10, marginBottom: 5, }}>
                        <ul style={{margin:0, listStyleType:'none', padding:0}}>{e}</ul>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }


    render(){
        if(this.state.page === 0){
            return this.pageZero()
        }
        if(this.state.loading &&!this.props.dontCheck && this.state.page !==0){
            return(
                <div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h2>Loading... </h2>
                    </div>
                </div>
            )
        }
        if(this.state.dwAccountID){
        // if(false){
            return(
                <div>
                    <div>
                        <p>Are you sure you want to create a new DriveWealth account for your {this.props.product === 'robo'?'robo':null}{this.props.product === 'normal'?'brokerage account':null}{this.props.product === 'group'?'trade group':null} ?</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-evenly',}}>
                        <div style={{width:'95%', margin:'auto'}}>
                            <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                                <div
                                    onClick={()=>this.createAccExistingUser()}
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:10,borderWidth:'1.25px', borderColor: 'rgb(52,131,129)', paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10,width:'60%',margin:'auto' }}
                                >
                                    {this.state.submitClicked? 
                                    <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                    <p style={{margin:0,color:'rgb(52,131,129)'}}>
                                        Yes, create a new DriveWealth account.
                                    </p>
                                    }
                                </div>
                                {/* <div
                                    onClick={()=>this.setState({dwAccountID:false})}
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:10,borderWidth:'1.25px', borderColor: 'rgb(52,131,129)', paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                >
                                    <p style={{margin:0,color:'rgb(52,131,129)'}}>
                                        Create a new DriveWealth account with updated Information
                                    </p>
                                </div> */}
                                {this.props.external?
                                    <div
                                        onClick={()=>this.props.goBack()}
                                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:10,borderWidth:'1.25px', borderColor: 'rgb(52,131,129)', paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, width:'60%',margin:'auto'}}
                                    >
                                        <p style={{margin:0,color:'rgb(52,131,129)'}}>
                                            Go back
                                        </p>
                                    </div>
                                :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div style={{display:'flex', flexDirection:'column'}}>
                  <div style={{width:'100%'}}>
                        <div style={{width:'100%'}}>
                            {this.whatPage()}
                        </div>
                        <div style={{width:'100%',display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                            {this.state.errors.length > 0 ? this.showErrors():null}
                        </div>
                        <div style={{display:'flex', justifyContent:'space-evenly'}}>
                            {this.state.page > 1?
                                <div style={{display:'flex',height:'100%', justifyContent:'center',alignItems:'center',}}>
                                    <div 
                                        style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid',borderColor:'#3b8e8c',borderWidth:'.25px', fontSize: 'smaller', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center', cursor:'pointer' }}
                                        onClick={() => {
                                                // console.log('back button clicked')
                                                this.setState({page:this.state.page-1,errors:[]})
                                            }
                                        } 
                                    >
                                        <p style={{ color: '#3b8e8c', margin: 0, fontWeight: 'bold', fontSize: 18 }}>
                                            Back
                                        </p> 
                                    </div>
                                </div>
                            : null}
                            <div style={{display:'flex',height:'100%', justifyContent:'center',alignItems:'center',}}>
                                <div 
                                    style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid',borderColor:'#3b8e8c',borderWidth:'.25px', fontSize: 'smaller', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center', cursor:'pointer' }}
                                    onClick={() => {
                                        console.log('submit button clicked')
                                            if(this.checkPage()){
                                                let page = this.state.page 
                                                console.log('pageCheck success')
                                                if(this.state.page === 7){
                                                    this.createAcc()
                                                }
                                                else if(this.state.page === 3){
                                                    this.setState({submitClicked:true})
                                                    this.checkAddress()
                                                }else{
                                                    window.scrollTo(0, 0)
                                                    page +=1 
                                                    this.setState({page})
                                                }
                                            }
                                            else{
                                                console.log('submit errors: ', this.state.errors)
                                            }
                                        }
                                    } 
                                >
                                    {this.state.submitClicked? 
                                    <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                    <p style={{ color: '#3b8e8c', margin: 0, fontWeight: 'bold', fontSize: 18 }}>
                                        {this.state.page === 7? 'Submit' : 'Next'}
                                    </p> 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
