import React, { Component } from "react";
import Features from "../layout/Features";
import { Container, Row } from "react-bootstrap";
import ConceptOnHomePage from "../layout/ConceptOnHomePage";
import UniqueSellingProps from "../layout/UniqueSellingProps";
import MobileAppPromo from "../layout/MobileAppPromo";
import TopPerform from "../TopPerform";
import Footer from "../Footer";
import Header from "../PublicHeader"


export class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    //adding meta tags (apply to Home page only)
    var meta = document.createElement('meta');
    meta.name = "description";
    meta.content = "ForeSCITE is an AI-powered FinTech platform for risk-adjusted wealth management and risk analytics.";
    document.getElementsByTagName('head')[0].appendChild(meta);
    document.title = `ForeSCITE`;
  }

  componentWillUnmount() {
    var metaElem = document.querySelector('meta[name="description"]');
    metaElem.parentNode.removeChild(metaElem);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut} isSmallScreen={this.props.isSmallScreen}/>
      <div>
        <Container>
          <Row className="page-blank-row-md" />
          <ConceptOnHomePage isChinese={false} />
          <UniqueSellingProps isChinese={false} />
          <MobileAppPromo isChinese={false} />
          {/* <TopPerform isChinese={false} /> */}
          <Features />
          <Row className="page-blank-row" />
        </Container>
        <Footer />
      </div>
      </div>
    );
  }
}

export default Home;
