import React from 'react'
import {NavLink,} from "react-router-dom"
import { Card, Button, Row } from "react-bootstrap";

import ENDPOINT from './Endpoint'

import upArrow from "../assets/single_up.svg";
import downArrow from "../assets/single_down.svg";
import strongUpArrow from "../assets/double_up.svg";
import strongDownArrow from "../assets/double_down.svg";
import flatArrow from "../assets/flat.svg";

export default class SingleCompanyCard extends React.Component{
    state={
        hover: false,
        allocation: this.props.item.allocation,
        value: this.props.item.value,
        shares: this.props.item.shares
    }

  getArrow = (direction) => {
    switch (direction) {
      case -1:
        return downArrow;
      case 1:
        return upArrow;
      case 2:
        return strongUpArrow;
      case -2:
        return strongDownArrow;
      default:
        return flatArrow;
    }
  };

  checkLogo = (symbol)=>{
    let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    if (http.status != 404){
        return (
          <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:100, height:100,justifySelf:'flex-end',}}>
                <img 
                    // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
                    src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
                    onerror="this.onerror=null; this.remove();" 
                    style={{objectFit:'contain', width:100,height:100, }}
                />
            </div>
        )
    }
    else{
        return (
          <div style={{borderRadius:8, padding: 10, backgroundColor:'transparent', }}>
            
          </div>
        )
    }
}

    titleColor = (direction)=>{
      if (direction == 0) {
        // return "#a8a6a5"
        return "rgb(84, 83, 82)"
      }
      else if (direction > 0) {
        return 'rgb(43,174,43)'
      } 
      else {
        return "#e31111"
      }
    }

  removeFromWatchlist(symbol) {
    this.props.remove(symbol)
    fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
      method: "POST",
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken
      },
      body: JSON.stringify({ delete: [symbol] })
    })
  }

  addToWatchlist(symbol) {
    this.props.add(symbol)
    fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
      method: "POST",
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken
      },
      body: JSON.stringify({ add: [symbol] })
    })
  }

    render(){
      let { item, index, sector, editMode, dashboard, watchlisted, sudoed, construct} = this.props
        if(dashboard){
          console.log('dashboard', item.symbol)
          return(
            <div style={{ height: "100%", }}>
              <div
                style={{
                  padding: 25,
                  width: 250,
                  borderRadius: 8,
                  margin: 10,
                  backgroundColor: this.state.hover ? '#F8F9FA' : "rgb(248,249,250)",
                  textDecoration: "none",
                }}
              >
                <Row
                  style={{
                    backgroundColor: this.titleColor(item.direction),
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                    textDecoration: "none",
                  }}
                >
                  <div>
                  {this.checkLogo(item.symbol)}
                </div>
                  <h4
                    style={{
                      color: "#FFF",
                      fontWeight: "bold",
                      marginTop: 4,
                      width: "60%",
                      textAlign: "left",
                      textDecoration: "none",
                    }}
                  >
                    {item.symbol}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 44,
                      textDecoration: "none",
                    }}
                    onMouseEnter={() => this.setState({ hover: true })}
                    onMouseLeave={() => this.setState({ hover: false })}
                  >
                    <h6
                      style={{
                        borderRadius: 5,
                        color: this.titleColor(item.direction),
                        marginTop: 5,
                        backgroundColor: "#FFF",
                        padding: 5,
                        textDecoration: "none",
                      }}
                    >
                      {item.price
                        ? `${item.priceIntraday ? item.priceIntraday.toFixed(2) : item.price.toFixed(2)}`
                        : "00.00"}
                    </h6>
                  </div>
                </Row>
                <h5
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    textDecoration: "none",
                    color: "rgb(17,17,17)",
                    height:48,
                  }}
                >
                  {item.company}
                </h5>
                <div style={{display:'flex', justifyContent:'space-around'}}>
                  <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: sudoed ? 'rgb(253,234,234)' : 'rgb(226,238,227)', borderRadius: 10, cursor: 'pointer', marginLeft: 2, width: '100%', height: 30 }}
                    onClick={() => sudoed ? this.props.removeSudo(item) : this.props.addSudo(item)}
                  >
                    <p style={{ margin: 0, fontWeight: 'bold', color: sudoed ?'rgb(187,58,60)':'rgb(51,173,53)' }}>{sudoed ? 'remove' : 'add to portfolio'}</p>
                  </div>
                </div>
              </div>
            </div>
          )
          
        }


        if(editMode){
          console.log('edit', item.symbol)

          return (
            <div style={{ height: "85%",}}>
            <div
              style={{
                padding: 25,
                width: 250,
                borderRadius: 8,
                margin: 10,
                backgroundColor: this.state.hover ? '#F8F9FA' : "rgb(248,249,250)",
                textDecoration: "none",
                cursor: 'pointer',
              }}
              onMouseEnter={() => this.setState({ hover: true })}
              onMouseLeave={() => this.setState({ hover: false })}
            >
              <Row
                style={{
                  backgroundColor: this.titleColor(item.direction),
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 8,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 8,
                  textDecoration: "none",
                }}
              >
                <h4
                  style={{
                    color: "#FFF",
                    fontWeight: "bold",
                    marginTop: 4,
                    width: "60%",
                    textAlign: "left",
                    textDecoration: "none",
                  }}
                >
                  {item.symbol}
                </h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 44,
                    textDecoration: "none",
                  }}
                  onMouseEnter={() => this.setState({ hover: true })}
                  onMouseLeave={() => this.setState({ hover: false })}
                  onClick={()=>this.removeFromWatchlist(item.symbol)}
                >
                    <h6
                      style={{
                        borderRadius: 5,
                        marginTop: 5,
                        backgroundColor: 'rgb(230,88,82)',
                        color: 'rgb(255,255,255)',
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingRight: 10,
                        paddingLeft: 10,
                        textDecoration: "none",
                        fontWeight: 'bold'
                      }}
                    >
                      X
                  </h6>
                    
                </div>
              </Row>
              <h5
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  textDecoration: "none",
                  color: "rgb(17,17,17)",
                }}
              >
                {item.company}
              </h5>
              <h6
                style={{
                  fontWeight: "300",
                  textAlign: "left",
                  color: "#808080",
                  textDecoration: "none",
                }}
              >
                {item.industry}
              </h6>
              <div style={{ display: 'flex' }}>
                <h6 style={{
                  fontWeight: "300",
                  textAlign: "left",
                  color: "#808080",
                  textDecoration: "none",
                }}>Current Trend:</h6>
                <img
                  src={this.getArrow(item.direction)}
                  style={{
                    marginLeft: 5,
                    width: 20,
                    height: 20,
                  }}
                />
              </div>
            </div>
            </div>
          )
        }else{
          console.log('other', item.symbol)
        return (
          <NavLink
            style={{ textDecoration: "none" }}
            key={item.symbol}
            to={`/CompanyPage/${item.symbol}`}
          >
            <div>
              {this.checkLogo(item.symbol)}
            </div>
            <div
              style={{
                padding: 25,
                width: 250,
                borderRadius: 8,
                margin: 10,
                height: "85%",
                backgroundColor: this.state.hover ? '#F8F9FA': "rgb(248,249,250)",
                textDecoration: "none",
                cursor: 'pointer',
              }}
              onMouseEnter={()=>this.setState({hover:true})}
              onMouseLeave={()=>this.setState({hover:false})}
            >
              <Row
                style={{
                  backgroundColor: this.titleColor(item.direction),
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 8,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 8,
                  textDecoration: "none",
                }}
              >
                <h4
                  style={{
                    color: "#FFF",
                    fontWeight: "bold",
                    marginTop: 4,
                    width: "60%",
                    textAlign: "left",
                    textDecoration: "none",
                  }}
                >
                  {item.symbol}
                </h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 44,
                    textDecoration: "none",
                  }}
                >
                  <h6
                    style={{
                      borderRadius: 5,
                      color: this.titleColor(item.direction),
                      marginTop: 5,
                      backgroundColor: "#FFF",
                      padding: 5,
                      textDecoration: "none",
                    }}
                  >
                    {item.price
                      ? `${item.priceIntraday ? item.priceIntraday.toFixed(2) : item.price.toFixed(2)}`
                      : "00.00"}
                  </h6>
                </div>
              </Row>
              <h5
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  textDecoration: "none",
                  color: "rgb(17,17,17)",
                }}
              >
                {item.company}
              </h5>
              <h6
                style={{
                  fontWeight: "300",
                  textAlign: "left",
                  color: "#808080",
                  textDecoration: "none",
                }}
              >
                {item.industry}
              </h6>
              <div style={{display: 'flex'}}>
                <h6 style={{
                  fontWeight: "300",
                  textAlign: "left",
                  color: "#808080",
                  textDecoration: "none",
                }}>Current Trend:</h6>
                <img
                  src={this.getArrow(item.direction)}
                  style={{
                    marginLeft: 5,
                    width: 20,
                    height: 20,
                  }}
                />
              </div>
            </div>
          </NavLink>
        );}
    }
}