import React, { Component } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

export class TopPerform extends Component {
  state = {
    isChinese: this.props.isChinese
  };

  render() {
    return (
      <Container>
        <Row>
          <Col className="top-table-col">
            {this.state.isChinese ? (
              <p className="top-return-text">
                AllocateRite是最佳的智能投资顾问
              </p>
            ) : (
              <p className="top-return-text">
                AllocateRite is the <span className="top">top</span> performing
                robo
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="top-table-col">
            <center>
              <Table responsive className="top-perform-table">
                <thead>
                  <tr className="title-text">
                    <th>#</th>
                    {this.state.isChinese ? (
                      <th>智能顾问</th>
                    ) : (
                      <th>Robo Advisor</th>
                    )}
                    {this.state.isChinese ? (
                      <th>两年回报率</th>
                    ) : (
                      <th>Two-Year Annualized Return</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr className="allocaterite-tr">
                    <th>1</th>
                    <th>AllocateRite</th>
                    <th>
                      <span className="allocaterite-best">8.18%</span>
                    </th>
                  </tr>
                  <tr className="others-tr">
                    <th className="others-tr">2</th>
                    <th className="others-tr">Fidelity Go</th>
                    <th className="others-tr">
                      7.26<span>%</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="others-tr">3</th>
                    <th className="others-tr">WiseBanyan</th>
                    <th className="others-tr">
                      7.00<span>%</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="others-tr">4</th>
                    <th className="others-tr">Wealthfront</th>
                    <th className="others-tr">
                      6.92<span>%</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="others-tr">5</th>
                    <th className="others-tr">SigFig</th>
                    <th className="others-tr">
                      6.87<span>%</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="others-tr">6</th>
                    <th className="others-tr">TD Ameritrade</th>
                    <th className="others-tr">
                      6.71<span>%</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="others-tr">7</th>
                    <th className="others-tr">Vanguard</th>
                    <th className="others-tr">
                      6.46<span>%</span>
                    </th>
                  </tr>
                  <tr className="last-tr">
                    <th className="others-tr">8</th>
                    <th className="others-tr">Ally Financial</th>
                    <th className="others-tr">
                      6.33<span>%</span>
                    </th>
                  </tr>
                </tbody>
              </Table>
            </center>
          </Col>
        </Row>
        <Row>
          {this.state.isChinese ? (
            <p className="table-privacy-issue">
              AllocateRite的回报数据基于美国国内综合基金-
              GIPS®2018年全年验证，于2019年6月30日完成。
              在Barron的19年7月26日基于静态60/40分配报告中表明，
              与其他竞争对手相比之下，AllocateRite拥有最佳的股票和固定收益的动态组合。
            </p>
          ) : (
            <p className="table-privacy-issue">
              AllocateRite returns are based on its flagship U.S. Domestic
              Composite fund. GIPS® verified through 2018. Performance through
              6/30/19. Competitor returns are reported in
              <span className="italic"> Barron’s</span>, 7/26/19 and are based
              on a static 60/40 allocation. In contrast, AllocateRite uses a
              dynamic mix of equities and fixed income.
            </p>
          )}
        </Row>
      </Container>
    );
  }
}

export default TopPerform;
