import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import ConceptOnHomePage from "../layout/ConceptOnHomePage";
import UniqueSellingProps from "../layout/UniqueSellingProps";
import MobileAppPromo from "../layout/MobileAppPromo";
import TopPerform from "../TopPerform";
import ChineseFooter from "../layout/ChineseFooter";
import ChineseFeatures from "../layout/ChineseFeatures";
import Header from "../layout/Header"


export class ChineseHome extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <div>
        <Container>
          <Row className="page-blank-row-md" />
          <ConceptOnHomePage isChinese={true} />
          <UniqueSellingProps isChinese={true} />
          <MobileAppPromo isChinese={true} />
          <TopPerform isChinese={true} />
          <ChineseFeatures />
          <Row className="page-blank-row" />
        </Container>
        <ChineseFooter />
      </div>
      </div>
    );
  }
}

export default ChineseHome;
