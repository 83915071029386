import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterPageTitle from "../layout/FooterPageTitle";
import Footer from "../Footer";
import Header from "../PublicHeader"
const brandColor = "rgb(70,182,179)"

export class Disclosures extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  dwLinks(){
      return(
          <div>
              <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://legal.drivewealth.com/disclosures-disclaimers' style={{color:brandColor, fontSize:20}}>DriveWealth Risk Disclosure</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://legal.drivewealth.com/bcp' style={{color:brandColor, fontSize:20}}>DriveWealth Business Continuity Plan</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://legal.drivewealth.com/form-client-relationship-summary' style={{color:brandColor, fontSize:20}}>DriveWealth Customer Relationship Summary</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://static1.squarespace.com/static/5d84d0802787ab07bdc5bc7e/t/5ef5ff44e5a2240b8bcae99d/1593179972919/Form+CRD+6-2020++DriveWealth.pdf' style={{color:brandColor, fontSize:20}}>Potential Conflicts of Interest</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://legal.drivewealth.com/customer-account-agreement' style={{color:brandColor, fontSize:20}}>DriveWealth Account Agreement</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://legal.drivewealth.com/terms-of-use' style={{color:brandColor, fontSize:20}}>DriveWealth Terms of Use</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://legal.drivewealth.com/funding-withdrawal-policies' style={{color:brandColor, fontSize:20}}>DriveWealth Funding and Withdrawal Policies</a>
                </div>
                {/* <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='' style={{color:brandColor, fontSize:20}}>DriveWealth Clearing Brochure</a>
                </div> */}
          </div>
      )
  }

  govtLinks(){
      return(
        <div>
            <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='http://www.finra.org/industry/customer-identification-program-notice' style={{color:brandColor, fontSize:20}}>FINRA Customer ID Program Notice</a>
                </div>
                
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://legal.drivewealth.com/sec-rule-606' style={{color:brandColor, fontSize:20}}>SEC Rule 606</a>
                </div>
        </div>
      )
  }

  fsLinks(){
      return(
          <div style={{paddingBottom: 20}}>
               <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://www.allocaterite.com/PrivacyPolicy' style={{color:brandColor, fontSize:20}}>ForeSCITE Privacy Policy</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://www.allocaterite.com/TermsofUse' style={{color:brandColor, fontSize:20}}>ForeSCITE Terms of Use</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://www.allocaterite.com/GeneralDisclaimer' style={{color:brandColor, fontSize:20}}>ForeSCITE General Disclaimer</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://www.allocaterite.com/EULA' style={{color:brandColor, fontSize:20}}>ForeSCITE EULA</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://www.allocaterite.com/Legal/ForeSCITE-2021-ADV-Part2A.pdf' style={{color:brandColor, fontSize:20}}>ForeSCITE ADV Part 2</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://www.allocaterite.com/Legal/ForeSCITE-2021-CRS.pdf' style={{color:brandColor, fontSize:20}}>ForeSCITE CRS</a>
                </div>
          </div>
      )
  }

  faq(){
      return(
            <div style={{paddingBottom: 20}}>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://www.allocaterite.com/PrivacyPolicy' style={{color:brandColor}}>What is ForeSCITE?</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://www.allocaterite.com/TermsofUse' style={{color:brandColor}}>Who is DriveWealth</a>
                </div>
                <div style={{paddingBottom: 10}}>
                    <a target="_blank" href='https://www.allocaterite.com/GeneralDisclaimer' style={{color:brandColor}}>My accounts and subscription </a>
                </div>
            </div>
      )
  }

  render() {
    //   console.log('small screen?', this.props.isSmallScreen)
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
        <div>
            <FooterPageTitle title="ForeSCITE Legal" />
            <div style={{width:'80%', margin:'auto',}}>
                <div style={{width:'50%'}}>
                    <h2 style={{textAlign:'center',marginTop:20,}}> Disclosures</h2>
                </div>
                <div style={{ display:'flex', alignItems:'flex-start', justifyContent:'space-between', width:"100%", marginTop:20,marginBottom:20}}>
                    <div style={{width:this.props.isSmallScreen?'90%':'30%'}}>
                        <h4>ForeSCITE</h4>
                        {this.fsLinks()}
                        <h4>Regulatory</h4>
                        {this.govtLinks()}
                        {this.props.isSmallScreen? <h4>DriveWealth</h4>:null}
                        {this.props.isSmallScreen? this.dwLinks():null}
                    </div>
                    {this.props.isSmallScreen?null:
                        <div style={{width:'30%'}}>
                            <h4>DriveWealth</h4>
                            {this.dwLinks()}
                        </div>
                    }
                    {this.props.isSmallScreen?null:
                         <div style={{width:'30%'}}>
                            {/* <h5>FAQ</h5>
                            {this.faq()} */}
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
      </div>
    );
  }
}

export default Disclosures;
